import React, { useState, useEffect } from "react";
import useDealer from "hooks/useDealer";
import DealerCatalog from "views/Dealers/Tabs/DealerCatalog";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SelectDealer from "components/SelectDealer/SelectDealer";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal";
import Notification from "components/Notification/Notification.js";
import CreateDealerCatalog, { DEFAULT_INITIAL_DATA } from "./CreateDealerCatalog";
import AreYouSure from "components/AreYouSure/AreYouSure";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// services
import DealerCatalogService from "services/DealerCatalogService";
import DeleteButton from "components/DeleteButton/DeleteButton";

export const CREATE_CATALOG = {
    title: "Create dealer catalog",
    successMessage: "Dealer catalog created successfully",
};

export const EDIT_CATALOG = {
    title: "Edit dealer catalog",
    successMessage: "Dealer catalog updated successfully",
};

export default function DealerCatalogsView() {
    const dealer = useDealer();
    const dlid = dealer.id;
    const [open, setOpen] = useState(false);
    const [catalogs, setCatalogs] = useState([]);
    const [form, setForm] = useState(EDIT_CATALOG);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState(DEFAULT_INITIAL_DATA);
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });

    useEffect(() => {
        const getCatalogs = async (dlid) => {
            const catalogs = await DealerCatalogService.getAll(dlid).then((x) => x.data);
            setCatalogs(catalogs);
        };

        if (dlid) getCatalogs(dlid);
    }, [dlid]);

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "catalogName",
        },
        {
            Header: "Display Name",
            accessor: "displayName",
        },
        {
            Header: "EN Display Name",
            accessor: "enDisplayName",
        },
        {
            Header: "ES Display Name",
            accessor: "esDisplayName",
        },
        {
            Header: "PT Display Name",
            accessor: "ptDisplayName",
        },
        {
            Header: "FR Display Name",
            accessor: "frDisplayName",
        },
        {
            Header: "Actions",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                onClick={() => {
                                    setForm(EDIT_CATALOG);
                                    setOpenModal(true);
                                    setInitialValues(original);
                                }}
                            >
                                <Edit />
                            </Button>
                            <DeleteButton
                                title="catalog"
                                handleClick={async () => {
                                    const id = original.id;
                                    const catalogId = original.catalogId;

                                    await DealerCatalogService.del(id, dlid, catalogId);
                                    setCatalogs(
                                        [...catalogs].filter((catalog) => catalog.id !== id)
                                    );
                                }}
                            />
                            {/*<Button
                                justIcon
                                round
                                simple
                                color="danger"
                                className="edit"
                                onClick={() => {
                                    setInitialValues(original);
                                    setOpen(true);
                                }}
                            >
                                <Close />
                            </Button>*/}
                        </div>
                    </>
                );
            },
        },
    ];

    const onSuccess = (catalog) => {
        if (form === CREATE_CATALOG) {
            setCatalogs([...catalogs, catalog]);
        }

        if (form === EDIT_CATALOG) {
            const nCatalogs = catalogs.map((c) => (c.id === catalog.id ? { ...c, ...catalog } : c));
            setCatalogs(nCatalogs);
        }

        setInitialValues({});
        setOpenModal(false);

        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                    onClose={() =>
                        setNotification({
                            ...notification,
                            open: false,
                        })
                    }
                />
            )}

            {openModal ? (
                <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                    <div style={{ minWidth: 1200 }}></div>
                    <CreateDealerCatalog
                        isEditing={initialValues.id}
                        initialValues={initialValues}
                        onSuccess={(catalog) => onSuccess(catalog)}
                    />
                </Modal>
            ) : null}

            <GridContainer>
                <GridItem xs={12}>
                    <SelectDealer />
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_CATALOG);
                                    setOpenModal(true);
                                    setInitialValues({});
                                }}
                            >
                                Create dealer catalog
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={columns}
                                data={catalogs}
                                showPagination={false}
                                showSorting={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <AreYouSure
                open={open}
                title={`Are you sure?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    const id = initialValues.id;
                    const catalogId = initialValues.catalogId;

                    await DealerCatalogService.del(id, dlid, catalogId);
                    setCatalogs([...catalogs].filter((catalog) => catalog.id !== initialValues.id));
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}
