import http from "./Http";

const getAll = async (id) => {
    const res = await http.get(`/themes?themeId=${id}`);
    return res.data;
};

const put = async (body) => {
    const res = await http.put(`/themes`, body);
    return res.data;
};

const ThemeService = {
    getAll,
    put
};

export default ThemeService;