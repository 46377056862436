import { useFormik } from 'formik';
import * as yup from 'yup';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// const
import { CREATE_CATALOG, EDIT_CATALOG } from './Catalogs';

//services
import CatalogService from '../../services/CatalogService';

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Catalog name is required'),
    displayName: yup
        .string()
        .required('Display name is required'),
    enDisplayName: yup
        .string()
        .required('EN display name is required'),
    esDisplayName: yup
        .string()
        .required('ES display name is required'),
    ptDisplayName: yup
        .string()
        .required('PT display name is required'),
    frDisplayName: yup
        .string()
        .required('FR display name is required'),
    enSlug: yup
        .string()
        .required('EN slug name is required'),
    esSlug: yup
        .string()
        .required('ES slug name is required'),
    ptSlug: yup
        .string()
        .required('PT slug name is required'),
    frSlug: yup
        .string()
        .required('FR slug name is required'),
    memo: yup
        .string()
        .required('Memo is required'),
    createdAt: yup.date().nullable()
});

const useStyles = makeStyles({
    updateProfileButton: {
        float: "right",
    },
});

export default function CreateCatalog({ form = CREATE_CATALOG, initialValues, onSuccess }) {
    const classes = useStyles();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            name: initialValues.name || '',
            displayName: initialValues.displayName || '',
            enDisplayName: initialValues.enDisplayName || '',
            esDisplayName: initialValues.esDisplayName || '',
            ptDisplayName: initialValues.ptDisplayName || '',
            frDisplayName: initialValues.frDisplayName || '',
            enSlug: initialValues.enSlug || '',
            esSlug: initialValues.esSlug || '',
            ptSlug: initialValues.ptSlug || '',
            frSlug: initialValues.frSlug || '',
            memo: initialValues.memo || '',
            createdAt: initialValues.createdAt || null
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_CATALOG) createCatalog(values);
            if (form === EDIT_CATALOG) updateCatalog(values);
        },
    });

    const createCatalog = async body => {
        try {
            const catalog = await CatalogService.post(body);
            onSuccess(catalog.data);
        } catch (error) {
            console.error(error);
        }
    }

    const updateCatalog = async body => {
        try {
            const catalog = await CatalogService.put(body);
            onSuccess(catalog.data);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="name"
                                name="name"
                                label="Catalog name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="displayName"
                                name="displayName"
                                label="Display name"
                                value={formik.values.displayName}
                                onChange={formik.handleChange}
                                error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                                helperText={formik.touched.displayName && formik.errors.displayName}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="enDisplayName"
                                name="enDisplayName"
                                label="EN display name"
                                value={formik.values.enDisplayName}
                                onChange={formik.handleChange}
                                error={formik.touched.enDisplayName && Boolean(formik.errors.enDisplayName)}
                                helperText={formik.touched.enDisplayName && formik.errors.enDisplayName}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="esDisplayName"
                                name="esDisplayName"
                                label="ES display name"
                                value={formik.values.esDisplayName}
                                onChange={formik.handleChange}
                                error={formik.touched.esDisplayName && Boolean(formik.errors.esDisplayName)}
                                helperText={formik.touched.esDisplayName && formik.errors.esDisplayName}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="ptDisplayName"
                                name="ptDisplayName"
                                label="PT display name"
                                value={formik.values.ptDisplayName}
                                onChange={formik.handleChange}
                                error={formik.touched.ptDisplayName && Boolean(formik.errors.ptDisplayName)}
                                helperText={formik.touched.ptDisplayName && formik.errors.ptDisplayName}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="frDisplayName"
                                name="frDisplayName"
                                label="FR display name"
                                value={formik.values.frDisplayName}
                                onChange={formik.handleChange}
                                error={formik.touched.frDisplayName && Boolean(formik.errors.frDisplayName)}
                                helperText={formik.touched.frDisplayName && formik.errors.frDisplayName}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="enSlug"
                                name="enSlug"
                                label="EN slug"
                                value={formik.values.enSlug}
                                onChange={formik.handleChange}
                                error={formik.touched.enSlug && Boolean(formik.errors.enSlug)}
                                helperText={formik.touched.enSlug && formik.errors.enSlug}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="esSlug"
                                name="esSlug"
                                label="ES slug"
                                value={formik.values.esSlug}
                                onChange={formik.handleChange}
                                error={formik.touched.esSlug && Boolean(formik.errors.esSlug)}
                                helperText={formik.touched.esSlug && formik.errors.esSlug}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="ptSlug"
                                name="ptSlug"
                                label="PT slug"
                                value={formik.values.ptSlug}
                                onChange={formik.handleChange}
                                error={formik.touched.ptSlug && Boolean(formik.errors.ptSlug)}
                                helperText={formik.touched.ptSlug && formik.errors.ptSlug}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="frSlug"
                                name="frSlug"
                                label="PT slug"
                                value={formik.values.frSlug}
                                onChange={formik.handleChange}
                                error={formik.touched.frSlug && Boolean(formik.errors.frSlug)}
                                helperText={formik.touched.frSlug && formik.errors.frSlug}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="memo"
                                name="memo"
                                label="Memo"
                                value={formik.values.memo}
                                onChange={formik.handleChange}
                                error={formik.touched.memo && Boolean(formik.errors.memo)}
                                helperText={formik.touched.memo && formik.errors.memo}
                            />
                        </GridItem>
                    </GridContainer>
                    <Button type="submit" color="rose" className={classes.updateProfileButton}>Save</Button>
                </form>
            </GridItem>
        </GridContainer>
    );
}