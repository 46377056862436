import http from "./Http";

const getAll = async (dlid = "") => {
    const { data } = await http.get("/roles?dlid=" + dlid);
    return data;
};

const get = async (id) => {
    const { data } = await http.get(`/roles/${id}`);
    return data;
};

const post = async (body) => {
    const { data } = await http.post("/roles", body);
    return data;
};

const put = async (body) => {
    const { data } = await http.put(`/roles/${body.id}`, body);
    return data;
};

const del = async (id) => {
    return http.delete(`/roles/${id}`);
};

const RoleService = {
    getAll,
    get,
    post,
    put,
    del,
};

export default RoleService;
