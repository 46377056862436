import React, { useState } from 'react';
import {
    Switch,
    FormControlLabel,
    Button,
    Popover,
    TextField,
    makeStyles,
} from '@material-ui/core';

import OrderService from 'services/OrderService';

const useStyles = makeStyles((theme) => ({
    popoverContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    passwordField: {
        marginBottom: theme.spacing(1),
    },
}));

const OrderLockSwitch = ({ orderId, isLocked = false }) => {
    const [locked, setLocked] = useState(isLocked);
    const [originalLocked, setOriginalLocked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const classes = useStyles();

    const handleLockChange = (event) => {
        setOriginalLocked(locked);
        setLocked(event.target.checked);
        setAnchorEl(event.currentTarget);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError(false);
    };

    const handleApply = async () => {
        if (password === '12345') {
            let isLocked;
            if (locked)
                isLocked = await OrderService.lockOrder(orderId);
            else
                isLocked = await OrderService.unlockOrder(orderId);

            if (locked !== isLocked.isLocked) setLocked(isLocked.isLocked);
            setAnchorEl(null);
        } else {
            setPasswordError(true);
        }
    };

    const handleCancel = () => {
        setLocked(originalLocked);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'order-lock-popover' : undefined;

    return (
        <>
            <FormControlLabel
                control={<Switch checked={locked} onChange={handleLockChange} />}
                label={locked ? 'Order blocked' : 'Unlocked order'}
            />
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => handleCancel()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.popoverContent}>
                    <TextField
                        className={classes.passwordField}
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        error={passwordError}
                        helperText={passwordError && 'Incorrect password'}
                    />
                    <Button onClick={handleApply} variant="contained" color="primary">
                        Aplicar
                    </Button>
                </div>
            </Popover>
        </>
    );
};

export default OrderLockSwitch;
