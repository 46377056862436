import http from "./Http";

const getAll = async ({ startRow = 0, pageSize = 10, dealerId = "" }) => {
    const res = await http.get(
        `/image-library?startRow=${startRow}&pageSize=${pageSize}&dealerId=${dealerId}`
    );
    return res.data;
};

const get = async (id) => {
    const res = await http.get(`/image-library/${id}`);
    return res.data;
};

const del = async (id) => {
    return http.delete(`/image-library/${id}`);
};

const post = async (image) => {
    const res = await http.post(`/image-library`, image);
    return res.data;
};

const put = async (id, image) => {
    const res = await http.put(`/image-library/${id}`, image);
    return res.data;
};

const ImageLibraryService = {
    getAll,
    get,
    del,
    post,
    put,
};

export default ImageLibraryService;
