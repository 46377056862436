import { useEffect, useState } from "react";
import { useFormik } from "formik";
import RmaService from "services/RmaService";
import * as yup from "yup";
import useRmaCommunication from "./useRmaCommunication";
import useOrderChanges from "./useOrderChanges";
import useCrud from "./useCrud";
import useDealer from "./useDealer";
import useUser from "./useUser";
import useEmail from "./useEmail";
import { financial } from "helpers";

const useRmaForm = (rmaId, rmaOrderNumber) => {
    const [rma, setRma] = useState({});
    const [customerCommunication, setCustomerCommunication] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    const [initialValues, setInitialValues] = useState({
        isBusinessAcc: false,
        billCompany: "",
        billContact: "",
        billAddress1: "",
        billAddress2: "",
        billCountry: "",
        billState: "",
        billCity: "",
        billZip: "",
        billPhone: "",
        billEmail: "",
        shipCountry: "",
        shipState: "",
    });
    const rmaCommunication = useRmaCommunication();
    const { sendOrderChangeNotification } = useOrderChanges();
    const { updateEntity } = useCrud("rma", {}, false);
    const { createEntity, readAllEntities } = useCrud("dealer_communications", {}, false);
    const dealer = useDealer();
    const user = useUser();
    const { sendEmailAsync } = useEmail();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: yup.object({}),
        onSubmit: async (values) => {},
    });

    useEffect(() => {
        const fetchCommunications = async (dealerId, orderNumber, rmaNumber) => {
            const params = {
                dealer_id: dealerId,
                order_number: orderNumber,
                rma_number: rmaNumber,
            };

            const comm = await readAllEntities(params);
            setCustomerCommunication(comm);
        };

        const fetchRma = async (rmaId) => {
            const res = await RmaService.get(rmaId);
            setInitialValues(res.order);

            const orderDetailParsed = res.orderDetail.map((o) => ({
                ...o,
                salesTaxAmount: res.rma.salesTaxAmount,
            }));

            setOrderDetail(orderDetailParsed);

            rmaCommunication.setRmaStatus(res.rma.rmaDealerStatus);

            if (res.order.dealerId && res.order.orderNumber && res.rma.rmaNumber) {
                fetchCommunications(res.order.dealerId, res.order.orderNumber, res.rma.rmaNumber);
            }

            setRma(res.rma);
        };

        fetchRma(rmaId);

        return () => {};
    }, [rmaId]);

    const handleClickSubmit = async (closeDialog) => {
        if (rmaCommunication.rmaStatus !== rma.rmaDealerStatus) {
            if (rmaCommunication.doNotsendNotification === false) {
                /*await sendOrderChangeNotification({
                    orderNumber: initialValues.orderNumber,
                    billCompany: initialValues.billCompany,
                    billEmail: initialValues.billEmail,
                });*/

                await insertNotification(
                    rmaId,
                    rmaOrderNumber,
                    rmaCommunication.message,
                    rma.type,
                    rma
                );
            }

            const customerData = {
                id: rmaId,
                rma_dealer_status: rmaCommunication.rmaStatus,
                rma_dealer_user: user?.completeName || "",
            };

            await updateEntity(customerData, () => {}, false);
            closeDialog();
        } else {
            closeDialog();
        }
    };

    const insertNotification = async (
        rmaId = "",
        rmaOrderNumber = "",
        message = "",
        rmaType = "",
        rma
    ) => {
        message = message.replace(/{{orderNumber}}/g, rma.orderNumber);
        message = message.replace(/{{customerName}}/g, initialValues.billCompany);
        message = message.replace(/{{refundAmount}}/g, financial(rma.price));

        try {
            let subject = `${rmaType} Request Update for Order ${rmaOrderNumber}`;
            let body = message.replace(/\n/g, "<br>");

            const communicationEntity = {
                dealer_id: initialValues.dealerId,
                order_number: initialValues.orderNumber,
                from_name: dealer.company,
                from_mail: dealer.notificationEmail,
                to_name: initialValues.billCompany,
                to_mail: initialValues.billEmail,
                subject: subject,
                message: body,
                folder_id: 1,
                rma_id: rmaId,
                rma_number: rma.rmaNumber,
            };

            await createEntity(communicationEntity, () => {}, false);

            if (message.length > 0) {
                await sendEmailAsync(initialValues.billEmail, "", subject, body, "");
            }
        } catch (error) {
            console.error("Error registering notification", error);
        }
    };

    return {
        formik,
        orderDetail,
        rmaCommunication,
        handleClickSubmit,
        customerCommunication,
        rma,
    };
};

export default useRmaForm;
