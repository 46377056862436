import React, { useState, useEffect } from "react";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.js";
import Notification from "components/Notification/Notification.js";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

import DealerMakeService from '../../../services/DealerMakeService';

export default function MakeYears({
    defYears = [],
    dlid,
    makeId,
    catalogName,
    onSave = () => { }
}) {
    const classes = useStyles();
    const [years, setYears] = useState([]);

    useEffect(() => {
        const getYears = async (dlid, makeId, catalogName) => {
            const years = await DealerMakeService.getYears(dlid, makeId, catalogName);
            setYears(
                years.map(y => ({ ...y, isChecked: defYears.includes(y.name) }))
            )
        };

        if (dlid && makeId && catalogName) getYears(dlid, makeId, catalogName);
    }, [dlid, makeId, defYears, catalogName]);

    const handleClickCheckbox = (e, makeId, year) => {
        const _years = years.map(y => y.name === year.name ? { ...y, isChecked: !y.isChecked } : y);
        setYears(_years);
        onSave(makeId, _years);
    }

    const handleClickToogle = (makeId, selectAll = true) => {
        const _years = years.map(y => ({ ...y, isChecked: selectAll }));
        setYears(_years)
        onSave(makeId, _years);
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ display: 'flex' }}>
                        <Link
                            href="javascript: void(0)"
                            onClick={() => handleClickToogle(makeId, true)}
                            style={{ fontSize: 14, marginLeft: 5, marginRight: 5 }}
                        >
                            Select all
                        </Link>
                        <Link
                            href="javascript: void(0)"
                            onClick={() => handleClickToogle(makeId, false)}
                            style={{ fontSize: 14, marginLeft: 5, marginRight: 5 }}
                        >
                            Unselect all
                        </Link>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                {
                    years.map((year, i) => (
                        <GridItem
                            key={i}
                            xs={12}
                            sm={12}
                            md={3}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={false}
                                                tabIndex={-1}
                                                checked={year.isChecked}
                                                onClick={(e) => {
                                                    handleClickCheckbox(e, makeId, year);
                                                }}
                                                checkedIcon={
                                                    <Check className={classes.checkedIcon} />
                                                }
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                classes={{
                                                    checked: classes.checked,
                                                    root: classes.checkRoot,
                                                }}
                                            />
                                        }
                                        label={year.name}
                                    />
                                </div>
                            </div>
                        </GridItem>
                    ))
                }
            </GridContainer>
        </>
    )
}