import { withStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const StyledFormControlLabel = withStyles((theme) => ({
    root: {
        "& .MuiTypography-root": {
            color: "black",
            fontSize: 14,
        },
    },
}))(FormControlLabel);

export default function CheckboxMUI(props) {
    const {
        checked = false,
        onChange = () => {},
        color = "primary",
        label = "",
        ...otherProps
    } = props;

    return (
        <StyledFormControlLabel
            {...otherProps}
            control={<Checkbox checked={checked} onChange={onChange} color={color} />}
            label={label}
        />
    );
}
