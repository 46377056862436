import { useState } from "react";
import { Box, IconButton, Tooltip, Paper, Button } from "@material-ui/core";
import DialpadIcon from "@material-ui/icons/Dialpad";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ShippingCarrierCalculator from "components/ShippingCarrierCalculator/ShippingCarrierCalculator";

export default function ToggleCalculator({ formik }) {
    const [show, setShow] = useState(true);

    if (!show)
        return (
            <Box flex={0.25}>
                <Paper
                    style={{
                        marginLeft: 10,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Tooltip title="Open shipping cost calculator">
                        <IconButton onClick={() => setShow(!show)}>
                            <DialpadIcon />
                        </IconButton>
                    </Tooltip>
                </Paper>
            </Box>
        );

    return (
        <Box flex={2} style={{ marginLeft: 10 }}>
            <Paper elevation={2} style={{ height: "100%", padding: 10 }}>
                <ShippingCarrierCalculator order={formik.values} />
                <br />
                <Button
                    fullWidth
                    style={{ backgroundColor: "#0976b4", color: "#fff" }}
                    variant="contained"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => setShow(!show)}
                >
                    Hide section
                </Button>
            </Paper>
        </Box>
    );
}
