import React from "react";
import PageLayout from "layouts/PageLayout";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";
import TextField from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";

import { Card, CardContent, Link, FormControlLabel, Switch, Button as ButtonMUI } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import useStorePolicies from "hooks/useStorePolicies";

export default function StorePolicies() {
    const {
        formik,
        handlePolicyNameChange,
        handleRestoreDefaultPolicy,
        handlePolicyEnableChange,
        handlePolicyDescriptionChange,
        handleAddPolicy,
        confirmPolicyDeletion
    } = useStorePolicies();

    return (
        <PageLayout
            customComponent={
                <Button
                    color="success"
                    variant="contained"
                    onClick={handleAddPolicy}
                >
                    add new policy
                </Button>
            }
        >
            <form onSubmit={formik.handleSubmit}>
                <GridContainer>
                    {formik.values.policies.map((policy, index) => (
                        <Card key={index} style={{ margin: 20, marginTop: 0, width: '100%' }}>
                            <CardContent>
                                <GridItem xs={12} sm={12} md={12}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        label={`Policy #${index + 1}`}
                                        value={policy.name}
                                        handleBlur={(value) => handlePolicyNameChange(index, value)}
                                    />
                                    <Link href="#" onClick={(e) => handleRestoreDefaultPolicy(e, index)}>
                                        Restore default policy
                                    </Link>
                                    <br />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={policy.isEnable}
                                                onChange={(e) => {
                                                    handlePolicyEnableChange(index, e.target.checked)
                                                }}
                                            />}
                                        label="Enable?"
                                    />
                                    <HtmlEditor
                                        title=""
                                        data={policy.description}
                                        onBlur={(value) => { handlePolicyDescriptionChange(index, value) }}
                                    />
                                    <ButtonMUI
                                        variant="outlined"
                                        color="secondary"
                                        endIcon={<DeleteIcon />}
                                        style={{ float: 'right', marginTop: 10, marginBottom: 10 }}
                                        onClick={() => confirmPolicyDeletion(policy.id, index)}
                                    >
                                        Delete policy
                                    </ButtonMUI>
                                </GridItem>
                            </CardContent>
                        </Card>
                    ))}
                </GridContainer>

                <div style={{ textAlign: 'center', marginTop: 10 }}>
                    <Button type="submit" color="rose">
                        Update store policies
                    </Button>
                </div>
            </form>
        </PageLayout>
    );
}
