import React, { useState, useEffect, useRef } from "react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import InputIcon from "@material-ui/icons/Input";

// core components
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Notification from "components/Notification/Notification.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import Modal from "components/Modal/Modal.js";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useUser from "hooks/useUser";

//libs
import memoize from "fast-memoize";

//service
import QuoteService from "../../services/QuoteService";
import CustomerService from "../../services/CustomerService";
import DealerService from "../../services/DealerService";
import EditQuote from "components/EditQuote/EditQuote";
import { formatDate } from "helpers";
import DeleteButton from "components/DeleteButton/DeleteButton";

export const CREATE_QUOTE = {
    title: "Create customer quote",
    successMessage: "Quote created successfully",
};

export const EDIT_QUOTE = {
    title: "Edit customer quote",
    successMessage: "Quote updated successfully",
};

export const CONVERT_QUOTE_TO_ORDER = {
    title: "Convert quote to order",
    successMessage: "Order created successfully",
};

export default function CustomerQuotes() {
    const [user] = useUser();
    const [dlid, setDlid] = useState(0);
    const [cid, setCid] = useState(0);
    const [dealers, setDealers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [status, setStatus] = useState([]);
    const [search, setSearch] = useState("");
    const [orders, setOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const [notification, setNotification] = useState({
        open: false,
        message: CREATE_QUOTE.successMessage,
    });
    const [modal, setModal] = useState({
        open: false,
        title: CREATE_QUOTE.title,
    });
    const [initialValues, setInitialValues] = useState({});
    const [form, setForm] = useState(CREATE_QUOTE);

    useEffect(() => {
        const getDealers = async (dlid) => {
            const dealers = await DealerService.getAll(dlid).then((o) => o.data);
            if (dealers.length === 1) setDlid(dealers[0]?.id);
            setDealers(dealers);
        };
        if (user?.isAdmin === 0) {
            getDealers(user?.dealerId);
        } else {
            getDealers("");
        }
    }, [dlid, user?.dealerId]);

    useEffect(() => {
        const getCustomers = async (dlid) => {
            const customers = await CustomerService.getAll(dlid, "", 0, 10000000n).then(
                (o) => o.data
            );
            setCustomers(customers.data);
        };
        getCustomers(dlid);
    }, [dlid]);

    const getQuote = async (id) => {
        const quote = await QuoteService.get(id);
        return quote;
    };

    const columns = [
        {
            Header: "Quote number",
            accessor: "quoteNumber",
        },
        {
            Header: "Name",
            accessor: "billCompany",
        },
        {
            Header: "Total",
            Cell: ({
                row: {
                    original: { quoteAmount },
                },
            }) => {
                return `$${quoteAmount}`;
            },
        },
        {
            Header: "Sent To",
            accessor: "shipCompany",
        },
        {
            Header: "Date",
            Cell: ({
                row: {
                    original: { createdAt },
                },
            }) => {
                return `${formatDate(createdAt)}`;
            },
        },
        {
            Header: () => <div style={{ textAlign: "center" }}>Payment</div>,
            accessor: "payment",
            Cell: ({
                row: {
                    original: { payment },
                },
            }) => {
                return <div style={{ textAlign: "center" }}>{payment}</div>;
            },
        },
        {
            Header: () => <div style={{ textAlign: "center" }}></div>,
            accessor: "actions",
            Cell: ({ row: { original } }) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            title="Edit order products"
                            onClick={async () => {
                                setForm(EDIT_QUOTE);
                                const quote = await getQuote(original.id);
                                setInitialValues(quote);
                                setModal({
                                    ...modal,
                                    open: !modal.open,
                                });
                            }}
                        >
                            <EditIcon />
                        </Button>
                        <DeleteButton
                            title="customer quote"
                            onClick={async () => {
                                await QuoteService.del(original.id);
                                setOrders([...orders].filter((c) => c.id !== original.id));
                            }}
                        />
                        {/*<Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            title="Delete"
                            onClick={() => {
                                setInitialValues(original);
                                setOpen(true);
                            }}
                        >
                            <Close />
                        </Button>*/}
                        <Button
                            justIcon
                            round
                            simple
                            color="info"
                            className="edit"
                            title="Convert quote to order"
                            onClick={async () => {
                                setForm(CONVERT_QUOTE_TO_ORDER);
                                const quote = await getQuote(original.id);
                                setInitialValues(quote);
                                setModal({
                                    ...modal,
                                    open: !modal.open,
                                });
                            }}
                        >
                            <InputIcon />
                        </Button>
                    </div>
                );
            },
        },
    ];

    const fetchData = React.useCallback(
        memoize(
            (fromDate, toDate, statusId, search, cid, dlid) => async ({ pageSize, pageIndex }) => {
                const fetchId = ++fetchIdRef.current;

                if (fetchId === fetchIdRef.current) {
                    const startRow = pageSize * pageIndex;

                    const orders = await QuoteService.getAll(
                        startRow,
                        pageSize,
                        fromDate ? formatDate(fromDate) : "",
                        toDate ? formatDate(toDate) : "",
                        statusId,
                        search,
                        cid,
                        dlid
                    ).then((x) => x.data);
                    setOrders(orders?.data || []);
                    setPageCount(Math.ceil(orders.recordsTotal / pageSize));
                }
            }
        ),
        []
    );

    const onSuccess = async (quote) => {
        if (form === CREATE_QUOTE) {
            setOrders([quote, ...orders]);
        }

        if (form === EDIT_QUOTE) {
            const nOrders = orders.map((c) => (c.id === quote.id ? { ...c, ...quote } : c));
            setOrders(nOrders);
        }

        if (form === CONVERT_QUOTE_TO_ORDER) {
            await QuoteService.del(initialValues.id);
            setOrders([...orders].filter((c) => c.id !== quote.id));
        }

        setInitialValues({});
        setModal({ ...modal, open: !modal.open });
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={``}
                onAccept={async () => {
                    await QuoteService.del(initialValues.id);
                    setOrders([...orders].filter((c) => c.id !== initialValues.id));
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />

            {notification.open && (
                <Notification
                    open={notification.open}
                    message={notification.message}
                    close
                    color="success"
                    onClose={(e) => setNotification({ ...notification, open: false })}
                />
            )}

            <Modal
                open={modal.open}
                modalTitle={form.title}
                handleClose={() => {
                    setModal({ ...modal, open: false });
                }}
            >
                {modal.open ? (
                    <>
                        <div style={{ minWidth: 1200 }}></div>
                        <EditQuote form={form} quote={initialValues} onSuccess={onSuccess} />
                    </>
                ) : null}
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="primary"
                            icon
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_QUOTE);
                                    setInitialValues({});
                                    setModal({ ...modal, open: true });
                                }}
                            >
                                Create quote
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <div style={{ marginBottom: 10 }}>
                                <GridContainer>
                                    {user?.isAdmin === 1 && (
                                        <GridItem xs={12} sm={12} md={2}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                    Dealer
                                                </InputLabel>
                                                <Select
                                                    id="dealerId"
                                                    name="dealerId"
                                                    value={dlid}
                                                    onChange={(e) => {
                                                        setDlid(e.target.value);
                                                        setCid(0);
                                                    }}
                                                    fullWidth
                                                >
                                                    {dealers.length > 1 && (
                                                        <MenuItem key={0} value={0}>
                                                            All dealers
                                                        </MenuItem>
                                                    )}
                                                    {dealers.map((dealer, index) => (
                                                        <MenuItem key={index} value={dealer.id}>
                                                            {dealer.id}. {dealer.company}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    )}
                                    <GridItem xs={12} sm={12} md={2}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Customers
                                            </InputLabel>
                                            <Select
                                                id="dealerId"
                                                name="dealerId"
                                                value={cid}
                                                onChange={(e) => {
                                                    setCid(e.target.value);
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem key={0} value={0}>
                                                    Select customer
                                                </MenuItem>

                                                {customers.map((customer, index) => (
                                                    <MenuItem key={index} value={customer.id}>
                                                        {customer.billCompany}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                id="date-picker-dialog"
                                                label="From date"
                                                format="MM/dd/yyyy"
                                                value={fromDate}
                                                onChange={(value) => setFromDate(value)}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                fullWidth
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                id="date-picker-dialog"
                                                label="To date"
                                                format="MM/dd/yyyy"
                                                value={toDate}
                                                onChange={(value) => setToDate(value)}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                fullWidth
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <TextField
                                            onBlur={(e) => setSearch(e.target.value)}
                                            label="Search String"
                                            helperText="[Name, Order# etc]"
                                            fullWidth
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {
                                                console.log(fromDate, toDate, status, search);
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </div>

                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    <ReactTable
                                        columns={columns}
                                        data={orders}
                                        fetchData={fetchData(
                                            fromDate,
                                            toDate,
                                            "",
                                            search,
                                            cid,
                                            dlid
                                        )}
                                        pageCount={pageCount}
                                        manualPagination={true}
                                        showSorting={false}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
