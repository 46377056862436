import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CatalogMakes from "./CatalogMakes";

// const
import { EDIT_CATALOG } from './DealerCatalog';

//services
import PageTechService from '../../../services/DealerPageTechService';
import PageService from '../../../services/PageService';

const validationSchema = yup.object({
    pagetechPosition: yup.string().required(),
    enTitle: yup.string().required(),
    esTitle: yup.string().required(),
    ptTitle: yup.string().required(),
    frTitle: yup.string().required()
});

const useStyles = makeStyles({
    updateProfileButton: {
        float: "right",
    },
});

export default function CreateCatalog({ dlid = '', form = CREATE_PAGE_TECH, initialValues, onSuccess = () => { } }) {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            dealerCatalogId: initialValues.dealerCatalogId || 0,
            id: initialValues.id || 0,
            displayName: initialValues.displayName || '',
            displayName2: initialValues.displayName2 || '',
            enDisplayName: initialValues.enDisplayName || '',
            enDisplayName2: initialValues.enDisplayName2 || '',
            esDisplayName: initialValues.esDisplayName || '',
            ptDisplayName: initialValues.ptDisplayName || '',
            frDisplayName: initialValues.frDisplayName || '',
            memo: initialValues.memo || '',
            createdAt: initialValues.createdAt || '',
            updatedAt: initialValues.updatedAt || ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

        },
    });

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="displayName2"
                                name="displayName2"
                                label="Display Name"
                                value={formik.values.displayName2}
                                onChange={formik.handleChange}
                                error={formik.touched.displayName2 && Boolean(formik.errors.displayName2)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="enDisplayName2"
                                name="enDisplayName2"
                                label="EN Display Name"
                                value={formik.values.enDisplayName2}
                                onChange={formik.handleChange}
                                error={formik.touched.enDisplayName2 && Boolean(formik.errors.enDisplayName2)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="esDisplayName"
                                name="esDisplayName"
                                label="ES Display Name"
                                value={formik.values.esDisplayName}
                                onChange={formik.handleChange}
                                error={formik.touched.esDisplayName && Boolean(formik.errors.esDisplayName)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="ptDisplayName"
                                name="ptDisplayName"
                                label="PT Display Name"
                                value={formik.values.ptDisplayName}
                                onChange={formik.handleChange}
                                error={formik.touched.ptDisplayName && Boolean(formik.errors.ptDisplayName)}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="frDisplayName"
                                name="frDisplayName"
                                label="EN Display Name"
                                value={formik.values.frDisplayName}
                                onChange={formik.handleChange}
                                error={formik.touched.frDisplayName && Boolean(formik.errors.frDisplayName)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="memo"
                                name="memo"
                                label="Memo"
                                value={formik.values.memo}
                                onChange={formik.memo}
                                error={formik.touched.memo && Boolean(formik.errors.memo)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="createdAt"
                                name="createdAt"
                                label="created at"
                                value={formik.values.createdAt}
                                onChange={formik.handleChange}
                                error={formik.touched.createdAt && Boolean(formik.errors.createdAt)}
                                InputProps={{ readOnly: true, disableUnderline: true }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="updatedAt"
                                name="updatedAt"
                                label="Updated at"
                                value={formik.values.updatedAt}
                                onChange={formik.handleChange}
                                error={formik.touched.updatedAt && Boolean(formik.errors.updatedAt)}
                                InputProps={{ readOnly: true, disableUnderline: true }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <CatalogMakes
                                dlid={dlid}
                                catalogId={initialValues.id}
                                catalogName={initialValues.name}
                                onSuccess={() => onSuccess(formik.values)}
                            />
                        </GridItem>
                    </GridContainer>
                </form>
            </GridItem>
        </GridContainer>
    )
}
/*export default function CreateCatalog({ dlid = '', form = CREATE_PAGE_TECH, initialValues, onSuccess = () => { } }) {
    const [pages, setPages] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const getPages = async () => {
            const pages = await PageService.getAll();
            setPages(pages)
        };
        getPages();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            pagetechPosition: initialValues.pagetechPosition || '',
            pageId: initialValues.pageId || 0,
            pageName: initialValues.pageName || '',
            enTitle: initialValues.enTitle || '',
            esTitle: initialValues.esTitle || '',
            ptTitle: initialValues.ptTitle || '',
            frTitle: initialValues.frTitle || '',
            enHtml: initialValues.enHtml || '',
            esHtml: initialValues.esHtml || '',
            ptHtml: initialValues.ptHtml || '',
            frHtml: initialValues.frHtml || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_PAGE_TECH) createPageTech({ ...values, dealerId: dlid });
            if (form === EDIT_PAGE_TECH) updatePageTech({ ...values, dealerId: dlid });
        },
    });

    const createPageTech = async body => {
        try {
            const techPage = await PageTechService.post(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    }

    const updatePageTech = async body => {
        try {
            const techPage = await PageTechService.put(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                type="number"
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="pagetechPosition"
                                name="pagetechPosition"
                                label="Page tech position"
                                value={formik.values.pagetechPosition}
                                onChange={formik.handleChange}
                                error={formik.touched.pagetechPosition && Boolean(formik.errors.pagetechPosition)}
                                inputProps={{
                                    max: 10
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                style={{ marginTop: 5 }}>
                                <InputLabel id="demo-simple-select-label">Page</InputLabel>
                                <Select
                                    id="pageId"
                                    name="pageId"
                                    value={formik.values.pageId}
                                    onChange={(e) => {
                                        formik.setFieldValue("pageId", e.target.value);
                                        formik.setFieldValue(
                                            "pageName",
                                            pages.find(p => p.id === e.target.value)?.pageName || ''
                                        );
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>Select page</MenuItem>
                                    {
                                        pages.map(({ id, pageName }, _) => <MenuItem key={id} value={id}>{pageName}</MenuItem>)
                                    }

                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="enTitle"
                                name="enTitle"
                                label="English title"
                                value={formik.values.enTitle}
                                onChange={formik.handleChange}
                                error={formik.touched.enTitle && Boolean(formik.errors.enTitle)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <HtmlEditor
                                title="English HTML"
                                data={formik.values.enHtml}
                                onBlur={(value) => formik.setFieldValue("enHtml", value)} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="esTitle"
                                name="esTitle"
                                label="Spanish title"
                                value={formik.values.esTitle}
                                onChange={formik.handleChange}
                                error={formik.touched.esTitle && Boolean(formik.errors.esTitle)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <HtmlEditor
                                title="Spanish HTML"
                                data={formik.values.esHtml}
                                onBlur={(value) => formik.setFieldValue("esHtml", value)} />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="ptTitle"
                                name="ptTitle"
                                label="Portuguese title"
                                value={formik.values.ptTitle}
                                onChange={formik.handleChange}
                                error={formik.touched.ptTitle && Boolean(formik.errors.ptTitle)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <HtmlEditor
                                title="Portuguese HTML"
                                data={formik.values.ptHtml}
                                onBlur={(value) => formik.setFieldValue("ptHtml", value)} />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="frTitle"
                                name="frTitle"
                                label="French title"
                                value={formik.values.frTitle}
                                onChange={formik.handleChange}
                                error={formik.touched.frTitle && Boolean(formik.errors.frTitle)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <HtmlEditor
                                title="French HTML"
                                data={formik.values.frHtml}
                                onBlur={(value) => formik.setFieldValue("frHtml", value)} />
                        </GridItem>
                    </GridContainer>

                    <div style={{ textAlign: 'center', margin: 10 }}>
                        <Button type="submit" color="rose">Save and close</Button>
                    </div>
                </form>
            </GridItem>
        </GridContainer>
    );
}*/