import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import VehicleService from 'services/VehicleService';

export default function TrimSelector(props) {
    const {
        dlid,
        makeId,
        selectedYear,
        selectedModel,
        selectedTrim,
        handleChange
    } = props;
    const [trims, setTrims] = useState([]);

    useEffect(() => {
        if (dlid && makeId && selectedYear && selectedModel)
            loadTrims(dlid, makeId, selectedYear, selectedModel);
    }, [selectedYear, selectedModel]);

    const loadTrims = async (dlid, makeId, selectedYear, selectedModel) => {
        try {
            const trims = await VehicleService.getTrims({
                dlid,
                makeId,
                catalog: 'isuzu',
                yearId: selectedYear,
                modelId: selectedModel
            });
            setTrims(trims);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <TextField
            select
            label="Trim"
            value={selectedTrim}
            onChange={handleChange}
            variant="outlined"
            fullWidth
        >
            {trims.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};