import { useEffect, useState } from 'react';
import RequestService from 'services/RequestService';
import usePagination from './usePagination';

const useRequest = (open = 1) => {
    const [request, setRequest] = useState({
        totalRecords: 0,
        requests: [],
    });
    const { pagination, changePagination, pageCount } = usePagination(1, 10, request.totalRecords);

    const fetchRequest = async (page, pageSize) => {
        try {
            const response = await RequestService.getAll(
                open,
                page,
                pageSize
            );
            setRequest(response.data);
        } catch (err) {
        }
    };

    useEffect(() => {
        fetchRequest(pagination.page, pagination.pageSize);
    }, [open, pagination.page, pagination.pageSize]);

    return {
        fetchRequest,
        request,
        setRequest,
        pagination,
        changePagination,
        pageCount,
    };
};

export default useRequest;
