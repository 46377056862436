import React from "react";
import {
    List,
    ListSubheader,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Typography,
    IconButton,
} from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import SendIcon from "@material-ui/icons/Send";
import RateReviewIcon from "@material-ui/icons/RateReview";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import CreateFolder from "./CreateFolder";

export default function MailboxOptions({
    mainFolders = [],
    customFolders = [],
    setCustomFolders = () => {},
    totalMessages = 0,
    selectedFolder = "inbox",
    setSelectedFolder = () => {},
    handleClickFolder = () => {},
    onCreateMail = () => {},
    handleCreateFolder = () => {},
    handleDeleteFolder = () => {},
}) {
    const { openDialog, closeDialog } = useConfirmationDialog();

    const handleClick = () => {
        openDialog({
            formFrameTitle: "Create folder",
            fullWidth: true,
            maxWidth: "sm",
            message: (
                <CreateFolder
                    handleCreateFolder={(folderName) => {
                        handleCreateFolder(folderName);
                        closeDialog();
                    }}
                />
            ),
        });
    };

    return (
        <List
            dense
            component="nav"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Mailbox
                </ListSubheader>
            }
        >
            <ListItem button onClick={onCreateMail} selected={selectedFolder === "new"}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Compose Message" />
            </ListItem>

            <Divider style={{ marginTop: 5, marginBottom: 5 }} />

            {mainFolders.map((option, index) => (
                <div key={option.id}>
                    <ListItem
                        button
                        onClick={() => handleClickFolder(option.id)}
                        selected={selectedFolder === option.id}
                    >
                        <ListItemIcon>
                            {index === 0 && <EmailIcon fontSize="small" />}
                            {index === 1 && <SendIcon fontSize="small" />}
                            {index === 2 && <RateReviewIcon fontSize="small" />}
                        </ListItemIcon>
                        <ListItemText primary={option.colName} />
                        {index === 0 && (
                            <ListItemSecondaryAction>
                                <Typography variant="caption" color="textSecondary">
                                    ({totalMessages})
                                </Typography>
                            </ListItemSecondaryAction>
                        )}
                    </ListItem>
                </div>
            ))}

            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <CreateNewFolderIcon />
                </ListItemIcon>
                <ListItemText primary="Create folder" />
            </ListItem>

            {customFolders.map((folder) => (
                <div key={folder.id}>
                    <ListItem
                        button
                        onClick={() => {
                            setSelectedFolder(folder.id);
                        }}
                    >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={folder.colName} />
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteFolder(
                                    folder.id,
                                    folder.colName,
                                    customFolders,
                                    setCustomFolders
                                );
                            }}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </ListItem>
                </div>
            ))}
        </List>
    );
}
