import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const sendResetToken = async (baseUrl, dealerId = '', email = '') => {
    const res = await http.post(`${BASE_URL_API_ECOMMERCE}/admin/send-reset-token`, { baseUrl, dealerId: parseInt(dealerId) || 0, email });
    return res.data;
}

const resetPassword = async (token = '', newPassword = '', repeatNewPassword = '') => {
    const res = await http.post(`${BASE_URL_API_ECOMMERCE}/admin/reset-password`, {
        token,
        newPassword: btoa(newPassword),
        repeatNewPassword: btoa(repeatNewPassword)
    });
    return res.data;
}

const adminResetPassword = async (userId, userType, newPassword = '', repeatNewPassword = '') => {
    const res = await http.put(`/password-management`, {
        userId,
        userType,
        newPassword: btoa(newPassword),
        repeatNewPassword: btoa(repeatNewPassword)
    });
    return res.data;
}

const ResetPasswordService = {
    sendResetToken,
    resetPassword,
    adminResetPassword
}

export default ResetPasswordService;