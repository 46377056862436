import React, { useState, useEffect } from "react";

// material
import Alert from "@material-ui/lab/Alert";
import { Box, Tabs, Tab, Paper, Typography, TextField } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import ShippingCarrierCalculator from "components/ShippingCarrierCalculator/ShippingCarrierCalculator";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

// custom components
import OrderHeader from "./Tabs/OrderHeader";
import OrderProducts from "./Tabs/OrderProducts";
import OrderLog from "./Tabs/OrderLog";
import ToogleCalculator from "./ToogleCalculator";
import DealerCustomers from "components/DealerCustomers/DealerCustomers";
import CCcharge from "components/CCcharge/CCcharge";
import OrderLockSwitch from "components/OrderLockSwitch/OrderLockSwitch";
import CustomerComunication from "components/CustomerComunication/CustomerComunication";

//libs
import { useFormik } from "formik";
import * as yup from "yup";

// services
import http, { BASE_URL_API_ECOMMERCE } from "services/Http";
import OrderService from "services/OrderService";
import DealerService from "services/DealerService";

// const
import { INITIAL_VALUES } from "./InitialValues";
import { CREATE_ORDER, EDIT_ORDER } from "views/OrdersNew/Orders";
import useShipment from "hooks/useShipment";
import useCustomerCommunication from "hooks/useCustomerCommunication";
import useCrud from "hooks/useCrud";
import useOrderChanges from "hooks/useOrderChanges";

const validationSchema = {
    billCompany: yup.string().required(),
    billAddress1: yup.string().required(),
    billState: yup.string().required("State is required"),
    billCity: yup.string().required("City is required"),
    billZip: yup.string().required("Zip is required"),
    billPhone: yup.string().required(),
    billEmail: yup.string().email("Must be a valid email").required(),
    details: yup.array().min(1, "You must add at least one product"),
};

export default function EditOrder({ form, order, onSuccess = () => {} }) {
    const [tabId, setTabId] = useState(0);
    const [dealer, setDealer] = useState({ id: 0 });
    const [valSchema, setValSchema] = useState({});
    const [newProducts, setNewProducts] = useState([]);
    const [trackingNumber, setTrackingNumber] = useState(() => {
        if (order && order.details && order.details.length > 0) {
            const sortedDetails = [...order.details].sort((a, b) => a.id - b.id);
            const lastDetail = sortedDetails[sortedDetails.length - 1];
            return lastDetail.trackingNumber;
        }
        return "";
    });
    const [hasProductsWithoutTrackingNumber, setHasProductsWithoutTrackingNumber] = useState(() => {
        if (order && order.details && order.details.length > 0) {
            return order.details.some((detail) => detail.statusOrder != 6);
        }
        return false;
    });
    const [shippingCarrier, setShippingCarrier] = useState(() => {
        if (order && order.details && order.details.length > 0) {
            const sortedDetails = [...order.details].sort((a, b) => a.id - b.id);
            const lastDetail = sortedDetails[sortedDetails.length - 1];
            return lastDetail.shippingCarrier;
        }
        return "";
    });
    const { isShipDisabled, toggleShipStatus } = useShipment();
    const customerCommunication = useCustomerCommunication(
        order.dealerId,
        order.orderNumber,
        order
    );
    const { readAllEntities } = useCrud("order_details", {}, false);
    const { checkOrderChanges } = useOrderChanges();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...(Object.keys(order).length === 0 ? INITIAL_VALUES : order),
            isBusinessAcc: false,
            sameAddress: true,
        },
        validationSchema: yup.object(valSchema),
        onSubmit: async (values) => {
            const newOrderStatusId = customerCommunication.values.orderStatusId;
            const newShippingStatusId = customerCommunication.values.shippingStatusId;
            const newDeclinedReason = customerCommunication.values.declinedReason;
            const originalOrder = order;
            let newOrder = values;

            if (form.title === CREATE_ORDER.title) {
                postOrder({
                    ...values,
                    ...(values.sameAddress && {
                        shipCompany: values.billCompany,
                        shipContact: values.billContact,
                        shipAddress1: values.billAddress1,
                        shipAddress2: values.billAddress2,
                        shipState: values.billState,
                        shipCity: values.billCity,
                        shipZip: values.billZip,
                        shipCountry: values.billCountry,
                        shipPhone: values.billPhone,
                        shipEmail: values.billEmail,
                    }),
                });
            }

            if (form.title === EDIT_ORDER.title) {
                await checkOrderChanges({
                    prevOrder: originalOrder,
                    newOrder: newOrder,
                });
                await putOrder(values);
                await handleClickShipNow();
            }

            if (
                newOrderStatusId &&
                newOrderStatusId !== "" &&
                (order.orderStatusId !== newOrderStatusId ||
                    (newOrderStatusId === 5 && order.shippingStatusId !== newShippingStatusId) ||
                    (newOrderStatusId === 6 && order.declinedReasonId !== newDeclinedReason))
            ) {
                newOrder = {
                    ...newOrder,
                    orderStatusId: newOrderStatusId,
                };

                await customerCommunication.handleClick(
                    order,
                    customerCommunication.values,
                    order.orderStatusId,
                    newOrderStatusId
                );
            }
        },
    });

    useEffect(() => {
        const getDealers = async (dlid = "") => {
            const dealer = await DealerService.get(dlid);
            setDealer(dealer);
        };

        if (formik.values.dealerId && formik.values.customerId) {
            setValSchema(validationSchema);
            getDealers(formik.values.dealerId);
        }

        return () => {
            setValSchema({});
        };
    }, [formik.values.dealerId, formik.values.customerId]);

    const postOrder = async (body) => {
        try {
            const order = await http.post(`${BASE_URL_API_ECOMMERCE}/order`, {
                ...body,
                dlid: body.dealerId,
                token: "NO TOKEN",
                instructions: body.instructions || "-",
                orderAmount: body.details.reduce(
                    (accum, obj) => accum + Number(obj.price) * parseInt(obj.qty),
                    0
                ),
                cart: body.details.map((d) => ({ ...d, isTaxable: 1, token: "NO TOKEN" })),
            });
            onSuccess(order.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const putOrder = async (body) => {
        try {
            const subTotal = body.details.reduce((acc, item) => {
                const itemTotalPrice = item.price * item.qty;
                return acc + itemTotalPrice;
            }, 0);

            const orderSummary = await OrderService.getOrderSummary(
                body.dealerId,
                subTotal,
                body.shipState
            );

            const order = await OrderService.put(body.id, {
                ...body,
                orderSummary: {
                    summary: orderSummary?.data?.orderSummary || [],
                    total: orderSummary?.data?.total,
                },
            });
            onSuccess(order);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChangeDealer = (dealer) => {
        formik.setFieldValue("dealerId", dealer.id);
        setDealer(dealer);
    };

    const handleChangeCustomer = (customer) => {
        formik.setFieldValue("customerId", customer.id);
        formik.setFieldValue("billCompany", customer.billCompany);
        formik.setFieldValue("billContact", customer.billContact);
        formik.setFieldValue("billAddress1", customer.billAddress1);
        formik.setFieldValue("billAddress2", customer.billAddress2);
        formik.setFieldValue("billCity", customer.billAddress2);
        formik.setFieldValue("billState", customer.billAddress2);
        formik.setFieldValue("billZip", customer.billAddress2);
        formik.setFieldValue("billPhone", customer.billPhone);
        formik.setFieldValue("billEmail", customer.billEmail);
        formik.setFieldValue("shipCompany", customer.shipCompany);
        formik.setFieldValue("shipContact", customer.shipContact);
        formik.setFieldValue("shipAddress1", customer.shipAddress1);
        formik.setFieldValue("shipAddress2", customer.shipAddress2);
        formik.setFieldValue("shipCity", customer.shipAddress2);
        formik.setFieldValue("shipState", customer.shipAddress2);
        formik.setFieldValue("shipZip", customer.shipAddress2);
        formik.setFieldValue("shipPhone", customer.shipPhone);
        formik.setFieldValue("shipEmail", customer.shipEmail);
        formik.setFieldValue("intMemo", customer.intMemo);
        formik.setFieldValue("userMemo", customer.userMemo);
    };

    // Funcion que se utiliza para el antiguo botón "Ship and now" V2
    const handleClickShipNow = async () => {
        const { id: orderId, details = [] } = formik.values;
        let products = details.filter(
            (d) => d.shipQty !== null && d.shipQty !== undefined && d.shipQty > 0
        );

        if (products.length === 0) {
            return;
        }

        try {
            const queryParams = { order_id: orderId };
            const productsFromDb = await readAllEntities(queryParams);

            products = products.map((p) => {
                return {
                    ...p,
                    id: p.id
                        ? p.id
                        : productsFromDb.find((pp) => pp.partNumber === p.partNumber)?.id,
                };
            });

            const order = await OrderService.shipOrder(orderId, products);
            onSuccess(order);
        } catch (error) {
            console.error("error inserting products");
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {form.title === CREATE_ORDER.title ? (
                    <DealerCustomers
                        onDealerChange={handleChangeDealer}
                        onCustomerChange={handleChangeCustomer}
                    />
                ) : null}

                {Object.values(formik.errors).length > 0 ? (
                    <Alert variant="outlined" severity="error" style={{ marginTop: 10 }}>
                        Please correct the following errors
                        <ul>
                            {Object.values(formik.errors).map((e) => (
                                <li key={e}>{e}</li>
                            ))}
                        </ul>
                    </Alert>
                ) : null}

                <Box display="flex" width="100%" mt={1}>
                    <Box flex={8}>
                        <Paper elevation={2} style={{ height: "100%", padding: 5 }}>
                            <Box marginTop={5} padding={2}>
                                <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                        <Edit />
                                    </CardIcon>
                                </CardHeader>
                                <Box display="flex" alignItems="center" mb={5}>
                                    <Typography flex={1}>Edit order</Typography>
                                    <Box flex={1} textAlign={"right"}>
                                        <Typography gutterBottom style={{ fontWeight: "bold" }}>
                                            Order Number: {formik?.values?.orderNumber}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Tabs
                                    value={tabId}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={(_, newValue) => setTabId(newValue)}
                                    TabIndicatorProps={{ style: { background: "#ef662f" } }}
                                >
                                    <Tab value={0} label="Order Info" />
                                    <Tab value={1} label="Items" />
                                    <Tab value={2} label="Log" />
                                </Tabs>
                            </Box>
                            <Box padding={2}>
                                {tabId === 0 && (
                                    <>
                                        <OrderHeader
                                            showTrackingInfo
                                            formik={formik}
                                            trackingComponent={
                                                <GridContainer>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            margin="dense"
                                                            id="billCompany"
                                                            name="billCompany"
                                                            label="Tracking Number"
                                                            value={trackingNumber}
                                                            onChange={(e) => {
                                                                const newTrackingNumber =
                                                                    e.target.value;

                                                                setTrackingNumber(
                                                                    newTrackingNumber
                                                                );

                                                                if (
                                                                    newTrackingNumber &&
                                                                    hasProductsWithoutTrackingNumber
                                                                ) {
                                                                    const detailsParsed = [
                                                                        ...formik.values.details,
                                                                    ].map((d) => {
                                                                        if (d.statusOrder != 6) {
                                                                            const maxShipQty =
                                                                                parseInt(d.qty) -
                                                                                parseInt(
                                                                                    d.qtyShipped
                                                                                );

                                                                            return {
                                                                                ...d,
                                                                                trackingNumber: trackingNumber,
                                                                                shipQty: maxShipQty,
                                                                                statusOrder: 6,
                                                                                shippingCarrier: shippingCarrier,
                                                                            };
                                                                        }
                                                                        return d;
                                                                    });

                                                                    formik.setFieldValue(
                                                                        "details",
                                                                        detailsParsed
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            margin="dense"
                                                            id="billCompany"
                                                            name="billCompany"
                                                            label="Shipping Carrier"
                                                            value={shippingCarrier}
                                                            onChange={(e) => {
                                                                setShippingCarrier(e.target.value);
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            }
                                        />
                                        <CustomerComunication
                                            dealerId={formik.values.dealerId}
                                            orderNumber={formik.values.orderNumber}
                                            customerCommunication={customerCommunication}
                                        />
                                    </>
                                )}
                                {tabId === 1 && (
                                    <OrderProducts
                                        dealer={dealer}
                                        formik={formik}
                                        isShipDisabled={isShipDisabled}
                                        newProducts={newProducts}
                                        setNewProducts={setNewProducts}
                                        trackingNumber={trackingNumber}
                                        shippingCarrier={shippingCarrier}
                                    />
                                )}
                                {tabId === 2 && <OrderLog formik={formik} />}
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 15,
                                }}
                            >
                                <Box>
                                    <CCcharge
                                        formik={formik}
                                        isLocked={formik.values.isLocked === 1}
                                    />
                                </Box>
                                <Box ml={2}>
                                    <Button type="submit" color="rose">
                                        Save and close
                                    </Button>
                                </Box>
                                {/*tabId === 1 && (
                                    <Box ml={2}>
                                        {isShipDisabled ? (
                                            <Button
                                                type="button"
                                                color="rose"
                                                onClick={toggleShipStatus}
                                            >
                                                Ship & save
                                            </Button>
                                        ) : (
                                            <Button
                                                type="button"
                                                color="rose"
                                                onClick={handleClickShipNow}
                                            >
                                                Ship now
                                            </Button>
                                        )}
                                    </Box>
                                )*/}
                            </Box>
                        </Paper>
                    </Box>
                    {tabId == 1 && <ToogleCalculator formik={formik} />}
                </Box>

                {/*formik.values.dealerId && formik.values.customerId ?
                    <GridContainer style={{ display: 'none' }}>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'right' }} >
                            <OrderLockSwitch
                                orderId={formik.values.id}
                                isLocked={formik.values.isLocked === 1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <NavPills
                                active={0}
                                onChange={(e) => setTabId(e)}
                                color="warning"
                                tabs={[
                                    {
                                        tabButton: "Header",
                                        tabContent: (
                                            <OrderHeader formik={formik} />
                                        ),
                                    },
                                    {
                                        tabButton: "Products",
                                        tabContent: (
                                            <OrderProducts
                                                dealer={dealer}
                                                formik={formik}
                                                isShipDisabled={isShipDisabled} />
                                        ),
                                    },
                                    {
                                        tabButton: "Comment/Log",
                                        tabContent: (
                                            <OrderLog formik={formik} />
                                        ),
                                    }
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 10 }}>
                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <Box>
                                    <CCcharge
                                        formik={formik}
                                        isLocked={formik.values.isLocked === 1}
                                    />
                                </Box>
                                <Box ml={2}>
                                    <Button type="submit" color="rose">Save and close</Button>
                                </Box>
                                <Box ml={2}>
                                    <Button type="button" color="rose" onClick={toggleShipStatus}>Ship & save</Button>
                                </Box>
                            </Box>
                        </GridItem>
                    </GridContainer>
                    : null
                */}
            </form>
        </>
    );
}
