import { useState, useEffect } from "react";
import { useSnackbar } from "./useSnackbar";
import useDealerId from "./useDealerId";
import PartSelectionDialog from "views/HomePageSpecials/PartSelectionDialog";
import { useConfirmationDialog } from "./useConfirmationDialog";
import useCrud from "./useCrud";

const useHomePageSpecialsForm = () => {
    const [partNumber, setPartNumber] = useState("");
    const [specialPage, setSpecialPage] = useState({
        sectionTitle: "",
        componentType: "C",
    });
    const openSnackbar = useSnackbar();
    const dealerId = useDealerId();
    const { openDialog, closeDialog } = useConfirmationDialog();
    const { data: dealer, readAllEntities: readAllDealers, updateEntity: updateDealer } = useCrud(
        "dealers",
        {},
        false
    );
    const { data: products = [], readAllEntities, createEntity, deleteEntity } = useCrud(
        "home_page_special_products_v2",
        {},
        false
    );

    const fetchDealer = async () => {
        await readAllDealers({
            id: dealerId,
        });
    };

    const fetchProducts = async () => {
        await readAllEntities({
            dealer_id: dealerId,
        });
    };

    useEffect(() => {
        if (dealerId) {
            fetchDealer();
            fetchProducts();
        }
    }, [dealerId]);

    useEffect(() => {
        if (dealer.length === 1) {
            setSpecialPage({
                sectionTitle: dealer[0].homepageSpecialsSectionTitle,
                componentType: dealer[0].homepageSpecialsCompType,
            });
        }
    }, [dealer]);

    const handleChangeSectionTitle = (e) => {
        setSpecialPage({
            ...specialPage,
            sectionTitle: e.target.value,
        });
    };

    const handleChangeCompType = (e) => {
        setSpecialPage({
            ...specialPage,
            componentType: e.target.value,
        });
    };

    const searchProduct = () => {
        openDialog({
            formFrameTitle: "Home Page Specials - Product selection",
            fullWidth: true,
            maxWidth: "lg",
            message: (
                <PartSelectionDialog
                    partNumber={partNumber}
                    onProductSelect={handleProductSelect}
                />
            ),
        });
    };

    const handleProductSelect = async (product) => {
        setPartNumber("");
        closeDialog();
        await handleAddProduct(product);
        await fetchProducts();
    };

    const handleAddProduct = async (product) => {
        const [make, year, model, trim, engine, category, subcategory] = product.slugs;

        const parsedProduct = {
            dealer_id: dealerId,
            product_id: product.id,
            name: product.name,
            part_number: product.part_number,
            path_image: product.path_image,
            assembly: product.assembly,
            make_id: make.id,
            make_name: make.name,
            make_slug: make.slug,
            year_name: year.name,
            model_id: model.id,
            model_name: model.name,
            model_slug: model.slug,
            trim_id: trim.id,
            trim_name: trim.name,
            trim_slug: trim.slug,
            engine_id: engine.id,
            engine_name: engine.name,
            engine_slug: engine.slug,
            engine_vtceid: engine.VehicleToEngineConfigID,
            category_id: category.id,
            category_name: category.name,
            category_slug: category.slug,
            subcategory_id: subcategory.id,
            subcategory_name: subcategory.name,
            subcategory_slug: subcategory.slug,
            price_price: product.price.price,
            price_show_price: product.price.showPrice,
        };

        createEntity(parsedProduct);
    };

    const handleUpdateHomePageSpecial = async () => {
        updateDealer({
            id: dealerId,
            homepage_specials_section_title: specialPage.sectionTitle,
            homepage_specials_comp_type: specialPage.componentType,
        });
    };

    const handleDeleteProduct = async (id) => {
        try {
            await deleteEntity(id);
            await fetchProducts();
            openSnackbar("success", "Remove successfully.");
        } catch (error) {
            openSnackbar("error", "An error occurred while removing. Please try again.");
        }
    };

    const handleDeleteSelected = async (selected = []) => {
        try {
            for (let i = 0; i < selected.length; i++) {
                const id = selected[i];
                await deleteEntity(id);
            }
            await fetchProducts();
            openSnackbar("success", "Remove successfully.");
        } catch (error) {
            openSnackbar("error", "An error occurred while removing. Please try again.");
        }
    };

    return {
        partNumber,
        setPartNumber,
        specialPage,
        setSpecialPage,
        products,
        handleAddProduct,
        handleChangeSectionTitle,
        handleChangeCompType,
        handleUpdateHomePageSpecial,
        handleDeleteProduct,
        handleDeleteSelected,
        searchProduct,
    };
};

export default useHomePageSpecialsForm;
