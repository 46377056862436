import React, { useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CreateProduct from "components/CreateProduct/CreateProduct";
import Searchkeyword from "components/Searchkeyword/Searchkeyword";

// material
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const INITIAL_VALUES = {
    id: 0,
    partNumber: "",
    description: "",
    price: "",
    qty: "1",
};

export default function SearchProduct({ dealer, onAddProduct = () => {} }) {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [selProduct, setSelProduct] = useState(INITIAL_VALUES);

    return (
        <>
            {!open && (
                <Button color="primary" onClick={() => setOpen(!open)}>
                    Add product
                </Button>
            )}

            {open ? (
                <Paper elevation={2} style={{ padding: 10 }}>
                    <Box style={{ textAlign: "right" }}>
                        <IconButton onClick={() => setOpen(!open)}>
                            <CloseIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box>
                        <Searchkeyword
                            dealer={dealer}
                            onClick={(value) => {
                                setSelProduct({
                                    ...value,
                                    id: value.id,
                                    partNumber: value.part_number,
                                    description: value.name,
                                    price: value.price.price,
                                    qty: 1,
                                });
                                setShow(!show);
                            }}
                        />
                    </Box>
                    {show != 0 ? (
                        <Box>
                            <CreateProduct
                                selProduct={selProduct}
                                setSelProduct={setSelProduct}
                                onAddProduct={(value) => {
                                    const [
                                        make,
                                        year,
                                        model,
                                        trim,
                                        engine,
                                        category,
                                        subcategory,
                                    ] = value.slugs;

                                    onAddProduct({
                                        id: 0,
                                        quoteId: 0,
                                        catalogId: 1,
                                        makeSlug: make.slug,
                                        year: year.name,
                                        modelSlug: model.slug,
                                        trimSlug: trim.slug,
                                        engineSlug: engine.slug,
                                        vehicleToEngineConfigId: engine.VehicleToEngineConfigID,
                                        partNumber: value.partNumber,
                                        description: value.description,
                                        cost: 0,
                                        price: value.price,
                                        qty: value.qty,
                                        extendedPrice: value.price,
                                        pictureUrl: value.path_image,
                                    });

                                    setShow(!show);
                                }}
                            />
                        </Box>
                    ) : null}
                </Paper>
            ) : null}
        </>
    );
}
