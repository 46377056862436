import useEmail from "./useEmail";

const useOrderChanges = () => {
    const { sendEmailAsync } = useEmail();

    const checkOrderChanges = ({ prevOrder, newOrder }) => {
        let i = 0;

        // Se actualizó el estado de la orden
        /*if (prevOrder.orderStatusId !== newOrder.orderStatusId) {
            i++;
        }*/

        // Se eliminó un producto
        if (prevOrder.details.length !== newOrder.details.length) {
            i++;
        }

        newOrder.details.map((pd) => {
            const { id, itemWeight, price, statusOrder, trackingNumber, shipQty } = pd;
            const prevProduct = prevOrder.details.find((x) => x.id === id);

            if (itemWeight !== prevProduct?.itemWeight) {
                i++;
            }

            if (price !== prevProduct?.price) {
                i++;
            }

            if (statusOrder !== prevProduct?.statusOrder) {
                i++;
            }

            if (trackingNumber !== prevProduct?.trackingNumber) {
                i++;
            }

            if (shipQty !== prevProduct?.shipQty) {
                i++;
            }
        });

        if (i > 0) {
            sendOrderChangeNotification(newOrder);
        }
    };

    const sendOrderChangeNotification = async (newOrder) => {
        try {
            const subject = `Your order #${newOrder.orderNumber} has been updated`;
            const body = `
            <p>Dear ${newOrder.billCompany},</p>
            <p>Your order #${newOrder.orderNumber} has been updated. Please log in to your account to see the changes made.</p>`;

            await sendEmailAsync(newOrder.billEmail, "", subject, body, "");
        } catch (error) {
            console.error("Error sending email");
        }
    };

    return {
        checkOrderChanges,
        sendOrderChangeNotification,
    };
};

export default useOrderChanges;
