import React, { useState } from "react";
import PageLayout from "layouts/PageLayout";
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import useItemCore from "hooks/useItemCore";

function ProductItem({ product = {} }) {
    const { handleClickEdit } = useItemCore();
    const {
        name = "Body Side Graphics",
        partNumber = "822139219AA",
        description = "Body side graphic is designed to fit Jeep Grand Cherokee 4xe.",
    } = product;

    return (
        <Box display="flex">
            <Box flex={9}>
                <Box display="flex">
                    <Typography variant="h6" gutterBottom style={{ textTransform: "math-auto" }}>
                        {name}
                    </Typography>
                    <Box marginLeft="auto">
                        <FormControlLabel
                            value="start"
                            control={<Switch color="primary" />}
                            label={
                                <Typography style={{ color: "#000" }}>Hide Item(s): Yes</Typography>
                            }
                            labelPlacement="start"
                        />
                    </Box>
                </Box>
                <Typography gutterBottom style={{ textTransform: "math-auto" }}>
                    Part #{partNumber}
                </Typography>
                <Typography gutterBottom style={{ textTransform: "math-auto" }}>
                    {description}
                </Typography>
            </Box>
            <Box flex={0.25} ml={2}>
                <Divider orientation="vertical" />
            </Box>
            <Box flex={3}>
                <List dense component="nav">
                    <ListItem>
                        <ListItemText primary="Cost" />
                        <ListItemSecondaryAction>
                            <Typography style={{ fontWeight: 600 }}>$75.00</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="List" />
                        <ListItemSecondaryAction>
                            <Typography style={{ fontWeight: 600 }}>$112.00</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Labor Time" />
                        <ListItemSecondaryAction>
                            <Typography style={{ fontWeight: 600 }}>0.5</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <ButtonGroup fullWidth orientation="vertical" variant="text">
                    <Button
                        variant="contained"
                        startIcon={<BorderColorIcon />}
                        onClick={handleClickEdit}
                    >
                        EDIT INFORMATION
                    </Button>
                    <Button variant="contained" color="primary" style={{ marginTop: 10 }}>
                        DELETE
                    </Button>
                </ButtonGroup>
            </Box>
        </Box>
    );
}

function VehicleSelector() {
    return (
        <Box display="flex" alignItems="space-between">
            <Box flex="3" mr={1}>
                <TextField fullWidth select label="1 | Make">
                    {[].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box flex="3" mr={1}>
                <TextField fullWidth select label="2 | Year">
                    {[].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box flex="3" mr={1}>
                <TextField fullWidth select label="3 | Model">
                    {[].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box flex="3" mr={1}>
                <TextField fullWidth select label="4 | Trim">
                    {[].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box flex="3" mr={1}>
                <TextField fullWidth select label="5 | Engine">
                    {[].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box flex="3" mr={1}>
                <TextField fullWidth select label="6 | Category">
                    {[].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box flex="3">
                <TextField fullWidth select label="7 | Subcategory">
                    {[].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        </Box>
    );
}

function Search() {
    const partNumber = "";

    return (
        <>
            <Box display="flex" mt={2}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Type part number"
                    name="title"
                    value={partNumber}
                    onChange={(e) => console.log(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton disabled={partNumber.length === 0} onClick={() => {}}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disabled={partNumber.length === 0} onClick={() => {}}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant="outlined" style={{ marginLeft: 5 }}>
                    SEARCH
                </Button>
            </Box>
            <Box mt={1}>
                <ButtonGroup size="small" variant="text" aria-label="text primary button group">
                    <Button>By Categories</Button>
                    <Button>By SubCategories</Button>
                </ButtonGroup>
            </Box>
        </>
    );
}

export default function EditItem() {
    const [tabId, setTabId] = useState(0);

    return (
        <PageLayout>
            <Typography variant="h6" gutterBottom style={{ textTransform: "math-auto" }}>
                Item(s) Core Settings
            </Typography>
            <Box>
                <Tabs
                    value={tabId}
                    onChange={(e, newValue) => {
                        setTabId(newValue);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    style={{ maxWidth: 500 }}
                >
                    <Tab label="Search by Part Number" />
                    <Tab label="Search by Make/Model" />
                </Tabs>
            </Box>
            {tabId === 1 ? <VehicleSelector /> : <Search />}

            <Box mt={2.5}>
                {[...Array(2)].map((p, index) => (
                    <Paper key={index} style={{ padding: 20, marginBottom: 15 }}>
                        <ProductItem />
                    </Paper>
                ))}
                <Divider style={{ marginTop: 50, marginBottom: 15 }} />
                <Typography variant="h6" gutterBottom>
                    EXISTING RULES
                </Typography>
                {[...Array(3)].map((p, index) => (
                    <Paper key={index} style={{ padding: 20, marginBottom: 15 }}>
                        <ProductItem />
                    </Paper>
                ))}
            </Box>
        </PageLayout>
    );
}
