import React, { useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Box, TextField, InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal";
import ColorsList from "./ColorSelect";
import useModal from "hooks/useModal";
import useShippingMethodForm from "hooks/useShippingMethodForm";

const StyledBox = withStyles((theme) => ({
    root: {
        marginBottom: 10
    },
}))(Box);

export default function CreateShippingMethod({ handleAddShippingMethod = () => { } }) {
    const onSubmit = async (values) => {
        handleAddShippingMethod(values);
        handleCloseModal();
    }

    const { open, handleOpenModal, handleCloseModal } = useModal();
    const { formik, resetForm } = useShippingMethodForm(onSubmit);

    useEffect(() => {

        return () => {
            if (open)
                resetForm();
        };
    }, [open]);

    return (
        <>
            <Button color="primary" onClick={handleOpenModal}>
                Add Shipping Options
            </Button>

            <Modal open={open} modalTitle={"Create shipping option"} handleClose={handleCloseModal}>
                <Box minWidth={300} />
                <form onSubmit={formik.handleSubmit}>
                    <StyledBox>
                        <TextField
                            fullWidth
                            size="small"
                            label="Name:"
                            name="shippingMethodName"
                            value={formik.values.shippingMethodName}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingMethodName && Boolean(formik.errors.shippingMethodName)}
                        />
                    </StyledBox>
                    <StyledBox>
                        <TextField
                            type="number"
                            fullWidth
                            size="small"
                            label="Min. Shipping:"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            name="minShipping"
                            value={formik.values.minShipping}
                            onChange={formik.handleChange}
                            error={formik.touched.minShipping && Boolean(formik.errors.minShipping)}
                        />
                    </StyledBox>
                    <StyledBox>
                        <TextField
                            type="number"
                            fullWidth
                            size="small"
                            label="Max. Shipping:"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            name="maxShipping"
                            value={formik.values.maxShipping}
                            onChange={formik.handleChange}
                            error={formik.touched.maxShipping && Boolean(formik.errors.maxShipping)}
                        />
                    </StyledBox>
                    <StyledBox>
                        <TextField
                            type="number"
                            fullWidth
                            size="small"
                            label="Order Total:"
                            InputProps={{
                                startAdornment: <></>,
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            name="orderTotal"
                            value={formik.values.orderTotal}
                            onChange={formik.handleChange}
                            error={formik.touched.orderTotal && Boolean(formik.errors.orderTotal)}
                        />
                    </StyledBox>
                    <StyledBox>
                        <TextField
                            type="number"
                            fullWidth
                            size="small"
                            label="Flat Ship. Charges:"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            name="flatShipCharges"
                            value={formik.values.flatShipCharges}
                            onChange={formik.handleChange}
                            error={formik.touched.flatShipCharges && Boolean(formik.errors.flatShipCharges)}
                        />
                    </StyledBox>
                    <StyledBox>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="freeShip"
                                    checked={formik.values.freeShip}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Free Ship."
                        />
                    </StyledBox>
                    <StyledBox>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="hideShipAddr"
                                    checked={formik.values.hideShipAddr}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Hide Ship. Addr."
                        />
                    </StyledBox>
                    <StyledBox>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="pickUp"
                                    checked={formik.values.pickUp}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Pick up"
                        />
                    </StyledBox>
                    <StyledBox>
                        <ColorsList
                            name="flagColor"
                            value={formik.values.flagColor}
                            onChange={formik.handleChange}
                            error={formik.touched.flagColor && Boolean(formik.errors.flagColor)}
                        />
                    </StyledBox>

                    <Box textAlign="center">
                        <Button type="submit" color="rose">
                            Save and close
                        </Button>
                    </Box>
                </form>
            </Modal>
        </>
    )
}
