import { Button, Paper, TextField, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CheckboxMUI from "components/CheckboxMUI/CheckboxMUI";
import GenericSelect from "components/GenericSelect/GenericSelect";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import TableWrapper from "components/TableWrapper/TableWrapper";
import useCustomerCommunication from "hooks/useCustomerCommunication";

export default function CustomerComunication({ dealerId, orderNumber, customerCommunication }) {
    const {
        templates,
        communications = [],
        values,
        setValues,
        updateFieldValue,
        orderStatusList,
        shippingStatusList,
        declinedReasonList,
    } = customerCommunication;

    const columns = [
        {
            Header: "From",
            accessor: "fromName",
        },
        {
            Header: "Subject",
            accessor: "subject",
        },
        {
            Header: "Message",
            accessor: "message",
            Cell: ({ cell }) => {
                const rows = Math.ceil(cell.value.length / 80);

                return (
                    <div
                        style={{ overflowWrap: "break-word" }}
                        dangerouslySetInnerHTML={{ __html: cell.value }}
                    />
                );
            },
        },
        {
            Header: "Date",
            accessor: "createdAtParsed",
        },
    ];

    return (
        <>
            <Typography variant="subtitle2" gutterBottom style={{ marginTop: 20 }}>
                Customer Communication
            </Typography>
            <GridContainer>
                {/* ROW 0 */}
                <GridItem xs={12} sm={12} md={3}>
                    <CheckboxMUI
                        label="Do not send notification email to customer"
                        checked={values.doNotSendEmail}
                        onChange={() =>
                            updateFieldValue(setValues, "doNotSendEmail", !values.doNotSendEmail)
                        }
                    />
                    <CheckboxMUI
                        label={`Add "Your approval is needed" in customer email`}
                        checked={values.addApprovalText}
                        onChange={() =>
                            updateFieldValue(setValues, "addApprovalText", !values.addApprovalText)
                        }
                    />
                    <GenericSelect
                        fullWidth
                        label="Update Order Status"
                        size="small"
                        value={values.orderStatusId}
                        onChange={(e) =>
                            updateFieldValue(setValues, "orderStatusId", e.target.value)
                        }
                        options={orderStatusList}
                        mt={10}
                        showNone={false}
                    />

                    {values.orderStatusId === 5 && (
                        <GenericSelect
                            fullWidth
                            label="Shipping Status"
                            size="small"
                            value={values.shippingStatusId}
                            onChange={(e) => {
                                updateFieldValue(setValues, "shippingStatusId", e.target.value);
                            }}
                            options={shippingStatusList}
                            mt={10}
                        />
                    )}
                    {values.orderStatusId === 6 && (
                        <GenericSelect
                            fullWidth
                            label="Declined reason (Internal)"
                            size="small"
                            value={values.declinedReason}
                            onChange={(e) =>
                                updateFieldValue(setValues, "declinedReason", e.target.value)
                            }
                            options={declinedReasonList}
                            mt={10}
                            showNone={false}
                        />
                    )}
                    <GenericSelect
                        fullWidth
                        label="Select message template"
                        valueProp="id"
                        labelProp="name"
                        size="small"
                        value={values.templateId}
                        onChange={(e) => {
                            const template = templates.find((t) => t.id === e.target.value);
                            updateFieldValue(setValues, "templateId", e.target.value);

                            if (template) {
                                updateFieldValue(setValues, "messageText", template.description);
                            }
                        }}
                        options={templates}
                        mt={10}
                        mb={10}
                    />
                    <TextFieldRender
                        fullWidth
                        variant="outlined"
                        label="Write a message"
                        multiline
                        rows={10}
                        style={{ marginTop: 20 }}
                        value={values.messageText}
                        handleBlur={(value) => updateFieldValue(setValues, "messageText", value)}
                    />
                    {/*<Button
                        fullWidth
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 10 }}
                        onClick={() => {
                            if (values.orderStatusId && values.messageText) {
                                handleClick();
                            }
                        }}
                    >
                        {loading ? "Sending..." : "Apply Changes"}
                    </Button>*/}
                </GridItem>
                {/* ROW 0 */}
                <GridItem xs={12} sm={12} md={9}>
                    <Paper elevation={3}>
                        <TableWrapper columns={columns} data={communications} />
                    </Paper>
                </GridItem>
            </GridContainer>
        </>
    );
}
