import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import MuiAlert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

//libs
import { useFormik } from "formik";
import * as yup from "yup";

//const
export const INITIAL_VALUES = {
    email: "",
    password: "",
};

const defValidationSchema = {
    email: yup.string().required(),
    password: yup.string().required(),
};

import UserService from "services/UserService";
import DealerService from "services/DealerService";

const useStyles = makeStyles(styles);

export default function LoginPage() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    let dlid = query.get("dlid");
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const [cardAnimaton, setCardAnimation] = useState("cardHidden");

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_VALUES,
        validationSchema: yup.object(defValidationSchema),
        onSubmit: async (values) => {
            if (!dlid) {
                const dealers = await DealerService.getAll("").then((d) => d.data);
                const cb = (d) => d.dealerAdminDomain === window.location.hostname;

                if (dealers.some(cb)) {
                    dlid = dealers.find(cb).id;
                }
            }

            login({
                ...values,
                dlid,
            });
        },
    });

    const login = async (body) => {
        try {
            const user = await UserService.login(body);
            localStorage.setItem("_gid", user);
            localStorage.setItem("_sdlid", dlid ? 1 : 0);
            if (dlid) localStorage.setItem("_dlid", dlid);
            window.location.pathname = "/admin";
        } catch (error) {
            setAlert({
                show: true,
                message: error.response.data.message,
            });
        }
    };

    useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form onSubmit={formik.handleSubmit}>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="rose"
                            >
                                <h4 className={classes.cardTitle}>Login</h4>
                            </CardHeader>
                            <CardBody>
                                {alert.show ? (
                                    <MuiAlert
                                        onClose={() => setAlert({ ...alert, show: false })}
                                        severity="error"
                                    >
                                        {alert.message}
                                    </MuiAlert>
                                ) : null}
                                <CustomInput
                                    labelText="User"
                                    id="email"
                                    name="email"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        onChange: formik.handleChange,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={formik.values.email}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                />
                                <CustomInput
                                    labelText="Password"
                                    id="password"
                                    name="password"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        onChange: formik.handleChange,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    lock_outline
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                    }}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.password && Boolean(formik.errors.password)
                                    }
                                />
                                <Link href="/auth/forgot-password" onClick={() => {}}>
                                    Forgot password?
                                </Link>
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button type="submit" color="rose" simple size="lg" block>
                                    Login
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
