import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

export default function SaveButton({ ...otherProps }) {
    return (
        <Box style={{ textAlign: "center" }} mt={1}>
            <Button {...otherProps} type="submit" color="rose">
                Save and close
            </Button>
        </Box>
    );
}
