import React, { useState, useEffect, useCallback } from "react";
import {
    useTable,
    useFilters,
    useAsyncDebounce,
    useSortBy,
    usePagination,
    useExpanded
} from "react-table";
import classnames from "classnames";

// core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const newStyles = {
    ...styles,
    formControlMargins: {
        margin: "3px 0 !important",
    },
    gridContainer: {
        justifyContent: "center",
    },
};

const useStyles = makeStyles(newStyles);

export default function Table({
    columns,
    data,
    fetchData = () => { },
    renderRowSubComponent,
    showPagination = true,
    showSorting = true,
    activeRow = -1,
    manualPagination = false,
    pageCount: controlledPageCount
}) {
    const classes = useStyles();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        visibleColumns,
    } = useTable(
        {
            columns,
            data,
            manualPagination,
            manualSortBy: false,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: controlledPageCount
        },
        useFilters, // useFilters!
        useSortBy,
        useExpanded,
        usePagination
    );

    useEffect(() => {
        if (manualPagination) fetchData({ pageIndex, pageSize });
    }, [fetchData, pageIndex, pageSize]);

    return (
        <>
            <div className="ReactTable -striped -highlight">
                <table {...getTableProps()} className="rt-table">
                    <thead className="rt-thead -header">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                                {headerGroup.headers.map((column, key) => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={classnames("rt-th rt-resizable-header", {
                                            "-cursor-pointer": showSorting && headerGroup.headers.length - 1 !== key,
                                            "-sort-asc": column.isSorted && !column.isSortedDesc,
                                            "-sort-desc": column.isSorted && column.isSortedDesc,
                                        })}
                                    >
                                        <div className="rt-resizable-header-content">
                                            {column.render("Header")}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="rt-tbody">
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={i}>
                                    <tr
                                        {...row.getRowProps()}
                                        className={classnames(
                                            "rt-tr",
                                            { " -odd": i % 2 === 0 },
                                            { " -even": i % 2 === 1 }
                                        )}
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} className="rt-td">
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {
                                    /*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                    OLD: row.isExpanded && 
                                    */}
                                    {activeRow == i && renderRowSubComponent ? (
                                        <tr>
                                            <td colSpan={visibleColumns.length}>
                                                {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                                */}
                                                {renderRowSubComponent({ row })}
                                            </td>
                                        </tr>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>

                {showPagination && <div className="pagination-top">
                    <div className="-pagination">
                        <div className="-previous">
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                className="-btn"
                            >
                                Previous
                            </button>
                        </div>
                        <div className="-center">
                            <GridContainer className={classes.gridContainer}>
                                <GridItem xs={12} sm={6} md={4}>
                                    <FormControl
                                        fullWidth
                                        className={
                                            classes.selectFormControl +
                                            " " +
                                            classes.formControlMargins
                                        }
                                    >
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={pageSize}
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                            inputProps={{
                                                name: "numberOfRows",
                                                id: "number-of-rows",
                                            }}
                                        >
                                            {[10, 20, 30, 40, 50].map((prop) => {
                                                return (
                                                    <MenuItem
                                                        key={prop}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected,
                                                        }}
                                                        value={prop}
                                                    >
                                                        {prop} rows
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className="-next">
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                className="-btn"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    );
}