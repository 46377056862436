import React, { useState, useEffect } from "react";

// @material-ui/core components
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// const
export const CREATE_ROLE = {
    title: 'Create role',
    successMessage: 'Role created successfully'
}

export const EDIT_ROLE = {
    title: 'Edit role',
    successMessage: 'Role updated successfully'
}

export default function CreateProduct({ selProduct, setSelProduct, onAddProduct = () => { } }) {
    useEffect(() => {
        return () => {
            setSelProduct({
                ...selProduct,
                partNumber: '',
                description: '',
                price: '',
                qty: 1
            })
        }
    }, []);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    fullWidth
                    margin="dense"
                    id="partNumber"
                    name="partNumber"
                    label={"Part number"}
                    value={selProduct.partNumber}
                    onChange={(e) => setSelProduct({ ...selProduct, partNumber: e.target.value })}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <TextField
                    fullWidth
                    margin="dense"
                    id="description"
                    name="description"
                    label={"Description"}
                    value={selProduct.description}
                    onChange={(e) => setSelProduct({ ...selProduct, description: e.target.value })}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
                <TextField
                    fullWidth
                    margin="dense"
                    id="price"
                    name="price"
                    label={"Price"}
                    inputProps={{ min: 1, style: { textAlign: 'right' } }}
                    value={selProduct.price}
                    onChange={(e) => setSelProduct({ ...selProduct, price: e.target.value })}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
                <TextField
                    type="number"
                    fullWidth
                    margin="dense"
                    id="qty"
                    name="qty"
                    label={"Quantity"}
                    value={selProduct.qty}
                    onChange={(e) => setSelProduct({ ...selProduct, qty: e.target.value })}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={2} style={{ marginTop: 8 }}>
                <Button type="button" color="rose" onClick={() => onAddProduct(selProduct)}>Add</Button>
            </GridItem>
        </GridContainer>
    );
}