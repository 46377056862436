import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// const
import { CREATE_PAYMENT_METHOD, EDIT_PAYMENT_METHOD } from "./PaymentMethods";

//services
import PaymentMethodService from "../../services/PaymentMethodService";

const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
});

const useStyles = makeStyles(styles);

export default function CreatePaymentMethod({
    form = CREATE_PAYMENT_METHOD,
    initialValues,
    onSuccess,
}) {
    const classes = useStyles();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            name: initialValues.name || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_PAYMENT_METHOD) createPaymentMethod(values);
            if (form === EDIT_PAYMENT_METHOD) updatePaymentMethod(values);
        },
    });

    const createPaymentMethod = async (body) => {
        try {
            const paymentMethod = await PaymentMethodService.post(body);
            onSuccess(paymentMethod);
        } catch (error) {
            console.error(error);
        }
    };

    const updatePaymentMethod = async (body) => {
        try {
            const paymentMethod = await PaymentMethodService.put(body);
            onSuccess(paymentMethod);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                margin="dense"
                                id="name"
                                name="name"
                                label="Payment Method name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: 5 }}>
                            <Button type="submit" color="rose">
                                Save and close
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </GridItem>
        </GridContainer>
    );
}
