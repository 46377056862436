import React, { useState } from "react";

//@material-ui/core components
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

export default function EditFieldText(props) {
    const { onSubmit = () => { } } = props;
    const [enText, setEnText] = useState(props.enText);
    const [esText, setEsText] = useState(props.esText);
    const [ptText, setPtText] = useState(props.ptText);
    const [frText, setFrText] = useState(props.frText);
    const [url, setUrl] = useState(props.url);
    const [newTab, setNewTab] = useState(props.newTab);

    const toogleNewTab = () => setNewTab(!newTab);

    const handleClick = () => {
        onSubmit({
            enText,
            esText,
            frText,
            ptText,
            url,
            newTab
        });
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="EN field"
                        value={enText}
                        onChange={(e) => setEnText(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="ES field"
                        value={esText}
                        onChange={(e) => setEsText(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="PT field"
                        value={ptText}
                        onChange={(e) => setPtText(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="FR field"
                        value={frText}
                        onChange={(e) => setFrText(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Destination URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={12}>
                    <FormControlLabel
                        control={<Checkbox checked={newTab} onChange={toogleNewTab} />}
                        label="Check to allow link to open a new tab"
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 10 }}>
                    <Button type="button" color="rose" onClick={handleClick}>Save and close</Button>
                </GridItem>
            </GridContainer>
        </>
    )
}