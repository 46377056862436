import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";

// const
import { CREATE, EDIT } from "../Dealers";

//services
import DealerService from "../../../services/DealerService";

// @material-ui/core components
import { TextField, Box, MenuItem, Checkbox, FormLabel } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Notification from "components/Notification/Notification.js";
import useUser from "hooks/useUser";
import PasswordChangeDialog from "components/PasswordChangeDialog/PasswordChangeDialog";
import TooglePassword from "components/TooglePassword/TooglePassword";
import { useSnackbar } from "hooks/useSnackbar";

const validationSchema = yup.object({
    dealerDomain: yup.string().required("Dealer domain is required"),
    company: yup.string().required("Company is required"),
    address1: yup.string().required("Address is required"),
});

const languages = [
    {
        id: "en",
        value: "EN",
    },
    {
        id: "es",
        value: "ES",
    },
    {
        id: "pt",
        value: "PT",
    },
    {
        id: "fr",
        value: "FR",
    },
];

//TempKey@2024

export default function DealerTab({ form = CREATE, dlid, initialValues, onSubmit = () => {} }) {
    const openSnackbar = useSnackbar();
    const [notification, setNotification] = useState({
        open: false,
        message: "Settings updated successfully",
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...initialValues,
            id: initialValues.id || 0,
            dealerDomain: initialValues.dealerDomain || "",
            dealerAdminDomain: initialValues.dealerAdminDomain || "",
            company: initialValues.company || "",
            address1: initialValues.address1 || "",
            address2: initialValues.address2 || "",
            city: initialValues.city || "",
            state: initialValues.state || "",
            zip: initialValues.zip || "",
            country: initialValues.country || "",
            country: initialValues.country || "",
            billContact: initialValues.billContact || "",
            billPhone: initialValues.billPhone || "",
            billEmail: initialValues.billEmail || "",
            shipContact: initialValues.shipContact || "",
            shipPhone: initialValues.shipPhone || "",
            shipEmail: initialValues.shipEmail || "",
            partsContact: initialValues.partsContact || "",
            partsPhone: initialValues.partsPhone || "",
            partsEmail: initialValues.partsEmail || "",
            envyMainUser: initialValues.envyMainUser || "admin",
            password: initialValues.password || "VGVtcEtleUAyMDI0",
            locale: initialValues.locale || "",
            languagesAvailable: (initialValues.languagesAvailable || "").split(","),
            registrationType: initialValues.registrationType || "",
            assetsRootUrl: initialValues.assetsRootUrl || "",
            assetsUploadPath: initialValues.assetsUploadPath || "",
            imagesBaseUrl: initialValues.imagesBaseUrl || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE) createDealer(values);
            if (form === EDIT) updateDealer(values);
        },
    });

    const createDealer = async (body) => {
        try {
            const dealer = await DealerService.post(body).then((x) => x.data);
            onSubmit(dealer);
            openNotification(true, "Dealer created");
        } catch (error) {
            console.error(error);
        }
    };

    const updateDealer = async (body) => {
        try {
            const user = await DealerService.put(body);
            openNotification(true, "Dealer updated");
        } catch (error) {
            initialValues;
        }
    };

    const openNotification = (open = notification.open, message = notification.message) =>
        setNotification({
            ...notification,
            open,
            message,
        });

    const handleChangePassword = async (values) => {
        try {
            const dealerId = formik.values.id;

            await DealerService.changePassword({
                dealerId: dealerId,
                password: btoa(values.password),
            });
            openSnackbar("success", "Password has been changed successfully!");
            location.reload();
        } catch (error) {
            openSnackbar("error", "There was an error while trying to change the password");
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Notification
                    open={notification.open}
                    message={notification.message}
                    close
                    color="success"
                    onClose={(e) => openNotification(e)}
                />

                <GridContainer>
                    <GridItem xs={6} sm={6} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Dealer id"
                            value={formik.values.id}
                            inputProps={{ readOnly: true }}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={6} sm={6} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="dealerDomain"
                            name="dealerDomain"
                            label="Store url"
                            value={formik.values.dealerDomain}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.dealerDomain && Boolean(formik.errors.dealerDomain)
                            }
                            helperText={formik.touched.dealerDomain && formik.errors.dealerDomain}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="dealerAdminDomain"
                            name="dealerAdminDomain"
                            label="Admin domain"
                            value={formik.values.dealerAdminDomain}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.dealerAdminDomain &&
                                Boolean(formik.errors.dealerAdminDomain)
                            }
                            helperText={
                                formik.touched.dealerAdminDomain && formik.errors.dealerAdminDomain
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="company"
                            name="company"
                            label="Company"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            error={formik.touched.company && Boolean(formik.errors.company)}
                            helperText={formik.touched.company && formik.errors.company}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="address1"
                            name="address1"
                            label="Address 1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            error={formik.touched.address1 && Boolean(formik.errors.address1)}
                            helperText={formik.touched.address1 && formik.errors.address1}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="address2"
                            name="address2"
                            label="Address 2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            error={formik.touched.address2 && Boolean(formik.errors.address2)}
                            helperText={formik.touched.address2 && formik.errors.address2}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="city"
                            name="city"
                            label="City"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="state"
                            name="state"
                            label="State"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="zip"
                            name="zip"
                            label="ZIP"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                            helperText={formik.touched.zip && formik.errors.zip}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="country"
                            name="country"
                            label="Country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="billContact"
                            name="billContact"
                            label="Bill contact"
                            value={formik.values.billContact}
                            onChange={formik.handleChange}
                            error={formik.touched.billContact && Boolean(formik.errors.billContact)}
                            helperText={formik.touched.billContact && formik.errors.billContact}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="billPhone"
                            name="billPhone"
                            label="Bill phone"
                            value={formik.values.billPhone}
                            onChange={formik.handleChange}
                            error={formik.touched.billPhone && Boolean(formik.errors.billPhone)}
                            helperText={formik.touched.billPhone && formik.errors.billPhone}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="billEmail"
                            name="billEmail"
                            label="Bill email"
                            value={formik.values.billEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.billEmail && Boolean(formik.errors.billEmail)}
                            helperText={formik.touched.billEmail && formik.errors.billEmail}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="shipContact"
                            name="shipContact"
                            label="Ship contact"
                            value={formik.values.shipContact}
                            onChange={formik.handleChange}
                            error={formik.touched.shipContact && Boolean(formik.errors.shipContact)}
                            helperText={formik.touched.shipContact && formik.errors.shipContact}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="shipPhone"
                            name="shipPhone"
                            label="Ship phone"
                            value={formik.values.shipPhone}
                            onChange={formik.handleChange}
                            error={formik.touched.shipPhone && Boolean(formik.errors.shipPhone)}
                            helperText={formik.touched.shipPhone && formik.errors.shipPhone}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="shipEmail"
                            name="shipEmail"
                            label="Ship email"
                            value={formik.values.shipEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.shipEmail && Boolean(formik.errors.shipEmail)}
                            helperText={formik.touched.shipEmail && formik.errors.shipEmail}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="partsContact"
                            name="partsContact"
                            label="Parts contact"
                            value={formik.values.partsContact}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.partsContact && Boolean(formik.errors.partsContact)
                            }
                            helperText={formik.touched.partsContact && formik.errors.partsContact}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="partsPhone"
                            name="partsPhone"
                            label="Parts phone"
                            value={formik.values.partsPhone}
                            onChange={formik.handleChange}
                            error={formik.touched.partsPhone && Boolean(formik.errors.partsPhone)}
                            helperText={formik.touched.partsPhone && formik.errors.partsPhone}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="partsEmail"
                            name="partsEmail"
                            label="Parts email"
                            value={formik.values.partsEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.partsEmail && Boolean(formik.errors.partsEmail)}
                            helperText={formik.touched.partsEmail && formik.errors.partsEmail}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="envyMainUser"
                            name="envyMainUser"
                            label="Main user"
                            value={formik.values.envyMainUser}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.envyMainUser && Boolean(formik.errors.envyMainUser)
                            }
                            helperText={formik.touched.envyMainUser && formik.errors.envyMainUser}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TooglePassword
                            password={formik.values.password}
                            helperText={<PasswordChangeDialog onSuccess={handleChangePassword} />}
                        />
                        {/*<TextField
                            fullWidth
                            margin="dense"
                            label="Password"
                            value={showPassword ? atob(formik.values.password) : "***************"}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <>
                                        {isAdmin ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleTogglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null}
                                    </>
                                ),
                            }}
                            helperText={
                                <PasswordChangeDialog onSuccess={() => location.reload()} />
                            }
                        />*/}
                        {/*<TextField
                            fullWidth
                            type={isAdmin === 1 ? "text" : "password"}
                            margin="dense"
                            id="password"
                            name="password"
                            label="Password"
                            value={showPassword ? atob(formik.values.password) : ""}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {isAdmin ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleTogglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null}
                                    </>
                                ),
                            }}
                        />*/}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Box display="flex">
                            <Box flex={1}>
                                <label style={{ fontSize: 12, color: "#9b9b9b" }}>
                                    Enabled languages
                                </label>
                                <TextField
                                    select
                                    fullWidth
                                    value={formik.values.languagesAvailable}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        formik.setFieldValue("languagesAvailable", value);
                                        //setSelectedLanguages(value);
                                    }}
                                    SelectProps={{
                                        multiple: true,
                                        renderValue: (selected) => selected.join(", "),
                                    }}
                                >
                                    {languages.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            <Checkbox
                                                checked={formik.values.languagesAvailable.includes(
                                                    option.id
                                                )}
                                            />
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>

                            <Box flex={1} ml={1} mt={0.55}>
                                <TextField
                                    fullWidth
                                    select
                                    label="Default language"
                                    id="locale"
                                    name="locale"
                                    value={formik.values.locale.substring(0, 2)}
                                    onChange={formik.handleChange}
                                    error={formik.touched.locale && Boolean(formik.errors.locale)}
                                    helperText={formik.touched.locale && formik.errors.locale}
                                >
                                    {languages
                                        .filter((l) =>
                                            formik.values.languagesAvailable.includes(l.id)
                                        )
                                        .map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Box>
                        </Box>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Country"
                            id="country"
                            name="country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                        >
                            {[
                                { id: "USA", value: "USA" },
                                { id: "CAN", value: "Canada" },
                            ].map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Registration Type"
                            id="registrationType"
                            name="registrationType"
                            value={formik.values.registrationType}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.registrationType &&
                                Boolean(formik.errors.registrationType)
                            }
                            helperText={
                                formik.touched.registrationType && formik.errors.registrationType
                            }
                        >
                            {[
                                { id: "Detailed", value: "Detailed" },
                                { id: "Simplified", value: "Simplified" },
                            ].map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </GridItem>
                </GridContainer>

                {/* Luis para mas tarde, en Dealers general vamos a quitar y mover los siguientes campos a la pantalla de Seetings:Assets Root Url, Assets Upload Path , Images Base Url */}
                <GridContainer>
                    <GridItem xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Assets Root Url"
                            margin="dense"
                            id="assetsRootUrl"
                            name="assetsRootUrl"
                            value={formik.values.assetsRootUrl}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.assetsRootUrl && Boolean(formik.errors.assetsRootUrl)
                            }
                            helperText={formik.touched.assetsRootUrl && formik.errors.assetsRootUrl}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Assets Upload Path"
                            margin="dense"
                            id="assetsUploadPath"
                            name="assetsUploadPath"
                            value={formik.values.assetsUploadPath}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.assetsUploadPath &&
                                Boolean(formik.errors.assetsUploadPath)
                            }
                            helperText={
                                formik.touched.assetsUploadPath && formik.errors.assetsUploadPath
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                        <TextField
                            fullWidth
                            label="Images Base Url"
                            margin="dense"
                            id="imagesBaseUrl"
                            name="imagesBaseUrl"
                            value={formik.values.imagesBaseUrl}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.imagesBaseUrl && Boolean(formik.errors.imagesBaseUrl)
                            }
                            helperText={formik.touched.imagesBaseUrl && formik.errors.imagesBaseUrl}
                        />
                    </GridItem>
                </GridContainer>

                <div style={{ textAlign: "center", margin: 10 }}>
                    <Button type="submit" color="rose">
                        Save
                    </Button>
                </div>
            </form>
        </>
    );
}
