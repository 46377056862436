import { useState, useEffect } from "react";
import DealerCatalogService from "services/DealerCatalogService";
import useDealerId from "./useDealerId";

const useDealerCatalogs = (dealerIdFromOrder = "") => {
    const dlid = useDealerId();
    const [catalogs, setCatalogs] = useState([]);

    useEffect(() => {
        const fetchCatalogs = async (dlid) => {
            const catalogs = await DealerCatalogService.getAll(dlid).then((x) => x.data);
            setCatalogs(catalogs);
        };

        if ((dlid || dealerIdFromOrder) && catalogs.length === 0) {
            fetchCatalogs(dlid || dealerIdFromOrder);
        }
    }, [dealerIdFromOrder, dlid]);

    return catalogs;
};

export default useDealerCatalogs;
