import { useState } from "react";
import {
    Box,
    Card,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import { useFormik } from "formik";
import * as yup from "yup";
import TableWrapper from "components/TableWrapper/TableWrapper";
import Button from "components/CustomButtons/Button.js";
import PartSelectionDialog from "views/HomePageSpecials/PartSelectionDialog";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import usePartNumberValidator from "hooks/usePartNumberValidator";
import useDealerId from "hooks/useDealerId";
import useDealerCatalogs from "hooks/useDealerCatalogs";

/*const initialValues = {
    id: "",
    showInCarouselFormat: false,
    ruleName: "",
    mainPartNumber: "",
    products: [],
};*/

export default function CreateRelatedItem({
    initialValues,
    onUpdate = () => {},
    onInsert = () => {},
}) {
    const dealerId = useDealerId();
    const [isValidated, setIsValidated] = useState(null);
    const [partNumber, setPartNumber] = useState("");
    const [partNumberLabel, setPartNumberLabel] = useState("");
    const [catalogName, setCatalogName] = useState("");
    const [show, setShow] = useState(false);
    const { openDialog, closeDialog } = useConfirmationDialog();
    const partValidator = usePartNumberValidator();
    const dealerCatalogs = useDealerCatalogs();

    const validationSchema = yup.object().shape({});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values.id) {
                onUpdate(values);
            } else {
                onInsert(values);
            }
        },
    });

    const columns = [
        {
            Header: "Part number",
            accessor: "part_number",
        },
    ];

    const handleClickSearch = () => {
        openDialog({
            formFrameTitle: `Search results for: ${partNumber}`,
            fullWidth: true,
            maxWidth: "lg",
            message: (
                <PartSelectionDialog
                    partNumber={partNumber}
                    onProductSelect={handleProductSelect}
                />
            ),
        });
    };

    const handleProductSelect = (product) => {
        closeDialog();
        const products = [...formik.values.products];
        formik.setFieldValue("products", [...products, { ...product, status: "new" }]);
        setPartNumber("");
    };

    const handleClickDelete = (id) => {
        const product = [...formik.values.products].find((p) => p.id === id);

        if (!product) return;

        if (product.status === "new") {
            const updatedProducts = [...formik.values.products].filter((p) => p.id !== id);
            formik.setFieldValue("products", updatedProducts);
        }

        if (product.status === "") {
            const updatedProducts = formik.values.products.map((p) =>
                p.id === id ? { ...p, status: "deleted" } : p
            );
            formik.setFieldValue("products", updatedProducts);
        }
    };

    //68246682AA
    //68157106AA
    const handleClickValidatePartNumber = async () => {
        const partNumber = [mainPartNumber];
        const [{ exists = false }] = await partValidator.validatePartNumbers(
            dealerId,
            partNumber,
            catalogName
        );

        if (exists) {
            setIsValidated(true);
            //setError(false);
            //setShow(true);
        } else {
            setIsValidated(false);
            //setError(true);
            //setShow(false);
        }
    };

    const handleClickAdd = (partNumber) => {
        setPartNumberLabel();
        setShow(true);
        setPartNumberLabel(partNumber);
    };

    const mainPartNumber = formik.values.mainPartNumber;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box mb={2}>
                <FormControlLabel
                    control={
                        <Switch
                            name="display"
                            checked={formik.values.display}
                            value={formik.values.display}
                            onChange={formik.handleChange}
                        />
                    }
                    label="Display?"
                />
            </Box>
            <Box mb={2}>
                <TextField
                    fullWidth
                    label="Rule name"
                    name="ruleName"
                    value={formik.values.ruleName}
                    onChange={formik.handleChange}
                />
            </Box>
            <Box display="flex" mb={2}>
                <TextField
                    flex={1}
                    select
                    label="Catalog"
                    value={formik.values.catalogId}
                    onChange={(e) => {
                        const catalogId = e.target.value;
                        const { catalogName = "" } = dealerCatalogs.find((c) => c.id == catalogId);

                        setCatalogName(catalogName);
                        formik.setFieldValue("catalogId", catalogId);
                    }}
                    fullWidth
                    style={{
                        marginRight: 10,
                    }}
                >
                    {dealerCatalogs.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.enDisplayName}
                        </MenuItem>
                    ))}
                </TextField>
                {catalogName !== "" && (
                    <TextField
                        fullWidth
                        label="Main part number"
                        name="mainPartNumber"
                        value={mainPartNumber}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    {(isValidated === null || isValidated === false) && (
                                        <Tooltip title="Validate part number">
                                            <IconButton
                                                onClick={handleClickValidatePartNumber}
                                                disabled={mainPartNumber.length === 0}
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {isValidated === true && (
                                        <Tooltip title="Add part number">
                                            <IconButton
                                                onClick={() => handleClickAdd(mainPartNumber)}
                                                style={{
                                                    color: isValidated ? "#198754" : "",
                                                }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        error={isValidated == false}
                        helperText={
                            isValidated == false
                                ? "Part does not exist"
                                : "Please validate and add the part number"
                        }
                    />
                )}
            </Box>
            {(show || formik.values.products.length > 0) && (
                <Box mb={2}>
                    <Card style={{ padding: 10 }}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ marginTop: 10, marginBottom: 10 }}
                        >
                            Related products for {partNumberLabel}
                        </Typography>
                        <TextField
                            fullWidth
                            label="Part number"
                            value={partNumber}
                            onChange={(e) => setPartNumber(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton
                                            onClick={handleClickSearch}
                                            disabled={partNumber.length === 0}
                                        >
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box>
                            <TableWrapper
                                showDeleteButton
                                columns={columns}
                                data={formik.values.products.filter((p) => p.status !== "deleted")}
                                onDeleteClick={handleClickDelete}
                            />
                        </Box>
                    </Card>
                </Box>
            )}
            <Divider style={{ marginBottom: 20 }} />
            <Box>
                <Button type="submit" color="rose" fullWidth>
                    Save and close
                </Button>
            </Box>
        </form>
    );
}
