import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// @material-ui/core components
import { Box, Tabs, Tab, Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Alert from "@material-ui/lab/Alert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import CreatePageTechMeta from "./CreatePageTechMeta";
import CreatePageTechCont from "./CreatePageTechCont";

// const
import { CREATE_PAGE_TECH, EDIT_PAGE_TECH } from "./DealerPageTech";

//services
import PageTechService from "../../../services/DealerPageTechService";
import PageService from "../../../services/PageService";

const TECH_META_VAL = {
    enMetaTitle: yup.string().required(),
};

const TECH_CONT_VAL = {
    pagetechPosition: yup.string().required(),
    /*enTitle: yup.string().required(),
    esTitle: yup.string().required(),
    ptTitle: yup.string().required(),
    frTitle: yup.string().required()*/
};

const validationSchema = yup.object({
    pageId: yup.string().required(),
    ...TECH_META_VAL,
    ...TECH_CONT_VAL,
});

export default function CreatePageTech({
    dlid = "",
    form = CREATE_PAGE_TECH,
    initialValues,
    onSuccess = () => {},
}) {
    const [tabId, setTabId] = useState(0);
    const [pages, setPages] = useState([
        {
            id: 1,
            pageName: "Landing page",
        },
        {
            id: 2,
            pageName: "Catalog flow years",
        },
        {
            id: 3,
            pageName: "Catalog flow models",
        },
        {
            id: 4,
            pageName: "Catalog flow trims",
        },
        {
            id: 5,
            pageName: "Catalog flow engines",
        },
        {
            id: 6,
            pageName: "Catalog flow categories",
        },
        {
            id: 7,
            pageName: "Catalog flow subcategories",
        },
        {
            id: 8,
            pageName: "Products page",
        },
        {
            id: 9,
            pageName: "Product page",
        },
    ]);

    /*useEffect(() => {
        const getPages = async () => {
            const pages = await PageService.getAll();
            setPages(pages);
        };
        getPages();
    }, []);*/

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            pageId: initialValues.pageId || "",

            //PageTechContent
            pagetechPosition: initialValues.pagetechPosition || "",
            pageName: initialValues.pageName || "",
            enTitle: initialValues.enTitle || "",
            esTitle: initialValues.esTitle || "",
            ptTitle: initialValues.ptTitle || "",
            frTitle: initialValues.frTitle || "",
            enHtml: initialValues.enHtml || "",
            esHtml: initialValues.esHtml || "",
            ptHtml: initialValues.ptHtml || "",
            frHtml: initialValues.frHtml || "",

            //PageTechMeta
            enMetaTitle: initialValues.enMetaTitle || "",
            enMetaDescription: initialValues.enMetaDescription || "",
            enMetaKeywords: initialValues.enMetaKeywords || "",
            esMetaTitle: initialValues.esMetaTitle || "",
            esMetaDescription: initialValues.esMetaDescription || "",
            esMetaKeywords: initialValues.esMetaKeywords || "",
            ptMetaTitle: initialValues.ptMetaTitle || "",
            ptMetaDescription: initialValues.ptMetaDescription || "",
            ptMetaKeywords: initialValues.ptMetaKeywords || "",
            frMetaTitle: initialValues.frMetaTitle || "",
            frMetaDescription: initialValues.frMetaDescription || "",
            frMetaKeywords: initialValues.frMetaKeywords || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_PAGE_TECH) createPageTech({ ...values, dealerId: dlid });
            if (form === EDIT_PAGE_TECH) updatePageTech({ ...values, dealerId: dlid });
        },
    });

    const createPageTech = async (body) => {
        try {
            const techPage = await PageTechService.post(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    };

    const updatePageTech = async (body) => {
        try {
            const techPage = await PageTechService.put(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer style={{ marginBottom: 10 }}>
                        <GridItem xs={12} sm={12} md={3}>
                            <FormControl
                                variant="standard"
                                fullWidth
                                style={{ marginTop: 5 }}
                                error={Boolean(formik.errors.pageId)}
                            >
                                <InputLabel id="demo-simple-select-label">Page</InputLabel>
                                <Select
                                    id="pageId"
                                    name="pageId"
                                    value={formik.values.pageId}
                                    onChange={(e) => {
                                        formik.setFieldValue("pageId", e.target.value);
                                        formik.setFieldValue(
                                            "pageName",
                                            pages.find((p) => p.id === e.target.value)?.pageName ||
                                                ""
                                        );
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={0} value={0}>
                                        Select page
                                    </MenuItem>
                                    {pages.map(({ id, pageName }, _) => (
                                        <MenuItem key={id} value={id}>
                                            {pageName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                    {Object.keys(formik.errors).length > 0 && (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Alert severity="error">
                                    There are fields to complete in the tab:
                                    <ul style={{ padding: 0, marginLeft: 12, marginTop: 0 }}>
                                        {Object.keys(formik.errors).some(
                                            (r) => Object.keys(TECH_META_VAL).indexOf(r) >= 0
                                        ) && (
                                            <li>
                                                <b>pagetech meta</b>
                                            </li>
                                        )}
                                        {Object.keys(formik.errors).some(
                                            (r) => Object.keys(TECH_CONT_VAL).indexOf(r) >= 0
                                        ) && (
                                            <li>
                                                <b> pagetech content</b>
                                            </li>
                                        )}
                                    </ul>
                                </Alert>
                            </GridItem>
                        </GridContainer>
                    )}

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Tabs
                                value={tabId}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(_, newValue) => setTabId(newValue)}
                                TabIndicatorProps={{ style: { background: "#ef662f" } }}
                            >
                                <Tab value={0} label="Header" />
                                <Tab value={1} label="Products" />
                            </Tabs>

                            <Box padding={2}>
                                {tabId === 0 && <CreatePageTechMeta formik={formik} />}
                                {tabId === 1 && <CreatePageTechCont formik={formik} />}
                            </Box>
                        </GridItem>
                    </GridContainer>

                    <div style={{ textAlign: "center", margin: 10 }}>
                        <Button type="submit" color="rose">
                            Save and close
                        </Button>
                    </div>
                </form>
            </GridItem>
        </GridContainer>
    );
}
