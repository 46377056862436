import http from "./Http";

const getAll = async (dlid) => {
    return http.get(`/dealer-misc?dlid=${dlid}`);
};

const put = async (dlid, body) => {
    return http.put(`/dealer-misc/${dlid}`, body, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const putFooter = async (dlid, body = []) => {
    return http.put(`/dealer-misc/footer-options/${dlid}`, body);
};

const putShopFeed = async (dlid, body) => {
    return http.put(`/dealer-misc/google-shopping-feed/${dlid}`, body);
};

const DealerMiscService = {
    getAll,
    put,
    putFooter,
    putShopFeed,
};

export default DealerMiscService;
