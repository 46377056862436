import { useState, useEffect } from "react";
import { useSnackbar } from "./useSnackbar";
import ApiService from "services/ApiService";

function objectToQueryString(obj) {
    if (!obj) return "";
    return Object.entries(obj)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
}

const useCrud = (entity, queryParams = {}, shouldDoUseEffect = true) => {
    const [data, setData] = useState([]);
    const openSnackbar = useSnackbar();

    useEffect(() => {
        if (shouldDoUseEffect) {
            readAllEntities(queryParams);
        }
    }, [shouldDoUseEffect]);

    const createEntity = async (body, callback, showSnackbar = true) => {
        try {
            await ApiService.post(entity, body);

            if (callback) {
                callback();
            } else {
                readAllEntities();
            }

            if (showSnackbar) {
                openSnackbar("success", "Inserted successfully.");
            }
        } catch (error) {
            openSnackbar("error", "Error creating.");
        }
    };

    const readAllEntities = async (queryParams) => {
        setData([]);
        const queryString = objectToQueryString(queryParams);
        try {
            const res = await ApiService.getAll(entity, queryString);
            setData(res.data);
            return res.data;
        } catch (error) {
            openSnackbar("error", "Error fetching.");
        }
    };

    const updateEntity = async (body, callback, showSnackbar = true) => {
        try {
            await ApiService.put(entity, body);

            if (callback) {
                callback();
            } else {
                readAllEntities();
            }

            if (showSnackbar) {
                openSnackbar("success", "Updated successfully.");
            }
        } catch (error) {
            openSnackbar("error", "Error updating.");
        }
    };

    const deleteEntity = async (id, callback) => {
        try {
            await ApiService.del(entity, id);

            if (callback) {
                callback();
            } else {
                readAllEntities();
            }

            openSnackbar("success", "Remove successfully.");
        } catch (error) {
            openSnackbar("error", "Error deleting.");
        }
    };

    const deleteAllEntity = async (rows = [], callback) => {
        try {
            for (let i = 0; i < rows.length; i++) {
                const body = rows[i];
                await ApiService.put(entity, body);
            }

            if (callback) {
                callback();
            } else {
                readAllEntities();
            }

            openSnackbar("success", "Remove successfully.");
        } catch (error) {
            openSnackbar("error", "Error deleting.");
        }
    };

    const deleteAllEntitiesHard = async (ids = [], callback) => {
        try {
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                await ApiService.del(entity, id);
            }

            if (callback) {
                callback();
            } else {
                readAllEntities();
            }

            openSnackbar("success", "Remove successfully.");
        } catch (error) {
            openSnackbar("error", "Error deleting.");
        }
    };

    return {
        data,
        setData,
        createEntity,
        readAllEntities,
        updateEntity,
        deleteEntity,
        deleteAllEntity,
        deleteAllEntitiesHard,
    };
};

export default useCrud;
