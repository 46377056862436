import React from "react";
//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";

export default function AddressForm({
    showTitle = true,
    title = "",
    classes,
    formik,
    addressFields = {},
}) {
    const { address1 } = addressFields;

    return (
        <>
            {showTitle && (
                <GridContainer style={{ marginTop: 20 }}>
                    <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                        <legend style={{ fontSize: 16, fontWeight: "bold" }}>{title}</legend>
                    </GridItem>
                </GridContainer>
            )}

            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                        Address 1:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id={address1.value}
                        name={address1.value}
                        value={formik.values[address1.value]}
                        onChange={formik.handleChange}
                        error={
                            formik.touched[address1.value] && Boolean(formik.errors[address1.value])
                        }
                        helperText={formik.touched[address1.value] && formik.errors[address1.value]}
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                        Address 2:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id={addressFields.address2.value}
                        name={addressFields.address2.value}
                        value={formik.values[addressFields.address2.value]}
                        onChange={formik.handleChange}
                        error={
                            formik.touched[addressFields.address2.value] &&
                            Boolean(formik.errors[addressFields.address2.value])
                        }
                        helperText={
                            formik.touched[addressFields.address2.value] &&
                            formik.errors[addressFields.address2.value]
                        }
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                        City:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={3}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id={addressFields.city.value}
                        name={addressFields.city.value}
                        value={formik.values[addressFields.city.value]}
                        onChange={formik.handleChange}
                        error={
                            formik.touched[addressFields.city.value] &&
                            Boolean(formik.errors[addressFields.city.value])
                        }
                        helperText={
                            formik.touched[addressFields.city.value] &&
                            formik.errors[addressFields.city.value]
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                        ZIP:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={3}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id={addressFields.zip.value}
                        name={addressFields.zip.value}
                        value={formik.values[addressFields.zip.value]}
                        onChange={formik.handleChange}
                        error={
                            formik.touched[addressFields.zip.value] &&
                            Boolean(formik.errors[addressFields.zip.value])
                        }
                        helperText={
                            formik.touched[addressFields.zip.value] &&
                            formik.errors[addressFields.zip.value]
                        }
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                        Country:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={3}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id={addressFields.country.value}
                        name={addressFields.country.value}
                        value={formik.values[addressFields.country.value]}
                        onChange={formik.handleChange}
                        error={
                            formik.touched[addressFields.country.value] &&
                            Boolean(formik.errors[addressFields.country.value])
                        }
                        helperText={
                            formik.touched[addressFields.country.value] &&
                            formik.errors[addressFields.country.value]
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                        State:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={3}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id={addressFields.state.value}
                        name={addressFields.state.value}
                        value={formik.values[addressFields.state.value]}
                        onChange={formik.handleChange}
                        error={
                            formik.touched[addressFields.state.value] &&
                            Boolean(formik.errors[addressFields.state.value])
                        }
                        helperText={
                            formik.touched[addressFields.state.value] &&
                            formik.errors[addressFields.state.value]
                        }
                    />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                        Telephone:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={3}>
                    <TextField
                        fullWidth
                        margin="dense"
                        id={addressFields.telephone.value}
                        name={addressFields.telephone.value}
                        value={formik.values[addressFields.telephone.value]}
                        onChange={formik.handleChange}
                        error={
                            formik.touched[addressFields.telephone.value] &&
                            Boolean(formik.errors[addressFields.telephone.value])
                        }
                        helperText={
                            formik.touched[addressFields.telephone.value] &&
                            formik.errors[addressFields.telephone.value]
                        }
                    />
                </GridItem>
            </GridContainer>
        </>
    );
}
