import { useState, useEffect } from 'react';
import { useSnackbar } from './useSnackbar';
import useDealer from './useDealer';
import CustomPageService from 'services/CustomPageService';

const useCustomPage = () => {
    const openSnackbar = useSnackbar();
    const { id: dealerId } = useDealer();

    const [initialValues, setInitialValues] = useState({
        pages: []
    });

    const fetchPages = async () => {
        try {
            const { data = [] } = await CustomPageService.getAll(dealerId);
            setInitialValues({
                pages: data
            });
        } catch (error) {
            console.error('Error fetching custom page:', error);
        }
    };

    useEffect(() => {
        if (dealerId)
            fetchPages(dealerId);

        return () => {
            setInitialValues({
                pages: []
            });
        }
    }, [dealerId]);

    const handleDeletePage = async (id) => {
        try {
            await CustomPageService.del(id);
            openSnackbar('success', 'Page deleted successfully');
            const updatedPages = initialValues.pages.filter((p) => p.id !== id);
            setInitialValues({
                pages: updatedPages,
            });
        } catch (error) {
            openSnackbar('error', 'An error occurred while deleting the page. Please try again.');
        }
    };

    return {
        pages: initialValues.pages,
        fetchPages,
        handleDeletePage,
    };
}

export default useCustomPage;