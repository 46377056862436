import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

export default function TextFieldRender(props) {
    const { handleBlur = () => {}, mt = 0, ...otherProps } = props;
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        handleBlur(newValue);
    };

    return (
        <TextField
            value={value}
            onChange={handleChange}
            onBlur={() => handleBlur(value)}
            style={{ marginTop: mt }}
            {...otherProps}
        />
    );
}
