import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const getAll = async (dlid) => {
    return http.get(`/dealer-makes?dlid=${dlid}`);
};

const getMakes = async (dlid, catalog) => {
    const makes = await http.get(
        `${BASE_URL_API_ECOMMERCE}/makes?dealerId=${dlid}&region=USA&catalog=${catalog}`
    );
    return makes.data.data;
};

const getYears = async (dlid, makeId, catalog) => {
    const years = await http.get(
        `${BASE_URL_API_ECOMMERCE}/years/makes/${makeId}?dealerId=${dlid}&region=USA&catalog=${catalog}&allYears`
    );
    return years.data.data;
};

const post = async (body) => {
    return http.post("/dealer-makes", body);
};

const DealerMakeService = {
    getAll,
    getMakes,
    getYears,
    post,
};

export default DealerMakeService;
