import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";

export default function MaterialUIPickers({ label = "", date, setDate, ...otherProps }) {
    const defaultDate = date ? new Date(date) : null;

    const handleDateChange = (date) => {
        if (!date) {
            return setDate("");
        }

        const parseDate = Date.parse(date);
        if (!isNaN(parseDate)) {
            setDate(format(date, "MM/dd/yyyy"));
        }
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                fullWidth
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label={label}
                value={defaultDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                style={{ margin: 0 }}
                autoOk
            />
        </MuiPickersUtilsProvider>
    );
}
/*import React from 'react';
import TextField from '@material-ui/core/TextField';

const CalendarPicker = ({ label = '', date, setDate, ...otherProps }) => {
    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    return (
        <TextField
            {...otherProps}
            id="date-picker"
            label={label}
            type="date"
            value={date}
            onChange={handleDateChange}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default CalendarPicker;*/
