import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";
import useFormik from "hooks/useFormik";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

const fields = [
    {
        fieldName: "type",
        required: true,
    },
    {
        fieldName: "name",
        required: true,
    },
    {
        fieldName: "description",
        required: false,
    },
];

export default function CreateTemplate({
    initialValues,
    createEntity = () => {},
    updateEntity = () => {},
    onSuccess = () => {},
}) {
    const handleInsert = (values) => {
        createEntity(values);
        onSuccess();
    };

    const handleUpdate = (values) => {
        updateEntity(values);
        onSuccess();
    };

    const formik = useFormik(fields, initialValues, handleInsert, handleUpdate);

    return (
        <form onSubmit={formik.handleSubmit}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Template Type</FormLabel>
                        <RadioGroup
                            value={formik.values.type}
                            onChange={(event) => formik.setFieldValue("type", event.target.value)}
                        >
                            <FormControlLabel value="orders" control={<Radio />} label="Orders" />
                            <FormControlLabel value="rma" control={<Radio />} label="RMA" />
                        </RadioGroup>
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <TextFieldRender
                        fullWidth
                        variant="outlined"
                        label="Template name"
                        mt={20}
                        value={formik.values.name}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        handleBlur={(value) => formik.setFieldValue("name", value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <TextFieldRender
                        fullWidth
                        variant="outlined"
                        label="Description"
                        multiline
                        rows={10}
                        mt={20}
                        value={formik.values.description}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        handleBlur={(value) => formik.setFieldValue("description", value)}
                    />
                </GridItem>
            </GridContainer>
            <Button type="submit" color="rose" fullWidth>
                Save and close
            </Button>
        </form>
    );
}
