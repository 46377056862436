import http from "./Http";

const post = async (formData) => {
    return http.post(`/email/send-email`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const EmailService = {
    post,
};

export default EmailService;