import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import DealerPricingService from "services/DealerPricingService";
import { useSnackbar } from "./useSnackbar";

const useDealerPricing = () => {
    const openSnackbar = useSnackbar();
    const dealerId = localStorage.getItem("_dlid");
    const [priorityToggle, setPriorityToggle] = useState(false);
    const [inflatedPriceToggle, setInflatedPriceToggle] = useState(false);
    const [catalogId, setCatalogId] = useState("");
    const [makeId, setMakeId] = useState("");
    const [initialValues, setInitialValues] = useState({
        pricing: [],
    });

    const fetchPricing = async (dealerId) => {
        try {
            const { data = [] } = await DealerPricingService.getAll(dealerId, "", "");
            setInitialValues({ pricing: data });
        } catch (error) {
            console.error("Error fetching dealer pricing:", error);
        }
    };

    useEffect(() => {
        if (dealerId) {
            fetchPricing(dealerId, "", "");
        }
    }, [dealerId]);

    const validationSchema = yup.object({});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateDealerPricing(values);
        },
    });

    const handleAddDealerPricing = async (pricingData) => {
        try {
            await DealerPricingService.post({ ...pricingData, dealerId });
            fetchPricing(dealerId, "", "");
            openSnackbar("success", "Dealer pricing inserted successfully.");
        } catch (error) {
            openSnackbar("error", "Error creating dealer pricing.");
        }
    };

    const updateDealerPricing = async (pricingData) => {
        try {
            await DealerPricingService.put(pricingData);
            fetchPricing(dealerId, "", "");
            openSnackbar("success", "Dealer pricing updated successfully.");
        } catch (error) {
            openSnackbar(
                "error",
                "An error occurred while updating dealer pricing. Please try again."
            );
        }
        /*try {
            for (let i = 0; i < pricingData.pricing.length; i++) {
                let pricingItem = pricingData.pricing[i];
                pricingItem = {
                    ...pricingItem,
                    isDefault: pricingItem.id === defaultRuleId,
                };
                await DealerPricingService.put({
                    ...pricingItem,
                    dealerId,
                    catalogId,
                    makeId,
                    priorityToggle,
                    defaultRuleId,
                });
            }
            openSnackbar("success", "Dealer pricing updated successfully.");
        } catch (error) {
            openSnackbar(
                "error",
                "An error occurred while updating dealer pricing. Please try again."
            );
        }*/
    };

    const handleDeleteDealerPricing = async (id) => {
        try {
            await DealerPricingService.del(id);
            fetchPricing(dealerId, "", "");
            openSnackbar("success", "Dealer pricing deleted successfully.");
        } catch (error) {
            openSnackbar("error", "Error deleting dealer pricing.");
        }
    };

    const setAsDefault = async (id) => {
        try {
            await DealerPricingService.setAsDefault(id);
            fetchPricing(dealerId, "", "");
            openSnackbar("success", "Set as default successfully.");
        } catch (error) {
            openSnackbar("error", "An error occurred while updating dealer pricing.");
        }
    };

    return {
        formik,
        catalogId,
        setCatalogId,
        makeId,
        setMakeId,
        handleAddDealerPricing,
        updateDealerPricing,
        handleDeleteDealerPricing,
        priorityToggle,
        setPriorityToggle,
        inflatedPriceToggle,
        setInflatedPriceToggle,
        setAsDefault,
    };
};

export default useDealerPricing;
