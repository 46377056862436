import React from "react";
import { Redirect, Route } from "react-router-dom";
import useUser from "hooks/useUser";
import useMenu from "hooks/useMenu";
import useRoutes from "hooks/useRoutes";

export default function ProtectedRoute({ component: Component, ...restOfProps }) {
    const [user] = useUser();
    const menu = useMenu();
    const isAuthenticated = localStorage.getItem("_gid");
    /*const routesAllowed = menu
        .map(m => ({ ...m, }))
        .reduce((acc, curr) => [...acc, curr, ...(curr.views || [])], [])
        .filter(m => (user?.menuOptions || []).includes(m.id.toString()));*/

    /*if (
        (user?.menuOptions || []).length > 0 &&
        routesAllowed.length > 0 &&
        !(routesAllowed.some(m => `${m.layout}${m.path}` == restOfProps?.location?.pathname))
    ) {
        console.log(
            (user?.menuOptions || []),
            routesAllowed
        )
    }*/

    /*routesAllowed = routesAllowed
        .reduce((acc, curr) => ('views' in curr ? [...acc, ...curr.views] : [...acc, curr]), [])
        .map(r => ({ ...r, pathname: `${r.layout}${r.path}` }));
    const isAuthenticated = localStorage.getItem("_gid");
    const pathName = restOfProps?.location?.pathname;*/

    /*if (
        routes.map(r => ({ ...r, pathname: `${r.layout}${r.path}` })).some(r => user?.isAdmin === 0 && (r.pathname == restOfProps?.location?.pathname && r.onlyAdmin))
    ) {
        return (<Redirect to="/admin" />)
    }*/

    /*if (!routesAllowed.some(r => r.pathname === pathName)) {
        return (<Redirect to="/admin" />)
    }*/

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isAuthenticated ? <Component {...props} /> : <Redirect to="/auth/login-page" />
            }
        />
    );
}
