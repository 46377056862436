import { useFormik } from "formik";
import * as yup from "yup";

const defaultValues = {
    catalogId: "",
    makeId: "",
    customerId: "",
    startRange: "",
    endRange: "",
    costPlus: "",
    listMinus: "",
    minProfit: "",
    inflateMsrp: "",
};

const useDealerPricingForm = (initialValues = defaultValues, formSubmitCallback) => {
    const validationSchema = yup.object({});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            formSubmitCallback(values);
        },
    });

    const resetForm = () => {
        formik.resetForm();
    };

    return {
        formik,
        resetForm,
    };
};

export default useDealerPricingForm;
