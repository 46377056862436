import React, { useState, useEffect, useRef } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// materials
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from '@material-ui/core/InputLabel';

// hooks
import useDealers from "hooks/useDealers";
import useCustomers from "hooks/useCustomers";

// services
import DealerService from "services/DealerService";

export default function DealerCustomers(props) {
    const {
        onDealerChange = () => { },
        onCustomerChange = () => { }
    } = props;
    const [dlid, setDlid] = useState(0);
    const [cid, setCid] = useState(0);
    const dealers = useDealers();
    const customers = useCustomers(dlid);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
                <FormControl
                    variant="standard"
                    fullWidth>
                    <InputLabel id="demo-simple-select-label">Dealer</InputLabel>
                    <Select
                        id="dealerId"
                        name="dealerId"
                        value={dlid}
                        onChange={async (e) => {
                            const dlid = e.target.value;
                            const dealer = await DealerService.get(dlid);
                            setDlid(dlid);
                            setCid(0);
                            onDealerChange(dealer);
                        }}
                        fullWidth
                    >
                        {dealers.length > 1 && <MenuItem key={0} value={0}>Select dealer</MenuItem>}
                        {
                            dealers.map((dealer, index) => <MenuItem key={index} value={dealer.id}>{dealer.id}. {dealer.company}</MenuItem>)
                        }

                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <FormControl
                    disabled={dlid === 0}
                    variant="standard"
                    fullWidth>
                    <InputLabel id="demo-simple-select-label">Customers</InputLabel>
                    <Select
                        id="dealerId"
                        name="dealerId"
                        value={cid}
                        onChange={(e) => {
                            const customer = customers.find(c => c.id === e.target.value) || {};
                            setCid(e.target.value);
                            onCustomerChange(customer);
                        }}
                        fullWidth
                    >
                        {dealers.length > 1 && <MenuItem key={0} value={0}>Select customer</MenuItem>}
                        {
                            customers.map((customer, index) => <MenuItem key={index} value={customer.id}>{customer.billCompany}</MenuItem>)
                        }

                    </Select>
                </FormControl>
            </GridItem>
        </GridContainer>
    )
}