import React, { useState, useEffect } from "react";

//core
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Modal from "components/Modal/Modal.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import CountryService from 'services/CountryService';

const useStyles = makeStyles(styles);

export default function SelectStates(props) {
    const {
        selStates = {
            selectAll: false,
            selItems: []
        },
        setSelStates = () => { },
        openModal,
        setOpenModal = () => { },
        handleClickSave = () => { }
    } = props;
    const classes = useStyles();
    const [states, setStates] = useState([]);

    useEffect(() => {
        const getStates = async () => {
            const states = await CountryService.getStates();
            setStates(states);
        };

        getStates();
    }, []);

    return (
        <>
            <Modal
                open={openModal}
                modalTitle={"United States State List"}
                handleClose={() => {
                    setOpenModal(!openModal)
                }}
            >
                <div style={{ minWidth: 800 }}></div>
                <p>
                    (If nothing is checked, ALL State will be available. Once you checkmark Selected State, only those will be allowed.)
                </p>
                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={6}
                        style={{
                            marginLeft: 30,
                        }}
                    >
                        <b style={{ marginRight: 20 }}>States</b>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={false}
                                    tabIndex={-1}
                                    checked={selStates.selectAll}
                                    onClick={(e) => {
                                        setSelStates({
                                            selectAll: !selStates.selectAll,
                                            selItems: selStates.selectAll ? [] : states.map(c => c.code)
                                        });
                                    }}
                                    checkedIcon={
                                        <Check className={classes.checkedIcon} />
                                    }
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    classes={{
                                        checked: classes.checked,
                                        root: classes.checkRoot,
                                    }}
                                />
                            }
                            label={"Select all"}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer
                    style={{
                        height: "auto"
                    }}
                >
                    {
                        states.map(s => (
                            <GridItem
                                key={s.code}
                                xs={12}
                                sm={12}
                                md={3}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={false}
                                                    tabIndex={-1}
                                                    checked={selStates.selItems.includes(s.code)}
                                                    onClick={(e) => {
                                                        if (selStates.selItems.includes(s.code))
                                                            setSelStates({
                                                                ...selStates,
                                                                selItems: selStates.selItems.filter(sel => sel !== s.code)
                                                            })
                                                        else
                                                            setSelStates({
                                                                ...selStates,
                                                                selItems: [...selStates.selItems, s.code]
                                                            })
                                                    }}
                                                    checkedIcon={
                                                        <Check className={classes.checkedIcon} />
                                                    }
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot,
                                                    }}
                                                />
                                            }
                                            label={s.name}
                                        />
                                    </div>
                                </div>
                            </GridItem>
                        ))
                    }
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 5 }}>
                        <Button
                            type="button"
                            color="rose"
                            onClick={() => {
                                handleClickSave();
                                setOpenModal(!openModal);
                            }}
                        >Save and close</Button>
                    </GridItem>
                </GridContainer>
            </Modal>
        </>
    )
}