import { useState, useEffect } from "react";
import { useFieldUpdater } from "./useFieldUpdater";
import useCrud from "./useCrud";
import useUser from "./useUser";
import OrderService from "services/OrderService";

const orderStatusList = [
    {
        value: 1,
        label: "New Orders",
    },
    {
        value: 2,
        label: "In Stock",
    },
    {
        value: 3,
        label: "Pending Approval",
    },
    {
        value: 4,
        label: "On Order",
    },
    {
        value: 5,
        label: "Completed",
    },
    {
        value: 6,
        label: "Declined",
    },
];

const shippingStatusList = [
    {
        value: 1,
        label: "Shipped",
    },
    {
        value: 2,
        label: "Picked up",
    },
];

const declinedReasonList = [
    {
        value: 1,
        label: "Long Back Order (1-30 Days)",
    },
    {
        value: 2,
        label: "Long Back Order (1-2 Months)",
    },
    {
        value: 3,
        label: "Long Back order (2 + Months)",
    },
    {
        value: 4,
        label: "Item/s Discontinued",
    },
    {
        value: 5,
        label: "Item/s Pricing Error",
    },
    {
        value: 6,
        label: "The customer no longer wants the item/s",
    },
    {
        value: 7,
        label: "Item/s no longer available",
    },
    {
        value: 8,
        label: "Shipping cost is incorrect",
    },
    {
        value: 9,
        label:
            "The payment method declined; unable to contact the customer for a new payment method",
    },
    {
        value: 10,
        label: "High Risk Order",
    },
    {
        value: 11,
        label: "Oversized item (Cannot be shipped)",
    },
];

const initialValues = {
    doNotSendEmail: false,
    addApprovalText: false,
    orderStatusId: "",
    shippingStatusId: "",
    declinedReason: "",
    templateId: "",
    messageText: "",
};

const useCustomerCommunication = (dealerId, orderNumber, order) => {
    const [user] = useUser();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(initialValues);
    const { data: communications = [], readAllEntities, createEntity } = useCrud(
        "dealer_communications",
        {},
        false
    );
    const { readAllEntities: fetchDealer } = useCrud("dealers", {}, false);

    const fetchCommunications = async () => {
        const params = {
            dealer_id: dealerId,
            order_number: orderNumber,
        };

        const comm = await readAllEntities(params);

        /*if (comm.length > 0) {
            const { orderStatusId = "" } = comm[comm.length - 1];
            updateFieldValue(setValues, "orderStatusId", orderStatusId);
        }*/
    };

    useEffect(() => {
        if (dealerId && orderNumber) {
            fetchCommunications();
            updateFieldValue(setValues, "orderStatusId", order.orderStatusId);
            updateFieldValue(setValues, "shippingStatusId", order.shippingStatusId);
            updateFieldValue(setValues, "declinedReason", order.declinedReasonId);
        }
    }, [dealerId, orderNumber]);

    const { data: templates = [] } = useCrud("templates");
    const { updateFieldValue } = useFieldUpdater();

    const handleClick = async (order, commValues, prevOrderStatusId, nextOrderStatusId) => {
        const prevOrderStatus =
            orderStatusList.find((os) => os.value == prevOrderStatusId)?.label || "";
        const nextOrderStatus =
            orderStatusList.find((os) => os.value == nextOrderStatusId)?.label || "";

        setLoading(true);
        await updateOrderStatus(prevOrderStatus, nextOrderStatus);
        await fetchCommunications();
        //await insertNotification(order, commValues);
        setLoading(false);
    };

    const getMessageText = (orderStatusId, shippingStatusId, declinedReason) => {
        if (orderStatusId === 5) {
            if (shippingStatusId) {
                const shippingStatus = shippingStatusList.find(
                    (ss) => ss.value === shippingStatusId
                );
                return `Order status: Completed, Shipping status: ${shippingStatus.label}`;
            } else {
                return "Order status: Completed";
            }
        } else if (orderStatusId === 6) {
            if (declinedReason) {
                const reason = declinedReasonList.find((dr) => dr.value === declinedReason);
                return `Order status: Declined, Reason: ${reason.label}`;
            } else {
                return "Order status: Declined";
            }
        } else {
            const orderStatus = orderStatusList.find((os) => os.value === orderStatusId);
            let message = `Order status: ${orderStatus.label}`;

            if (shippingStatusId) {
                const shippingStatus = shippingStatusList.find(
                    (ss) => ss.value === shippingStatusId
                );
                message += `, Shipping status: ${shippingStatus.label}`;
            }

            return message;
        }
    };

    //
    const updateOrderStatus = async (prevOrderStatus, nextOrderStatus) => {
        try {
            const { id = 0, completeName: userName = "" } = user;
            let messageText = getMessageText(
                values.orderStatusId,
                values.shippingStatusId,
                values.declinedReason
            );

            const body = {
                ...values,
                dealerId: dealerId,
                orderNumber: orderNumber,
                userId: id,
                userName: userName,
                type: "CUSTOMER_COMMUNICATION",
                messageText,
                prevOrderStatus,
                nextOrderStatus,
            };
            await OrderService.updateOrderStatus(body);
            setValues(initialValues);
        } catch (error) {
            console.log("error", error);
        }
    };

    const insertNotification = async (order, commValues) => {
        try {
            const [dealer] = await fetchDealer({ id: order.dealerId });
            const subject = `Your order #${order.orderNumber} has been updated`;

            const newOrderStatus = orderStatusList.find(
                (os) => os.value == commValues.orderStatusId
            );
            const newShipStatus = shippingStatusList.find(
                (st) => st.value == commValues.shippingStatusId
            );

            const body = `
            <p>Dear ${order.billCompany},</p>
            <p>
                Your order #${
                    order.orderNumber
                } has been updated. Please log in to your account to see the changes made.
            </p>
            ${commValues.orderStatusId ? `<p>Order status: ${newOrderStatus.label}</p>` : ""}
            ${commValues.shippingStatusId ? `<p>Shipping Status: ${newShipStatus.label}</p>` : ""}
            `;

            const communicationEntity = {
                dealer_id: order.dealerId,
                order_number: order.orderNumber,
                from_name: dealer.company,
                from_mail: dealer.notificationEmail,
                to_name: order.billCompany,
                to_mail: order.billEmail,
                subject: subject,
                message: body,
                folder_id: 1,
            };

            await createEntity(communicationEntity, () => {}, false);
        } catch (error) {
            console.error("Error registering notification");
        }
    };

    const orderStatus = orderStatusList.reduce(
        (obj, item) => ({ ...obj, [item.value]: item.label }),
        {}
    );

    return {
        templates,
        values,
        setValues,
        updateFieldValue,
        orderStatusList,
        shippingStatusList,
        declinedReasonList,
        handleClick,
        communications: communications
            .map((c) => ({
                ...c,
                orderStatus: c.orderStatusId in orderStatus ? orderStatus[c.orderStatusId] : "",
            }))
            .sort((a, b) => b.id - a.id),
        loading,
    };
};

export default useCustomerCommunication;
