import React from "react";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import RequestActions from "./RequestActions";
import TextFieldRender from 'components/TextFieldRender/TextFieldRender';
import CatalogSelector from "components/CatalogSelector/CatalogSelector";

import useRequestForm from "hooks/useRequestForm";
import useRequestValidation from "hooks/useRequestValidation";

export default function PriceIncorrectForm({ initialValues = {}, onSuccess = () => { } }) {
    const { submitRequest, onApprove, onDecline, onMarkAsOpen } = useRequestForm(onSuccess);
    const formik = useRequestValidation('price-incorrect', submitRequest, initialValues);

    const handleCatalogChange = (e) => formik.setFieldValue("catalogId", e.target.value);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Typography variant="body1">
                    Please provide us with the correct pricing and
                    <br /> allow us 1 business day  to make the changes.
                </Typography>

                <Box my={2}>
                    <CatalogSelector
                        value={formik.values.catalogId}
                        onChange={handleCatalogChange}
                        error={formik.touched.catalogId && Boolean(formik.errors.catalogId)}
                    />
                </Box>
                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="Part#:"
                        variant="outlined"
                        size="small"
                        value={formik.values.partNumber}
                        handleBlur={(value) => formik.setFieldValue("partNumber", value)}
                        error={formik.touched.partNumber && Boolean(formik.errors.partNumber)}
                    />
                </Box>
                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="List Price:"
                        type="number"
                        variant="outlined"
                        size="small"
                        value={formik.values.listPrice}
                        handleBlur={(value) => formik.setFieldValue("listPrice", parseFloat(value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        error={formik.touched.listPrice && Boolean(formik.errors.listPrice)}

                    />
                </Box>
                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="Cost:"
                        type="number"
                        variant="outlined"
                        size="small"
                        value={formik.values.cost}
                        handleBlur={(value) => formik.setFieldValue("cost", parseFloat(value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        error={formik.touched.cost && Boolean(formik.errors.cost)}
                    />
                </Box>
                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="Core Price:"
                        type="number"
                        variant="outlined"
                        size="small"
                        value={formik.values.costPrice}
                        handleBlur={(value) => formik.setFieldValue("costPrice", parseFloat(value))}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        error={formik.touched.costPrice && Boolean(formik.errors.costPrice)}
                    />
                </Box>
                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="Comment:"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formik.values.comment}
                        handleBlur={(value) => formik.setFieldValue("comment", value)}
                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                    />
                </Box>
                <RequestActions
                    initialValues={initialValues}
                    formik={formik}
                    onApprove={onApprove}
                    onDecline={onDecline}
                    onMarkAsOpen={onMarkAsOpen}
                />
            </Box>
        </form>
    )
}
