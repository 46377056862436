import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CustomerService from "services/CustomerService";

export default function CustomerSelector({ dealerId, customerId = 0, setCustomerId = () => {} }) {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        const getCustomers = async (dealerId) => {
            const { data: customers = [] } = await CustomerService.getAll(
                dealerId,
                "",
                0,
                10000000
            );
            setCustomers(customers.data);
        };

        if (dealerId) {
            getCustomers(dealerId);
        }

        return () => {
            setCustomers([]);
        };
    }, [dealerId]);

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-label">Customers</InputLabel>
            <Select
                id="dealerId"
                name="dealerId"
                value={customerId}
                onChange={(e) => setCustomerId(e)}
                fullWidth
            >
                <MenuItem key={0} value={0}>
                    Select customer
                </MenuItem>

                {customers.map((customer, index) => (
                    <MenuItem key={index} value={customer.id}>
                        {customer.billCompany}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
