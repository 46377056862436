import http from "./Http";

const getCountries = async () => {
    const data = await http.get(`/countries`);
    return data.data;
};

const getStates = async () => {
    const data = await http.get(`/states`);
    return data.data;
};

const postCountries = async (id, body) => {
    return http.post(`/countries/${id}`, body);
};

const postStates = async (id, body) => {
    return http.post(`/states/${id}`, body);
};

const CountryService = {
    getCountries,
    getStates,
    postCountries,
    postStates
};

export default CountryService;