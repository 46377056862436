import { useState } from "react";

const useSelection = () => {
    const [selectedAll, setSelectedAll] = useState(false);
    const [selected, setSelected] = useState([]);

    const handleClickSelectAll = (allItemIds = []) => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);

        if (newSelectedAll) {
            setSelected(allItemIds);
        } else {
            setSelected([]);
        }
    };

    const handleClickSelectItem = (itemId) => {
        setSelected((prevSelected) => {
            if (prevSelected.includes(itemId)) {
                return prevSelected.filter((id) => id !== itemId);
            } else {
                return [...prevSelected, itemId];
            }
        });
    };

    return {
        selectedAll,
        setSelectedAll,
        selected,
        setSelected,
        handleClickSelectAll,
        handleClickSelectItem,
    };
};

export default useSelection;
