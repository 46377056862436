import { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import DealerService from "services/DealerService";
import useDealer from "hooks/useDealer";

export default function CatalogSelector({ value, onChange, ...otherProps }) {
    const { id: dealerId } = useDealer();
    const [catalogs, setCatalogs] = useState([]);

    useEffect(() => {
        const fetchDealer = async (dealerId) => {
            try {
                const response = await DealerService.get(dealerId);
                setCatalogs(response.catalogs);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (dealerId) fetchDealer(dealerId);
    }, [dealerId]);

    return (
        <FormControl fullWidth size="small" {...otherProps}>
            <InputLabel>Catalog</InputLabel>
            <Select value={value} onChange={onChange} label="Catalog">
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {catalogs.map((item) => (
                    <MenuItem key={item.catalogId} value={item.catalogId}>
                        {item.enDisplayName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
