import { useState } from "react";
import PageLayout from "layouts/PageLayout";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/ReactTable/ReactTableV2";
import CalendarPicker from "components/CalendarPicker/CalendarPicker";
import useActivityLog from "hooks/useActivityLog";
import moment from 'moment';

const ActivityLog = () => {
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const { activityLog, changePagination, pageCount } = useActivityLog(fromDate, toDate);

    const columns = [
        {
            Header: 'Date',
            accessor: 'formattedEventDate',
        },
        {
            Header: 'Name',
            accessor: 'eventDescription',
        },
    ];

    const fetchData = async ({ pageIndex, pageSize }) => changePagination(pageIndex, pageSize);

    return (
        <PageLayout>
            <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                    <CalendarPicker label="From date" date={fromDate} setDate={setFromDate} />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <CalendarPicker label="To date" date={toDate} setDate={setToDate} />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={() => {

                        }}>
                        Go
                    </Button>
                </GridItem>
            </GridContainer>
            <br />
            <Table
                columns={columns}
                data={activityLog.logs}
                fetchData={fetchData}
                pageCount={pageCount}
            />
        </PageLayout>
    );
};

export default ActivityLog;
