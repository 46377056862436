import { useLocation } from "react-router-dom";
import PageLayout from "layouts/PageLayout";
import CommunicationHub from "components/CommunicationHub/CommunicationHub";

export default function MessageCenter() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderNumber = searchParams.get("orderNumber");
    const notificationId = searchParams.get("nId");

    return (
        <PageLayout>
            <CommunicationHub orderNumber={orderNumber} notificationId={notificationId} />
        </PageLayout>
    );
}
