import { useState, useEffect } from "react";
import RoleService from "services/RoleService";

const useDealerRoles = () => {
    const [roles, setRoles] = useState([]);

    const getRoles = async () => {
        try {
            const roles = await RoleService.getAll();
            setRoles(roles);
        } catch (error) {
            console.error("Error fetching roles: ", error);
        }
    };

    useEffect(() => {
        getRoles(); // Llamamos a getRoles cuando se monta el hook
    }, []);

    // Function to create a new role
    const createRole = async (body, callback) => {
        try {
            const role = await RoleService.post(body);
            if (callback) callback(null, role);
        } catch (error) {
            console.error("Error creating role: ", error);
            if (callback) callback(error);
        }
    };

    // Function to update an existing role
    const updateRole = async (body, callback) => {
        try {
            const role = await RoleService.put(body);
            if (callback) callback(null, role);
        } catch (error) {
            console.error("Error updating role: ", error);
            if (callback) callback(error);
        }
    };

    // Function to delete a role
    const deleteRole = async (id, callback) => {
        try {
            await RoleService.del(id);
            if (callback) callback(null);
        } catch (error) {
            console.error("Error deleting role: ", error);
            if (callback) callback(error);
        }
    };

    return { roles, setRoles, getRoles, createRole, updateRole, deleteRole };
};

export default useDealerRoles;
