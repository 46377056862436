import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import VehicleService from 'services/VehicleService';

export default function YearSelector({ dlid, makeId, selectedYear, handleChange }) {
    const [years, setYears] = useState([]);

    useEffect(() => {
        if (dlid && makeId) loadYears(dlid, makeId);
    }, [dlid, makeId]);

    const loadYears = async () => {
        try {
            const years = await VehicleService.getYears({ dlid: 13, makeId: 1, catalog: 'isuzu' });
            setYears(years);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <TextField
            select
            label="Year"
            value={selectedYear}
            onChange={handleChange}
            variant="outlined"
            fullWidth
        >
            {years.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};