import useDealer from "hooks/useDealer";
import { BASE_URL_API } from "services/Http";

export default function InputFile({ onFileUpload = () => { }, ...otherProps }) {
    const { id: dealerId } = useDealer();
    const baseUrlApi = BASE_URL_API.replace('/api', '');

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append("files", file);

            fetch(`${baseUrlApi}/upload?dlid=${dealerId}`, {
                method: "POST",
                body: formData,
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error("Error al subir el archivo.");
                    }
                })
                .then((data) => {
                    onFileUpload(data);
                })
                .catch((error) => {
                    //console.error("Error:", error);
                });
        }
    };

    return (
        <input
            {...otherProps}
            type="file"
            onChange={handleFileChange}
        />
    );
}
