import React, { useState, useEffect } from "react";

// core components
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

//libs
import { useFormik } from 'formik';
import * as yup from 'yup';
import useUser from "hooks/useUser";

//services
import PaymentService from "services/PaymentService";

const validationSchema = {
    amountToBeCharged: yup.number().required().min(1),
    reason: yup.string().required()
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        '&:hover': {
        },
    },
    fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function CCchargeForm({ dealerId, orderId, fullAmount = 0, onSuccess = () => { }, onError = () => { } }) {
    const classes = useStyles();
    const [user] = useUser();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            dealerId,
            orderId,
            userId: user?.id || 0,
            fullAmount: Number(fullAmount),
            amountToBeCharged: 0,
            reason: ''
        },
        validationSchema: yup.object(validationSchema),
        onSubmit: async (values) => {
            setLoading(true);
            await postPayment(values);
            setLoading(false);
        },
    });

    const postPayment = async body => {
        try {
            await PaymentService.post({
                dealerId: body.dealerId,
                orderId: body.orderId,
                userId: body.userId,
                transactionAmount: body.amountToBeCharged,
                reason: body.reason
            })
                .then((response) => onSuccess())
                .catch(function (error) {
                    if (error.response) {
                        onError(error.response.data.message);
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="billCompany"
                            name="billCompany"
                            label="Full amount of the order"
                            value={formik.values.fullAmount}
                            InputProps={{
                                readOnly: true
                            }}
                            disabled
                        />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <TextField
                            type="number"
                            fullWidth
                            margin="dense"
                            id="amountToBeCharged"
                            name="amountToBeCharged"
                            label="Amount to be charged"
                            value={formik.values.amountToBeCharged}
                            onChange={formik.handleChange}
                            error={formik.touched.amountToBeCharged && Boolean(formik.errors.amountToBeCharged)}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="reason"
                            name="reason"
                            label="Reason"
                            multiline
                            rows={4}
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            error={formik.touched.reason && Boolean(formik.errors.reason)}
                            inputProps={{
                                maxLength: 255,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 10 }}>
                        <div className={classes.wrapper}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                            >
                                {loading ? "processing charge" : "Apply"}
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </GridItem>
                </GridContainer>
            </form>
        </>
    )
}