import React, { useState, useEffect } from "react";
import ReactTable from "components/ReactTable/ReactTable.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, IconButton, Input } from "@material-ui/core";

// @material-ui/icons
import { Check, Clear, RestoreSharp } from "@material-ui/icons";

import ShippingService from '../../services/ShippingService';

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

function TextField({ type = 'text', initialValue = '', onChange }) {
    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <Input
        type={type}
        defaultValue={initialValue}
        onChange={({ target: { value } }) => setValue(value)}
        onBlur={onChange} />;
}

export default function DealerShipping({ dlid = 0 }) {
    const classes = useStyles();
    const [notification, setNotification] = useState({
        open: false,
        color: 'success'
    });
    const [shipments, setShipments] = useState([]);

    useEffect(() => {
        const getShipments = async (dlid) => {
            const shipments = await ShippingService.getAll(dlid).then(x => x.data);
            setShipments(shipments);
        };

        getShipments(dlid);
    }, [dlid]);

    const updateShipment = async (rowIdx) => {
        const shipment = shipments[rowIdx];
        await ShippingService.put(shipment);
    }

    const handleClick = async ({ target: { checked } }, shipment) => {
        if (checked) {
            const res = await ShippingService.post({
                ...shipment,
                dealerId: dlid
            }).then(x => x.data);

            setShipments(
                [
                    ...shipments.filter(x => x.shippingId !== shipment.shippingId),
                    {
                        ...shipment,
                        dealerShippingId: res.dealerShippingId,
                        isChecked: checked
                    }
                ]
            );

        } else {
            await ShippingService.del(shipment.dealerShippingId);

            setShipments(
                [
                    ...shipments.filter(x => x.shippingId !== shipment.shippingId),
                    {
                        ...shipment,
                        displayName: '',
                        profitPercent: '',
                        memo: '',
                        isChecked: checked
                    }
                ]
            );
        }
    }

    const columns = [
        {
            Header: "",
            accessor: "isChecked",
            Cell: (props) => {
                const rowIdx = props.row.id;
                const shipment = shipments[rowIdx];

                return <>
                    <div style={{ textAlign: 'center' }}>
                        <Checkbox
                            tabIndex={-1}
                            checked={shipment.isChecked}
                            onClick={(e) => handleClick(e, shipment)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                            }}
                        />
                    </div>
                </>
            }
        },
        {
            Header: "",
            accessor: "name",
        },
        {
            Header: "displayName",
            accessor: "Display name",
            Cell: (props) => {
                const rowIdx = props.row.id;
                const shipment = shipments[rowIdx];

                return <>
                    <TextField initialValue={shipment.displayName} onChange={(e) => {
                        if (shipment.displayName != e.target.value)
                            setShipments(
                                [
                                    ...shipments.filter(x => x.shippingId !== shipment.shippingId),
                                    {
                                        ...shipment,
                                        displayName: e.target.value
                                    }
                                ]
                            );
                    }} />
                </>
            }
        },
        {
            Header: "profitPercent",
            accessor: "Profit Percent",
            Cell: (props) => {
                const rowIdx = props.row.id;
                const shipment = shipments[rowIdx];

                return <>
                    <div style={{ float: 'right' }}>
                        <TextField type={'number'} initialValue={shipment.profitPercent} onChange={(e) => {
                            setShipments(
                                [
                                    ...shipments.filter(x => x.shippingId !== shipment.shippingId),
                                    {
                                        ...shipment,
                                        profitPercent: e.target.value
                                    }
                                ]
                            );
                        }} />
                    </div>
                </>
            }
        },
        {
            Header: "Memo",
            accessor: "memo",
            Cell: (props) => {
                const rowIdx = props.row.id;
                const shipment = shipments[rowIdx];

                return <>
                    <TextField initialValue={shipment.memo} onChange={(e) => {
                        setShipments(
                            [
                                ...shipments.filter(x => x.shippingId !== shipment.shippingId),
                                {
                                    ...shipment,
                                    memo: e.target.value
                                }
                            ]
                        );
                    }} />
                </>
            }
        },
        {
            Header: "",
            accessor: "actions",
            Cell: (props) => {
                const rowIdx = props.row.id;
                const shipment = shipments[rowIdx];

                return <>
                    <div style={{ textAlign: 'center' }}>
                        <IconButton
                            disabled={shipment.dealerShippingId === null}
                            style={{ color: shipment.dealerShippingId === null ? '#00000042' : '#4caf50' }}
                            onClick={() => {
                                updateShipment(rowIdx);
                            }}>
                            <Check />
                        </IconButton>
                    </div>
                </>
            }
        },
    ];

    return (
        <>
            <ReactTable columns={columns} data={shipments.sort((a, b) => a.shippingId - b.shippingId)} />
        </>
    );
}