import React, { useState, useEffect, useRef } from "react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import useUser from "hooks/useUser.js";

// custom components
import CreateDealer from "./CreateDealer.js";

import DealerService from '../../services/DealerService';

export const CREATE = 'CREATE';
export const EDIT = 'EDIT';

export default function Dealers() {
    const [user] = useUser();
    const [dlid, setDlid] = useState(0);
    const [dealers, setDealers] = useState([]);
    const [tabId, setTabId] = useState(0);
    const [form, setForm] = useState(CREATE);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        color: 'success'
    });
    const userRef = useRef(null);

    /*useEffect(() => {
        if (userRef.current != user?.dealerId && user?.dealerId) {
            userRef.current = user?.dealerId;
            setDlid(user?.dealerId);
        }
    }, [user?.dealerId])

    useEffect(() => {
        const getDealers = async (dlid) => {
            const dealers = await DealerService.getAll(dlid);
            setDealers(dealers.data);
        };
        if (dlid != 0) getDealers(dlid);
    }, [dlid]);*/

    useEffect(() => {
        const getDealers = async (dlid) => {
            const dealers = await DealerService.getAll(dlid).then(o => o.data);
            if (dealers.length === 1) {
                setDlid(dealers[0]?.id);
                setInitialValues(dealers[0]);
            }
            setDealers(dealers);
        };
        if (user?.isAdmin === 0) {
            getDealers(user?.dealerId);
        } else {
            getDealers('');
        }
    }, [dlid, user?.dealerId]);

    const onSuccess = (dealer) => {
        if (form === CREATE) {
            setDealers([...dealers, dealer]);
            setInitialValues(dealer);
        }


        if (form === EDIT) {
            const nDealers = dealers.map(c => c.id === dealer.id ? { ...c, ...dealer } : c);
            setDealers(nDealers);
        }
    }

    if (user?.isAdmin === 0)
        return (
            <CreateDealer
                form={EDIT}
                initialValues={initialValues}
                tabId={tabId}
                onChangeTab={(e) => setTabId(e)}
                onSuccess={onSuccess}
            />
        )

    return (
        <>
            {notification.open && <Notification open={notification.open} color={notification.color} message={notification.message} />}

            <Modal open={openModal} modalTitle={form.title} handleClose={() => setOpenModal(false)}>
                {openModal &&
                    <CreateDealer
                        form={form}
                        initialValues={initialValues}
                        tabId={tabId}
                        onChangeTab={(e) => setTabId(e)}
                        onSuccess={onSuccess}
                    />
                }
            </Modal>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            {user?.isAdmin === 1 && <Button color="primary" onClick={() => {
                                setTabId(0);
                                setForm(CREATE);
                                setOpenModal(true);
                                setInitialValues({});
                            }}>
                                Create dealer
                            </Button>}
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                showPagination={true}
                                showSorting={true}
                                columns={[
                                    {
                                        Header: "Id",
                                        accessor: "id",
                                    },
                                    {
                                        Header: "Dealer domain",
                                        accessor: "dealerDomain",
                                    },
                                    {
                                        Header: "Company",
                                        accessor: "company",
                                    },
                                    {
                                        Header: "Address 1",
                                        accessor: "address1",
                                    },
                                    {
                                        Header: "Address 2",
                                        accessor: "address2",
                                    },
                                    {
                                        Header: "City",
                                        accessor: "city",
                                    },
                                    {
                                        Header: "State",
                                        accessor: "state",
                                    },
                                    {
                                        Header: "ZIP",
                                        accessor: "zip",
                                    },
                                    {
                                        Header: "Actions",
                                        Cell: (props) => {
                                            const rowIdx = props.row.id;
                                            const dealer = dealers[rowIdx];

                                            return (
                                                <>
                                                    <div className="actions-right">
                                                        <Button
                                                            justIcon
                                                            round
                                                            simple
                                                            color="warning"
                                                            className="edit"
                                                            onClick={() => {
                                                                setTabId(0);
                                                                setForm(EDIT)
                                                                setOpenModal(true);
                                                                setInitialValues(dealer);
                                                            }}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                        {user.isAdmin === 1 && <Button
                                                            justIcon
                                                            round
                                                            simple
                                                            color="danger"
                                                            className="edit"
                                                            onClick={() => {
                                                                alert('Delete');
                                                            }}
                                                        >
                                                            <Close />
                                                        </Button>}
                                                    </div>
                                                </>
                                            );
                                        }
                                    }
                                ]}
                                data={dealers}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}