import React, { useState, useEffect } from "react";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.js";
import Notification from "components/Notification/Notification.js";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

// @material-ui/icons
import Check from "@material-ui/icons/Check";

//services
import DealerMakeService from '../../../services/DealerMakeService';

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

function DealerYears({ years = [], setYears = () => { }, dlid = 0, makeId = 0, makes = [], setMakes = () => { } }) {
    const classes = useStyles();
    const selYears = (makes.find(m => m.makeId === makeId)?.years || []);

    useEffect(() => {
        const getYears = async (dlid, makeId) => {
            const years = await DealerMakeService.getYears(dlid, makeId).then(res => res.data.data || []);
            setYears(years);
        }

        if (dlid && makeId) getYears(dlid, makeId);
    }, [dlid, makeId]);

    return (
        <>
            {
                years.map(year => {
                    const disabled = selYears.includes(year.name) && selYears.length === 1;

                    return (
                        <GridItem xs={12} sm={12} md={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        disabled={disabled}
                                        checked={selYears.includes(year.name)}
                                        onClick={(e) => {
                                            setMakes(
                                                makes.map(m => m.makeId === makeId ?
                                                    {
                                                        ...m,
                                                        ...{
                                                            years: e.target.checked ? [...m.years, year.name] : m.years.filter(y => y != year.name)
                                                        }
                                                    } :
                                                    m
                                                )
                                            );
                                        }}
                                        checkedIcon={
                                            <Check className={classes.checkedIcon} />
                                        }
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                        }}
                                    />
                                }
                                label={year.name}
                            />
                        </GridItem>
                    );
                })
            }
        </>
    );
}

export default function DealerMakes({ dlid }) {
    const classes = useStyles();
    const [years, setYears] = useState([]);
    const [selMake, setSelMake] = useState({});
    const [labelVisible, setLabelVisible] = useState(-1);
    const [rowVisible, setRowVisible] = useState(-1);
    const [makes, setMakes] = useState([]);
    const [notification, setNotification] = useState({
        open: false,
        message: 'Dealer makes updated successfully'
    });

    useEffect(() => {
        const getMakes = async (dlid) => {
            const makes = await DealerMakeService.getAll(dlid).then(res => res.data.map(r => {
                return {
                    ...r,
                    years: r.years || [],
                    isChecked: r.isChecked
                };
            }));
            setMakes(makes);
        };

        if (dlid) getMakes(dlid);
    }, [dlid]);

    const handleClickCheckbox = (e, index, make) => {
        if (e.target.checked) {
            setSelMake(make);
            setRowVisible(index);
        } else {
            setRowVisible(-1);
        }

        setMakes(
            makes.map(m => m.makeId === make.makeId ?
                {
                    ...m,
                    ...{
                        isChecked: !m.isChecked
                    }
                } :
                m
            )
        );
    }

    const handleClickSave = async () => {
        const body = {
            dealerId: dlid,
            makes
        };

        const dealerMakes = await DealerMakeService.post(body).then(x => x.data);
        openNotification(true, notification.message);
    }

    const openNotification = (open = notification.open, message = notification.message) => setNotification({
        ...notification,
        open,
        message
    });

    const selectAll = (makeId) => {
        setMakes(
            makes.map(m => m.makeId === makeId ?
                {
                    ...m,
                    ...{
                        years: years.map(y => y.name)
                    }
                } :
                m
            )
        );
    }

    const unselectAll = (makeId) => {
        setMakes(
            makes.map(m => m.makeId === makeId ?
                {
                    ...m,
                    ...{
                        years: m.years.filter((_, i) => i === 0)
                    }
                } :
                m
            )
        );
    }

    const templateEditYears = (dlid, { makeId = 0, displayName = '' } = {}) => {
        return (
            <GridContainer style={{ margin: 10 }}>
                <GridItem xs={12} sm={12} md={12}>
                    <Typography variant="body2" gutterBottom>
                        {displayName} years:
                        <Link href="javascript: void(0)" onClick={() => selectAll(makeId)} style={{ fontSize: 12, marginLeft: 5, marginRight: 5 }}>
                            Select all
                        </Link>
                        /
                        <Link href="javascript: void(0)" onClick={() => unselectAll(makeId)} style={{ fontSize: 12, marginLeft: 5 }}>
                            Unselect all
                        </Link>
                    </Typography>
                    <GridContainer style={{ margin: 10 }}>
                        <DealerYears
                            years={years}
                            setYears={setYears}
                            dlid={dlid}
                            makeId={makeId}
                            makes={makes}
                            setMakes={setMakes} />
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    };

    return (
        <>
            <Notification
                open={notification.open}
                message={notification.message}
                close
                color="success"
                onClose={(e) => openNotification(e)}
            />

            <GridContainer style={{ margin: 10 }}>
                {
                    makes.map((make, i) => {
                        const start = i - 4;
                        const end = i - 1;
                        const disabled = make.isChecked && makes.filter(c => c.isChecked).length === 1;

                        return (
                            <>
                                {(rowVisible >= start && rowVisible <= end) && (i > 0 && i % 4 === 0) && (
                                    templateEditYears(dlid, selMake)
                                )}

                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={3}
                                    onMouseOver={() => setLabelVisible(i)}
                                    onMouseOut={() => setLabelVisible(i)}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={disabled}
                                                        tabIndex={-1}
                                                        checked={make.isChecked}
                                                        onClick={(e) => {
                                                            handleClickCheckbox(e, i, make);
                                                        }}
                                                        checkedIcon={
                                                            <Check className={classes.checkedIcon} />
                                                        }
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot,
                                                        }}
                                                    />
                                                }
                                                label={make.displayName}
                                            />
                                        </div>
                                        {labelVisible === i && <div>
                                            <Link
                                                href="javascript:void(0)"
                                                onClick={() => {
                                                    setSelMake(make);
                                                    setRowVisible(
                                                        rowVisible === i ? -1 : i
                                                    );
                                                }}
                                                style={{
                                                    fontSize: 12
                                                }}>
                                                Edit years
                                            </Link>
                                        </div>}
                                    </div>
                                </GridItem>

                                {(rowVisible >= start && rowVisible <= end + 1) && (i + 1 === makes.length) && (
                                    templateEditYears(selMake)
                                )}
                            </>
                        );
                    })
                }
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginBottom: 10 }}>
                    <Button color="rose" size="sm" onClick={handleClickSave}>
                        Save
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );
}