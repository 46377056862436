import http from "./Http";

const getAll = async (id) => {
    return http.get(`/dealer-users?dlid=${id}`);
};

const post = async body => {
    return http.post("/dealer-users", {
        ...body,
        password: btoa(body.password)
    });
};

const put = async body => {
    return http.put(`/dealer-users/${body.id}`, body);
};

const del = async id => {
    return http.delete(`/dealer-users/${id}`);
};

const DealerUserService = {
    getAll,
    post,
    put,
    del
};

export default DealerUserService;