import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Button from "components/CustomButtons/Button.js";

//Custom
import PartCreationForm, {
    DEF_INITIAL_VALUES as INIT_VAL_PART,
} from "components/PartCreationForm/PartCreationForm";
import MultiVehicleSelector, {
    DEF_INITIAL_VALUES as INIT_VAL_VEH_SEL,
} from "components/MultiVehicleSelector/MultiVehicleSelector";

//Libs
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";

//Services
import PartService from "services/PartService";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

const validationSchema = yup.object({
    name: yup.string().required("The name is required"),
    partNo: yup.string().required("The part number is required"),
    mainPart: yup.string().required("The main part is required"),
    price: yup.string().required("The price is required"),
    description: yup.string().required("The description is required"),
    enPartName: yup.string().required("The English name is required"),
    esPartName: yup.string().required("The Spanish name is required"),
    ptPartName: yup.string().required("The Portuguese name is required"),
    frPartName: yup.string().required("The French name is required"),
});

export default function PartCreationTabs({ catalog, onSuccess = () => {} }) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...INIT_VAL_PART,
            ...INIT_VAL_VEH_SEL,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            postPart({
                ...values,
                price: Number(values.price),
            });
        },
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const postPart = async (body) => {
        try {
            const part = await PartService.post(catalog, body);
            onSuccess(part);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="form tabs"
            >
                <Tab label="Part info" />
                <Tab label="Part-Vehicle Association" />
            </Tabs>

            {Object.values(formik.errors).length > 0 ? (
                <Alert variant="outlined" severity="error" style={{ marginTop: 10 }}>
                    Please correct the following errors
                    <ul>
                        {Object.values(formik.errors).map((e) => (
                            <li key={e}>{e}</li>
                        ))}
                    </ul>
                </Alert>
            ) : null}

            <Box marginTop={2}>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        {value === 0 && <PartCreationForm formik={formik} />}
                        {value === 1 && (
                            <>
                                <MultiVehicleSelector formik={formik} />
                            </>
                        )}
                        <Box style={{ textAlign: "center" }}>
                            <Button
                                type="submit"
                                color="rose"
                                className={classes.updateProfileButton}
                            >
                                Save and close
                            </Button>
                        </Box>
                    </form>
                </FormikProvider>
            </Box>
        </div>
    );
}
