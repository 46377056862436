import React, { useState, useEffect } from "react";

// material
import Alert from "@material-ui/lab/Alert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";

// custom components
import QuoteHeader from "./Tabs/QuoteHeader";
import QuoteProducts from "./Tabs/QuoteProducts";
import QuoteLog from "./Tabs/QuoteLog";
import DealerCustomers from "components/DealerCustomers/DealerCustomers";

//libs
import { useFormik } from 'formik';
import * as yup from 'yup';

// services
import http, { BASE_URL_API_ECOMMERCE } from "services/Http";
import QuoteService from "services/QuoteService";
import DealerService from "services/DealerService";

// const
import { INITIAL_VALUES } from "./InitialValues";
import { CREATE_QUOTE, EDIT_QUOTE, CONVERT_QUOTE_TO_ORDER } from "views/CustomerQuotes/CustomerQuotes";

const validationSchema = {
    billCompany: yup.string().required(),
    billAddress1: yup.string().required(),
    billState: yup.string().required('State is required'),
    billCity: yup.string().required('City is required'),
    billZip: yup.string().required('Zip is required'),
    billPhone: yup.string().required(),
    billEmail: yup.string().email('Must be a valid email').required(),
    details: yup.array().min(1, "You must add at least one product")
}

export default function EditQuote({ form, quote, onSuccess = () => { } }) {
    const [dealer, setDealer] = useState({ id: 0 });
    const [valSchema, setValSchema] = useState({});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...Object.keys(quote).length === 0 ? INITIAL_VALUES : quote,
            isBusinessAcc: false,
            sameAddress: true
        },
        validationSchema: yup.object(valSchema),
        onSubmit: async (values) => {
            if (form.title === CREATE_QUOTE.title) postQuote(values);
            if (form.title === EDIT_QUOTE.title) putQuote(values);
            if (form.title === CONVERT_QUOTE_TO_ORDER.title) createOrder(values);
        },
    });

    useEffect(() => {
        const getDealers = async (dlid = '') => {
            const dealer = await DealerService.get(dlid);
            setDealer(dealer);
        };

        if (formik.values.dealerId && formik.values.customerId) {
            setValSchema(validationSchema);
            getDealers(formik.values.dealerId);
        }

        return () => {
            setValSchema({});
        }
    }, [formik.values.dealerId, formik.values.customerId]);

    const postQuote = async body => {
        try {
            const quote = await QuoteService.post(body);
            onSuccess(quote);
        } catch (error) {
            console.error(error);
        }
    }

    const putQuote = async body => {
        try {
            const quote = await QuoteService.put(body.id, body);
            onSuccess(quote);
        } catch (error) {
            console.error(error);
        }
    }

    const createOrder = async body => {
        try {
            await http.post(`${BASE_URL_API_ECOMMERCE}/order`, {
                ...body,
                dlid: body.dealerId,
                token: 'NO TOKEN',
                instructions: body.instructions || '-',
                orderAmount: body.details.reduce((accum, obj) => accum + (Number(obj.price) * parseInt(obj.qty)), 0),
                cart: body.details.map(d => ({ ...d, isTaxable: 1, token: 'NO TOKEN' }))
            });
            onSuccess(quote);
        } catch (error) {
            console.error(error);
        }
    }

    const handleChangeDealer = (dealer) => {
        formik.setFieldValue('dealerId', dealer.id);
        setDealer(dealer);
    }

    const handleChangeCustomer = (customer) => {
        formik.setFieldValue('customerId', customer.id);
        formik.setFieldValue('billCompany', customer.billCompany);
        formik.setFieldValue('billContact', customer.billContact);
        formik.setFieldValue('billAddress1', customer.billAddress1);
        formik.setFieldValue('billAddress2', customer.billAddress2);
        formik.setFieldValue('billCity', customer.billAddress2);
        formik.setFieldValue('billState', customer.billAddress2);
        formik.setFieldValue('billZip', customer.billAddress2);
        formik.setFieldValue('billPhone', customer.billPhone);
        formik.setFieldValue('billEmail', customer.billEmail);
        formik.setFieldValue('shipCompany', customer.shipCompany);
        formik.setFieldValue('shipContact', customer.shipContact);
        formik.setFieldValue('shipAddress1', customer.shipAddress1);
        formik.setFieldValue('shipAddress2', customer.shipAddress2);
        formik.setFieldValue('shipCity', customer.shipAddress2);
        formik.setFieldValue('shipState', customer.shipAddress2);
        formik.setFieldValue('shipZip', customer.shipAddress2);
        formik.setFieldValue('shipPhone', customer.shipPhone);
        formik.setFieldValue('shipEmail', customer.shipEmail);
        formik.setFieldValue('intMemo', customer.intMemo);
        formik.setFieldValue('userMemo', customer.userMemo);
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {form.title === CREATE_QUOTE.title ?
                    <DealerCustomers
                        onDealerChange={handleChangeDealer}
                        onCustomerChange={handleChangeCustomer}
                    /> : null
                }

                {/*<h2>dealerId={formik.values.dealerId}</h2>
                <h2>customerId={formik.values.customerId}</h2>*/}

                {Object.values(formik.errors).length > 0 ?
                    <Alert variant="outlined" severity="error" style={{ marginTop: 10 }}>
                        Please correct the following errors
                        <ul>
                            {Object.values(formik.errors).map(e => (<li key={e}>{e}</li>))}
                        </ul>
                    </Alert> : null
                }

                {formik.values.dealerId && formik.values.customerId ?
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <NavPills
                                active={0}
                                onChange={(e) => setTabId(e)}
                                color="warning"
                                tabs={[
                                    {
                                        tabButton: "Header",
                                        tabContent: (
                                            <QuoteHeader formik={formik} />
                                        ),
                                    },
                                    {
                                        tabButton: "Products",
                                        tabContent: (
                                            <QuoteProducts dealer={dealer} formik={formik} />
                                        ),
                                    },
                                    {
                                        tabButton: "Comment/Log",
                                        tabContent: (
                                            <QuoteLog formik={formik} />
                                        ),
                                    }
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button type="submit" color="rose">Save and close</Button>
                        </GridItem>
                    </GridContainer> : null
                }
            </form>
        </>
    )
}