import { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import Modal from "components/Modal/Modal";
import useRequestForm from 'hooks/useRequestForm';

const StyledFormControl = withStyles(() => ({
    root: {
        '& label': {
            color: '#000'
        }
    },
}))(FormControl);

const StyledTypography = withStyles(() => ({
    root: {
        fontWeight: 900,
        '& span': {
            fontWeight: 'normal'
        }
    },
}))(Typography);

export default function RequestFormModal({ open, handleCloseModal }) {
    const { selectedForm, handleRadioChange, resetSelectedForm } = useRequestForm();

    useEffect(() => {
        return () => {
            if (open)
                resetSelectedForm()
        }
    }, [open])

    return (
        <Modal open={open} modalTitle={selectedForm.title} handleClose={handleCloseModal}>
            <Box>
                {selectedForm.component && (selectedForm.component)}

                {!selectedForm.component && (
                    <>
                        <StyledFormControl component="fieldset">
                            <RadioGroup aria-label="request" name="request" value={selectedForm.value} onChange={handleRadioChange}>
                                <FormControlLabel value="price-incorrect" control={<Radio />} label="Price Incorrect" />
                                <FormControlLabel value="discontinued" control={<Radio />} label="Discontinued" />
                                <FormControlLabel value="old-superseded" control={<Radio />} label="Old/Superseded" />
                                <FormControlLabel value="report-other-issues" control={<Radio />} label="Report Other Issues" />
                            </RadioGroup>
                        </StyledFormControl>
                        <Box mt={2}>
                            <StyledTypography variant="body1">
                                For additional help feel free to contact us directly at:
                            </StyledTypography>
                            <StyledTypography variant="body1">
                                Email: <span><a href="mailto:support@smartdealer.com">support@smartdealer.com</a></span>
                            </StyledTypography>
                            <StyledTypography variant="body1">
                                Phone: <span>800-915-3353</span>
                            </StyledTypography>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    )
}