import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Modal from "components/Modal/Modal.js";
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";
import MuiAlert from '@material-ui/lab/Alert';

//libs
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from "react";
import ResetPasswordService from "services/ResetPasswordService";
import useUser from "hooks/useUser";

const defValidationSchema = {
    newPassword: yup.string()
        .required('Field required')
        .min(6, 'Password min length'),
    repeatNewPassword: yup.string()
        .required('Field required')
        .min(6, 'Password min length')
        .oneOf([yup.ref('newPassword'), null], 'Passwords not match')
}

export default function PasswordManagement({ userId, userName = '', userType }) {
    const [modal, setModal] = useState({
        open: false,
        title: 'Change password'
    });
    const [alert, setAlert] = React.useState({
        open: false,
        text: ''
    });
    const [user] = useUser();

    useEffect(() => {
        return () => {
            formik.setFieldValue('newPassword', '');
            formik.setFieldValue('repeatNewPassword', '');
            setAlert({
                open: false,
                text: ''
            });
        };
    }, [modal.open]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: '',
            repeatNewPassword: ''
        },
        validationSchema: yup.object(defValidationSchema),
        onSubmit: async (values) => {
            resetPassword(values.newPassword, values.repeatNewPassword);
        },
    });

    const handleClickOpen = () => setModal({ ...modal, open: true });

    const handleClickClose = () => setModal({ ...modal, open: false });

    const resetPassword = async (newPassword = '', repeatNewPassword = '') => {
        try {
            await ResetPasswordService.adminResetPassword(userId, userType, newPassword, repeatNewPassword);
            setAlert({ ...alert, open: true, text: 'Password changed successfully', severity: 'success' });
            setTimeout(() => {
                handleClickClose();
            }, 1000);
        } catch (error) {
            setAlert({ ...alert, open: true, text: 'Error changing password ' + error.response.data.message, severity: 'error' });
        }
    }

    const showPasswordManagement = (user?.isAdmin === 1 || user?.isDealerAdmin === 1);

    if (showPasswordManagement === false) return null;

    return (
        <>
            <Modal open={modal.open} modalTitle={modal.title} handleClose={handleClickClose}>
                <form onSubmit={formik.handleSubmit}>
                    {alert.open ? <MuiAlert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                        {alert.text}
                    </MuiAlert> : null}
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="userName"
                                name="userName"
                                label="Username"
                                value={userName}
                                disabled
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                type="password"
                                fullWidth
                                margin="dense"
                                id="newPassword"
                                name="newPassword"
                                label="Password"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                type="password"
                                fullWidth
                                margin="dense"
                                id="repeatNewPassword"
                                name="repeatNewPassword"
                                label="Repeat new password"
                                value={formik.values.repeatNewPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
                                helperText={formik.touched.repeatNewPassword && formik.errors.repeatNewPassword}
                            />
                        </GridItem>
                    </GridContainer>
                    <div style={{ textAlign: 'center', margin: 10 }}>
                        <Button type="submit" color="rose">Save</Button>
                    </div>
                </form>
            </Modal>

            <Tooltip title="Change password">
                <IconButton onClick={handleClickOpen}>
                    <VpnKeyIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    );
}
