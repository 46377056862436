import { useState } from "react";

const useOrderStatusShipment = () => {
    const [data, setData] = useState([
        {
            "id": 1,
            "status": "In Stock"
        },
        {
            "id": 2,
            "status": "Processing"
        },
        {
            "id": 3,
            "status": "Pending"
        },
        {
            "id": 4,
            "status": "On Order"
        },
        {
            "id": 5,
            "status": "Ready to Ship"
        },
        {
            "id": 6,
            "status": "Shipped"
        },
        {
            "id": 7,
            "status": "Declined"
        }
    ]);

    return data;
}

export default useOrderStatusShipment;
