import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from './useSnackbar';
import useDealer from './useDealer';
import useUser from './useUser';
import PolicyService from 'services/PolicyService';
import { useConfirmationDialog } from './useConfirmationDialog';

const defaultPolicies = [
    {
        name: "RETURN POLICY",
        description: "For more information on return/replacement avialability and time frame please take a look in your email for your order confirmation. On the bottom of the order confirmation you will see the return policy or the contact information of the fulfilment dealership for more information."
    },
    {
        name: "PRICING, ERRORS AND OMISSIONS",
        description: "Despite our best efforts to maintain the accuracy of the information on this site, it cannot guarantee the accuracy or completeness of any information, including prices, specifications, images, availability, and services.  Items may be mispriced, inaccurately described, or unavailable.  Although unlikely dealership reserves the right to modify information to correct errors, inaccuracies, or omissions at any time without notice."
    },
    {
        name: "SHIPPING OPTIONS",
        description: "The Mopar Accessories and Parts eStore offers shipping on eligible orders with various carriers based on the fulfilling dealer choice. Some items may require pick-up at your fulfilling dealer due to size or weight restrictions. Contact your fulfilling dealer for additional options."
    },
    {
        name: "PAYMENT METHODS",
        description: "Purchases on the Mopar Accessories and Parts eStore will be processed by your fulfilling dealer. Most dealers accept PayPal and major credit cards, including Visa, MasterCard, Discover and American Express."
    }
];

const useStorePolicies = () => {
    const openSnackbar = useSnackbar();
    const { openDialog } = useConfirmationDialog();
    const { id: dealerId } = useDealer();
    const [user] = useUser();

    const userId = user && user.id;
    const [initialValues, setInitialValues] = useState({
        policies: []
    });

    const fetchPolicies = async (dealerId) => {
        try {
            const { data = [] } = await PolicyService.getAll(dealerId);
            setInitialValues({
                policies: data.map(d => ({ ...d, isEnable: d.isEnable === 1 }))
            });
        } catch (error) {
            console.error('Error fetching shipping methods:', error);
        }
    };

    useEffect(() => {
        if (dealerId)
            fetchPolicies(dealerId);
    }, [dealerId]);

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            createOrUpdatePolicies({
                ...values,
                dealerId,
                userId
            });
        },
    });

    const createOrUpdatePolicies = async (policiesData) => {
        try {
            await PolicyService.post(policiesData);
            openSnackbar('success', 'Store policies updated successfully.');
            fetchPolicies(dealerId);
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating store policies. Please try again.');
        }
    }

    const handlePolicyNameChange = (index, value) => {
        formik.setFieldValue(`policies[${index}].name`, value);
    };

    const handleRestoreDefaultPolicy = (e, index) => {
        e.preventDefault();
        const updatedPolicies = [...formik.values.policies];
        updatedPolicies[index].name = defaultPolicies[index].name;
        updatedPolicies[index].description = defaultPolicies[index].description;

        formik.setValues({
            ...formik.values,
            policies: updatedPolicies,
        });
    };

    const handlePolicyEnableChange = (index, checked) => {
        formik.setFieldValue(`policies[${index}].isEnable`, checked);
    };

    const handlePolicyDescriptionChange = (index, value) => {
        formik.setFieldValue(`policies[${index}].description`, value);
    };

    const handleAddPolicy = () => {
        const newPolicy = {
            isEnable: false,
            name: '',
            description: ''
        };
        const updatedPolicies = [...initialValues.policies, newPolicy];
        setInitialValues({ policies: updatedPolicies });
    };

    const confirmPolicyDeletion = (index) => {
        openDialog({
            title: 'Are you sure?',
            onConfirm: () => {
                deletePolicy(index);
            },
        });
    };

    const deletePolicy = async (policyId, index) => {
        try {
            await PolicyService.del(policyId);

            const updatedPolicies = [...formik.values.policies];
            updatedPolicies.splice(index, 1);

            formik.setValues({
                ...formik.values,
                policies: updatedPolicies,
            });

            openSnackbar('success', 'Policy deleted successfully');
        } catch (error) {
            console.error('Error deleting policy:', error);
            openSnackbar('error', 'An error occurred while deleting the policy. Please try again.');
        }
    };

    return {
        formik,
        handlePolicyNameChange,
        handleRestoreDefaultPolicy,
        handlePolicyEnableChange,
        handlePolicyDescriptionChange,
        handleAddPolicy,
        confirmPolicyDeletion
    };
}

export default useStorePolicies;