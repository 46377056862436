import React, { useState, useEffect, useRef } from "react";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DealerService from "services/DealerService";

const FOR_A_SPECIFIC_DEALER = '1';

export default function SelectDealer() {
    const [dealers, setDealers] = useState([]);
    const [dlid, setDlid] = useState(0);
    const _dlid = localStorage.getItem("_dlid");
    const _sdlid = localStorage.getItem("_sdlid");

    useEffect(() => {
        const getDealers = async () => {
            const dealers = await DealerService.getAll().then(o => o.data);
            setDealers(dealers);
        };
        getDealers();
    }, []);

    useEffect(() => {
        if (_dlid) setDlid(_dlid);
    }, [_dlid]);

    const handleChange = (e) => {
        setDlid(e.target.value);
        localStorage.setItem("_dlid", e.target.value);
        location.reload();
    };

    if (_sdlid == FOR_A_SPECIFIC_DEALER) return null;

    return (
        <FormControl variant="filled" style={{ width: 250 }}>
            <InputLabel id="demo-simple-select-label">Dealers</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dlid}
                onChange={handleChange}
                label="Dealers"
            >
                <MenuItem value={0}>Select dealer</MenuItem>
                {
                    dealers.map(dealer => (
                        <MenuItem key={dealer.id} value={dealer.id}>{dealer.company}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}