import { useState } from "react";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";
import Box from "components/Box/Box";
import { IconButton, Paper, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useFormik from "hooks/useFormik";
import useParts from "hooks/useParts";

const fields = [
    {
        fieldName: "partNumber",
        required: true,
    },
    {
        fieldName: "en",
        required: true,
    },
    {
        fieldName: "es",
        required: true,
    },
    {
        fieldName: "pt",
        required: true,
    },
    {
        fieldName: "fr",
        required: true,
    },
];

export default function CreateTranslatePart({
    initialValues,
    createEntity = () => {},
    updateEntity = () => {},
    onSuccess = () => {},
}) {
    const { searchParts } = useParts();
    const [showFields, setShowFields] = useState(initialValues && initialValues.id);
    const [hasResults, setHasResults] = useState(null);

    const handleInsert = (values) => {
        createEntity(values);
        onSuccess();
    };

    const handleUpdate = (values) => {
        updateEntity(values);
        onSuccess();
    };

    const handleSearchClick = async () => {
        const partNumber = formik.values.partNumber;
        const part = await searchParts(partNumber);

        if (part.length > 0) {
            setHasResults(true);
            setShowFields(true);
            formik.setFieldValue("en", part[0].enPartName);
            formik.setFieldValue("es", part[0].esPartName);
            formik.setFieldValue("pt", part[0].ptPartName);
            formik.setFieldValue("fr", part[0].frPartName);
        } else {
            setHasResults(false);
            setShowFields(false);
        }
    };

    const formik = useFormik(fields, initialValues, handleInsert, handleUpdate);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box mt={1.5}>
                <TextFieldRender
                    fullWidth
                    variant="outlined"
                    label="Part Number"
                    value={formik.values.partNumber}
                    error={formik.touched.partNumber && Boolean(formik.errors.partNumber)}
                    handleBlur={(value) => {
                        const prevPartNumber = formik.values.partNumber;

                        if (prevPartNumber !== value) {
                            setShowFields(false);
                            setHasResults(null);
                        }

                        formik.setFieldValue("partNumber", value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={handleSearchClick}>
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                    helperText={
                        hasResults === true ? (
                            <Typography component="span" style={{ color: "green" }}>
                                Results found for {formik.values.partNumber}
                            </Typography>
                        ) : hasResults === false ? (
                            <Typography component="span" style={{ color: "red" }}>
                                No results found for {formik.values.partNumber}
                            </Typography>
                        ) : (
                            ""
                        )
                    }
                />
            </Box>
            {showFields && (
                <Paper style={{ marginTop: 10, padding: 10 }}>
                    <Box>
                        <TextFieldRender
                            fullWidth
                            variant="outlined"
                            label="EN"
                            value={formik.values.en}
                            error={formik.touched.en && Boolean(formik.errors.en)}
                            handleBlur={(value) => formik.setFieldValue("en", value)}
                        />
                    </Box>
                    <Box mt={1.5}>
                        <TextFieldRender
                            fullWidth
                            variant="outlined"
                            label="ES"
                            value={formik.values.es}
                            error={formik.touched.es && Boolean(formik.errors.es)}
                            handleBlur={(value) => formik.setFieldValue("es", value)}
                        />
                    </Box>
                    <Box mt={1.5}>
                        <TextFieldRender
                            fullWidth
                            variant="outlined"
                            label="PT"
                            value={formik.values.pt}
                            error={formik.touched.pt && Boolean(formik.errors.pt)}
                            handleBlur={(value) => formik.setFieldValue("pt", value)}
                        />
                    </Box>
                    <Box mt={1.5}>
                        <TextFieldRender
                            fullWidth
                            variant="outlined"
                            label="FR"
                            value={formik.values.fr}
                            error={formik.touched.fr && Boolean(formik.errors.fr)}
                            handleBlur={(value) => formik.setFieldValue("fr", value)}
                        />
                    </Box>
                    <Box>
                        <Button type="submit" color="rose" fullWidth>
                            Save and close
                        </Button>
                    </Box>
                </Paper>
            )}
        </form>
    );
}
