import React, { useState, useEffect } from "react";

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Notification from "components/Notification/Notification.js";
import Button from "components/CustomButtons/Button.js";

import DealerMiscService from 'services/DealerMiscService';
import CountryService from 'services/CountryService';
import SelectCountries from "components/SelectCountries/SelectCountries";
import SelectStates from "components/SelectStates/SelectStates";
import useDealer from "hooks/useDealer";


export default function ShippingZones() {
    const { id: dlid } = useDealer();
    const [selCountries, setSelCountries] = useState({
        selectAll: false,
        selItems: []
    });
    const [selStates, setSelStates] = useState({
        selectAll: false,
        selItems: []
    });
    const [openModal, setOpenModal] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        color: 'success',
        message: 'Shipping zones updated successfully'
    });

    useEffect(() => {
        const getDealer = async (dlid) => {
            let dealer = await DealerMiscService.getAll(dlid).then(d => d.data?.[0] || {});
            dealer = {
                selectedCountries: JSON.parse(dealer.selectedCountries) || [],
                selectedStates: JSON.parse(dealer.selectedStates) || []
            }
            setSelCountries({
                ...selCountries,
                selItems: dealer.selectedCountries
            });
            setSelStates({
                ...selStates,
                selItems: dealer.selectedStates
            });
        };
        if (dlid) getDealer(dlid)
    }, [dlid]);

    const handleClickSave = async () => {
        await CountryService.postCountries(dlid, {
            selCountries: selCountries.selItems
        });
        await CountryService.postStates(dlid, {
            selStates: selStates.selItems
        });
        setNotification({
            ...notification,
            open: !notification.open
        });
    }

    return (
        <>
            {notification.open && <Notification open={notification.open} color={notification.color} message={notification.message} />}

            <SelectCountries
                selCountries={selCountries}
                setSelCountries={setSelCountries}
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleClickSave={handleClickSave}
            />

            <SelectStates
                selStates={selStates}
                setSelStates={setSelStates}
                openModal={openModal}
                setOpenModal={setOpenModal}
            />

            <GridContainer style={{ marginTop: 20 }}>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 5 }}>
                    <Button
                        type="button"
                        color="rose"
                        onClick={handleClickSave}
                    >Save</Button>
                </GridItem>
            </GridContainer>
        </>
    )
}