import { Divider, Typography, Box } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import UpsCarrier from "./UpsCarrier";
import FedexCarrier from "./FedexCarrier";

export default function ShippingCarrier() {
    return (
        <>
            <Typography gutterBottom style={{ fontSize: 16, fontWeight: 500 }}>
                Shipping Carrier
            </Typography>
            <Divider />
            <Box mt={2.5}>
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <UpsCarrier />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                        <FedexCarrier />
                    </GridItem>
                </GridContainer>
            </Box>
        </>
    )
}
