import { useEffect, useState } from 'react';
import OrderService from 'services/OrderService';
import usePagination from './usePagination';
import useDealer from "./useDealer";

const useOrder = (startDate = '', endDate = '') => {
    const { id: dealerId } = useDealer();
    const [order, setOrder] = useState({
        recordsTotal: 0,
        data: []
    });
    const { pagination, changePagination, pageCount } = usePagination(1, 10, order.recordsTotal);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const startRow = (pagination.page - 1) * pagination.pageSize;
                const { data = [] } = await OrderService.getAll(
                    startRow,
                    pagination.pageSize,
                    startDate,
                    endDate,
                    '',
                    '',
                    '',
                    dealerId
                );
                setOrder(data);
            } catch (err) {
            }
        };

        fetchData();
    }, [dealerId, startDate, endDate, pagination.page, pagination.pageSize]);

    return {
        order,
        setOrder,
        pagination,
        changePagination,
        pageCount
    };
};

export default useOrder;
