import React, { useState, useEffect } from "react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";

// custom components
import CreateCatalog from "./CreateCatalog.js";

import CatalogService from "../../services/CatalogService";
import DeleteButton from "components/DeleteButton/DeleteButton.js";
import useCrud from "hooks/useCrud.js";

export const CREATE_CATALOG = {
    title: "Create catalog",
    successMessage: "catalog created successfully",
};

export const EDIT_CATALOG = {
    title: "Edit catalog",
    successMessage: "catalog updated successfully",
};

const createdAt = new Date();

export default function Catalogs() {
    const [catalogs, setCatalogs] = useState([]);
    const [form, setForm] = useState(CREATE_CATALOG);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });
    const { deleteEntity } = useCrud("catalogs", {}, false);

    useEffect(() => {
        const getCatalogs = async () => {
            const catalogs = await CatalogService.getAll().then((c) => {
                return c.data.map((cat) => {
                    return {
                        ...cat,
                        createdAt: new Date(),
                    };
                });
            });

            setCatalogs(catalogs);
        };
        getCatalogs();
    }, []);

    const onSuccess = (catalog) => {
        if (form === CREATE_CATALOG) {
            setCatalogs([...catalogs, catalog]);
        }

        if (form === EDIT_CATALOG) {
            const nCatalogs = catalogs.map((c) => (c.id === catalog.id ? { ...c, ...catalog } : c));
            setCatalogs(nCatalogs);
        }

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    const columns = [
        {
            Header: "Id",
            accessor: "id",
            order: false,
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Display",
            accessor: "displayName",
        },
        {
            Header: "Actions",
            Cell: (props) => {
                const rowIdx = props.row.id;
                const catalog = catalogs[rowIdx];

                return (
                    <>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                onClick={() => {
                                    setForm(EDIT_CATALOG);
                                    setOpenModal(true);
                                    setInitialValues(catalog);
                                }}
                            >
                                <Edit />
                            </Button>
                            <DeleteButton
                                title="catalog"
                                handleClick={async () => {
                                    await deleteEntity(catalog.id);
                                    setCatalogs([...catalogs].filter((c) => c.id !== catalog.id));

                                    /*await QuoteService.del(initialValues.id);
                                    setOrders([...orders].filter((c) => c.id !== initialValues.id));
                                    setOpen(false);*/
                                }}
                            />
                            {/*<Button
                                justIcon
                                round
                                simple
                                color="danger"
                                className="edit"
                                onClick={() => {
                                    alert('Delete');
                                }}
                            >
                                <Close />
                            </Button>*/}
                        </div>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                />
            )}

            <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                <CreateCatalog
                    form={form}
                    initialValues={initialValues}
                    onSuccess={(catalog) => onSuccess(catalog)}
                />
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_CATALOG);
                                    setOpenModal(true);
                                    setInitialValues({});
                                }}
                            >
                                Create catalog
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={columns}
                                data={catalogs}
                                showPagination={false}
                                showSorting={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
