import { Switch, FormControlLabel, Typography, FormControl, InputLabel, Input, Box } from '@material-ui/core';
import CustomBox from 'components/Box/Box';
import Button from "components/CustomButtons/Button.js";
import useFedexCarrier from 'hooks/useFedexCarrier';

export default function FedexCarrier() {
    const { formik } = useFedexCarrier();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography gutterBottom style={{ fontSize: 16, fontWeight: 500 }}>
                Fedex Carrier:
            </Typography>
            <CustomBox>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            name="fedexCarrierEnabled"
                            checked={formik.values.fedexCarrierEnabled}
                            onChange={formik.handleChange}
                        />
                    }
                    label="Fedex Carrier:"
                />
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Account Number:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="fedexCarrierAccountNumber"
                        value={formik.values.fedexCarrierAccountNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.fedexCarrierAccountNumber && Boolean(formik.errors.fedexCarrierAccountNumber)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Meter Number:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="fedexCarrierMeterNumber"
                        value={formik.values.fedexCarrierMeterNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.fedexCarrierMeterNumber && Boolean(formik.errors.fedexCarrierMeterNumber)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Acount Key:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="fedexCarrierAccountKey"
                        value={formik.values.fedexCarrierAccountKey}
                        onChange={formik.handleChange}
                        error={formik.touched.fedexCarrierAccountKey && Boolean(formik.errors.fedexCarrierAccountKey)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Password:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="fedexCarrierPassword"
                        value={formik.values.fedexCarrierPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.fedexCarrierPassword && Boolean(formik.errors.fedexCarrierPassword)}
                    />
                </FormControl>
            </CustomBox>
            <Box textAlign="center">
                <Button type="submit" color="rose">
                    Update Fedex Carrier
                </Button>
            </Box>
        </form>
    )
}
