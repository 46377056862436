import React from "react";
import { TextField, Typography, Box } from '@material-ui/core';
import PageLayout from "layouts/PageLayout";
import CustomerSelectionDialog from "./CustomerSelectionDialog";
import Button from "components/CustomButtons/Button.js";
import useNewMessageForm from "hooks/useNewMessageForm";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";

export default function NewMessage() {
    const { isLoading, formik, recipientCount = 0, handleRecipientExists, fileInputRef } = useNewMessageForm();

    return (
        <PageLayout showSelectDealer={false}>
            <form onSubmit={formik.handleSubmit}>
                <Box mb={2}>
                    <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
                        To
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <CustomerSelectionDialog
                            selected={formik.values.recipients}
                            handleRecipientExists={handleRecipientExists}
                        />
                        {recipientCount > 0 && (
                            <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none', marginLeft: 10 }}>
                                {recipientCount} selected
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        name="subject"
                        label="Subject:"
                        size="small"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        error={formik.touched.subject && Boolean(formik.errors.subject)}
                    />
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
                        Message
                    </Typography>
                    <HtmlEditor
                        dlid="0"
                        height={250}
                        title=""
                        data={formik.values.messageBody}
                        onBlur={(value) => formik.setFieldValue("messageBody", value)}
                    />
                    {/*<CKEditor
                        editor={ClassicEditor}
                        data={formik.values.messageBody}
                        onBlur={(_, editor) => {
                            formik.setFieldValue("messageBody", editor.getData());
                        }}
                        config={{ height: '100px' }}
                        onReady={editor => {
                            editor.editing.view.change(writer => {
                                writer.setStyle('height', '225px', editor.editing.view.document.getRoot());
                            });
                        }}
                        error={true}
                    />*/}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
                        Attachment
                    </Typography>
                    <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            formik.setFieldValue("attachment", file);
                        }}
                        ref={fileInputRef}
                    />
                </Box>
                <Box textAlign="center">
                    <Button type="submit" color="rose">
                        {isLoading ? "Sending..." : "Send"}
                    </Button>
                </Box>
            </form>
        </PageLayout>
    )
}
