import React, { useState, useEffect } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// services
import useDealer from "hooks/useDealer";
import useStates from "hooks/useStates";
import { Box, Paper } from "@material-ui/core";

export default function CustomerForm({
    formik,
    rmaNumber = "",
    showTrackingInfo = false,
    trackingComponent = null,
    ...otherProps
}) {
    const [states, setStates] = useState([]);
    const isBusinessAcc = formik.values.isBusinessAcc;
    const sameAddress = formik.values.sameAddress;
    const dealer = useDealer();
    const { fetchStatesByCountry } = useStates();

    useEffect(() => {
        const getStates = async (billCountry) => {
            const states = await fetchStatesByCountry(billCountry);
            setStates(states);
        };

        if (formik.values.billCountry.length > 0) {
            getStates(formik.values.billCountry);
        }

        return () => {
            setStates([]);
        };
    }, [formik.values.billCountry]);

    return (
        <>
            <GridContainer {...otherProps}>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <Typography variant="subtitle2" gutterBottom style={{ margin: 0 }}>
                                BILLING INFORMATION
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isBusinessAcc}
                                        onChange={() =>
                                            formik.setFieldValue("isBusinessAcc", !isBusinessAcc)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Business Account"
                                style={{
                                    marginLeft: "auto",
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    {/*<Box textAlign="center" style={{ fontSize: 15 }}>
                        <strong>Order Number:</strong> {formik.values.orderNumber}
                    </Box>*/}

                    {rmaNumber && (
                        <Box textAlign="center" style={{ fontSize: 15 }}>
                            <strong>Rma Number:</strong> {rmaNumber}
                        </Box>
                    )}

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={isBusinessAcc ? 6 : 12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="billCompany"
                                name="billCompany"
                                label={isBusinessAcc ? "Business Name" : "Name"}
                                value={formik.values.billCompany}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.billCompany && Boolean(formik.errors.billCompany)
                                }
                            />
                        </GridItem>
                        {isBusinessAcc && (
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="billContact"
                                    name="billContact"
                                    label="Contact Person"
                                    value={formik.values.billContact}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billContact &&
                                        Boolean(formik.errors.billContact)
                                    }
                                />
                            </GridItem>
                        )}
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="billAddress1"
                                name="billAddress1"
                                label="Address line 1"
                                value={formik.values.billAddress1}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.billAddress1 &&
                                    Boolean(formik.errors.billAddress1)
                                }
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="billAddress2"
                                name="billAddress2"
                                label="Address line 2"
                                value={formik.values.billAddress2}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.billAddress2 &&
                                    Boolean(formik.errors.billAddress2)
                                }
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <FormControl fullWidth style={{ marginTop: 5 }}>
                                <InputLabel>Country</InputLabel>
                                <Select
                                    id="billCountry"
                                    name="billCountry"
                                    value={formik.values.billCountry}
                                    onChange={(e) =>
                                        formik.setFieldValue("billCountry", e.target.value)
                                    }
                                >
                                    {["CAN", "CA"].includes(formik.values.billCountry) && (
                                        <MenuItem value="CA">Canada</MenuItem>
                                    )}

                                    {["USA", "USA"].includes(formik.values.billCountry) && (
                                        <MenuItem value="USA">USA</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <FormControl fullWidth style={{ marginTop: 5 }}>
                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                <Select
                                    id="billState"
                                    name="billState"
                                    value={formik.values.billState}
                                    onChange={(e) =>
                                        formik.setFieldValue("billState", e.target.value)
                                    }
                                >
                                    <MenuItem value={0}>Select state</MenuItem>
                                    {states.map((state) => (
                                        <MenuItem key={state.code} value={state.code}>
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="billCity"
                                name="billCity"
                                label="City"
                                value={formik.values.billCity}
                                onChange={formik.handleChange}
                                error={formik.touched.billCity && Boolean(formik.errors.billCity)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="billZip"
                                name="billZip"
                                label="ZIP"
                                value={formik.values.billZip}
                                onChange={formik.handleChange}
                                error={formik.touched.billZip && Boolean(formik.errors.billZip)}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="billPhone"
                                name="billPhone"
                                label="Phone"
                                value={formik.values.billPhone}
                                onChange={formik.handleChange}
                                error={formik.touched.billPhone && Boolean(formik.errors.billPhone)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="billEmail"
                                name="billEmail"
                                label="Email"
                                value={formik.values.billEmail}
                                onChange={formik.handleChange}
                                error={formik.touched.billEmail && Boolean(formik.errors.billEmail)}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer style={{ marginTop: 20 }}>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <Typography variant="subtitle2" gutterBottom style={{ margin: 0 }}>
                                SHIPPING ADDRESS
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={sameAddress}
                                        onChange={() =>
                                            formik.setFieldValue("sameAddress", !sameAddress)
                                        }
                                        color="primary"
                                    />
                                }
                                label="Shipping same as billing"
                                style={{
                                    marginLeft: "auto",
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    {!sameAddress && (
                        <>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={isBusinessAcc ? 6 : 12}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="shipCompany"
                                        name="shipCompany"
                                        label={isBusinessAcc ? "Business Name" : "Name"}
                                        value={formik.values.shipCompany}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.shipCompany &&
                                            Boolean(formik.errors.shipCompany)
                                        }
                                    />
                                </GridItem>
                                {isBusinessAcc && (
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipContact"
                                            name="shipContact"
                                            label="Contact Person"
                                            value={formik.values.shipContact}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipContact &&
                                                Boolean(formik.errors.shipContact)
                                            }
                                        />
                                    </GridItem>
                                )}
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="shipAddress1"
                                        name="shipAddress1"
                                        label="Address line 1"
                                        value={formik.values.shipAddress1}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.shipAddress1 &&
                                            Boolean(formik.errors.shipAddress1)
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="shipAddress2"
                                        name="shipAddress2"
                                        label="Address line 2"
                                        value={formik.values.shipAddress2}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.shipAddress2 &&
                                            Boolean(formik.errors.shipAddress2)
                                        }
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl fullWidth style={{ marginTop: 5 }}>
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            id="shipCountry"
                                            name="shipCountry"
                                            value={formik.values.shipCountry}
                                        >
                                            {["CAN", "CA"].includes(formik.values.shipCountry) && (
                                                <MenuItem value="CA">Canada</MenuItem>
                                            )}

                                            {["USA", "USA"].includes(formik.values.shipCountry) && (
                                                <MenuItem value="USA">USA</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl fullWidth style={{ marginTop: 5 }}>
                                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                                        <Select
                                            id="shipState"
                                            name="shipState"
                                            value={formik.values.shipState}
                                            onChange={(e) =>
                                                formik.setFieldValue("shipState", e.target.value)
                                            }
                                        >
                                            <MenuItem value={0}>Select state</MenuItem>
                                            {states.map((state) => (
                                                <MenuItem key={state.code} value={state.code}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="shipCity"
                                        name="shipCity"
                                        label="City"
                                        value={formik.values.shipCity}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.shipCity &&
                                            Boolean(formik.errors.shipCity)
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="shipZip"
                                        name="shipZip"
                                        label="ZIP"
                                        value={formik.values.shipZip}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.shipZip && Boolean(formik.errors.shipZip)
                                        }
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="shipPhone"
                                        name="shipPhone"
                                        label="Phone"
                                        value={formik.values.shipPhone}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.shipPhone &&
                                            Boolean(formik.errors.shipPhone)
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="shipEmail"
                                        name="shipEmail"
                                        label="Email"
                                        value={formik.values.shipEmail}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.shipEmail &&
                                            Boolean(formik.errors.shipEmail)
                                        }
                                    />
                                </GridItem>
                            </GridContainer>
                        </>
                    )}

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                multiline
                                rows={5}
                                variant="outlined"
                                margin="dense"
                                id="intMemo"
                                name="intMemo"
                                label="Internal order notes"
                                value={formik.values.intMemo}
                                onChange={formik.handleChange}
                                error={formik.touched.intMemo && Boolean(formik.errors.intMemo)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                multiline
                                rows={5}
                                variant="outlined"
                                margin="dense"
                                id="userMemo"
                                name="userMemo"
                                label="Internal user notes"
                                value={formik.values.userMemo || ""}
                                onChange={formik.handleChange}
                                error={formik.touched.userMemo && Boolean(formik.errors.userMemo)}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={showTrackingInfo ? 6 : 12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={5}
                                variant="outlined"
                                margin="dense"
                                id="instructions"
                                name="instructions"
                                label="Instructions"
                                value={formik.values.instructions}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.instructions &&
                                    Boolean(formik.errors.instructions)
                                }
                            />
                        </GridItem>
                        {showTrackingInfo && (
                            <GridItem xs={12} sm={12} md={6}>
                                {trackingComponent}
                            </GridItem>
                        )}
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </>
    );
}
