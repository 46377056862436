import React, { useState, useMemo } from "react";
import ReactTable from "components/ReactTable/ReactTableV2";
import Button from "components/CustomButtons/Button.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import DeleteIcon from "@material-ui/icons/Delete";
import PartService from "services/PartService";

export default function PartVehicleList({ catalog, vehicles = [], setVehicles }) {
    const [open, setOpen] = useState(false);
    const [rowId, setRowId] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: "Year",
                accessor: "year"
            },
            {
                Header: "Make",
                accessor: "makeName"
            },
            {
                Header: "Model",
                accessor: "modelName"
            },
            {
                Header: "Trim",
                accessor: "trim"
            },
            {
                Header: "Engine",
                accessor: "engine"
            },
            {
                Header: () => "Actions",
                id: 'expander',
                Cell: ({ row }) => (
                    <>
                        <Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            title="Delete"
                            onClick={() => {
                                setRowId(row.original.id)
                                setOpen(true);
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </>
                ),
            },
        ]
    );

    return (
        <>
            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    const part = await PartService.del(catalog, rowId);
                    const newVehicles = vehicles.filter(v => v.id != rowId);
                    setVehicles(newVehicles);
                }}
                onCancel={() => setOpen(false)}
            />

            <ReactTable
                columns={columns}
                data={vehicles}
                manualPagination={true}
                showSorting={false}
            />
        </>
    )
}