export const INITIAL_VALUES = {
    "id": "",
    "dealerId": "",
    "orderNumber": "",
    "customerId": "",
    "orderDate": "",
    "requiredDate": "",
    "payTerms": "",
    "orderAmount": "",
    "salesman": "",
    "intMemo": "",
    "billCompany": "",
    "billAddress1": "",
    "billAddress2": "",
    "billCity": "",
    "billState": "",
    "billZip": "",
    "billCountry": "",
    "billPhone": "",
    "billEmail": "",
    "billContact": "",
    "shipCompany": "",
    "shipAddress1": "",
    "shipAddress2": "",
    "shipCity": "",
    "shipState": "",
    "shipZip": "",
    "shipCountry": "",
    "shipPhone": "",
    "shipEmail": "",
    "shipContact": "",
    "instructions": "",
    "shipVia": "",
    "shippingCharges": "",
    "shipMemo": "",
    "taxRate": "",
    "taxAmount": "",
    "taxState": "",
    "taxCity": "",
    "taxZip": "",
    "token": "",
    "locale": "",
    "orderStatusId": "",
    "purchaseOrderNumber": "",
    "createdAt": "",
    "updatedAt": "",
    "salesTaxExceptionNo": "",
    "salesTaxRate": "",
    "salesTaxAmount": "",
    "shippingAmount": "",
    "userMemo": "",
    "subtotal": "",
    "details": []
};

export const ORDER_STATUS = [
    {
        "id": 1,
        "name": "New orders"
    },
    {
        "id": 2,
        "name": "In stock"
    },
    {
        "id": 3,
        "name": "Pending"
    },
    {
        "id": 4,
        "name": "On order"
    },
    {
        "id": 5,
        "name": "Completed"
    },
    {
        "id": 6,
        "name": "Declined"
    },
    {
        "id": 7,
        "name": "Returns"
    }
]