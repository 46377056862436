import React, { useState } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import CreateProduct from "components/CreateProduct/CreateProduct";
import Searchkeyword from "components/Searchkeyword/Searchkeyword";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import useCrud from "hooks/useCrud";
import useProductImageUrl from "hooks/useProductImageUrl";

// material
import { Paper, Box, IconButton, TextField, InputAdornment } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import PartSelectionDialog from "views/HomePageSpecials/PartSelectionDialog";

export const INITIAL_VALUES = {
    id: 0,
    partNumber: "",
    description: "",
    price: "",
    qty: "1",
};

export default function SearchProduct({ dealer, orderId = "", onAddProduct = () => {} }) {
    const [partNumber, setPartNumber] = useState("");
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [selProduct, setSelProduct] = useState(INITIAL_VALUES);
    const { openDialog, closeDialog } = useConfirmationDialog();
    const { readAllEntities, createEntity } = useCrud("order_details", {}, false);
    const { getProductImageUrl } = useProductImageUrl();

    const handleClick = () => {
        openDialog({
            formFrameTitle: `Search results for: ${partNumber}`,
            fullWidth: true,
            maxWidth: "lg",
            message: (
                <PartSelectionDialog
                    orderDealerId={dealer.id}
                    partNumber={partNumber}
                    onProductSelect={handleProductSelect}
                />
            ),
        });
    };

    const handleProductSelect = async (product) => {
        const [orderItem] = await readAllEntities({
            order_id: orderId,
        });
        const [make, year, model, trim, engine] = product.slugs;
        const price = product.price;

        const productEntity = {
            order_id: orderId,
            catalog_id: 1,
            make_slug: make.slug,
            year: year.name,
            model_slug: model.slug,
            trim_slug: trim.slug,
            engine_slug: engine.slug,
            VehicleToEngineConfigID: engine.VehicleToEngineConfigID,
            part_number: product.part_number,
            description: product.name,
            cost: price.price,
            price: price.price,
            qty: 1,
            extended_price: price.price,
            catalog_id: orderItem.catalogId,
            token: orderItem.token,
            picture_url: getProductImageUrl(make.slug, product.part_number),
            is_taxable: 1,
            qtyShipped: 0,
        };

        //await createEntity(productEntity);
        onAddProduct(productEntity);
        closeDialog();
    };

    return (
        <>
            {!open && (
                <Button color="primary" onClick={() => setOpen(!open)}>
                    Add product
                </Button>
            )}

            {open && (
                <Paper elevation={2} style={{ padding: 10 }}>
                    <Box style={{ textAlign: "right" }}>
                        <IconButton onClick={() => setOpen(!open)}>
                            <CloseIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            size="small"
                            label="Item Part Number"
                            name="title"
                            value={partNumber}
                            onChange={(e) => setPartNumber(e.target.value)}
                            InputProps={{
                                style: {
                                    width: 250,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={partNumber.length === 0}
                                            onClick={handleClick}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                marginTop: 10,
                            }}
                        />
                    </Box>
                </Paper>
            )}
        </>
    );
    return (
        <>
            {!open && (
                <Button color="primary" onClick={() => setOpen(!open)}>
                    Add product
                </Button>
            )}

            {open ? (
                <Paper elevation={2} style={{ padding: 10 }}>
                    <Box style={{ textAlign: "right" }}>
                        <IconButton onClick={() => setOpen(!open)}>
                            <CloseIcon color="secondary" fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box>
                        <Searchkeyword
                            dealer={dealer}
                            onClick={(value) => {
                                setSelProduct({
                                    ...value,
                                    id: value.id,
                                    partNumber: value.part_number,
                                    description: value.name,
                                    price: value.price.price,
                                    qty: 1,
                                });
                                setShow(true);
                            }}
                        />
                    </Box>
                    {show != 0 ? (
                        <Box>
                            <CreateProduct
                                selProduct={selProduct}
                                setSelProduct={setSelProduct}
                                onAddProduct={(value) => {
                                    const [
                                        make,
                                        year,
                                        model,
                                        trim,
                                        engine,
                                        category,
                                        subcategory,
                                    ] = value.slugs;

                                    onAddProduct({
                                        id: 0,
                                        quoteId: 0,
                                        catalogId: 1,
                                        makeSlug: make.slug,
                                        year: year.name,
                                        modelSlug: model.slug,
                                        trimSlug: trim.slug,
                                        engineSlug: engine.slug,
                                        vehicleToEngineConfigId: engine.VehicleToEngineConfigID,
                                        partNumber: value.partNumber,
                                        description: value.description,
                                        cost: 0,
                                        price: value.price,
                                        qty: value.qty,
                                        extendedPrice: value.price,
                                        pictureUrl: value.path_image,
                                    });

                                    setShow(!show);
                                }}
                            />
                        </Box>
                    ) : null}
                </Paper>
            ) : null}
        </>
    );
}
