import React, { useState, useRef } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal";

import DealerRedirectService from "services/DealerRedirectService";

export const UPLOAD_REDIRECT = {
    title: 'Upload redirect',
    successMessage: 'File imported successfully'
}

export default function ImportRedFile({ dealerId, onImport = () => { } }) {
    const [openModal, setOpenModal] = useState(false);
    const ref = useRef();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            dealerId,
            redirectFile: ''
        },
        onSubmit: (values) => {
            const formData = new FormData();

            formData.append("dealerId", dealerId);

            if (values.redirectFile)
                formData.append("files", values.redirectFile);

            importCsv(formData);
        },
    });

    const importCsv = async body => {
        try {
            await DealerRedirectService.importCsv(body);
            ref.current.value = null;
            formik.setFieldValue("redirectFile", "");
            onImport();
            setOpenModal(!open);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Modal open={openModal} modalTitle="Upload 301 URL Redirect File" handleClose={() => setOpenModal(!openModal)}>
                <div style={{ minWidth: 500 }}></div>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <form onSubmit={formik.handleSubmit}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <input
                                        ref={ref}
                                        id="redirectFile"
                                        name="redirectFile"
                                        type="file"
                                        onChange={(e) => formik.setFieldValue("redirectFile", e.target.files[0])}
                                        accept=".csv"
                                    />
                                    <div style={{ marginTop: 10 }}>
                                        Notes: File must be a csv (e.g 'redirects.csv') and no larger than "10 mb"
                                        only two columns | Source URL | Target URL
                                    </div>
                                    <div>
                                        Only records that have source url and target url will be considered
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 5 }}>
                                    <Button
                                        type="submit"
                                        color="rose"
                                        disabled={!formik.values.redirectFile}
                                    >
                                        Import and close
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </GridItem>
                </GridContainer>
            </Modal>

            <Button
                color="twitter"
                onClick={() => {
                    setOpenModal(true);
                }}
                style={{ marginLeft: 10 }}
            >
                Import redirect file
            </Button>
        </>
    )
}