import PageLayout from "layouts/PageLayout";
import TableWrapper from "components/TableWrapper/TableWrapper";
import Button from "components/CustomButtons/Button.js";
import BasicDialog from "components/BasicDialog/BasicDialog";
import CreateRelatedItem from "./CreateRelatedItem";
import useRelatedItems from "hooks/useRelatedItems";
import { Switch } from "@material-ui/core";

export default function RelatedItemsSettings() {
    const {
        formValues,
        open,
        setOpen,
        data = [],
        handleInsert,
        handleUpdate,
        handleClickEdit,
        handleClickDelete,
        handleClickShowHide,
    } = useRelatedItems();

    const columns = [
        {
            Header: "Rule name",
            accessor: "ruleName",
        },
        {
            Header: "Main Part Number",
            accessor: "mainPartNumber",
        },
        {
            Header: "Part Mumbers",
            accessor: "relatedPartNumbers",
        },
        {
            Header: "Display?",
            accessor: "showInCarouselFormat",
            Cell: ({ row, cell }) => (
                <Switch
                    checked={row.original.display}
                    onChange={(e) => {
                        handleClickShowHide(row.original.id, e);
                    }}
                />
            ),
        },
    ];

    return (
        <PageLayout
            customComponent={
                <Button
                    color="primary"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    Create related items
                </Button>
            }
        >
            <BasicDialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
                {open && (
                    <CreateRelatedItem
                        initialValues={formValues}
                        onInsert={handleInsert}
                        onUpdate={handleUpdate}
                    />
                )}
            </BasicDialog>

            <TableWrapper
                showEditButton
                showDeleteButton
                columns={columns}
                data={data}
                onEditClick={handleClickEdit}
                onDeleteClick={handleClickDelete}
            />
        </PageLayout>
    );
}
