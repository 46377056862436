import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import VehicleService from 'services/VehicleService';

export default function ModelSelector(props) {
    const {
        dlid,
        makeId,
        selectedYear,
        selectedModel,
        handleChange
    } = props;
    const [models, setModels] = useState([]);

    useEffect(() => {
        if (dlid && makeId && selectedYear) loadModels(dlid, makeId, selectedYear);
    }, [selectedYear]);

    const loadModels = async (dlid, makeId, selectedYear) => {
        try {
            const models = await VehicleService.getModels({
                dlid,
                makeId,
                catalog: 'isuzu',
                yearId: selectedYear
            });
            setModels(models);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <TextField
            select
            label="Model"
            value={selectedModel}
            onChange={handleChange}
            variant="outlined"
            fullWidth
        >
            {models.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};