import http from "./Http";

const getAll = async (dlid = "", cid = "", mid = "") => {
    return http.get(`/dealer-pricing?dealerId=${dlid}&catalogId=${cid}&makeId=${mid}`);
};

const post = async (body) => {
    return http.post(`/dealer-pricing`, body);
};

const put = async (body) => {
    return http.put(`/dealer-pricing/${body.id}`, body);
};

const del = async (id = "") => {
    return http.delete(`/dealer-pricing/${id}`);
};

const setAsDefault = async (id = "") => {
    return http.put(`/dealer-pricing/set-default/${id}`);
};

const DealerPricingService = {
    getAll,
    post,
    put,
    del,
    setAsDefault,
};

export default DealerPricingService;
