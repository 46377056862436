import { useFormik } from 'formik';
import * as yup from 'yup';

const initialValues = {
    shippingMethodName: '',
    minShipping: 0,
    maxShipping: 0,
    orderTotal: 0,
    flatShipCharges: 0,
    pickUp: false,
    freeShip: false,
    hideShipAddr: false,
    flagColor: 0
};

const useShippingMethodForm = (formSubmitCallback) => {
    const validationSchema = yup.object({
        shippingMethodName: yup.string().required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            formSubmitCallback(values);
        },
    });

    const resetForm = () => {
        formik.resetForm();
    }

    return {
        formik,
        resetForm
    };
}

export default useShippingMethodForm;