import { useState } from "react";
import { Box, Link, Tabs, Tab } from "@material-ui/core";
import PageLayout from "layouts/PageLayout";
import Table from "components/ReactTable/ReactTableV2";
import Modal from "components/Modal/Modal";
import PriceIncorrectForm from "./PriceIncorrectForm";
import DiscontinuedForm from "./DiscontinuedForm";
import OldSupersededForm from "./OldSupersededForm";
import ReportOtherIssuesForm from "./ReportOtherIssuesForm";
import useRequest from "hooks/useRequest";
import useModal from "hooks/useModal";

export default function RequestForm() {
    const [isOpen, setIsOpen] = useState(1);
    const { fetchRequest, request, changePagination, pagination, pageCount } = useRequest(isOpen);
    const { open, handleOpenModal, handleCloseModal } = useModal();
    const [selectedCell, setSelectedCell] = useState(null);

    const handleClick = (e, cellData) => {
        e.preventDefault();
        setSelectedCell(cellData);
        handleOpenModal();
    }

    const columns = [
        {
            Header: 'Part#/Title',
            accessor: 'partNumber',
        },
        {
            Header: 'Type',
            accessor: 'requestTypeName',
        },
        {
            Header: 'Catalog name',
            accessor: 'catalogName',
        },
        {
            Header: 'Store Name',
            accessor: 'company',
        },
        {
            Header: 'Requested on',
            accessor: 'formattedCreatedAt',
        },
        {
            Header: 'Action',
            accessor: 'id',
            Cell: ({ cell }) => {
                return (
                    <Link href="#" onClick={(e) => handleClick(e, cell.row.original)} variant="body2">
                        Detail
                    </Link>
                );
            }
        },
    ];

    const fetchData = async ({ pageIndex, pageSize }) => changePagination(pageIndex, pageSize);

    const props = {
        initialValues: selectedCell,
        onSuccess: () => {
            handleCloseModal();
            fetchRequest(pagination.page, pagination.pageSize)
        }
    };

    return (
        <PageLayout showSelectDealer={false} customComponent={<></>}>
            <Box>
                <Tabs value={isOpen} onChange={(_, newValue) => setIsOpen(newValue)}>
                    <Tab value={1} label="Open" />
                    <Tab value={0} label="Close" />
                </Tabs>
            </Box>
            <Box>
                {(open && selectedCell) && (
                    <Modal fullWidth open={open} maxWidth="xs" modalTitle={selectedCell.requestTypeName} handleClose={handleCloseModal}>
                        {selectedCell.requestType === 'price-incorrect' ?
                            <PriceIncorrectForm {...props} /> :
                            null
                        }
                        {selectedCell.requestType === 'discontinued' ?
                            <DiscontinuedForm {...props} /> :
                            null
                        }
                        {selectedCell.requestType === 'old-superseded' ?
                            <OldSupersededForm {...props} /> :
                            null
                        }
                        {selectedCell.requestType === 'report-other-issues' ?
                            <ReportOtherIssuesForm {...props} /> :
                            null
                        }
                    </Modal>
                )}
                <Table
                    columns={columns}
                    data={request.requests}
                    fetchData={fetchData}
                    pageCount={pageCount}
                />
            </Box>
        </PageLayout>
    )
}
