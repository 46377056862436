const makeCodes = {
    suzuki: "1",
    porsche: "2",
    hyundai: "3",
    austin: "4",
    avanti: "5",
    citroen: "6",
    fiat: "7",
    lancia: "8",
    jensen: "9",
    rover: "10",
    land_rover: "11",
    morgan: "12",
    subaru: "13",
    triumph: "14",
    yugo: "15",
    alfa_romeo: "16",
    checker: "17",
    daewoo: "18",
    daihatsu: "19",
    jaguar: "20",
    kia: "21",
    opel: "22",
    renault: "23",
    excalibur: "24",
    laforza: "25",
    volvo: "27",
    peugeot: "28",
    saleen: "29",
    shelby: "30",
    bmw: "31",
    mini: "32",
    bertone: "33",
    qvale: "34",
    delorean: "35",
    panoz: "36",
    isuzu: "37",
    lamborghini: "38",
    chrysler: "39",
    dodge: "40",
    eagle: "41",
    jeep: "42",
    plymouth: "43",
    am_general: "44",
    buick: "45",
    cadillac: "46",
    chevrolet: "47",
    gmc: "48",
    geo: "50",
    oldsmobile: "51",
    pontiac: "52",
    saturn: "53",
    ford: "54",
    lincoln: "55",
    mercury: "56",
    merkur: "57",
    acura: "58",
    honda: "59",
    sterling: "60",
    detomaso: "61",
    maybach: "62",
    mercedes_benz: "63",
    mg: "64",
    saab: "65",
    american_motors: "66",
    nissan: "67",
    infiniti: "68",
    bentley: "69",
    rolls_royce: "70",
    international: "71",
    mitsubishi: "72",
    audi: "73",
    volkswagen: "74",
    lexus: "75",
    toyota: "76",
    aston_martin: "77",
    ferrari: "78",
    maserati: "79",
    mazda: "80",
    lotus: "84",
    morris: "86",
    studebaker: "87",
    sunbeam: "88",
    asuna: "89",
    lada: "90",
    scion: "91",
    hummer: "93",
    bricklin: "94",
    bugatti: "95",
    simca: "438",
    vauxhall: "439",
    talbot_lago: "440",
    stanguellini: "441",
    packard: "442",
    nash: "443",
    lagonda: "444",
    kaiser: "445",
    hudson: "446",
    hillman: "447",
    frazer_nash: "448",
    frazer: "449",
    desoto: "450",
    delahaye: "451",
    delage: "452",
    crosley: "453",
    cisitalia: "454",
    borgward: "455",
    allard: "456",
    ac: "457",
    tucker: "458",
    willys: "459",
    hupmobile: "460",
    graham: "461",
    american_bantam: "462",
    lasalle: "463",
    hispano_suiza: "464",
    pierce_arrow: "465",
    duesenberg: "466",
    cord: "467",
    reo: "468",
    auburn: "469",
    stutz: "470",
    american_austin: "471",
    marmon: "472",
    isotta_fraschini: "473",
    franklin: "474",
    oakland: "475",
    locomobile: "476",
    gardner: "477",
    essex: "478",
    stevens_duryea: "479",
    graham_paige: "480",
    jeffery: "481",
    rambler: "482",
    duryea: "483",
    smart: "484",
    toyopet: "485",
    alpine: "486",
    daimler: "487",
    healey: "488",
    nsu: "489",
    tvr: "490",
    cunningham: "491",
    darrin: "492",
    griffith: "493",
    kurtis: "494",
    facel_vega: "495",
    freightliner: "497",
    alvis: "501",
    armstrong_siddeley: "502",
    bristol: "503",
    goliath: "504",
    hrg: "505",
    hotchkiss: "506",
    humber: "507",
    jowett: "508",
    lanchester: "509",
    lea_francis: "510",
    moretti: "511",
    riley: "512",
    siata: "513",
    singer: "514",
    skoda: "515",
    standard: "516",
    tatra: "517",
    wolseley: "518",
    pegaso: "519",
    salmson: "520",
    reliant: "521",
    bond: "522",
    marathon: "523",
    arnolt_bristol: "524",
    dkw: "525",
    dellow: "526",
    metropolitan: "527",
    panhard: "528",
    swallow: "529",
    goggomobil: "530",
    maico: "531",
    messerschmitt: "532",
    berkeley: "533",
    dual_ghia: "534",
    lloyd: "535",
    moskvich: "536",
    wartburg: "537",
    zundapp: "538",
    denzel: "539",
    deutsch_bonnet: "540",
    fairthorpe: "541",
    abarth: "542",
    elva: "543",
    nardi: "544",
    peerless: "545",
    vespa: "546",
    daf: "547",
    sabra: "548",
    turner: "549",
    amphicar: "550",
    hansa: "551",
    osca: "552",
    glas: "553",
    iso: "554",
    bizzarrini: "555",
    matra: "556",
    monteverdi: "557",
    marcos: "558",
    edsel: "562",
    apperson: "563",
    biddle: "564",
    blackhawk: "565",
    case: "566",
    chandler: "567",
    cleveland: "568",
    cole: "569",
    continental: "570",
    davis: "571",
    de_vaux: "572",
    diana: "573",
    du_pont: "574",
    durant: "575",
    elcar: "576",
    erskine: "577",
    fwd: "578",
    falcon_knight: "579",
    flint: "580",
    hcs: "581",
    haynes: "582",
    hertz: "583",
    jewett: "584",
    jordan: "585",
    kissel: "586",
    lexington: "587",
    marquette: "588",
    maxwell: "589",
    moon: "590",
    paige: "591",
    rickenbacker: "593",
    roamer: "594",
    rockne: "595",
    rollin: "596",
    roosevelt: "597",
    scripps_booth: "598",
    sheridan: "599",
    star: "600",
    stearns_knight: "601",
    velie: "602",
    viking: "603",
    westcott: "604",
    whippet: "605",
    windsor: "606",
    yellow_cab: "607",
    workhorse: "608",
    allstate: "609",
    henry_j: "610",
    faw: "612",
    foton: "613",
    giant_motors: "614",
    seat: "615",
    ssangyong: "616",
    cub_cadet: "627",
    husqvarna: "629",
    john_deere: "725",
    kawasaki: "729",
    kubota: "733",
    bobcat: "853",
    tesla: "1164",
    ram: "1168",
    american_ironhorse: "1169",
    aprilia: "1170",
    arctic_cat: "1171",
    atk: "1172",
    benelli: "1173",
    beta: "1174",
    big_dog: "1175",
    bimota: "1176",
    bombardier: "1177",
    buell: "1178",
    bultaco: "1179",
    can_am: "1180",
    cannondale: "1181",
    cobra: "1182",
    ducati: "1183",
    e_ton: "1184",
    excelsior_henderson: "1185",
    gas_gas: "1186",
    harley_davidson: "1187",
    hm: "1188",
    husaberg: "1189",
    hyosung: "1190",
    indian: "1191",
    kasea: "1192",
    ktm: "1193",
    kymco: "1194",
    lem: "1195",
    moto_guzzi: "1196",
    moto_ski: "1197",
    mv_agusta: "1198",
    piaggio: "1199",
    polaris: "1200",
    rupp: "1201",
    sdg: "1202",
    sea_doo: "1203",
    ski_doo: "1204",
    tigershark: "1205",
    tm: "1206",
    victory: "1207",
    vor: "1208",
    wet_jet: "1209",
    yamaha: "1210",
    apollo: "1211",
    arnolt_mg: "1212",
    doretti: "1213",
    fargo: "1214",
    gordon_keeble: "1215",
    marauder: "1216",
    omega: "1217",
    panther: "1218",
    austin_healey: "1223",
    coda: "1225",
    vpg: "1227",
    mclaren: "1228",
    srt: "1230",
    redcat_motors: "1231",
    tgb: "1232",
    polini: "1233",
    cagiva: "1234",
    erik_buell_racing: "1235",
    vento: "1236",
    argo: "1237",
    redline: "1238",
    mastretta: "1241",
    fisker: "1243",
    case_ih: "1244",
    scorpion: "1246",
    sno_jet: "1247",
    vam: "1248",
    think: "1313",
    mobility_ventures: "1314",
    big_bear_choppers: "1315",
    gem: "1316",
    zero: "1317",
    baic: "1318",
    genesis: "1319",
    cfmoto: "1320",
    new_holland: "1321",
    odes: "1322",
    datsun: "1324",
    italika: "1325",
    jac: "1326",
    utilimaster: "1328",
    textron: "1332",
    zacua: "1334",
    karma: "1337",
    mahindra: "1338",
    spyker: "1341",
    royal_enfield: "1342",
    uaz: "1343",
    bajaj: "1346",
    byd: "1349",
    changan: "1350",
    polestar: "1356",
    jmc: "1360",
    keeway: "1362",
    rivian: "1368",
    sherco: "1369",
    lynx: "1370",
    lucid: "1371",
    tracker: "1374",
    chirey: "1398",
    cupra: "1404",
    lordstown_motors: "1405",
    vinfast: "1412",
    atul: "1415",
    brightdrop: "1417",
    boss: "1418",
    carabela: "1419",
    dinamo: "1423",
    dazon: "1424",
    islo: "1433",
    izuka: "1438",
    kingway_mx: "1445",
    sunl: "1446",
    sym: "1447",
    tvs: "1448",
    oubike: "1450",
    treck: "1451",
    torino: "1454",
    veloci: "1458",
    ineos: "1459",
    omoda: "1460",
    jetour: "1461",
    mullen: "1462",
    maxus: "1466",
};

const useProductImageUrl = () => {
    const getProductImageUrl = (makeSlug = "", partNumber = "", path_image = "") => {
        if (path_image) {
            try {
                new URL(path_image);
                return path_image;
            } catch (error) {}
        }

        if (!makeSlug || !partNumber) {
            return "";
        }

        const makeSlugNormalized = makeSlug.trim().toLowerCase().replace(/\s/g, "_");
        const makeCode = makeCodes[makeSlugNormalized];

        if (!makeCode) {
            return "";
        }

        const baseUrl = "https://moparimages.s3.amazonaws.com";
        const imageUrl = `${baseUrl}/mk_${makeCode}/${partNumber}.SVG`;
        return imageUrl;
    };

    return { getProductImageUrl };
};

export default useProductImageUrl;
