import { InputAdornment, Typography, FormControl, InputLabel, Input, FormHelperText, Box } from '@material-ui/core';
import CustomBox from 'components/Box/Box';
import Button from "components/CustomButtons/Button.js";
import useCustomFee from 'hooks/useCustomFee';

export default function CustomFee() {
    const { formik } = useCustomFee();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography gutterBottom style={{ fontSize: 16, fontWeight: 500 }}>
                Custom Fee:
            </Typography>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Custom Fee Title:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="customFeeTitle"
                        value={formik.values.customFeeTitle}
                        onChange={formik.handleChange}
                        error={formik.touched.customFeeTitle && Boolean(formik.errors.customFeeTitle)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Flat (Fixed) Fee:</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                        name="customFlatFee"
                        value={formik.values.customFlatFee}
                        onChange={formik.handleChange}
                        error={formik.touched.customFlatFee && Boolean(formik.errors.customFlatFee)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Percent Fee:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        endAdornment={
                            <InputAdornment position="start">%</InputAdornment>
                        }
                        name="customPercentFee"
                        value={formik.values.customPercentFee}
                        onChange={formik.handleChange}
                        error={formik.touched.customPercentFee && Boolean(formik.errors.customPercentFee)}
                    />
                    <FormHelperText>
                        of Order Total
                    </FormHelperText>
                </FormControl>
            </CustomBox>
            <Box textAlign="center">
                <Button type="submit" color="rose">
                    Update Custom Fee
                </Button>
            </Box>
        </form>
    )
}
