import React, { createContext, useContext, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import FormFrame from "components/FormFrame/FormFrame";

const ConfirmationDialogContext = createContext();

export function ConfirmationDialogProvider({ children }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({});

    const openDialog = (content) => {
        setDialogContent(content);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const handleConfirm = () => {
        closeDialog();
        dialogContent.onConfirm();
    };

    const fullWidth = dialogContent.fullWidth || false;
    const maxWidth = dialogContent.maxWidth || "xs";
    const showActions = dialogContent.showActions || false;
    const customDialog = dialogContent.customDialog || false;

    if (customDialog) {
        return (
            <ConfirmationDialogContext.Provider value={{ openDialog, closeDialog }}>
                {children}
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={isDialogOpen}
                    onClose={closeDialog}
                >
                    {dialogContent.title && <DialogTitle>{dialogContent.title}</DialogTitle>}
                    {dialogContent.message && (
                        <DialogContent>{dialogContent.message}</DialogContent>
                    )}
                    {showActions && (
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </ConfirmationDialogContext.Provider>
        );
    }

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog, closeDialog }}>
            {children}
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpen}
                onClose={closeDialog}
            >
                {dialogContent.title && <DialogTitle>{dialogContent.title}</DialogTitle>}
                {dialogContent.message && (
                    <DialogContent>
                        <FormFrame title={dialogContent.formFrameTitle} closeDialog={closeDialog}>
                            {dialogContent.message}
                        </FormFrame>
                    </DialogContent>
                )}
                {showActions && (
                    <DialogActions>
                        <Button onClick={closeDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleConfirm} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </ConfirmationDialogContext.Provider>
    );
}

export function useConfirmationDialog() {
    return useContext(ConfirmationDialogContext);
}
