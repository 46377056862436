import { useState, useEffect } from "react";
import PageLayout from "layouts/PageLayout";
import { FormControlLabel, Switch, TextField, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useFieldUpdater } from "hooks/useFieldUpdater";
import useCrud from "hooks/useCrud";
import useDealer from "hooks/useDealer";

const initialValues = {
    enable_pick_up_at_dealership: false,
    pick_up_description: "",
};

export default function PickUpOptions() {
    const dealer = useDealer();
    const [values, setValues] = useState(initialValues);
    const { updateFieldValue } = useFieldUpdater();
    const { updateEntity } = useCrud("dealers", {}, false);

    useEffect(() => {
        if (dealer && dealer.id) {
            setValues({
                ...values,
                enable_pick_up_at_dealership: dealer.enablePickUpAtDealership === 1,
                pick_up_description: dealer.pickUpDescription,
            });
        }
    }, [dealer]);

    const handleClick = () => {
        updateEntity({
            id: dealer.id,
            ...values,
            enable_pick_up_at_dealership: values.enable_pick_up_at_dealership ? 1 : 0,
        });
    };

    return (
        <PageLayout>
            <FormControlLabel
                control={
                    <Switch
                        name="checkedA"
                        checked={values.enable_pick_up_at_dealership}
                        onChange={() =>
                            updateFieldValue(
                                setValues,
                                "enable_pick_up_at_dealership",
                                !values.enable_pick_up_at_dealership
                            )
                        }
                    />
                }
                label="Enable Pick Up At Dealership"
            />
            <Box mt={2}>
                <TextField
                    fullWidth
                    multiline
                    label=""
                    variant="outlined"
                    rows={6}
                    value={values.pick_up_description}
                    onChange={(e) =>
                        updateFieldValue(setValues, "pick_up_description", e.target.value)
                    }
                />
            </Box>
            <Box textAlign="center" mt={2}>
                <Button type="button" color="rose" onClick={handleClick}>
                    Save and close
                </Button>
            </Box>
        </PageLayout>
    );
}
