import CreateItemCore from "views/RelatedItemsSettings/CreateItemCore";
import { useConfirmationDialog } from "./useConfirmationDialog";

const useItemCore = () => {
    const { openDialog, closeDialog } = useConfirmationDialog();

    const handleClickEdit = () => {
        openDialog({
            formFrameTitle: ``,
            fullWidth: true,
            maxWidth: "md",
            message: <CreateItemCore />,
        });
    };

    return {
        data: [],
        handleClickEdit,
    };
};

export default useItemCore;
