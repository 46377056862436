import { useFormik, FormikProvider, FieldArray } from "formik";
import * as yup from "yup";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VehicleSelector from '../VehicleSelector/VehicleSelector';

const validationSchema = yup.object({
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    password: yup
        .string("Enter your password")
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required")
});

export const DEF_INITIAL_VALUES = {
    vehicles: [{
        year: "",
        modelId: "",
        trimId: "",
        engineId: ""
    }]
}

export default function MultiVehicleSelector({ formik, initialValues = DEF_INITIAL_VALUES }) {
    /*const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        }
    });*/

    const lastVehicle = formik.values.vehicles[formik.values.vehicles.length - 1];

    return (
        <FieldArray
            name="vehicles"
            render={(arrayHelpers) => (
                <div>
                    {formik.values.vehicles.map((vehicle, index) => (
                        <Grid key={index} container spacing={3}>
                            <Grid item xs={11}>
                                <VehicleSelector
                                    selectedYear={vehicle.year}
                                    handleYearChange={(e) =>
                                        formik.setFieldValue(`vehicles.${index}.year`, e.target.value)
                                    }
                                    selectedModel={vehicle.modelId}
                                    handleModelChange={(e) =>
                                        formik.setFieldValue(`vehicles.${index}.modelId`, e.target.value)
                                    }
                                    selectedTrim={vehicle.trimId}
                                    handleTrimChange={(e) =>
                                        formik.setFieldValue(`vehicles.${index}.trimId`, e.target.value)
                                    }
                                    selectedEngine={vehicle.engineId}
                                    handleEngineChange={(e) =>
                                        formik.setFieldValue(`vehicles.${index}.engineId`, e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Box>
                                    <IconButton
                                        disabled={index === 0}
                                        onClick={() => arrayHelpers.remove(index)}
                                    >
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}

                    <Button
                        disabled={lastVehicle.engineId === ""}
                        fullWidth
                        variant="outlined"
                        onClick={() => arrayHelpers.push({
                            year: "",
                            modelId: "",
                            trimId: "",
                            engineId: ""
                        })}
                        style={{ marginTop: 20, marginBottom: 5 }}
                    >
                        Add new vehicle
                    </Button>
                </div>
            )}
        />
    );
}