import http from "./Http";

const getAll = async () => {
    return http.get("/catalogs");
};

const post = async body => {
    return http.post("/catalogs", body);
};

const put = async body => {
    return http.put(`/catalogs/${body.id}`, body);
};

const CatalogService = {
    getAll,
    post,
    put
};

export default CatalogService;