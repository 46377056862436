import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";

export default function EditButton({ handleClick = () => { } }) {
    return (
        <Button
            justIcon
            round
            simple
            color="warning"
            className="edit"
            onClick={handleClick}
        >
            <Edit />
        </Button>
    )
}
