import * as Yup from 'yup';
import { useFormik } from 'formik';
import useDealer from './useDealer';
import useUser from './useUser';

function useRequestValidation(requestType, onSubmit = () => { }, initialValuesOverride = {}) {
    const { id: dealerId = 0 } = useDealer();
    const [user] = useUser();
    const userId = user && user.id ? user.id : 0;

    let validationSchema;
    let initialValues = {
        dealerId,
        userId,
        requestType,
        catalogId: '',
        catalogName: '',
        partNumber: '',
        listPrice: '',
        cost: '',
        costPrice: '',
        comment: '',
        newPartNumber: '',
        issueDescription: '',
        name: '',
        emailOrPhone: '',
        ...initialValuesOverride,
    };

    switch (requestType) {
        case 'price-incorrect':
            validationSchema = Yup.object().shape({
                catalogId: Yup.number().required(),
                partNumber: Yup.string().required(),
                listPrice: Yup.number().required(),
                cost: Yup.number().required(),
                costPrice: Yup.number().required(),
                comment: Yup.string().required()
            });
            break;
        case 'discontinued':
            validationSchema = Yup.object().shape({
                catalogId: Yup.number().required(),
                partNumber: Yup.string().required(),
                comment: Yup.string().required(),
            });
            break;
        case 'old-superseded':
            validationSchema = Yup.object().shape({
                catalogId: Yup.number().required(),
                partNumber: Yup.string().required(),
                newPartNumber: Yup.string().required(),
                comment: Yup.string().required(),
            });
            break;
        case 'report-other-issues':
            validationSchema = Yup.object().shape({
                issueDescription: Yup.string().required(),
                name: Yup.string().required(),
                emailOrPhone: Yup.string().required(),
            });
            break;
        default:
            validationSchema = Yup.object().shape({});
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit
    });

    return formik;
}

export default useRequestValidation;
