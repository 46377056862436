import React, { useState, useEffect } from "react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import AreYouSure from "components/AreYouSure/AreYouSure";

// custom components
import DealerCreateUser from "./DealerCreateUser";

import DealerUserService from "../../../services/DealerUserService";
import PasswordManagement from "components/PasswordManagement/PasswordManagement";
import DeleteButton from "components/DeleteButton/DeleteButton";

export const CREATE_USER = {
    title: "Create user",
    successMessage: "user created successfully",
};

export const EDIT_USER = {
    title: "Edit user",
    successMessage: "user updated successfully",
};

const DEALER_ADMIN_ROLE = 1;

export default function DealerUsers({ dlid }) {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [form, setForm] = useState(CREATE_USER);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });

    useEffect(() => {
        const getUsers = async (dlid) => {
            const users = await DealerUserService.getAll(dlid);
            setUsers(users.data);
        };

        if (dlid) {
            getUsers(dlid);
        }
    }, [dlid]);

    const onSuccess = (user) => {
        if (form === CREATE_USER) {
            setUsers([...users, user]);
        }

        if (form === EDIT_USER) {
            const nUsers = users.map((c) => (c.id === user.id ? { ...c, ...user } : c));
            setUsers(nUsers);
        }

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                />
            )}

            <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                <DealerCreateUser
                    dlid={dlid}
                    form={form}
                    initialValues={initialValues}
                    onSuccess={(user) => onSuccess(user)}
                />
            </Modal>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_USER);
                                    setOpenModal(true);
                                    setInitialValues({});
                                }}
                            >
                                Create user
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "Id",
                                        accessor: "id",
                                    },
                                    {
                                        Header: "First name",
                                        accessor: "firstName",
                                    },
                                    {
                                        Header: "Last name",
                                        accessor: "lastName",
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                    },
                                    {
                                        Header: "Role",
                                        accessor: "role",
                                    },
                                    {
                                        Header: "Created at",
                                        accessor: "createdAt",
                                    },
                                    {
                                        Header: "Updated at",
                                        accessor: "updatedAt",
                                    },
                                    {
                                        Header: "Actions",
                                        Cell: (props) => {
                                            const rowIdx = props.row.id;
                                            const user = users[rowIdx];

                                            return (
                                                <>
                                                    <div className="actions-right">
                                                        <Button
                                                            justIcon
                                                            round
                                                            simple
                                                            color="warning"
                                                            className="edit"
                                                            onClick={() => {
                                                                setForm(EDIT_USER);
                                                                setOpenModal(true);
                                                                setInitialValues(user);
                                                            }}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                        <DeleteButton
                                                            title="dealer user"
                                                            handleClick={async () => {
                                                                await DealerUserService.del(
                                                                    user.id
                                                                );
                                                                setUsers(
                                                                    [...users].filter(
                                                                        (u) => u.id !== user.id
                                                                    )
                                                                );
                                                                setNotification({
                                                                    ...notification,
                                                                    open: true,
                                                                    message: "Successfully Deleted",
                                                                });
                                                            }}
                                                            disabled={
                                                                user.roleId == DEALER_ADMIN_ROLE
                                                            }
                                                        />
                                                        <PasswordManagement
                                                            userId={user.id}
                                                            userType="DEALER_USER"
                                                            userName={user.email}
                                                        />
                                                    </div>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                data={users}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <AreYouSure
                open={open}
                title={`Are you sure?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    await DealerUserService.del(initialValues.id);
                    setUsers([...users].filter((user) => user.id !== initialValues.id));
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}
