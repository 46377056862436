import TextField from '@material-ui/core/TextField';

function ColorPickerTextField({ name, value, onColorChange }) {
  const handleColorChange = (event) => {
    onColorChange(event.target.name, event.target.value);
  };

  return (
    <div>
      <TextField
        type="color"
        name={name}
        value={value}
        onChange={handleColorChange}
        InputProps={{
          inputProps: {
            style: { fontSize: '20px', width: 50 },
          },
        }}
      />
    </div>
  );
}

export default ColorPickerTextField;
