import React, { useState, useEffect } from "react";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import CreateRole from "./CreateRole";

import RoleService from "../../services/RoleService";
import DeleteButton from "components/DeleteButton/DeleteButton";

export const CREATE_ROLE = {
    title: "Create role",
    successMessage: "Role created successfully",
};

export const EDIT_ROLE = {
    title: "Edit role",
    successMessage: "Role updated successfully",
};

export default function Roles() {
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [form, setForm] = useState(CREATE_ROLE);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });

    useEffect(() => {
        const getRoles = async () => {
            const roles = await RoleService.getAll();
            setRoles(roles);
        };
        getRoles();
    }, []);

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Role name",
            accessor: "name",
        },
        {
            Header: "Role type",
            accessor: "roleType",
        },
        {
            Header: "Actions",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div>{["Admin", "Dealer admin"].includes(original.name)}</div>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                title="Edit"
                                onClick={() => {
                                    setForm(EDIT_ROLE);
                                    setOpenModal(true);
                                    setInitialValues(original);
                                }}
                            >
                                <Edit />
                            </Button>
                            {!["Admin", "Dealer admin"].includes(original.name) && (
                                <>
                                    <DeleteButton
                                        title="role"
                                        handleClick={async () => {
                                            await RoleService.del(original.id);
                                            setRoles(
                                                [...roles].filter((c) => c.id !== original.id)
                                            );
                                            setOpen(false);
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </>
                );
            },
        },
    ];

    const onSuccess = (role) => {
        if (form === CREATE_ROLE) {
            setRoles([...roles, role]);
        }

        if (form === EDIT_ROLE) {
            const nRoles = roles.map((c) => (c.id === role.id ? { ...c, ...role } : c));
            setRoles(nRoles);
        }

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                />
            )}

            <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                <div style={{ minWidth: 800 }}></div>
                <CreateRole
                    form={form}
                    initialValues={initialValues}
                    onSuccess={(role) => onSuccess(role)}
                />
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_ROLE);
                                    setOpenModal(true);
                                    setInitialValues({});
                                }}
                            >
                                Create role
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={columns}
                                data={roles.filter((r) => !r.dealerId)}
                                showPagination={true}
                                showSorting={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    await RoleService.del(initialValues.id);
                    setRoles([...roles].filter((c) => c.id !== initialValues.id));
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}
