import { Switch, FormControlLabel, Typography, FormControl, InputLabel, Input, Box } from '@material-ui/core';
import CustomBox from 'components/Box/Box';
import Button from "components/CustomButtons/Button.js";
import useUpsCarrier from 'hooks/useUpsCarrier';

export default function UpsCarrier() {
    const { formik } = useUpsCarrier();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography gutterBottom style={{ fontSize: 16, fontWeight: 500 }}>
                UPS Carrier:
            </Typography>
            <CustomBox>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            name="upsCarrierEnabled"
                            checked={formik.values.upsCarrierEnabled}
                            onChange={formik.handleChange}
                        />
                    }
                    label="UPS Carrier:"
                />
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>UPS User Name:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="upsCarrierUserName"
                        value={formik.values.upsCarrierUserName}
                        onChange={formik.handleChange}
                        error={formik.touched.upsCarrierUserName && Boolean(formik.errors.upsCarrierUserName)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>UPS Password:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="upsCarrierPassword"
                        value={formik.values.upsCarrierPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.upsCarrierPassword && Boolean(formik.errors.upsCarrierPassword)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Access License Number:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="upsCarrierAccessLicenseNumber"
                        value={formik.values.upsCarrierAccessLicenseNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.upsCarrierAccessLicenseNumber && Boolean(formik.errors.upsCarrierAccessLicenseNumber)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Shipper Number:</InputLabel>
                    <Input
                        startAdornment={<></>}
                        name="upsCarrierShipperNumber"
                        value={formik.values.upsCarrierShipperNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.upsCarrierShipperNumber && Boolean(formik.errors.upsCarrierShipperNumber)}
                    />
                </FormControl>
            </CustomBox>
            <Box textAlign="center">
                <Button type="submit" color="rose">
                    Update UPS Carrier
                </Button>
            </Box>
        </form>
    )
}
