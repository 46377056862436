import http from "./Http";

const getAll = async () => {
    const { data } = await http.get("/backoffice-menu");
    return data;
};

const MenuService = {
    getAll,
};

export default MenuService;