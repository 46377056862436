import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";

// const
import { CREATE_PAGE_TECH, EDIT_PAGE_TECH } from './DealerPageTech';

//services
import PageTechService from '../../../services/DealerPageTechService';
import PageService from '../../../services/PageService';
import { FormHelperText } from "@material-ui/core";

const validationSchema = yup.object({
    pagetechPosition: yup.string().required(),
    enTitle: yup.string().required(),
    esTitle: yup.string().required(),
    ptTitle: yup.string().required(),
    frTitle: yup.string().required()
});

const useStyles = makeStyles({
    updateProfileButton: {
        float: "right",
    },
});

export default function CreatePageTechCont({
    formik
    /*dlid = '',
    form = CREATE_PAGE_TECH,
    initialValues,
    onSuccess = () => { }*/
}) {
    /*const [pages, setPages] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const getPages = async () => {
            const pages = await PageService.getAll();
            setPages(pages)
        };
        getPages();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            pagetechPosition: initialValues.pagetechPosition || '',
            pageId: initialValues.pageId || 0,
            pageName: initialValues.pageName || '',
            enTitle: initialValues.enTitle || '',
            esTitle: initialValues.esTitle || '',
            ptTitle: initialValues.ptTitle || '',
            frTitle: initialValues.frTitle || '',
            enHtml: initialValues.enHtml || '',
            esHtml: initialValues.esHtml || '',
            ptHtml: initialValues.ptHtml || '',
            frHtml: initialValues.frHtml || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_PAGE_TECH) createPageTech({ ...values, dealerId: dlid });
            if (form === EDIT_PAGE_TECH) updatePageTech({ ...values, dealerId: dlid });
        },
    });

    const createPageTech = async body => {
        try {
            const techPage = await PageTechService.post(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    }

    const updatePageTech = async body => {
        try {
            const techPage = await PageTechService.put(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    }*/

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            type="number"
                            autoFocus
                            fullWidth
                            margin="dense"
                            id="pagetechPosition"
                            name="pagetechPosition"
                            label="Page tech position"
                            value={formik.values.pagetechPosition}
                            onChange={formik.handleChange}
                            error={formik.touched.pagetechPosition && Boolean(formik.errors.pagetechPosition)}
                            inputProps={{
                                max: 10
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="enTitle"
                            name="enTitle"
                            label="English title"
                            value={formik.values.enTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.enTitle && Boolean(formik.errors.enTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <HtmlEditor
                            title="English HTML"
                            data={formik.values.enHtml}
                            onBlur={(value) => formik.setFieldValue("enHtml", value)} />
                        <FormHelperText>Shortcodes: [make] [year] [model] [trim] [engine] [category] [subcategory] [partnumber] [partdescription] [salesprice] [showprice]</FormHelperText>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="esTitle"
                            name="esTitle"
                            label="Spanish title"
                            value={formik.values.esTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.esTitle && Boolean(formik.errors.esTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <HtmlEditor
                            title="Spanish HTML"
                            data={formik.values.esHtml}
                            onBlur={(value) => formik.setFieldValue("esHtml", value)} />
                        <FormHelperText>Shortcodes: [make] [year] [model] [trim] [engine] [category] [subcategory] [partnumber] [partdescription] [salesprice] [showprice]</FormHelperText>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="ptTitle"
                            name="ptTitle"
                            label="Portuguese title"
                            value={formik.values.ptTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.ptTitle && Boolean(formik.errors.ptTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <HtmlEditor
                            title="Portuguese HTML"
                            data={formik.values.ptHtml}
                            onBlur={(value) => formik.setFieldValue("ptHtml", value)} />
                        <FormHelperText>Shortcodes: [make] [year] [model] [trim] [engine] [category] [subcategory] [partnumber] [partdescription] [salesprice] [showprice]</FormHelperText>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="frTitle"
                            name="frTitle"
                            label="French title"
                            value={formik.values.frTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.frTitle && Boolean(formik.errors.frTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <HtmlEditor
                            title="French HTML"
                            data={formik.values.frHtml}
                            onBlur={(value) => formik.setFieldValue("frHtml", value)} />
                        <FormHelperText>Shortcodes: [make] [year] [model] [trim] [engine] [category] [subcategory] [partnumber] [partdescription] [salesprice] [showprice]</FormHelperText>
                    </GridItem>
                </GridContainer>

                {/*<div style={{ textAlign: 'center', margin: 10 }}>
                        <Button type="submit" color="rose">Save and close</Button>
                    </div>*/}
            </GridItem>
        </GridContainer>
    );
}