import React, { createContext, useContext, useState } from 'react';
import { Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const openSnackbar = (severity = 'success', title = '', message = '') => {
        setSeverity(severity);
        setTitle(title);
        setMessage(message);
        setIsOpen(true);
    };

    const closeSnackbar = () => {
        setIsOpen(false);
    };

    return (
        <SnackbarContext.Provider value={openSnackbar}>
            {children}
            <Snackbar
                open={isOpen}
                autoHideDuration={3000}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MuiAlert severity={severity}>{title}</MuiAlert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
}

export function useSnackbar() {
    const openSnackbar = useContext(SnackbarContext);
    return openSnackbar;
}
