import PageLayout from "layouts/PageLayout";
import { Box, TextField } from "@material-ui/core";
import TableWrapper from "components/TableWrapper/TableWrapper";
import Button from "components/CustomButtons/Button.js";
import CreateTranslate from "./CreateTranslate";
import CreateTranslatePart from "./CreateTranslatePart";
import useTranslate from "hooks/useTranslate";
import Tabs from "components/Tabs/Tabs";

export default function Translate() {
    const {
        openDialog,
        closeDialog,
        dealerId,
        translations,
        fetchTranslation,
        query,
        setQuery,
        handleClickDelete,
        handleClickInsert,
        handleClickUpdate,
        handleTabChange,
        tabId,
    } = useTranslate();

    const columns = [
        {
            Header: "EN",
            accessor: "en",
        },
        {
            Header: "ES",
            accessor: "es",
        },
        {
            Header: "PT",
            accessor: "pt",
        },
        {
            Header: "FR",
            accessor: "fr",
        },
    ];

    if (tabId === 1) {
        const newColumn = {
            Header: "Part Number",
            accessor: "partNumber",
        };

        columns.unshift(newColumn);
    }

    const tabs = [
        { label: "UI", content: <></> },
        { label: "Parts", content: <></> },
    ];

    const renderTranslateComponent = (initialValues) => {
        if (tabId === 0) {
            return (
                <CreateTranslate
                    initialValues={initialValues}
                    createEntity={handleClickInsert}
                    updateEntity={handleClickUpdate}
                    onSuccess={closeDialog}
                />
            );
        } else if (tabId === 1) {
            return (
                <CreateTranslatePart
                    initialValues={initialValues}
                    createEntity={handleClickInsert}
                    updateEntity={handleClickUpdate}
                    onSuccess={closeDialog}
                />
            );
        }
    };

    return (
        <PageLayout
            customComponent={
                <Button
                    color="primary"
                    onClick={() => {
                        openDialog({
                            formFrameTitle: "Create translation",
                            fullWidth: true,
                            maxWidth: "sm",
                            message: renderTranslateComponent(),
                        });
                    }}
                >
                    Create translation
                </Button>
            }
        >
            <Tabs tabs={tabs} onTabChange={handleTabChange} />

            <Box display="flex" alignItems="center">
                <Box flexGrow={1} />
                <TextField
                    label="Search"
                    variant="outlined"
                    value={query}
                    onChange={(e) => {
                        const newQuery = e.target.value.trim();
                        setQuery(newQuery);

                        if (newQuery.length >= 3) {
                            fetchTranslation(dealerId, 1, 10, newQuery);
                        } else {
                            fetchTranslation(dealerId, 1, 10, "");
                        }
                    }}
                />
            </Box>

            <TableWrapper
                showEditButton
                showDeleteButton
                columns={columns}
                data={translations.data}
                pageCount={translations.totalPages}
                onEditClick={(initialValues) => {
                    openDialog({
                        formFrameTitle: "Edit translation",
                        fullWidth: true,
                        maxWidth: "sm",
                        message: renderTranslateComponent(initialValues),
                    });
                }}
                onDeleteClick={handleClickDelete}
                fetchData={({ pageIndex, pageSize }) => {
                    fetchTranslation(dealerId, pageIndex, pageSize);
                }}
            />
        </PageLayout>
    );
}
