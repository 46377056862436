import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const get = async (countryISO = "") => {
    const res = await http.get(`${BASE_URL_API_ECOMMERCE}/states?countryIso=${countryISO}`);
    return res.data.data;
};

const StateService = {
    get,
};

export default StateService;
