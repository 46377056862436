import http from "./Http";

const post = async ({ dealerId, orderId, userId, transactionAmount, reason }) => {
    const res = await http.post(`/merchante-api`, {
        dealerId,
        orderId: Number(orderId),
        userId,
        transactionAmount: Number(transactionAmount),
        reason
    }).then(r => r.data)
    return res;
}

const PaymentService = {
    post
}

export default PaymentService;