import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FreeShipping from "./FreeShipping";
import HandlingFee from "./HandlingFee";
import CustomFee from "./CustomFee";

export default function ShippingFee() {
    return (
        <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
                <FreeShipping />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
                <HandlingFee />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
                <CustomFee />
            </GridItem>
        </GridContainer>
    )
}
