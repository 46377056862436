// core components
import SelectDealer from "../components/SelectDealer/SelectDealer";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardBody from "../components/Card/CardBody.js";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

export default function PageLayout({ children, showSelectDealer = true, customComponent }) {
    return (
        <GridContainer>
            {showSelectDealer && (
                <GridItem xs={12}>
                    <SelectDealer />
                </GridItem>
            )}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Assignment />
                        </CardIcon>
                        {customComponent}
                    </CardHeader>
                    <CardBody>{children}</CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
