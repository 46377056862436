import ProductService from "services/ProductService";

const usePartNumberValidator = () => {
    const validatePartNumbers = async (dealerId = "", partNumbers = [], catalogName = "") => {
        const validationResults = await ProductService.validatePartNumbers({
            dlid: dealerId,
            catalog: catalogName,
            partNumbers: partNumbers,
        });

        return validationResults;
    };

    return {
        validatePartNumbers,
    };
};

export default usePartNumberValidator;
