import { useState, useEffect } from "react";
import Papa from "papaparse";
import ProductService from "services/ProductService";
import useCrud from "./useCrud";
import useDealerId from "./useDealerId";
import { useSnackbar } from "./useSnackbar";

const useBulkItems = () => {
    const dealerId = useDealerId();
    const [catalogName, setCatalogName] = useState("");
    const [mustValidate, setMustValidate] = useState(false);
    const [products, setProducts] = useState([]);
    const [productsEditedParts, setProductsEditedParts] = useState([]);
    const [partNumberFilter, setPartNumberFilter] = useState("");
    const { readAllEntities, createEntity, updateEntity, deleteEntity } = useCrud(
        "dealer_bulk_items",
        {},
        false
    );
    const {
        readAllEntities: fetchEditedParts,
        createEntity: createEditedPart,
        updateEntity: updateEditedPart,
        deleteEntity: deleteEditedPart,
    } = useCrud("edited_parts", {}, false);
    const openSnackbar = useSnackbar();

    useEffect(() => {
        if (dealerId) {
            fetchBulkItems();
        }
    }, [dealerId]);

    const fetchBulkItems = async () => {
        /*const data = await readAllEntities({
            dealer_id: dealerId,
        });

        setProducts(data);*/

        const data = await fetchEditedParts({
            dealer_id: dealerId,
        });

        setProductsEditedParts(data);
    };

    const handleCSVUpload = (csvData) => {
        Papa.parse(csvData, {
            header: true,
            dynamicTyping: true,
            complete: async (result) => {
                const processedData = processCSVResult(result);

                if (!mustValidate) {
                    return setProducts(processedData);
                }

                const partNumbers = processedData.map((item) => item.partNumber);
                const validationResults = await validateProcessedData(partNumbers);
                const processedDataWithErrors = addErrorsToProcessedData(
                    processedData,
                    validationResults
                );
                setProducts(processedDataWithErrors);
            },
        });
    };

    // Part Number, Selling Price, Labor Time, Status, Title, Description, Core
    const processCSVResult = (csvResult) => {
        const maxId = products.reduce((max, product) => (product.id > max ? product.id : max), 0);

        const processedData = csvResult.data.map((p, index) => {
            /*const [
                partNumber,
                name,
                description,
                list,
                laborTime,
                core,
                hideItem,
                date,
            ] = Object.values(p);*/

            const [
                partNumber,
                sellingPrice,
                laborTime,
                hideItem,
                title,
                description,
                core,
            ] = Object.values(p);

            const id = maxId + 1;

            return {
                id,
                partNumber,
                nameTitle: title,
                description,
                list: sellingPrice,
                laborTime,
                core,
                hideItem,
                date: null,
                isNew: true,
            };
        });

        return processedData.filter((d) => d.partNumber);
    };

    const validateProcessedData = async (partNumbers = []) => {
        const validationResults = await validatePartNumbers(partNumbers);
        return validationResults;
    };

    const addErrorsToProcessedData = (processedData, validationResults) => {
        return processedData.map((item) => {
            const partNumberExists = validationResults.some(
                (r) => r.partNumber === item.partNumber && r.exists
            );
            return {
                ...item,
                error: !partNumberExists,
            };
        });
    };

    const validatePartNumbers = async (partNumbers = []) => {
        const validationResults = await ProductService.validatePartNumbers({
            dlid: dealerId,
            catalog: catalogName,
            partNumbers: partNumbers,
        });

        return validationResults;
    };

    const handlePartNumberChange = async (index, partNumber = "") => {
        const partNumbers = [partNumber];
        const validationResults = await validateProcessedData(partNumbers);
        const { exists = false } = validationResults.find((item) => item.partNumber === partNumber);

        setProducts((prevProducts) => {
            const updatedProducts = [...prevProducts];
            updatedProducts[index] = { ...updatedProducts[index], partNumber, error: !exists };
            return updatedProducts;
        });
    };

    const handleClickDelete = async (idToDelete) => {
        const product = [...products].find((product) => product.id == idToDelete);

        if (!product.isNew) {
            await deleteEntity(idToDelete);
        }

        const updatedProducts = [...products].filter((product) => product.id !== idToDelete);
        setProducts(updatedProducts);
    };

    const handleDownloadCSV = () => {
        const formattedProducts = products.map((p) => {
            return {
                partNumber: p.partNumber,
                name: p.name,
                description: p.description,
                list: p.list,
                laborTime: p.laborTime,
                core: p.core,
                hideItem: p.hideItem,
                date: p.date,
            };
        });

        generateCSV([
            {
                partNumber: "Part number(s)",
                name: "Name/Title",
                description: "Description",
                list: "List",
                laborTime: "Labor Time",
                core: "Core",
                hideItem: "Hide item(s)",
                date: "Date",
            },
            ...formattedProducts,
        ]);
    };

    const generateCSV = (data = []) => {
        const csvContent =
            "data:text/csv;charset=utf-8," +
            data.map((row) => Object.values(row).join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
    };

    const handleApplyChanges = async () => {
        const errorProductCount = products.filter((p) => p.error).length;

        if (errorProductCount > 0) {
            return openSnackbar(
                "warning",
                "Please correct any data inconsistencies before proceeding with data recording."
            );
        }

        let newProductsCount = 0;
        let updatedProductsCount = 0;

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            let newItem = {
                dealer_id: dealerId,
                part_number: product.partNumber,
                name_title: product.nameTitle,
                description: product.description,
                list: product.list,
                labor_time: product.laborTime,
                core: product.core,
                hide_items: product.hideItem,
                date: product.date,
                must_validate: mustValidate,
            };

            const productExist = productsEditedParts.some(
                (p) => p.partNumber === product.partNumber
            );

            if (!productExist) {
                //await createEntity(newItem, fetchBulkItems);

                createEditedPart({
                    dealer_id: dealerId,
                    part_number: product.partNumber,
                    title: product.nameTitle || "",
                    description: product.description,
                    selling_price: product.list,
                    core: product.core,
                    hide_item: product.hideItem == "0" ? 1 : 0,
                });
                newProductsCount++;
            } else {
                const productId = productsEditedParts.find(
                    (ep) => ep.partNumber === product.partNumber
                ).id;
                newItem = { id: productId, ...newItem };
                await updateEditedPart(newItem, fetchBulkItems);
                updatedProductsCount++;
            }
        }

        if (products.length > 0) {
            setProducts([]);

            return openSnackbar(
                "info",
                `Import completed: ${newProductsCount} new records and ${updatedProductsCount} records updated.`
            );
        }
    };

    return {
        catalogName,
        setCatalogName,
        mustValidate,
        setMustValidate,
        products,
        setProducts,
        productsEditedParts,
        handleCSVUpload,
        handlePartNumberChange,
        handleClickDelete,
        partNumberFilter,
        setPartNumberFilter,
        handleDownloadCSV,
        handleApplyChanges,
    };
};

export default useBulkItems;
