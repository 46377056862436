import http from "./Http";

const post = async body => {
    return http.post("/user-roles", body);
};

const put = async body => {
    return http.put("/user-roles", body);
};

const UserRoleService = {
    post,
    put
};

export default UserRoleService;