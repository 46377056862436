import http from "./Http";

const getAll = async (startRow = 0, pageSize = 10, fromDate = '', toDate = '', statusId = '', search = '', cid = '', dlid = '') => {
    return http.get(`/quotes?startRow=${startRow}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&statusId=${statusId}&search=${search}&cid=${cid}&dlid=${dlid}`);
};

const get = async (id) => {
    const res = await http.get(`/quotes/${id}`);
    return res.data;
};

const post = async body => {
    const res = await http.post(`/quotes`, body);
    return res.data;
};

const put = async (id, body) => {
    const res = await http.put(`/quotes/${id}`, body);
    return res.data;
};

const del = async (id, body) => {
    const res = await http.delete(`/quotes/${id}`, body);
    return res.data;
};

const delProduct = async (id, body) => {
    const res = await http.delete(`/quotes/products/${id}`, body);
    return res.data;
};

const QuoteService = {
    getAll,
    get,
    post,
    put,
    del,
    delProduct
};

export default QuoteService;