import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CalendarPicker from "components/CalendarPicker/CalendarPicker";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";

import useOrderFilters from "hooks/useOrderFilters";
import useUser from "hooks/useUser";

export default function OrderFilterSelector({ showSearch = false, onChange = () => {} }) {
    const [user] = useUser();
    const {
        dealers = [],
        dealerId = 0,
        setDealerId,
        customers = [],
        customerId = 0,
        setCustomerId,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        status,
        statusId,
        setStatusId,
        search,
        setSearch,
    } = useOrderFilters(onChange);

    return (
        <GridContainer>
            {user?.isAdmin === 1 && (
                <GridItem xs={12} sm={12} md={showSearch ? 2 : 3}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel>Dealer</InputLabel>
                        <Select
                            value={dealerId || 0}
                            onChange={(e) => setDealerId(e.target.value)}
                            fullWidth
                        >
                            {dealers.length > 1 && (
                                <MenuItem key={0} value={0}>
                                    All dealers
                                </MenuItem>
                            )}
                            {dealers.map((dealer, index) => (
                                <MenuItem key={index} value={dealer.id}>
                                    {dealer.id}. {dealer.company}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={showSearch ? 2 : 3}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Customers</InputLabel>
                    <Select
                        value={customerId}
                        onChange={(e) => setCustomerId(e.target.value)}
                        fullWidth
                    >
                        <MenuItem key={0} value={0}>
                            Select customer
                        </MenuItem>
                        {customers.map((customer, index) => (
                            <MenuItem key={index} value={customer.id}>
                                {customer.billCompany}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
                <CalendarPicker fullWidth label="From date" date={fromDate} setDate={setFromDate} />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
                <CalendarPicker fullWidth label="To date" date={toDate} setDate={setToDate} />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel>Order status</InputLabel>
                    <Select
                        value={statusId}
                        onChange={(e) => setStatusId(e.target.value)}
                        fullWidth
                    >
                        <MenuItem key={0} value={0}>
                            Select status
                        </MenuItem>
                        {status.map((statusItem, index) => (
                            <MenuItem key={index} value={statusItem.id}>
                                {statusItem.status}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridItem>
            {showSearch && (
                <GridItem xs={12} sm={2}>
                    <TextFieldRender
                        fullWidth
                        label="Search String"
                        helperText="[Name, Order# etc]"
                        value={search}
                        handleBlur={(value) => setSearch(value)}
                    />
                </GridItem>
            )}
            {/*<GridItem xs={12} sm={2}>
                <Button
                    color="success"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={() => {
                        console.log(
                            fromDate,
                            toDate,
                            status,
                            search
                        )
                    }}>
                    Search
                </Button>
            </GridItem>*/}
        </GridContainer>
    );
}
