import { useState, useEffect } from "react";
import useUser from "./useUser";
import MenuService from "services/MenuService";

const useRoutes = () => {
    const [user] = useUser();
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        const getMenu = async () => {
            const menu = await MenuService.getAll();
            //console.log(menu);
        }
        //getMenu();
        //console.log(user.menuOptions)
    }, [user]);

    /*useEffect(() => {
        const allRoutes = dashRoutes.map(r => ({ ...r, pathname: `${r.layout}${r.path}` }));
        const menuOptions = user?.menuOptions || {};
        const allowedMenu = Object.keys(menuOptions).filter(key => menuOptions[key]);

        const newAllRoutes = allRoutes.filter(route => {
            if ('views' in route) {
                return {
                    ...route,
                    views: route.views.filter(r => allowedMenu.includes(route.id))
                }
            } else if (allowedMenu.includes(route.id)) {
                return route;
            }
        });

        setRoutes(newAllRoutes);
    }, [dashRoutes, user]);*/

    return routes;
};

export default useRoutes;