import http from "./Http";

const getAll = async (dealerId = '') => {
    return http.get(`/store-policies?dealerId=${dealerId}`);
};

const post = async (body) => {
    return http.post(`/store-policies`, body);
};

const del = async (id = '') => {
    return http.delete(`/store-policies/${id}`);
};

const PolicyService = {
    getAll,
    post,
    del
};

export default PolicyService;