import http from "./Http";

const getById = async (catalog = '', id = '') => http.get(`/parts/id/${id}`);
const getByPartNo = async (catalog = '', part_no = '') => http.get(`/parts/part_no/${part_no}?catalog=${catalog}`);
const post = async (catalog = '', body) => http.post(`/parts?catalog=${catalog}`, body);
const put = async (id = '', body) => http.put(`/parts/${id}`, body);
const del = async (catalog = '', id = '') => http.delete(`/parts/${id}?catalog=${catalog}`);

const PartService = {
    getById,
    getByPartNo,
    post,
    put,
    del
};

export default PartService;