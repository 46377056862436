import PageLayout from "layouts/PageLayout";
import {
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    FormControlLabel,
    Radio,
    Paper,
    RadioGroup,
    Checkbox,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Box from "components/Box/Box";
import Table from "components/ReactTable/ReactTableV2";
import DeleteButton from "components/DeleteButton/DeleteButton";
import Button from "components/CustomButtons/Button.js";
import useHomePageSpecialsForm from "hooks/useHomePageSpecialsForm";
import useSelection from "hooks/useSelection";

export default function HomePageSpecials() {
    const { selectedAll, selected, handleClickSelectAll, handleClickSelectItem } = useSelection();
    const {
        partNumber,
        setPartNumber,
        specialPage,
        products = [],
        handleChangeSectionTitle,
        handleChangeCompType,
        handleUpdateHomePageSpecial,
        handleDeleteProduct,
        handleDeleteSelected,
        searchProduct,
    } = useHomePageSpecialsForm();

    const columns = [
        {
            Header: () => (
                <Checkbox
                    checked={selectedAll}
                    onClick={() => handleClickSelectAll(products.map((p) => p.id))}
                />
            ),
            accessor: "id",
            Cell: ({ value }) => (
                <Checkbox
                    checked={selected.includes(value)}
                    onClick={() => handleClickSelectItem(value)}
                />
            ),
        },
        {
            Header: "Brand",
            accessor: "makeName",
        },
        {
            Header: "Item Title",
            accessor: "name",
        },
        {
            Header: "Item Part#",
            accessor: "partNumber",
        },
        {
            Header: "Action",
            Cell: ({ cell }) => (
                <>
                    <DeleteButton
                        title="home page special"
                        handleClick={() => handleDeleteProduct(cell.row.original.id)}
                    />
                </>
            ),
        },
    ];

    return (
        <PageLayout>
            <Typography style={{ display: "flex", marginBottom: 10 }}>
                Add Items to the home page by entering the part number and selecting the
                <AddCircleOutlineIcon style={{ marginLeft: 2, marginRight: 2 }} />
                button. You may add up to 8 items at a time.
            </Typography>

            <Paper style={{ padding: 10 }}>
                <Box style={{ display: "flex" }}>
                    <Box style={{ flex: 1 }}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Section Title"
                            name="title"
                            value={specialPage.sectionTitle}
                            onChange={handleChangeSectionTitle}
                            InputProps={{
                                style: {
                                    width: 250,
                                },
                            }}
                        />
                    </Box>
                    <Box style={{ marginLeft: 10, flex: 3 }}>
                        <RadioGroup
                            row
                            name="component-type"
                            value={specialPage.componentType}
                            onChange={handleChangeCompType}
                        >
                            <FormControlLabel value="C" control={<Radio />} label="Carousel" />
                            <FormControlLabel value="I" control={<Radio />} label="Items" />
                        </RadioGroup>
                    </Box>
                </Box>
                <Box textAlign="center">
                    <Button type="button" color="rose" onClick={handleUpdateHomePageSpecial}>
                        Save and close
                    </Button>
                </Box>
            </Paper>

            <Paper style={{ padding: 10, marginTop: 20 }}>
                <Box display="flex" alignItems="baseline">
                    <Typography variant="h6" style={{ marginTop: 20 }}>
                        Items Visible on Home Page
                    </Typography>
                    <Box style={{ marginLeft: "auto" }}>
                        <DeleteButton
                            disabled={selected.length === 0}
                            buttonWithIcon
                            handleClick={() => handleDeleteSelected(selected)}
                        />
                    </Box>
                </Box>
                <TextField
                    fullWidth
                    size="small"
                    label="Item Part Number"
                    name="title"
                    value={partNumber}
                    onChange={(e) => setPartNumber(e.target.value)}
                    InputProps={{
                        style: {
                            width: 250,
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={partNumber.length === 0}
                                    onClick={searchProduct}
                                >
                                    <AddIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    style={{
                        marginTop: 10,
                    }}
                />
                <Table columns={columns} data={products} fetchData={() => {}} pageCount={1} />
            </Paper>
        </PageLayout>
    );
}
