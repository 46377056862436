import http from "./Http";

const post = async body => {
    return http.post(`/order-log`, body);
};

const OrderLogService = {
    post
};

export default OrderLogService;