import React from "react";
import PageLayout from "layouts/PageLayout";
import OrderTable from "components/OrderTable/OrderTable";
import OrderFilterSelector from "components/OrderFilterSelector/OrderFilterSelector";

export default function Orders() {
    return (
        <PageLayout customComponent={<></>}>
            <OrderFilterSelector />
            <OrderTable />
        </PageLayout>
    )
}
