import { useFormik } from "formik";
import * as yup from "yup";

const useCustomFormik = (fields, initialValues = {}, onInsert = () => {}, onUpdate = () => {}) => {
    const initialValuesParsed = fields.reduce((acc, item) => {
        const defaultValue = "defaultValue" in item ? item.defaultValue : "";
        acc[item.fieldName] = initialValues[item.fieldName] || defaultValue;
        return acc;
    }, {});

    const validationSchema = fields.reduce((acc, item) => {
        if (item.required) {
            acc[item.fieldName] = yup.string().required();
        }
        return acc;
    }, {});

    const formik = useFormik({
        enableReinitialize: true,
        initialValues:
            Object.keys(initialValues).length === 0 ? initialValuesParsed : initialValues,
        validationSchema: yup.object(validationSchema),
        onSubmit: async (values) => {
            if (values.id) {
                onUpdate(values);
            } else {
                onInsert(values);
            }
        },
    });

    return formik;
};

export default useCustomFormik;
