import React, { useState, useEffect } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal.js";
import CCchargeForm from "components/CCchargeForm/CCchargeForm";
import Notification from "components/Notification/Notification";

const SUCCESS_MSG = "Charge applied successfully";

export default function CCcharge({ formik, isLocked = false }) {
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
        message: SUCCESS_MSG
    });
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Modal
                open={openModal}
                modalTitle={"CC charge"}
                handleClose={() => {
                    setOpenModal(false);
                }}
            >
                {openModal ? (
                    <>
                        <div style={{ minWidth: 600 }}></div>
                        {notification.open &&
                            <Notification
                                open={notification.open}
                                message={notification.message}
                                close
                                color={notification.color}
                                onClose={(e) => setNotification({ ...notification, open: false })}
                            />
                        }
                        <CCchargeForm
                            dealerId={formik.values.dealerId}
                            orderId={formik.values.id}
                            fullAmount={formik.values.orderAmount}
                            onSuccess={() => setNotification({
                                ...notification,
                                open: true,
                                color: "success",
                                message: SUCCESS_MSG
                            })}
                            onError={(message) => setNotification({
                                ...notification,
                                open: true,
                                color: "danger",
                                message
                            })}
                        />
                    </>
                ) : null}
            </Modal>

            <Button
                type="button"
                color="linkedin"
                onClick={() => setOpenModal(true)}
                disabled={isLocked}
            >
                CC Charge
            </Button>
        </>
    )
}