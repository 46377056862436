import http from "./Http";

const getAll = async dlid => {
    const data = await http.get(`/dealer-redirects?dlid=${dlid}`);
    return data.data;
};

const post = async body => {
    const data = await http.post("/dealer-redirects", {
        dealerId: body.dealerId,
        sourceUrl: body.sourceUrl,
        targetUrl: body.targetUrl
    });
    return data.data;
};

const put = async (id, body) => {
    const data = await http.put(`/dealer-redirects/${id}`, {
        dealerId: body.dealerId,
        sourceUrl: body.sourceUrl,
        targetUrl: body.targetUrl
    });
    return data.data;
};

const del = async id => {
    return http.delete(`/dealer-redirects/${id}`);
};

const importCsv = async body => {
    const data = await http.post(
        "/dealer-redirects/import-csv",
        body,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
    );
    return data.data;
};

const DealerRedirectService = {
    getAll,
    post,
    put,
    del,
    importCsv
};

export default DealerRedirectService;