import { Button as ButtonMUI, Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";
import Box from "components/Box/Box";

export default function DeleteButton({
    buttonLabel = "Delete Selected Items",
    deleteTitle = "",
    customTitle = "",
    customSubTitle = "",
    confirmationBtnTitle = "",
    title = "item",
    subtitle = "",
    buttonWithIcon = false,
    handleClick = () => {},
    ...otherProps
}) {
    const { openDialog, closeDialog } = useConfirmationDialog();

    const handleClickLocal = (e) => {
        e.preventDefault();

        openDialog({
            customDialog: true,
            fullWidth: true,
            maxWidth: "xs",
            message: (
                <Box p={1.5}>
                    <Typography variant="h6" gutterBottom style={{ textTransform: "none" }}>
                        {customTitle || `Delete ${title}?`}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {customSubTitle ||
                            `This will delete this ${title} permanently. You cannot undo this action?`}
                    </Typography>

                    <Box display="flex" mt={2.5}>
                        <ButtonMUI
                            fullWidth
                            variant="contained"
                            style={{
                                backgroundColor: "#000",
                                color: "#fff",
                                marginRight: 10,
                            }}
                            onClick={() => {
                                handleClick();
                                setTimeout(() => {
                                    closeDialog();
                                }, 100);
                            }}
                        >
                            {confirmationBtnTitle || "Delete"}
                        </ButtonMUI>
                        <ButtonMUI
                            fullWidth
                            variant="outlined"
                            style={{ marginLeft: 10 }}
                            onClick={closeDialog}
                        >
                            Cancel
                        </ButtonMUI>
                    </Box>
                </Box>
            ),
        });
    };

    if (buttonWithIcon)
        return (
            <ButtonMUI
                variant="outlined"
                color="secondary"
                startIcon={<DeleteOutlineIcon />}
                onClick={handleClickLocal}
                {...otherProps}
            >
                {buttonLabel}
            </ButtonMUI>
        );

    return (
        <Button
            {...otherProps}
            justIcon
            round
            simple
            color="warning"
            className="edit"
            onClick={handleClickLocal}
        >
            <DeleteOutlineIcon />
        </Button>
    );
}
