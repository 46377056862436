import React, { useState, useEffect } from "react";
/*import { useFormik } from 'formik';
import * as yup from 'yup';*/

// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//services
import PartService from '../../services/PartService';

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export const CREATE_PART = {
    title: 'Create part',
    successMessage: 'Part created successfully'
}

export const EDIT_PART = {
    title: 'Edit part',
    successMessage: 'Part updated successfully'
}

export const DEF_INITIAL_VALUES = {
    id: 0,
    name: '',
    partNo: '',
    mainPart: '',
    price: 0,
    location: '',
    description: '',
    enPartName: '',
    esPartName: '',
    ptPartName: '',
    frPartName: '',
};

export default function PartCreationForm({ formik, form = CREATE_PART, initialValues = DEF_INITIAL_VALUES, onSuccess = () => { } }) {
    const classes = useStyles();

    /*const validationSchema = yup.object({
        name: yup.string().required('The name is required'),
        partNo: yup.string().required('The part number is required'),
        mainPart: yup.string().required('The main part is required'),
        price: yup.string().required('The price is required'),
        description: yup.string().required('The description is required'),
        enPartName: yup.string().required('The English name is required'),
        esPartName: yup.string().required('The Spanish name is required'),
        ptPartName: yup.string().required('The Portuguese name is required'),
        frPartName: yup.string().required('The French name is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            const body = { ...values, price: Number(values.price) };
            if (form.title === CREATE_PART.title) createPart(body);
            if (form.title === EDIT_PART.title) updatePart(body);
        },
    });*/

    const createPart = async body => {
        try {
            const part = await PartService.post(body);
            console.log(part);
            //onSuccess(part);
        } catch (error) {
            console.error(error);
        }
    }

    const updatePart = async body => {
        try {
            const part = await PartService.put(body.id, body);
            onSuccess(part);
        } catch (error) {
            console.error(error);
        }
    }

    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="partNo"
                    name="partNo"
                    label="Part number"
                    value={formik.values.partNo}
                    onChange={formik.handleChange}
                    error={formik.touched.partNo && Boolean(formik.errors.partNo)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="mainPart"
                    name="mainPart"
                    label="Main part"
                    value={formik.values.mainPart}
                    onChange={formik.handleChange}
                    error={formik.touched.mainPart && Boolean(formik.errors.mainPart)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    type="number"
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="price"
                    name="price"
                    label="Price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <TextField
                    variant="outlined"
                    multiline
                    rows={5}
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="description"
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="enPartName"
                    name="enPartName"
                    label="EN part name"
                    value={formik.values.enPartName}
                    onChange={formik.handleChange}
                    error={formik.touched.enPartName && Boolean(formik.errors.enPartName)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="esPartName"
                    name="esPartName"
                    label="ES part name"
                    value={formik.values.esPartName}
                    onChange={formik.handleChange}
                    error={formik.touched.esPartName && Boolean(formik.errors.esPartName)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="ptPartName"
                    name="ptPartName"
                    label="PT part name"
                    value={formik.values.ptPartName}
                    onChange={formik.handleChange}
                    error={formik.touched.ptPartName && Boolean(formik.errors.ptPartName)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField
                    autoFocus
                    fullWidth
                    margin="dense"
                    id="frPartName"
                    name="frPartName"
                    label="FR part name"
                    value={formik.values.frPartName}
                    onChange={formik.handleChange}
                    error={formik.touched.frPartName && Boolean(formik.errors.frPartName)}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 10 }}>

            </GridItem>
        </GridContainer>
    )
}