import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
    Box,
    TextField,
    Link,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Modal from "components/Modal/Modal";
import Button from "components/CustomButtons/Button.js";
import useModal from "hooks/useModal";
import useRangeManager from "hooks/useRangeManager";

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function RangeManager({ id, rangeCount = 0, shippingMethodName = '', handleChangeRange = () => { } }) {
    const classes = useStyles();
    const onSubmit = async (values) => {
        handleChangeRange(values);
        handleCloseModal();
    }
    const { open, handleOpenModal, handleCloseModal } = useModal();
    const { formik, fetchShippingMethod } = useRangeManager(onSubmit);

    const handleClick = (e) => {
        e.preventDefault();
        fetchShippingMethod(id);
        handleOpenModal();
    }

    return (
        <>
            <Link href="#" onClick={handleClick}>
                Manages Range ({rangeCount})
            </Link>

            <Modal open={open} modalTitle={`Shipping Options Price Range: ${shippingMethodName}`} handleClose={handleCloseModal}>
                <Box minWidth={600} />
                <form onSubmit={formik.handleSubmit}>
                    <Table size="small" className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={2}>Order Total Range [Min. - Max.]</StyledTableCell>
                                <StyledTableCell>Ship. Charge [% Order Total]	</StyledTableCell>
                                <StyledTableCell>Flat Ship. Charge</StyledTableCell>
                                <StyledTableCell>Min. Ship. Charge</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formik.values.ranges.map((range, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                style: { backgroundColor: '#fff' },
                                            }}
                                            style={{ width: 150 }}
                                            value={range.minOrderTotalRange}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    `ranges[${index}].minOrderTotalRange`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                style: { backgroundColor: '#fff' },
                                            }}
                                            style={{ width: 150 }}
                                            value={range.maxOrderTotalRange}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    `ranges[${index}].maxOrderTotalRange`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row" align="center">
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                style: { backgroundColor: '#fff' },
                                            }}
                                            style={{ width: 150 }}
                                            value={range.shipChargeOrderTotal}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    `ranges[${index}].shipChargeOrderTotal`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                style: { backgroundColor: '#fff' },
                                            }}
                                            style={{ width: 150 }}
                                            value={range.flatShipCharge}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    `ranges[${index}].flatShipCharge`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                style: { backgroundColor: '#fff' },
                                            }}
                                            style={{ width: 150 }}
                                            value={range.minShipCharge}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    `ranges[${index}].minShipCharge`,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box textAlign="center" mt={2}>
                        <Button type="submit" color="rose">
                            Save and close
                        </Button>
                    </Box>
                </form>
            </Modal>
        </>
    )
}
