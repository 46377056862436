import { useState, useEffect } from "react";
import { getUser } from "helpers";

const useUser = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const usr = getUser();
        setUser({
            ...usr,
            completeName: `${usr.firstName} ${usr.lastName}`
        })
    }, []);

    return [user];
};

export default useUser;