import Table from "components/ReactTable/ReactTableV2";
import EditButton from "components/EditButton/EditButton";
import DeleteButton from "components/DeleteButton/DeleteButton";
import { Box, Checkbox } from "@material-ui/core";

export default function TableWrapper({
    columns = [],
    data = [],
    showEditButton = false,
    showDeleteButton = false,
    onEditClick = () => {},
    onDeleteClick = () => {},
    showSelectAll = false,
    allIds = [],
    selectedAll = false,
    selected = [],
    handleClickSelectAll = () => {},
    handleClickSelectItem = () => {},
    ...otherProps
}) {
    if (showSelectAll) {
        columns = [
            {
                Header: () => (
                    <Checkbox checked={selectedAll} onClick={() => handleClickSelectAll(allIds)} />
                ),
                accessor: "id",
                Cell: ({ value }) => (
                    <Checkbox
                        checked={selected.includes(value)}
                        onClick={() => handleClickSelectItem(value)}
                    />
                ),
            },
            ...columns,
        ];
    }

    if (showEditButton || showDeleteButton) {
        columns.push({
            Header: "Action",
            Cell: ({ cell }) => (
                <Box display="flex">
                    {showEditButton ? (
                        <EditButton handleClick={() => onEditClick(cell.row.original)} />
                    ) : null}
                    {showDeleteButton ? (
                        <DeleteButton handleClick={() => onDeleteClick(cell.row.original.id)} />
                    ) : null}
                </Box>
            ),
        });
    }

    const fetchData = () => {};

    return (
        <Table
            {...otherProps}
            columns={columns}
            data={data}
            fetchData={otherProps.fetchData || fetchData}
            pageCount={otherProps.pageCount || 1}
        />
    );
}
