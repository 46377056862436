import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DealerShipping from 'services/DealerShipping';
import useDealer from './useDealer';
import { useSnackbar } from './useSnackbar';

const useCustomFee = () => {
    const openSnackbar = useSnackbar();
    const dealer = useDealer();
    const dealerId = dealer.id;
    const [initialValues, setInitialValues] = useState({
        customFeeTitle: '',
        customFlatFee: '',
        customPercentFee: ''
    });

    useEffect(() => {
        if (dealer.id > 0)
            setInitialValues({
                customFeeTitle: dealer.customFeeTitle,
                customFlatFee: dealer.customFlatFee,
                customPercentFee: dealer.customPercentFee
            });
    }, [dealer]);

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateCustomFee(values);
        },
    });

    const updateCustomFee = async (shippingData) => {
        try {
            await DealerShipping.putCustomFee({ ...shippingData, dealerId });
            openSnackbar('success', 'Custom fee updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating the custom fee. Please try again.');
        }
    }

    return {
        formik
    };
}

export default useCustomFee;