import React, { useState, useEffect } from "react";
import http from "services/Http";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Notification from "components/Notification/Notification.js";

//libs
import { useFormik } from "formik";
import * as yup from "yup";

//service
import DealerService from "../../services/DealerService";
import CustomerService from "../../services/CustomerService";
import DealerPricingService from "../../services/DealerPricingService";
import EditPassword from "views/Customers/EditPassword";
import useStates from "hooks/useStates";
import MaterialUIPickers from "components/CalendarPicker/CalendarPicker";
import { Box } from "@material-ui/core";
import useCrud from "hooks/useCrud";

//const
export const INITIAL_VALUES = {
    id: "",
    dealerId: "",
    customerType: "",
    billCompany: "",
    billAddress1: "",
    billAddress2: "",
    billCity: "",
    billState: "",
    billZip: "",
    billCountry: "",
    billPhone: "",
    billEmail: "",
    billContact: "",
    password: "",
    shipCompany: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZip: "",
    shipCountry: "",
    shipPhone: "",
    shipEmail: "",
    shipContact: "",
    instructions: "",
    shipVia: "",
    payTerms: "",
    salesman: "",
    intMemo: "",
    taxRate: "",
    taxState: "",
    taxCity: "",
    taxZip: "",
    locale: "",
    createdAt: "",
    updatedAt: "",
    salesTaxExceptionNo: "",
    taxEndDate: "",
    staxEndDate: "",
    costPlus: "",
    listMinus: "",
};

export default function CreateCustomer({
    form = "create",
    initialValues = INITIAL_VALUES,
    onSuccess = () => {},
}) {
    const [states, setStates] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [isBusinessAcc, setIsBusinessAcc] = useState(false);
    const [isSameAddr, setIsSameAddr] = useState(false);
    const [initialData, setInitialData] = useState(initialValues);
    const [notification, setNotification] = useState({
        open: false,
        color: "danger",
        message: "",
    });
    const { fetchStatesByCountry } = useStates();
    const { readAllEntities: fetchDealerCatalogs } = useCrud("dealer_catalogs", {}, false);
    const { readAllEntities: fetchCatalogMakes } = useCrud("catalog_makes", {}, false);
    const { updateEntity: updatePricing } = useCrud("dealer_pricing_rules", {}, false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialData,
        validationSchema: () => {
            return yup.object({
                dealerId: yup.string().required("Obligatory field"),
                billCompany: yup.string().required("Obligatory field"),
                billEmail: yup.string().required("Obligatory field"),
                billAddress1: yup.string().required("Obligatory field"),
                //billAddress2: yup.string().required("Obligatory field"),
                billCity: yup.string().required("Obligatory field"),
                billState: yup.string().required("Obligatory field"),
                billZip: yup.string().required("Obligatory field"),
                billPhone: yup.string().required("Obligatory field"),
                ...(form === "create" && {
                    password: yup
                        .string()
                        .min(6, "The password must be at least 6 characters")
                        .required("Obligatory field"),
                }),
            });
        },
        onSubmit: async (values) => {
            if (form == "create") {
                createCustomer(values);
            } else {
                updateCustomer(values);
            }
        },
    });

    useEffect(() => {
        const getStates = async (billCountry) => {
            const states = await fetchStatesByCountry(billCountry);
            setStates(states);
        };

        if (initialValues.billCountry.length > 0) {
            getStates(initialValues.billCountry);
        }

        setInitialData({
            ...initialValues,
            ...(initialValues.customerType.length === 0 && { customerType: 1 }),
            billCountry: initialValues.billCountry,
            password: "",
            shipCountry: initialValues.shipCountry,
            locale: initialValues.locale.substring(0, 2),
        });
    }, [initialValues]);

    const createCustomer = async (body) => {
        try {
            const customer = await CustomerService.post({
                ...body,
                password: btoa(body.password),
            }).then((x) => x.data);

            onSuccess({
                ...customer,
                qtyOrders: 0,
                amountOrders: 0,
            });
        } catch (error) {
            setNotification({
                ...notification,
                open: true,
                message: error.response.data.message || "",
            });
        }
    };

    const updateCustomer = async (body) => {
        const { data: dealerPricing = [] } = await DealerPricingService.getAll(body.dealerId);

        if (dealerPricing.some((d) => d.customerId == body.id)) {
            // Update
            const dp = dealerPricing.find((d) => d.customerId == body.id);

            await DealerPricingService.put({
                ...dp,
                costPlus: body.costPlus,
                listMinus: body.listMinus,
            });
        } else {
            // Insert
            const dealerCatalogs = await fetchDealerCatalogs({ dealer_id: body.dealerId });

            if (dealerCatalogs.length > 0) {
                const catalogId = dealerCatalogs[0].catalogId;
                const dealerMakes = await fetchCatalogMakes({
                    dealer_id: body.dealerId,
                    catalog_id: catalogId,
                });
                const makeId = dealerMakes?.[0]?.makeId;

                const pricingData = {
                    dealerId: body.dealerId,
                    catalogId: catalogId,
                    makeId: makeId,
                    customerId: body.id,
                    startRange: "0.01",
                    endRange: "5000000",
                    costPlus: body.costPlus || undefined,
                    listMinus: body.listMinus || undefined,
                    minProfit: "",
                    inflateMsrp: "",
                };

                await DealerPricingService.post(pricingData);
            }
        }

        //DealerPricingService.getAll();
        try {
            const customer = await CustomerService.put(body.id, body).then((x) => x.data);
            onSuccess(customer);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const getDealers = async () => {
            const dealers = await DealerService.getAll().then((d) => d.data);
            setDealers(dealers);
        };
        getDealers();
    }, []);

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <GridContainer>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <Typography variant="subtitle2" gutterBottom style={{ margin: 0 }}>
                                    BILLING INFORMATION
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.customerType == 2}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "customerType",
                                                    e.target.checked ? 2 : 1
                                                );
                                                setIsBusinessAcc(!isBusinessAcc);
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Business Account"
                                    style={{
                                        marginLeft: "auto",
                                    }}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    style={{ marginTop: 5 }}
                                    error={
                                        formik.touched.dealerId && Boolean(formik.errors.dealerId)
                                    }
                                >
                                    <InputLabel id="demo-simple-select-label">Dealer</InputLabel>
                                    <Select
                                        id="dealerId"
                                        name="dealerId"
                                        value={formik.values.dealerId}
                                        onChange={(e) => {
                                            formik.setFieldValue("dealerId", e.target.value);
                                        }}
                                        fullWidth
                                        disabled
                                    >
                                        {dealers.map((dealer) => (
                                            <MenuItem key={dealer.id} value={dealer.id}>
                                                {dealer.company}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                        {formik.touched.dealerId &&
                                            Boolean(formik.errors.dealerId) &&
                                            formik.touched.billCompany &&
                                            formik.errors.billCompany}
                                    </FormHelperText>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={isBusinessAcc ? 4 : 8}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="billCompany"
                                    name="billCompany"
                                    label={isBusinessAcc ? "Business Name" : "Name"}
                                    value={formik.values.billCompany}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billCompany &&
                                        Boolean(formik.errors.billCompany)
                                    }
                                    helperText={
                                        formik.touched.billCompany && formik.errors.billCompany
                                    }
                                />
                            </GridItem>
                            {isBusinessAcc && (
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="billContact"
                                        name="billContact"
                                        label="Contact Person"
                                        value={formik.values.billContact}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.billContact &&
                                            Boolean(formik.errors.billContact)
                                        }
                                        helperText={
                                            formik.touched.billContact && formik.errors.billContact
                                        }
                                    />
                                </GridItem>
                            )}
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="billAddress1"
                                    name="billAddress1"
                                    label="Address line 1"
                                    value={formik.values.billAddress1}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billAddress1 &&
                                        Boolean(formik.errors.billAddress1)
                                    }
                                    helperText={
                                        formik.touched.billAddress1 && formik.errors.billAddress1
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="billAddress2"
                                    name="billAddress2"
                                    label="Address line 2"
                                    value={formik.values.billAddress2}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billAddress2 &&
                                        Boolean(formik.errors.billAddress2)
                                    }
                                    helperText={
                                        formik.touched.billAddress2 && formik.errors.billAddress2
                                    }
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="billCity"
                                    name="billCity"
                                    label="City"
                                    value={formik.values.billCity}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billCity && Boolean(formik.errors.billCity)
                                    }
                                    helperText={formik.touched.billCity && formik.errors.billCity}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                {/*<TextField
                                    fullWidth
                                    margin="dense"
                                    id="billState"
                                    name="billState"
                                    label="State"
                                    value={formik.values.billState}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billState && Boolean(formik.errors.billState)
                                    }
                                    helperText={formik.touched.billState && formik.errors.billState}
                                />*/}
                                <FormControl fullWidth style={{ marginTop: 5 }}>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        id="billState"
                                        name="billState"
                                        value={formik.values.billState}
                                        onChange={(e) =>
                                            formik.setFieldValue("billState", e.target.value)
                                        }
                                    >
                                        <MenuItem value={0}>Select state</MenuItem>
                                        {states.map((state) => (
                                            <MenuItem key={state.code} value={state.code}>
                                                {state.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="billZip"
                                    name="billZip"
                                    label="ZIP"
                                    value={formik.values.billZip}
                                    onChange={formik.handleChange}
                                    error={formik.touched.billZip && Boolean(formik.errors.billZip)}
                                    helperText={formik.touched.billZip && formik.errors.billZip}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <FormControl fullWidth style={{ marginTop: 5 }}>
                                    <InputLabel>Country</InputLabel>
                                    <Select
                                        id="billCountry"
                                        name="billCountry"
                                        value={formik.values.billCountry}
                                    >
                                        {["CAN", "CA"].includes(formik.values.billCountry) && (
                                            <MenuItem value="CA">Canada</MenuItem>
                                        )}

                                        {["USA", "USA"].includes(formik.values.billCountry) && (
                                            <MenuItem value="USA">USA</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="billPhone"
                                    name="billPhone"
                                    label="Phone"
                                    value={formik.values.billPhone}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billPhone && Boolean(formik.errors.billPhone)
                                    }
                                    helperText={formik.touched.billPhone && formik.errors.billPhone}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    disabled={form == "edit"}
                                    fullWidth
                                    margin="dense"
                                    id="billEmail"
                                    name="billEmail"
                                    label="Email"
                                    value={formik.values.billEmail}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.billEmail && Boolean(formik.errors.billEmail)
                                    }
                                    helperText={formik.touched.billEmail && formik.errors.billEmail}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <EditPassword
                                    dealerId={formik.values.dealerId}
                                    customerId={formik.values.id}
                                />
                            </GridItem>
                            {form == "create" && (
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        type="password"
                                        fullWidth
                                        margin="dense"
                                        id="password"
                                        name="password"
                                        label="Password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.password &&
                                            Boolean(formik.errors.password)
                                        }
                                        helperText={
                                            formik.touched.password && formik.errors.password
                                        }
                                    />
                                </GridItem>
                            )}
                        </GridContainer>

                        <GridContainer style={{ marginTop: 20 }}>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <Typography variant="subtitle2" gutterBottom style={{ margin: 0 }}>
                                    SHIPPING ADDRESS
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isSameAddr}
                                            onChange={() => {
                                                if (!isSameAddr) {
                                                    formik.setFieldValue(
                                                        "shipCompany",
                                                        formik.values.billCompany
                                                    );
                                                    formik.setFieldValue(
                                                        "shipAddress1",
                                                        formik.values.billAddress1
                                                    );
                                                    formik.setFieldValue(
                                                        "shipAddress2",
                                                        formik.values.billAddress2
                                                    );
                                                    formik.setFieldValue(
                                                        "shipCity",
                                                        formik.values.billCity
                                                    );
                                                    formik.setFieldValue(
                                                        "shipState",
                                                        formik.values.billState
                                                    );
                                                    formik.setFieldValue(
                                                        "shipZip",
                                                        formik.values.billZip
                                                    );
                                                    formik.setFieldValue(
                                                        "shipPhone",
                                                        formik.values.billPhone
                                                    );
                                                    formik.setFieldValue(
                                                        "shipEmail",
                                                        formik.values.billEmail
                                                    );
                                                    formik.setFieldValue(
                                                        "shipCompany",
                                                        formik.values.billCompany
                                                    );
                                                    formik.setFieldValue(
                                                        "shipContact",
                                                        formik.values.billContact
                                                    );
                                                } else {
                                                    formik.setFieldValue("shipCompany", "");
                                                    formik.setFieldValue("shipAddress1", "");
                                                    formik.setFieldValue("shipAddress2", "");
                                                    formik.setFieldValue("shipCity", "");
                                                    formik.setFieldValue("shipState", "");
                                                    formik.setFieldValue("shipZip", "");
                                                    formik.setFieldValue("shipPhone", "");
                                                    formik.setFieldValue("shipEmail", "");
                                                    formik.setFieldValue("shipCompany", "");
                                                    formik.setFieldValue("shipContact", "");
                                                }

                                                setIsSameAddr(!isSameAddr);
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Shipping same as billing"
                                    style={{
                                        marginLeft: "auto",
                                    }}
                                />
                            </GridItem>
                        </GridContainer>

                        {!isSameAddr && (
                            <>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={isBusinessAcc ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipCompany"
                                            name="shipCompany"
                                            label={isBusinessAcc ? "Business Name" : "Name"}
                                            value={formik.values.shipCompany}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipCompany &&
                                                Boolean(formik.errors.shipCompany)
                                            }
                                            helperText={
                                                formik.touched.shipCompany &&
                                                formik.errors.shipCompany
                                            }
                                        />
                                    </GridItem>
                                    {isBusinessAcc && (
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id="shipContact"
                                                name="shipContact"
                                                label="Contact Person"
                                                value={formik.values.shipContact}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.shipContact &&
                                                    Boolean(formik.errors.shipContact)
                                                }
                                                helperText={
                                                    formik.touched.shipContact &&
                                                    formik.errors.shipContact
                                                }
                                            />
                                        </GridItem>
                                    )}
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipAddress1"
                                            name="shipAddress1"
                                            label="Address line 1"
                                            value={formik.values.shipAddress1}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipAddress1 &&
                                                Boolean(formik.errors.shipAddress1)
                                            }
                                            helperText={
                                                formik.touched.shipAddress1 &&
                                                formik.errors.shipAddress1
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipAddress2"
                                            name="shipAddress2"
                                            label="Address line 2"
                                            value={formik.values.shipAddress2}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipAddress2 &&
                                                Boolean(formik.errors.shipAddress2)
                                            }
                                            helperText={
                                                formik.touched.shipAddress2 &&
                                                formik.errors.shipAddress2
                                            }
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipCity"
                                            name="shipCity"
                                            label="City"
                                            value={formik.values.shipCity}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipCity &&
                                                Boolean(formik.errors.shipCity)
                                            }
                                            helperText={
                                                formik.touched.shipCity && formik.errors.shipCity
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        {/*<TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipState"
                                            name="shipState"
                                            label="State"
                                            value={formik.values.shipState}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipState &&
                                                Boolean(formik.errors.shipState)
                                            }
                                            helperText={
                                                formik.touched.shipState && formik.errors.shipState
                                            }
                                        />*/}
                                        <FormControl fullWidth style={{ marginTop: 5 }}>
                                            <InputLabel id="demo-simple-select-label">
                                                State
                                            </InputLabel>
                                            <Select
                                                id="shipState"
                                                name="shipState"
                                                value={formik.values.shipState}
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        "shipState",
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={0}>Select state</MenuItem>
                                                {states.map((state) => (
                                                    <MenuItem key={state.code} value={state.code}>
                                                        {state.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipZip"
                                            name="shipZip"
                                            label="ZIP"
                                            value={formik.values.shipZip}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipZip &&
                                                Boolean(formik.errors.shipZip)
                                            }
                                            helperText={
                                                formik.touched.shipZip && formik.errors.shipZip
                                            }
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <FormControl fullWidth style={{ marginTop: 5 }}>
                                            <InputLabel>Country</InputLabel>
                                            <Select
                                                id="shipCountry"
                                                name="shipCountry"
                                                value={formik.values.shipCountry}
                                            >
                                                {["CAN", "CA"].includes(
                                                    formik.values.shipCountry
                                                ) && <MenuItem value="CA">Canada</MenuItem>}

                                                {["USA", "USA"].includes(
                                                    formik.values.shipCountry
                                                ) && <MenuItem value="USA">USA</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipPhone"
                                            name="shipPhone"
                                            label="Phone"
                                            value={formik.values.shipPhone}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipPhone &&
                                                Boolean(formik.errors.shipPhone)
                                            }
                                            helperText={
                                                formik.touched.shipPhone && formik.errors.shipPhone
                                            }
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="shipEmail"
                                            name="shipEmail"
                                            label="Email"
                                            value={formik.values.shipEmail}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.shipEmail &&
                                                Boolean(formik.errors.shipEmail)
                                            }
                                            helperText={
                                                formik.touched.shipEmail && formik.errors.shipEmail
                                            }
                                        />
                                    </GridItem>
                                </GridContainer>
                            </>
                        )}

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    fullWidth
                                    margin="dense"
                                    id="instructions"
                                    name="instructions"
                                    label="Instructions"
                                    value={formik.values.instructions}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.instructions &&
                                        Boolean(formik.errors.instructions)
                                    }
                                    helperText={
                                        formik.touched.instructions && formik.errors.instructions
                                    }
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer style={{ marginTop: 20 }}>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <Typography variant="subtitle2" gutterBottom style={{ margin: 0 }}>
                                    OTHERS
                                </Typography>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="shipVia"
                                    name="shipVia"
                                    label="Ship vía"
                                    value={formik.values.shipVia}
                                    onChange={formik.handleChange}
                                    error={formik.touched.shipVia && Boolean(formik.errors.shipVia)}
                                    helperText={formik.touched.shipVia && formik.errors.shipVia}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="payTerms"
                                    name="payTerms"
                                    label="Pay terms"
                                    value={formik.values.payTerms}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.payTerms && Boolean(formik.errors.payTerms)
                                    }
                                    helperText={formik.touched.payTerms && formik.errors.payTerms}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="intMemo"
                                    name="intMemo"
                                    label="Int memo"
                                    value={formik.values.intMemo}
                                    onChange={formik.handleChange}
                                    error={formik.touched.intMemo && Boolean(formik.errors.intMemo)}
                                    helperText={formik.touched.intMemo && formik.errors.intMemo}
                                />
                            </GridItem>
                        </GridContainer>

                        {formik.values.customerType == 2 && (
                            <>
                                <GridContainer style={{ marginTop: 20 }}>
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                            style={{ margin: 0 }}
                                        >
                                            TAXES
                                        </Typography>
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3} style={{ display: "none" }}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="taxRate"
                                            name="taxRate"
                                            label="Tax rate"
                                            value={formik.values.taxRate}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.taxRate &&
                                                Boolean(formik.errors.taxRate)
                                            }
                                            helperText={
                                                formik.touched.taxRate && formik.errors.taxRate
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="taxState"
                                            name="taxState"
                                            label="Tax state"
                                            value={formik.values.taxState}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.taxState &&
                                                Boolean(formik.errors.taxState)
                                            }
                                            helperText={
                                                formik.touched.taxState && formik.errors.taxState
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="taxCity"
                                            name="taxCity"
                                            label="Tax city"
                                            value={formik.values.taxCity}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.taxCity &&
                                                Boolean(formik.errors.taxCity)
                                            }
                                            helperText={
                                                formik.touched.taxCity && formik.errors.taxCity
                                            }
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            id="taxZip"
                                            name="taxZip"
                                            label="Tax zip"
                                            value={formik.values.taxZip}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.taxZip &&
                                                Boolean(formik.errors.taxZip)
                                            }
                                            helperText={
                                                formik.touched.taxZip && formik.errors.taxZip
                                            }
                                        />
                                    </GridItem>
                                </GridContainer>
                            </>
                        )}

                        {formik.values.customerType == 2 && (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        id="salesTaxExceptionNo"
                                        name="salesTaxExceptionNo"
                                        label="Sales tax exception number"
                                        value={formik.values.salesTaxExceptionNo}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.salesTaxExceptionNo &&
                                            Boolean(formik.errors.salesTaxExceptionNo)
                                        }
                                        helperText={
                                            formik.touched.salesTaxExceptionNo &&
                                            formik.errors.salesTaxExceptionNo
                                        }
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Box mt={0.65}>
                                        <MaterialUIPickers
                                            fullWidth
                                            label="Tax end date"
                                            date={formik.values.staxEndDate}
                                            setDate={(newDate) => {
                                                formik.setFieldValue("staxEndDate", newDate);
                                            }}
                                        />
                                    </Box>
                                    {/*<TextField
                                        fullWidth
                                        margin="dense"
                                        id="taxEndDate"
                                        name="taxEndDate"
                                        label="Tax end date"
                                        value={formik.values.taxEndDate}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.taxEndDate &&
                                            Boolean(formik.errors.taxEndDate)
                                        }
                                        helperText={
                                            formik.touched.taxEndDate && formik.errors.taxEndDate
                                        }
                                        placeholder="MM/DD/YYYY"
                                    />*/}
                                </GridItem>
                            </GridContainer>
                        )}

                        <GridContainer style={{ marginTop: 20 }}>
                            <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <Typography variant="subtitle2" gutterBottom style={{ margin: 0 }}>
                                    Pricing
                                </Typography>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    margin="dense"
                                    id="costPlus"
                                    name="costPlus"
                                    label="Cost Plus"
                                    value={formik.values.costPlus}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.costPlus && Boolean(formik.errors.costPlus)
                                    }
                                    helperText={formik.touched.costPlus && formik.errors.costPlus}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    margin="dense"
                                    id="listMinus"
                                    name="listMinus"
                                    label="List Minus"
                                    value={formik.values.listMinus}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.listMinus && Boolean(formik.errors.listMinus)
                                    }
                                    helperText={formik.touched.listMinus && formik.errors.listMinus}
                                />
                            </GridItem>
                        </GridContainer>

                        {notification.open && (
                            <div style={{ marginTop: 10 }}>
                                <Notification
                                    open={notification.open}
                                    duration={5000}
                                    message={notification.message}
                                    close
                                    color={notification.color}
                                    onClose={(e) => {
                                        setNotification({ ...notification, open: false });
                                    }}
                                />
                            </div>
                        )}

                        <div style={{ textAlign: "center", margin: 10 }}>
                            <Button type="submit" color="rose">
                                Save
                            </Button>
                        </div>
                    </form>
                </GridItem>
            </GridContainer>
        </>
    );
}
