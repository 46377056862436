import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from './useSnackbar';
import useDealer from './useDealer';
import DealerTaxService from 'services/DealerTaxService';

const useDealerTax = () => {
    const openSnackbar = useSnackbar();
    const { id: dealerId } = useDealer();

    const [initialValues, setInitialValues] = useState({
        taxes: []
    });

    const fetchTaxes = async () => {
        try {
            const { data = [] } = await DealerTaxService.getAll(dealerId);
            setInitialValues({
                taxes: data
            });
        } catch (error) {
            console.error('Error fetching shipping methods:', error);
        }
    };

    useEffect(() => {
        if (dealerId)
            fetchTaxes(dealerId);
    }, [dealerId]);

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

        },
    });

    const handleDeleteTax = async (taxId) => {
        try {
            await DealerTaxService.del(taxId);
            openSnackbar('success', 'Tax deleted successfully');
            const updatedTaxes = initialValues.taxes.filter((tax) => tax.id !== taxId);
            setInitialValues({
                taxes: updatedTaxes,
            });
        } catch (error) {
            console.error('Error deleting Tax:', error);
            openSnackbar('error', 'An error occurred while deleting the Tax. Please try again.');
        }
    };

    return {
        taxes: initialValues.taxes,
        fetchTaxes,
        formik,
        handleDeleteTax,
    };
}

export default useDealerTax;