import React, { useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";

import EditableCardHeader from "components/EditableCardHeader/EditableCardHeader";
import Button from "components/CustomButtons/Button.js";
import RmaHeader from "./RmaHeader";
//import RmaProducts from "./RmaProducts";
//import RmaComment from "./RmaComment";
import useRmaForm from "hooks/useRmaForm";

export default function EditRma({ rmaId = "", rmaOrderNumber = "", closeDialog = () => {} }) {
    const [tabId, setTabId] = useState(0);
    const {
        formik,
        orderDetail,
        rmaCommunication,
        customerCommunication,
        handleClickSubmit,
        rma,
    } = useRmaForm(rmaId, rmaOrderNumber);

    const tabs = [
        {
            value: 0,
            label: "Header",
            content: (
                <RmaHeader
                    formik={formik}
                    orderDetail={orderDetail}
                    rmaCommunication={rmaCommunication}
                    customerCommunication={customerCommunication}
                    rma={rma}
                />
            ),
        },
        //{ value: 1, label: "Products", content: <RmaProducts /> },
        //{ value: 2, label: "Comment/Log", content: <RmaComment /> },
    ];

    return (
        <Box style={{ marginTop: 20 }}>
            <EditableCardHeader />
            <Box>
                <Tabs
                    value={tabId}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(_, newValue) => setTabId(newValue)}
                    TabIndicatorProps={{ style: { background: "#ef662f" } }}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={tab.value} value={tab.value} label={tab.label} />
                    ))}
                </Tabs>

                {tabs.map((tab, index) => (
                    <React.Fragment key={tab.value}>
                        {tabId === tab.value ? tab.content : null}
                    </React.Fragment>
                ))}

                <Box ml={2} textAlign="center">
                    <Button
                        type="button"
                        color="rose"
                        onClick={() => handleClickSubmit(closeDialog)}
                    >
                        Save and close
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
