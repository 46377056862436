import PageLayout from "layouts/PageLayout";
import { Box, FormControlLabel, Radio, Switch, Typography } from "@material-ui/core";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import DealerPricingTable from "./components/DealerPricingTable";
import CreateDealerPricing from "./components/CreateDealerPricing";
import CatalogSelector from "components/CatalogSelector/CatalogSelector";
import MakesSelector from "components/MakesSelector/MakesSelector";
import InfoButton from "components/InfoButton/InfoButton";
import useDealerPricing from "hooks/useDealerPricing";

export default function DealerPricing() {
    const {
        formik,
        catalogId,
        setCatalogId,
        makeId,
        setMakeId,
        handleAddDealerPricing,
        updateDealerPricing,
        handleDeleteDealerPricing,
        priorityToggle,
        setPriorityToggle,
        inflatedPriceToggle,
        setInflatedPriceToggle,
        setAsDefault,
    } = useDealerPricing();

    console.log(
        "formik.values.pricing",
        formik.values.pricing.filter((p) => !p.customerId)
    );

    return (
        <Box>
            <PageLayout
                customComponent={
                    <CreateDealerPricing
                        catalogId={catalogId}
                        makeId={makeId}
                        handleAddDealerPricing={handleAddDealerPricing}
                    />
                }
            >
                <Box display="flex">
                    <Box flex={1} display="flex" alignItems="center">
                        <CatalogSelector
                            value={catalogId}
                            onChange={(e) => {
                                setCatalogId(e.target.value);
                                setMakeId("");
                            }}
                            style={{ maxWidth: 200 }}
                        />
                        <InfoButton
                            description="The selected catalog price level allows you to set rules that affect the entire catalog as a whole."
                            style={{ marginLeft: 10, marginRight: 10 }}
                        />
                        <MakesSelector
                            showAllMakes
                            catalogId={catalogId}
                            disabled={catalogId === ""}
                            value={makeId}
                            onChange={(e) => {
                                setMakeId(e.target.value);
                            }}
                            style={{ maxWidth: 200 }}
                        />
                    </Box>
                    <Box>
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={
                                    <Switch
                                        checked={priorityToggle}
                                        onChange={() => setPriorityToggle(!priorityToggle)}
                                    />
                                }
                                label={
                                    <Box display="flex">
                                        Make List Rules Supersede Cost Rules:
                                        <InfoButton description="The default rule for your eStore is COST PLUS, unless the 'List Supersede Cost' toggle is modified." />
                                    </Box>
                                }
                            />
                            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            checked={inflatedPriceToggle}
                                            onChange={() =>
                                                setInflatedPriceToggle(!inflatedPriceToggle)
                                            }
                                        />
                                    }
                                    label={
                                        <Box display="flex">
                                            Show the inflated price:
                                            <InfoButton description="By default, this toggle is set to OFF, showing only the LIST PRICE. If the toggle is set to ON, both the LIST PRICE and INFLATED PRICE will be displayed, with the actual price shown where INFLATED PRICE has a strikethrough." />
                                        </Box>
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <DealerPricingTable
                    showDefaultCol
                    formik={formik}
                    data={formik.values.pricing.filter(
                        (p) => p.catalogId == catalogId && p.makeId == makeId
                    )}
                    updateDealerPricing={updateDealerPricing}
                    handleDeleteDealerPricing={handleDeleteDealerPricing}
                    setAsDefault={setAsDefault}
                />
            </PageLayout>
            <Card style={{ marginTop: 0 }}>
                <CardBody>
                    <Typography variant="subtitle2" gutterBottom>
                        Existing Pricing Rules
                    </Typography>
                    <DealerPricingTable
                        formik={formik}
                        data={formik.values.pricing.filter((p) => !p.customerId)}
                        updateDealerPricing={updateDealerPricing}
                        handleDeleteDealerPricing={handleDeleteDealerPricing}
                    />
                </CardBody>
            </Card>
        </Box>
    );
}

/*import PageLayout from "layouts/PageLayout";
import { withStyles } from "@material-ui/core/styles";
import { Box, FormControlLabel, Radio, Switch, Typography } from "@material-ui/core";
import CatalogSelector from "components/CatalogSelector/CatalogSelector";
import MakesSelector from "components/MakesSelector/MakesSelector";
import Table from "components/ReactTable/ReactTableV2";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import CreateDealerPricing from "./components/CreateDealerPricing";
import DeleteButton from "components/DeleteButton/DeleteButton";
import Button from "components/CustomButtons/Button.js";
import InfoButton from "components/InfoButton/InfoButton";
import useDealerPricing from "hooks/useDealerPricing";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";

const StyledTextField = withStyles((theme) => ({
    root: {
        maxWidth: 100,
    },
}))(TextFieldRender);

export default function DealerPricing() {
    const {
        formik,
        catalogId,
        setCatalogId,
        makeId,
        setMakeId,
        handleAddDealerPricing,
        handleDeleteDealerPricing,
        priorityToggle,
        setPriorityToggle,
        inflatedPriceToggle,
        setInflatedPriceToggle,
        defaultRuleId,
        setDefaultRuleId,
    } = useDealerPricing();

    const columns = [
        {
            Header: "Default",
            accessor: "isDefault",
            Cell: ({ row }) => {
                return (
                    <Radio
                        checked={defaultRuleId === row.original.id}
                        value={row.original.id}
                        onClick={() => {
                            setDefaultRuleId(row.original.id);
                        }}
                    />
                );
            },
        },
        {
            Header: "From",
            accessor: "from",
            Cell: ({ value, row: { index } }) => {
                return (
                    <StyledTextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={value || ""}
                        handleBlur={(value) =>
                            formik.setFieldValue(`pricing[${index}].from`, Number(value))
                        }
                    />
                );
            },
        },
        {
            Header: "To",
            accessor: "to",
            Cell: ({ value, row: { index } }) => {
                return (
                    <StyledTextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={value || ""}
                        handleBlur={(value) =>
                            formik.setFieldValue(`pricing[${index}].to`, Number(value))
                        }
                    />
                );
            },
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        Cost plus %
                        <InfoButton description="If you set  COST PLUS <Value> this value is the added percentage the item will be sold as on the front end." />
                    </Box>
                );
            },
            accessor: "costPlus",
            Cell: ({ value, row: { index } }) => {
                return (
                    <StyledTextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={value || ""}
                        handleBlur={(value) =>
                            formik.setFieldValue(`pricing[${index}].costPlus`, Number(value))
                        }
                    />
                );
            },
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        List minus %
                        <InfoButton description="If you set LIST MINUS <Value> this value is the discounted percentage the item will be sold as on the front end. " />
                    </Box>
                );
            },
            accessor: "listMinus",
            Cell: ({ value, row: { index } }) => {
                return (
                    <StyledTextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={value || ""}
                        handleBlur={(value) =>
                            formik.setFieldValue(`pricing[${index}].listMinus`, Number(value))
                        }
                    />
                );
            },
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        Min profit %
                        <InfoButton description="This value is specifically for items that do NOT have a cost price. It is a mandated option, and by default, the system will assign a value of 20% here." />
                    </Box>
                );
            },
            accessor: "minProfit",
            Cell: ({ value, row: { index } }) => {
                return (
                    <StyledTextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={value || ""}
                        handleBlur={(value) =>
                            formik.setFieldValue(`pricing[${index}].minProfit`, Number(value))
                        }
                    />
                );
            },
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        Inflate Msrp %
                        <InfoButton description="Inflate MSRP value is used to artificially inflate the shown suggest retail price.  " />
                    </Box>
                );
            },
            accessor: "inflateMsrp",
            Cell: ({ value, row: { index } }) => {
                return (
                    <StyledTextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={value || ""}
                        handleBlur={(value) =>
                            formik.setFieldValue(`pricing[${index}].inflateMsrp`, Number(value))
                        }
                    />
                );
            },
        },
        {
            Header: "",
            accessor: "id",
            Cell: ({ value }) => {
                return (
                    <DeleteButton
                        handleClick={() => {
                            handleDeleteDealerPricing(value);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Box>
            <PageLayout
                customComponent={
                    catalogId && makeId ? (
                        <CreateDealerPricing
                            catalogId={catalogId}
                            makeId={makeId}
                            handleAddDealerPricing={handleAddDealerPricing}
                        />
                    ) : null
                }
            >
                <Box display="flex">
                    <Box flex={1} display="flex" alignItems="center">
                        <CatalogSelector
                            value={catalogId}
                            onChange={(e) => {
                                setCatalogId(e.target.value);
                                setMakeId("");
                            }}
                            style={{ maxWidth: 200 }}
                        />
                        <InfoButton
                            description="The selected catalog price level allows you to set rules that affect the entire catalog as a whole."
                            style={{ marginLeft: 10, marginRight: 10 }}
                        />
                        <MakesSelector
                            showAllMakes
                            catalogId={catalogId}
                            disabled={catalogId === ""}
                            value={makeId}
                            onChange={(e) => {
                                setMakeId(e.target.value);
                            }}
                            style={{ maxWidth: 200 }}
                        />
                    </Box>
                    <Box>
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <FormControlLabel
                                labelPlacement="start"
                                control={
                                    <Switch
                                        checked={priorityToggle}
                                        onChange={() => setPriorityToggle(!priorityToggle)}
                                    />
                                }
                                label={
                                    <Box display="flex">
                                        Make List Rules Supersede Cost Rules:
                                        <InfoButton description="The default rule for your eStore is COST PLUS, unless the 'List Supersede Cost' toggle is modified." />
                                    </Box>
                                }
                            />
                            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            checked={inflatedPriceToggle}
                                            onChange={() =>
                                                setInflatedPriceToggle(!inflatedPriceToggle)
                                            }
                                        />
                                    }
                                    label={
                                        <Box display="flex">
                                            Show the inflated price:
                                            <InfoButton description="By default, this toggle is set to OFF, showing only the LIST PRICE. If the toggle is set to ON, both the LIST PRICE and INFLATED PRICE will be displayed, with the actual price shown where INFLATED PRICE has a strikethrough." />
                                        </Box>
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <Table
                        columns={columns}
                        data={formik.values.pricing}
                        fetchData={() => {}}
                        pageCount={1}
                    />
                    <Box textAlign="center" mt={2}>
                        <Button type="submit" color="rose">
                            Update pricing rules
                        </Button>
                    </Box>
                </form>
            </PageLayout>
            <Card style={{ marginTop: 0 }}>
                <CardBody>
                    <Typography variant="subtitle2" gutterBottom>
                        Existing Pricing Rules
                    </Typography>
                </CardBody>
            </Card>
        </Box>
    );
}*/

/*import useDealer from "hooks/useDealer";
import DealerPricing from "views/Dealers/Tabs/DealerPricing";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SelectDealer from "components/SelectDealer/SelectDealer";

import Assignment from "@material-ui/icons/Assignment";

export default function DealerPricingView() {
    const dealer = useDealer();

    return (
        <GridContainer>
            <GridItem xs={12}>
                <SelectDealer />
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Assignment />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <DealerPricing dlid={dealer.id} />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}*/
