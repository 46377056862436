import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

const TabPanel = ({ children, value, index }) => (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
    >
        {value === index && <div>{children}</div>}
    </Typography>
);

const CustomTabs = ({ tabs = [], onTabChange = () => {} }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onTabChange(event, newValue);
    };

    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="tabs">
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        label={tab.label}
                        id={`tab-${index}`}
                        aria-controls={`tabpanel-${index}`}
                    />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <TabPanel key={index} value={value} index={index}>
                    {tab.content}
                </TabPanel>
            ))}
        </>
    );
};

export default CustomTabs;
