import { useState, useEffect } from "react";
import DealerService from "services/DealerService";
import useUser from "./useUser";

const useDealer = () => {
    const [dealer, setDealer] = useState({
        id: 0,
    });
    //const [user] = useUser();
    const dlid = localStorage.getItem("_dlid");

    useEffect(() => {
        const getDealers = async (dlid = "") => {
            const dealers = await DealerService.getAll(dlid).then((o) => o.data);
            if (dealers.length === 1) {
                setDealer(dealers[0]);
            }
        };
        if (dlid) getDealers(dlid);
    }, [dlid]);

    /*useEffect(() => {
        const getDealers = async (dlid = '') => {
            const dealers = await DealerService.getAll(dlid).then(o => o.data);

            if (dealers.length === 1) {
                setDealer(dealers[0]);
                setTotal(1);
            } else {
                setDealers(dealers);
                setTotal(dealers.length);
            }
        };

        if (user && user?.isAdmin === 0) {
            getDealers(user.dealerId || '');
        } else {
            getDealers('');
        }
    }, [user]);*/

    return dealer;
};

export default useDealer;
