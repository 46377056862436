import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useUser from "hooks/useUser";

export default function TooglePassword({ password = "", ...otherProps }) {
    const [show, setShow] = useState(false);
    const [user] = useUser();
    const isAdmin = user?.isAdmin || 0;

    const handleClick = () => {
        setShow(!show);
    };

    return (
        <TextField
            {...otherProps}
            fullWidth
            margin="dense"
            label="Password"
            value={show ? atob(password) : "***************"}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <>
                        {isAdmin ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClick}
                                    edge="end"
                                >
                                    {show ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ) : null}
                    </>
                ),
            }}
        />
    );
}
