import React, { useState, useEffect } from "react";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import { Checkbox } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import CreateCatalog from "./CreateCatalog";

import DealerCatalogService from '../../../services/DealerCatalogService';

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CreateDealerCatalog, { DEFAULT_INITIAL_DATA } from "views/DealerCatalogs/CreateDealerCatalog";
const useStyles = makeStyles(styles);

export const EDIT_CATALOG = {
    title: 'Edit catalog',
    successMessage: 'catalog updated successfully'
}

export default function DealerCatalog({ dlid }) {
    const classes = useStyles();
    const [form, setForm] = useState(EDIT_CATALOG);
    const [openModal, setOpenModal] = useState(false);
    const [catalogs, setCatalogs] = useState([]);
    const [initialValues, setInitialValues] = useState(DEFAULT_INITIAL_DATA);
    const [notification, setNotification] = useState({
        open: false,
        color: 'success'
    });

    useEffect(() => {
        const getCatalogs = async (dlid) => {
            const catalogs = await DealerCatalogService.getAll(dlid).then(x => x.data);
            setCatalogs(catalogs);
        };

        if (dlid) getCatalogs(dlid);
    }, [dlid]);

    const handleClickCheckbox = async (e, catalog) => {
        const _catalogs = catalogs.map(c => c.id === catalog.id ? { ...c, isChecked: !c.isChecked } : c);
        setCatalogs(_catalogs);

        if (e.target.checked) {
            createDealerCatalog(dlid, catalog)
        } else {
            removeDealerCatalog(catalog.dealerCatalogId)
        }
    }

    const createDealerCatalog = async (dlid, catalog) => {
        try {
            const body = {
                ...catalog,
                dealerId: dlid,
                catalogId: catalog.id
            };
            await DealerCatalogService.post(body).then(r => {
                const data = r.data;
                const _catalogs = catalogs.map(c => c.id === catalog.id ? { ...c, dealerCatalogId: data.dealerCatalogId, isChecked: true } : c);
                setCatalogs(_catalogs);
            });
        } catch (error) {
            alert('ERR: Dealer Catalog -> createDealerCatalog')
        }
    }

    const removeDealerCatalog = async id => {
        try {
            await DealerCatalogService.del(id);
        } catch (error) {
            alert('ERR: Dealer Catalog -> removeDealerCatalog')
        }
    }

    const updateDealerCatalog = async (dlid, catalog) => {
        try {
            const body = {
                ...catalog,
                dealerId: dlid,
                catalogId: catalog.id
            };
            await DealerCatalogService.put(body).then(r => {
                const data = r.data;
                const _catalogs = catalogs.map(c => c.dealerCatalogId === catalog.dealerCatalogId ? { ...c, ...data, isChecked: true } : c);
                setCatalogs(_catalogs);
            });
        } catch (error) {
            alert('ERR: Dealer Catalog -> updateDealerCatalog')
        }
    }


    const onSuccess = (catalog) => {
        const _catalogs = catalogs.map(c => c.id === catalog.id ? { ...c, ...catalog } : c);
        setCatalogs(_catalogs);

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage
        });
    }

    const columns = [
        /*{
            Header: "",
            accessor: "isChecked",
            Cell: ({ row: { original } }) => {
                const disabled = original.isChecked && catalogs.filter(c => c.isChecked).length === 1;

                return <>
                    <div style={{ textAlign: 'center' }}>
                        <Checkbox
                            disabled={disabled}
                            tabIndex={-1}
                            checked={original.isChecked}
                            onClick={(e) => handleClickCheckbox(e, original)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                            }}
                        />
                    </div>
                </>
            }
        },*/
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "catalogName",
        },
        {
            Header: "Display Name",
            accessor: "displayName",
        },
        {
            Header: "EN Display Name",
            accessor: "enDisplayName",
        },
        {
            Header: "ES Display Name",
            accessor: "esDisplayName",
        },
        {
            Header: "PT Display Name",
            accessor: "ptDisplayName",
        },
        {
            Header: "FR Display Name",
            accessor: "frDisplayName",
        },
        {
            Header: "Actions",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                onClick={() => {
                                    setForm(EDIT_CATALOG)
                                    setOpenModal(true);
                                    setInitialValues(original);
                                }}
                            >
                                <Edit />
                            </Button>
                        </div>
                    </>
                );
            }
        }
    ];

    return (
        <>
            {notification.open &&
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                    onClose={() => setNotification({
                        ...notification,
                        open: false
                    })}
                />}

            <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                <div style={{ minWidth: 1200 }}></div>
                <CreateDealerCatalog isEditing={initialValues.id} initialValues={initialValues} />
                {/*<CreateCatalog
                    dlid={dlid}
                    form={form}
                    initialValues={initialValues}
                    onSuccess={(catalog) => {
                        updateDealerCatalog(dlid, catalog);
                        onSuccess(catalog);
                    }}
                />*/}
            </Modal>

            <ReactTable
                columns={columns}
                data={catalogs}
                showPagination={false}
                showSorting={false}
            />
        </>
    )
}