import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { LinearProgress, Radio } from "@material-ui/core";
import SkeletonTable from "components/SkeletonTable/SkeletonTable";
import ReactTableV2 from "components/ReactTable/ReactTableV2";
import { BASE_URL_API_ECOMMERCE } from "services/Http";
import useDealerId from "hooks/useDealerId";
import useProductImageUrl from "hooks/useProductImageUrl";
import { BASIC_AUTH } from "services/Http";
import useDealerCatalogs from "hooks/useDealerCatalogs";
import http from "services/Http";
import { isURL } from "helpers";

let scriptLoaded = false;
const accumulatedRecords = [];
let initialRecordsRendered = false;

export default function PartSelectionDialog({
    orderDealerId = "",
    partNumber = "",
    onProductSelect = () => {},
}) {
    const xhrRef = useRef(null);
    const dealerId = useDealerId();
    const dealerCatalogs = useDealerCatalogs(orderDealerId || dealerId);
    const [products, setProducts] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const { getProductImageUrl } = useProductImageUrl();

    useEffect(() => {
        const isMotor = dealerCatalogs.some((dc) => dc.catalogName === "motor");
        const script = document.createElement("script");

        if (dealerCatalogs.length > 0 && isMotor) {
            script.src = "/jsonpipe.js";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (!scriptLoaded) {
                    if (partNumber) {
                        searchProduct(partNumber);
                    }
                    scriptLoaded = true;
                }
            };
        }

        if (dealerCatalogs.length > 0 && !isMotor) {
            searchProductNoChunks(partNumber, dealerCatalogs);
        }

        return () => {
            if (isMotor) {
                if (xhrRef.current) {
                    xhrRef.current.abort();
                    xhrRef.current = null;
                }

                scriptLoaded = false;
                document.body.removeChild(script);
                accumulatedRecords.length = 0;
                initialRecordsRendered = false;
            }

            setProducts([]);
        };
    }, [dealerId, partNumber, dealerCatalogs]);

    const searchProduct = useCallback((keyword) => {
        const cleanPartNumber = keyword.replace(/[^a-zA-Z0-9]/g, "");
        const url = `${BASE_URL_API_ECOMMERCE}/suggestions-search?dlid=${dealerId}`;
        const data = {
            keyword: cleanPartNumber,
            catalog: "motor",
            locale: "en",
            allMakes: true,
        };

        xhrRef.current = window.jsonpipe.flow(url, {
            delimiter: "#####",
            success: (chunk) => {
                processChunk(chunk);
            },
            complete: (statusText) => {
                xhrRef.current = null;
                setNoRecords(true);
            },
            timeout: 3000000,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Basic ${BASIC_AUTH}`,
            },
            data: JSON.stringify(data),
            withCredentials: false,
        });
    }, []);

    function processChunk(chunk) {
        accumulatedRecords.push(...chunk);

        if (accumulatedRecords.length >= 10 && !initialRecordsRendered) {
            renderInitialRecords();
        }
    }

    function renderInitialRecords() {
        const initialRecords = accumulatedRecords.splice(0, 10);
        setProducts(initialRecords);
        initialRecordsRendered = true;
    }

    const searchProductNoChunks = useCallback(
        async (keyword, dealerCatalogs = [], offset = 0, limit = 10) => {
            const url = `${BASE_URL_API_ECOMMERCE}/suggestions-search?dlid=${dealerId}&offset=${offset}&limit=${limit}`;
            const data = {
                keyword: keyword,
                catalog: dealerCatalogs[0].catalogName,
                locale: "en",
                allMakes: true,
            };
            const products = await http.post(url, data).then((r) => r.data);

            if ("products" in products) {
                setProducts(products.products);
                if (products.products.length === 0) {
                    setNoRecords(true);
                }
            } else {
                setProducts(products);
                if (products.length === 0) {
                    setNoRecords(true);
                }
            }
        },
        []
    );

    const memoizedReactTable = useMemo(() => {
        return (
            <ReactTableV2
                columns={[
                    {
                        accessor: "id",
                        Cell: ({ row }) => (
                            <Radio
                                onClick={() => {
                                    const pathImage = row.original.pathImage;
                                    const [make] = row.original.slugs;
                                    const src = isURL(pathImage)
                                        ? pathImage
                                        : getProductImageUrl(
                                              make.slug,
                                              row.original.part_number,
                                              row.original.path_image
                                          );

                                    return onProductSelect({
                                        ...row.original,
                                        pathImage: src,
                                    });
                                }}
                            />
                        ),
                    },
                    {
                        accessor: "img",
                        Cell: ({ row }) => {
                            const pathImage = row.original.pathImage;
                            const [make] = row.original.slugs;
                            const src = isURL(pathImage)
                                ? pathImage
                                : getProductImageUrl(
                                      make.slug,
                                      row.original.part_number,
                                      row.original.path_image
                                  );

                            return <img src={src} width="100%" height="50" />;
                        },
                    },
                    {
                        Header: "Part number",
                        accessor: "part_number",
                    },
                    {
                        Header: "Price",
                        accessor: "price.price",
                        Cell: ({ value }) => `$${value}`,
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                    },
                    {
                        Header: "Make",
                        accessor: "slugs[0].name",
                    },
                    {
                        Header: "Year",
                        accessor: "slugs[1].name",
                    },
                    {
                        Header: "Model",
                        accessor: "slugs[2].name",
                    },
                    {
                        Header: "Trim",
                        accessor: "slugs[3].name",
                    },
                    {
                        Header: "Engine",
                        accessor: "slugs[4].name",
                    },
                ]}
                data={products}
                fetchData={({ pageIndex, pageSize }) => {
                    const isMotor = dealerCatalogs.some((dc) => dc.catalogName === "motor");
                    const startIndex = (pageIndex - 1) * pageSize;
                    const endIndex = startIndex + pageSize;

                    if (isMotor && pageIndex > 1) {
                        const pageRecords = accumulatedRecords.slice(startIndex, endIndex);
                        setProducts(pageRecords);
                    }

                    if (!isMotor && pageIndex > 1) {
                        searchProductNoChunks(partNumber, dealerCatalogs, startIndex, 10);
                    }
                }}
                pageCount={100}
            />
        );
    }, [products]);

    return (
        <>
            {products.length === 0 && !noRecords && <LinearProgress />}

            {products.length === 0 ? (
                <SkeletonTable
                    noRecords={noRecords}
                    noRecordsMessage={`No records found for part number ${partNumber}.`}
                    rowCount={10}
                    columnNames={[
                        "Part number",
                        "Price",
                        "Name",
                        "Make",
                        "Year",
                        "Model",
                        "Trim",
                        "Engine",
                    ]}
                />
            ) : (
                memoizedReactTable
            )}
        </>
    );
}
