import React from "react";
import { Box } from "@material-ui/core";
import PageLayout from "layouts/PageLayout";
import CreateShippingMethod from "./components/CreateShippingMethod";
import ShippingMethodTable from "./components/ShippingMethodTable";
import ShippingFee from "./components/ShippingFee";
import ShippingCarrier from "./components/ShippingCarrier";
import useManageShipping from "hooks/useManageShipping";
import ShippingZones from "components/ShippingZones/ShippingZones";

export default function ManageShippingOptions() {
    const { formik, handleAddShippingMethod, updateShippingRange, handleDeleteShippingMethod } = useManageShipping();

    return (
        <>
            <PageLayout customComponent={<CreateShippingMethod handleAddShippingMethod={handleAddShippingMethod} />}>
                <ShippingMethodTable formik={formik} updateShippingRange={updateShippingRange} handleDeleteShippingMethod={handleDeleteShippingMethod} />
                <Box mt={5}>
                    <ShippingFee />
                </Box>
                <Box mt={5}>
                    <ShippingCarrier />
                </Box>
            </PageLayout>
            <PageLayout showSelectDealer={false}>
                <ShippingZones />
            </PageLayout>
        </>
    );
}
