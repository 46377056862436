import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

function GenericSelect(props) {
    const {
        fullWidth = false,
        variant = "",
        size = "normal",
        label,
        value,
        valueProp,
        labelProp,
        onChange,
        options,
        mt = 0,
        mb = 0,
        showNone = true,
        ...otherProps
    } = props;

    return (
        <FormControl
            fullWidth={props.fullWidth || false}
            size={props.size || "normal"}
            style={{ marginTop: mt, marginBottom: mb }}
        >
            {label && <InputLabel>{label}</InputLabel>}
            <Select {...otherProps} value={value} onChange={onChange}>
                <MenuItem value="" style={{ display: "none" }}>
                    <em>None</em>
                </MenuItem>
                {options.map((option, index) => (
                    <MenuItem key={index} value={option[valueProp] || option.value}>
                        {option[labelProp] || option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

GenericSelect.propTypes = {
    //label: PropTypes.string.isRequired,
    valueProp: PropTypes.string,
    labelProp: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    fullWidth: PropTypes.bool,
};

export default GenericSelect;
