import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
    Button,
    Typography,
    Grid,
    Box,
    IconButton,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import Table from "components/ReactTable/ReactTableV2";
import CustomButton from "components/CustomButtons/Button.js";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";
import InfoIcon from "@material-ui/icons/Info";

const ImageUpload = ({ onSave, existingImages = [] }) => {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState("");
    const [overwrite, setOverwrite] = useState(false);

    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            setError("");
            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    status: existingImages.includes(file.name) ? "exists" : "approved",
                })
            );
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);

            fileRejections.forEach(({ file, errors }) => {
                setError(errors[0].message);
            });
        },
        [existingImages]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*",
        maxSize: 1048576, // 1MB
    });

    const columns = [
        {
            Header: "Filename",
            accessor: "path",
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value }) => (
                <Typography variant="body2" style={{ fontSize: 12 }}>
                    {value === "exists" ? (
                        <>
                            <InfoIcon fontSize="small" style={{ color: "black" }} /> Image Exist
                            Already
                        </>
                    ) : (
                        <>
                            <CheckBoxIcon fontSize="small" style={{ color: "green" }} /> Approved
                        </>
                    )}
                </Typography>
            ),
        },
    ];

    const handleSave = () => {
        if (onSave) {
            const filesToSave = overwrite
                ? files
                : files.filter((file) => file.status !== "exists");

            const formData = new FormData();
            filesToSave.forEach((file) => {
                formData.append("files", file);
            });
            formData.append("overwrite", overwrite);

            onSave(formData);
            setFiles([]);
        }
    };

    return (
        <Grid container spacing={3} style={{ padding: 20 }}>
            <Grid
                item
                xs={8}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Box width="100%">
                    <Typography variant="h6" style={{ marginBottom: 5 }}>
                        Upload images
                    </Typography>
                    <Box
                        {...getRootProps({
                            className: "dropzone",
                            style: {
                                width: "100%",
                                height: "100%",
                                minHeight: 250,
                                border: "2px dashed #cccccc",
                                borderRadius: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 20,
                                backgroundColor: isDragActive ? "#e0e0e0" : "#ffffff",
                            },
                        })}
                    >
                        <input {...getInputProps()} />
                        <div className="container">
                            <Typography variant="h5" gutterBottom>
                                {isDragActive ? "Drop the files here ..." : "Drop files here"}
                            </Typography>
                            {!isDragActive && <p>or</p>}
                            {!isDragActive && <Button variant="contained">Select Files</Button>}
                        </div>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="h6">Accepted images</Typography>
                <Box
                    style={{
                        border: "1px solid black",
                        padding: "16px",
                        height: "100%",
                        maxHeight: 250,
                    }}
                >
                    <Box display="flex">
                        <Box display="flex" alignItems="center" marginRight={2}>
                            <IconButton aria-label="approved">
                                <CheckBoxIcon fontSize="small" style={{ color: "green" }} />
                            </IconButton>
                            <Typography variant="body2" style={{ fontSize: 12 }}>
                                = Approved
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" marginRight={2}>
                            <IconButton aria-label="invalid format">
                                <CancelIcon fontSize="small" style={{ color: "red" }} />
                            </IconButton>
                            <Typography variant="body2" style={{ fontSize: 12 }}>
                                = Invalid Format
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <IconButton aria-label="image exists already">
                                <InfoIcon fontSize="small" style={{ color: "black" }} />
                            </IconButton>
                            <Typography variant="body2" style={{ fontSize: 12 }}>
                                = Image Exist Already
                            </Typography>
                        </Box>
                    </Box>
                    <Table columns={columns} data={files} fetchData={() => {}} pageCount={1} />
                </Box>
            </Grid>
            <Grid item xs={8}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={overwrite}
                            onChange={() => setOverwrite(!overwrite)}
                            name="overwriteSwitch"
                            color="primary"
                        />
                    }
                    label="Overwrite existing file"
                    labelPlacement="start"
                />
            </Grid>
            <Grid item xs={4}>
                {files.length === 0 ||
                (!overwrite && files.every((file) => file.status === "exists")) ? (
                    <Button disabled variant="contained" style={{ float: "right" }}>
                        Save
                    </Button>
                ) : (
                    <CustomButton
                        type="button"
                        color="rose"
                        style={{ float: "right" }}
                        onClick={handleSave}
                    >
                        Save
                    </CustomButton>
                )}
            </Grid>
        </Grid>
    );
};

export default ImageUpload;
