import http from "./Http";

const get = async (dealerId = '', startDate = '', endDate = '', page = '', pageSize = '') => {
    const url = `/activity-log?dealerId=${dealerId}&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`;
    return http.get(url);
};

const LogService = {
    get,
};

export default LogService;