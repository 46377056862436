import { useState } from "react";
import PageLayout from "layouts/PageLayout";
import CalendarPicker from "components/CalendarPicker/CalendarPicker";
import CustomButton from "components/CustomButtons/Button.js";
import Table from "components/ReactTable/ReactTableV2";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, Typography, Popover, Card } from "@material-ui/core";
import DateRangeIcon from '@material-ui/icons/DateRange';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useSalesReport from "hooks/useSalesReport";
import useOrder from "hooks/useOrder";

const useStyles = makeStyles({
    centerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    customTitle: {
        fontSize: 12,
        fontWeight: "bold",
    },
    customDetail: {
        fontSize: 11
    },
    greenText: {
        fontSize: 50,
        marginTop: 25,
        textAlign: 'center',
        color: '#8ec63f',
        fontWeight: 500
    },
    redText: {
        fontSize: 50,
        marginTop: 25,
        textAlign: 'center',
        color: '#ed1c25',
        fontWeight: 500
    },
    normalText: {
        fontSize: 50,
        fontSize: 50,
        marginTop: 25,
        textAlign: 'center',
        fontWeight: 500
    },
    textCenter: {
        textAlign: 'center'
    }
});

const filterOptions = [
    { title: "Last month", option: "last_month" },
    { title: "This month", option: "this_month" },
    { title: "This week", option: "this_week" },
    { title: "YTD report", option: "ytd_report" },
    { title: "Last year", option: "last_year" },
];

const OptionsList = ({ title = '', options = [] }) => {
    const classes = useStyles();

    return (
        <Box flex={1}>
            <Box p={2}>
                <Typography variant="h6" className={classes.customTitle}>{title}:</Typography>
                {options.map((option, index) => (
                    <Box
                        key={index}
                        display="flex"
                        mt={index === 0 ? 2 : 0}
                        mb={2}
                    >
                        <Typography
                            className={classes.customDetail}
                            style={{ flexGrow: 1 }}
                        >
                            {option.label}
                        </Typography>
                        <Typography className={classes.customDetail}>{option.value}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const convertDateFormat = (dateString = '') => {
    if (!dateString) return '';
    const parts = dateString.split('-');
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

export default function SalesReportDetail() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        selectedOption,
        setSelectedOption,
        customDateRange,
        setCustomDateRange,
        salesReportData,
        handleFilterByDateRangeClick
    } = useSalesReport();
    const {
        totalOrders = 0,
        ordersCanceled = 0,
        fulfillmentRatio = 0,
        netSalesPercentage = 0,
        netSalesAmount = 0,
        engagements = [],
        closingRatio = [],
        orderPerformance = [],
        salesPerformance = [],
        shippingPerformance = [],
        catalogPerformance = [],
        tax = [],
        profits = []
    } = salesReportData;

    const formattedStartDate = convertDateFormat(customDateRange.startDate);
    const formattedEndDate = convertDateFormat(customDateRange.endDate);
    const { order = [], changePagination, pageCount } = useOrder(formattedStartDate, formattedEndDate);

    const fetchData = async ({ pageIndex, pageSize }) => changePagination(pageIndex, pageSize);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover-textfields' : undefined;

    const columns = [
        {
            Header: 'Order Date',
            accessor: 'orderDate',
        },
        {
            Header: 'Order Ref#',
            accessor: '',
        },
        {
            Header: 'Invoice Number',
            accessor: 'orderNumber',
        },
        {
            Header: 'URL Orgin',
            accessor: ''
        },
        {
            Header: 'Status',
            accessor: ''
        },
        {
            Header: 'Sale Price',
            accessor: 'subtotal'
        },
        {
            Header: 'Charged Amount',
            accessor: 'orderAmount'
        },
        {
            Header: 'Net Sale',
            accessor: ''
        },
        {
            Header: 'Items',
            accessor: ''
        },
        {
            Header: 'Order Margin',
            accessor: ''
        },
        {
            Header: 'Gross Profits',
            accessor: ''
        },
        {
            Header: 'Revenue Share',
            accessor: ''
        }
    ];

    return (
        <PageLayout>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    Dealer Order Report
                </Box>
                <Box >
                    <Grid container spacing={1}>
                        {filterOptions.map((filter, index) => (
                            <Grid key={index} item>
                                <Button
                                    variant={filter.option === selectedOption ? 'contained' : 'outlined'}
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        setSelectedOption(filter.option);
                                    }}
                                >
                                    {filter.title}
                                </Button>
                            </Grid>
                        ))}
                        <Grid item>
                            <Button onClick={handleClick} variant="contained" size="small" startIcon={<DateRangeIcon />}>
                                Custom
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Box p={3} display="flex" flexDirection="column">
                                    <Box>
                                        <CalendarPicker
                                            fullWidth
                                            label="From date"
                                            date={customDateRange.startDate}
                                            setDate={(newStartDate) =>
                                                setCustomDateRange((prevDateRange) => ({
                                                    ...prevDateRange,
                                                    startDate: newStartDate,
                                                }))
                                            }
                                        />
                                    </Box>
                                    <Box mt={1}>
                                        <CalendarPicker
                                            fullWidth
                                            label="To date"
                                            date={customDateRange.endDate}
                                            setDate={(newEndDate) =>
                                                setCustomDateRange((prevDateRange) => ({
                                                    ...prevDateRange,
                                                    endDate: newEndDate,
                                                }))
                                            }
                                        />
                                    </Box>
                                    <CustomButton type="button" color="rose" onClick={() => {
                                        handleClose();
                                        handleFilterByDateRangeClick();
                                    }}>
                                        Filter
                                    </CustomButton>
                                </Box>
                            </Popover>
                        </Grid>
                        <Grid item>
                            <Button disabled variant="contained" size="small" startIcon={<GetAppIcon />}>
                                Export CSV
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {/* CONTENT */}
            <Box mt={2.5} display="flex">
                <Box flex={1}>
                    <OptionsList title="Engagements" options={engagements} />
                </Box>
                <Box flex={1}>
                    <OptionsList title="Closing Ratio" options={engagements} />
                </Box>
                <Box flex={1}>
                    <Box mt={2}>
                        <Typography variant="h6" className={`${classes.customTitle} ${classes.textCenter}`}>
                            Total Orders
                        </Typography>
                        <Box>
                            <Typography variant="h6" className={classes.greenText}>
                                {totalOrders}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box flex={1}>
                    <Box mt={2}>
                        <Typography variant="h6" className={`${classes.customTitle} ${classes.textCenter}`}>
                            Orders Canceled
                        </Typography>
                        <Box>
                            <Typography variant="h6" className={classes.redText}>
                                {ordersCanceled}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box flex={1}>
                    <Box mt={2}>
                        <Typography variant="h6" className={`${classes.customTitle} ${classes.textCenter}`}>
                            Fulfillment Ratio
                        </Typography>
                        <Box>
                            <Typography variant="h6" className={`${classes.normalText} ${classes.centerContent}`}>
                                <ArrowDropUpIcon color="primary" style={{ color: '#8ec63f', fontSize: 50 }} />
                                {fulfillmentRatio}%
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box flex={1}>
                    <Box mt={2} style={{ background: '#8ec63f', color: '#fff' }} p={2}>
                        <Typography variant="h6" className={`${classes.customTitle} ${classes.textCenter}`}>
                            Net Sales:
                        </Typography>
                        <Box textAlign="center">
                            <Typography
                                variant="h6"
                                className={`${classes.normalText} ${classes.centerContent}`}
                                style={{
                                    marginTop: 5,
                                    fontSize: 40
                                }}
                            >
                                <ArrowDropUpIcon color="primary" style={{ color: '#fff', fontSize: 50 }} />
                                {netSalesPercentage}%
                            </Typography>
                            ${netSalesAmount} over last month
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box mt={2.5} display="flex">
                <Box flex={1}>
                    <OptionsList title="Order Performance" options={orderPerformance} />
                </Box>
                <Box flex={1}>
                    <OptionsList title="Sales Performance" options={salesPerformance} />
                </Box>
                <Box flex={1}>
                    <Box>
                        <OptionsList title="Shipping Performance" options={shippingPerformance} />
                    </Box>
                    <Box>
                        <OptionsList title="Catalog Performance" options={catalogPerformance} />
                    </Box>
                </Box>
                <Box flex={1}>
                    <OptionsList title="Tax" options={tax} />
                </Box>
                <Box flex={1}>
                    <OptionsList title="Profits" options={profits} />
                </Box>
            </Box>

            <Box>
                <Card>
                    <Box p={2}>
                        <Typography variant="h6" className={classes.customTitle}>
                            Report Date: {customDateRange.startDate} - {customDateRange.endDate}
                        </Typography>
                        <Table
                            columns={columns}
                            data={order.data}
                            fetchData={fetchData}
                            pageCount={pageCount}
                        />
                    </Box>
                </Card>
            </Box>
        </PageLayout >
    )
}
