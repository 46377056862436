import { useState, useEffect } from 'react';
import useDealer from './useDealer';
import ReportService from 'services/ReportService';

const labelMapping = {
    'uniqueVisitors': 'Unique Visitors',
    'customerMessages': 'Customer Messages',
    'customerCalls': 'Customer Calls',
    'customerCarts': 'Customer Carts',
    'visitsToCart': 'Visits to Cart',
    'visitsToOrder': 'Visits to Order',
    'visitsToMessage': 'Visits to Message',
    'visitsToCalls': 'Visits to Calls',
    'totalOrders': 'Total Orders',
    'ordersCanceled': 'Orders Canceled',
    'fulfillmentRatio': 'Fulfillment Ratio',
    'numberOfDaysInReport': 'Number of Days in Report',
    'averageOrdersPerDay': 'Average Orders Per Day',
    'cartsCreated': 'Carts Created',
    'conversion': 'Conversion',
    'ordersCompleted': 'Orders Completed',
    'orderPendingOnOrder': 'Order Pending/On-order',
    'averageItemsPerOrder': 'Average Items Per Order',
    'numberOfOrdersAdjusted': 'Number of Orders Adjusted',
    'grossSiteSalesGenerated': 'Gross Site Sales Generated',
    'netSales': 'Net Sales',
    'netSalesShipped': 'Net Sales Shipped',
    'salesCanceledLost': 'Sales Canceled/ Lost',
    'salesPendingOnOrder': 'Sales Pending/On-Order',
    'totalSales': 'Total Sales',
    'averageSalesPerDay': 'Average Sales per Day',
    'averageSale': 'Average Sale',
    'averageRetainedMargin': 'Average Retained Margin',
    'collectedShipping': 'Collected Shipping',
    'accessory': 'Accessory',
    'replacement': 'Replacement',
    'other': 'Other',
    'totalOrdersWithTax': 'Total Orders with Tax',
    'totalTaxCollected': 'Total Tax Collected',
    'taxRate': 'Tax Rate',
    'totalNetProfits': 'Total Net Profits',
    'averageProfitPerSale': 'Average Profit per Sale',
    'totalLostProfits': 'Total Lost Profits',
    'dealerProfits': 'Dealer Profits',
    'retainedDealerMargin': 'Retained Dealer Margin',
    'percentOfNetProfit': '% of Net Profit',
    'commissionsPaid': 'Commissions Paid',
    'retainedCommMargin': 'Retained Comm Margin',
};

const useSalesReport = () => {
    const [selectedOption, setSelectedOption] = useState('this_week');
    const [customDateRange, setCustomDateRange] = useState({
        startDate: '',
        endDate: '',
    });
    const { id: dealerId } = useDealer();
    const [salesReportData, setSalesReportData] = useState({
        engagements: [],
        closingRatio: [],
        totalOrders: 0,
        ordersCanceled: 0,
        fulfillmentRatio: 0,
        netSalesPercentage: 0,
        netSalesAmount: 0,
        orderPerformance: [],
        salesPerformance: [],
        shippingPerformance: [],
        catalogPerformance: [],
        tax: [],
        profits: []
    });

    useEffect(() => {
        const fetchRange = async (selectedOption = '') => {
            const { data } = await ReportService.fetchDateRange(selectedOption);
            setCustomDateRange({
                startDate: data.startDate,
                endDate: data.endDate,
            });
            fetchSalesReport(dealerId, data.startDate, data.endDate);
        }

        if (selectedOption)
            fetchRange(selectedOption);
    }, [dealerId, selectedOption]);

    const fetchSalesReport = async (dealerId, startDate = '', endDate = '') => {
        try {
            const { data: response } = await ReportService.fetchSalesSummaryReport(
                dealerId,
                '',
                startDate,
                endDate
            );

            const engagements = transformData(response.engagements);
            const closingRatio = transformData(response.closingRatio);
            const orderPerformance = transformData(response.salesSummary.orderPerformance);
            const salesPerformance = transformData(response.salesSummary.salesPerformance);
            const shippingPerformance = transformData(response.salesSummary.shippingPerformance);
            const catalogPerformance = transformData(response.salesSummary.catalogPerformance);
            const tax = transformData(response.salesSummary.tax);
            const profits = transformData(response.salesSummary.profits);

            setSalesReportData({
                engagements,
                closingRatio,
                totalOrders: response.totalOrders,
                ordersCanceled: response.ordersCanceled,
                fulfillmentRatio: response.fulfillmentRatio,
                netSalesPercentage: response.netSalesPercentage,
                netSalesAmount: response.netSalesAmount,
                orderPerformance,
                salesPerformance,
                shippingPerformance,
                catalogPerformance,
                tax,
                profits
            });
        } catch (error) {
            console.error('Error fetching shipping methods:', error);
        }
    };

    const transformData = (conceptData = []) => {
        return Object.keys(conceptData).map(key => ({
            label: labelMapping[key] || key,
            value: conceptData[key]
        }));
    }

    const handleFilterByDateRangeClick = () => {
        fetchSalesReport(dealerId, customDateRange.startDate, customDateRange.endDate);
    }

    return {
        selectedOption,
        setSelectedOption,
        customDateRange,
        setCustomDateRange,
        salesReportData,
        handleFilterByDateRangeClick
    };
}

export default useSalesReport;