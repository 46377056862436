import http from "./Http";

const fetchDateRange = async (selectedOption = '') => {
    return http.get(`/date-range/${selectedOption}`);
};

const fetchOrderStatusCount = async (dealerId = '', startDate = '', endDate = '') => {
    return http.get(`/reports/order-status-count?dealerId=${dealerId}&startDate=${startDate}&endDate=${endDate}`);
};

const fetchSalesSummaryReport = async (dealerId = '', selectedOption = '', startDate = '', endDate = '') => {
    return http.get(`/reports/sales-summary?dealerId=${dealerId}&selectedOption=${selectedOption}&startDate=${startDate}&endDate=${endDate}`);
};

const ReportService = {
    fetchDateRange,
    fetchOrderStatusCount,
    fetchSalesSummaryReport
};

export default ReportService;