import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

import { cardTitle, grayColor } from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const style = (theme) => ({
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
        "& small": {
            fontSize: "80%",
            fontWeight: "400",
        },
    },
    ...modalStyle(theme),
});

const useStyles = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DEFAULT_MAX_WIDTH = 'lg';

export default function Modal({
    open = false,
    maxWidth = DEFAULT_MAX_WIDTH,
    modalTitle = '',
    handleClose = () => { },
    children,
    ...otherProps
}) {
    const classes = useStyles();

    return (
        <Dialog
            {...otherProps}
            classes={{
                root: classes.center + " " + classes.modalRoot,
            }}
            maxWidth={maxWidth}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleClose(false)}
        >
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <Edit />
                                </CardIcon>
                                <div style={{ display: 'flex' }}>
                                    <h4 className={classes.cardIconTitle}>
                                        {modalTitle}
                                    </h4>
                                    <Button
                                        justIcon
                                        key="close"
                                        aria-label="Close"
                                        color="transparent"
                                        onClick={() => handleClose(false)}
                                        style={{
                                            color: '#969696',
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        <Close className={classes.modalClose} />
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {children}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </DialogContent>
        </Dialog>
    );
}