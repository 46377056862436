import React, { useState, useEffect } from "react";

//core
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import CountryService from 'services/CountryService';

const useStyles = makeStyles(styles);

const US_ISO = 'US';

export default function SelectCountries(props) {
    const {
        selCountries = {
            selectAll: false,
            selItems: []
        },
        setSelCountries = () => { },
        openModal,
        setOpenModal = () => { }
    } = props;
    const classes = useStyles();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const getCountries = async () => {
            const countries = await CountryService.getCountries();
            setCountries(countries);
        };

        getCountries();
    }, []);

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                    <legend style={{ fontSize: 16, fontWeight: 'bold' }}>
                        Shipping Zones
                    </legend>
                    <p>
                        (If nothing is checked, ALL countries will be available. Once you checkmark select countries, only those will be allowed.)
                    </p>
                </GridItem>
            </GridContainer>

            <Box style={{ display: 'flex', alignItems: 'center', marginLeft: 30, }}>
                <Box>
                    <b style={{ marginRight: 20 }}>Countries</b>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                tabIndex={-1}
                                checked={selCountries.selectAll}
                                onClick={(e) => {
                                    setSelCountries({
                                        selectAll: !selCountries.selectAll,
                                        selItems: selCountries.selectAll ? [] : countries.map(c => c.id)
                                    });
                                }}
                                checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                }
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        }
                        label={"Select all"}
                    />
                </Box>
                <Box>
                    {
                        countries.filter(c => c.iso === US_ISO).map(c => (
                            <div>
                                <FormControlLabel
                                    key={1}
                                    control={
                                        <Checkbox
                                            disabled={false}
                                            tabIndex={-1}
                                            checked={selCountries.selItems.includes(c.id)}
                                            onClick={(e) => {
                                                if (selCountries.selItems.includes(c.id))
                                                    setSelCountries({
                                                        ...selCountries,
                                                        selItems: selCountries.selItems.filter(s => s !== c.id)
                                                    })
                                                else
                                                    setSelCountries({
                                                        ...selCountries,
                                                        selItems: [...selCountries.selItems, c.id]
                                                    })
                                            }}
                                            checkedIcon={
                                                <Check className={classes.checkedIcon} />
                                            }
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            classes={{
                                                checked: classes.checked,
                                                root: classes.checkRoot,
                                            }}
                                        />
                                    }
                                    label={c.nicename}
                                />
                                {(c.iso === US_ISO && selCountries.selItems.includes(c.id)) &&
                                    <Link
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            setOpenModal(!openModal)
                                        }}
                                        style={{
                                            fontSize: 12
                                        }}>
                                        Select state list
                                    </Link>
                                }
                            </div>
                        ))
                    }
                </Box>
            </Box>

            <GridContainer>
                <GridItem
                    xs={12}
                    sm={6}
                    style={{
                        marginLeft: 30,
                    }}
                >

                </GridItem>
            </GridContainer>

            <div
                style={{
                    height: 400,
                    overflowY: "scroll",
                    overflowX: "hidden"
                }}
            >
                <GridContainer
                    style={{
                        marginLeft: 18,
                        height: "auto"
                    }}
                >
                    {
                        [
                            ...countries.filter(c => selCountries.selItems.includes(c.id)),
                            ...countries.filter(c => !selCountries.selItems.includes(c.id))
                        ].map(c => (
                            <GridItem
                                key={c.id}
                                xs={12}
                                sm={12}
                                md={3}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={false}
                                                    tabIndex={-1}
                                                    checked={selCountries.selItems.includes(c.id)}
                                                    onClick={(e) => {
                                                        if (selCountries.selItems.includes(c.id))
                                                            setSelCountries({
                                                                ...selCountries,
                                                                selItems: selCountries.selItems.filter(s => s !== c.id)
                                                            })
                                                        else
                                                            setSelCountries({
                                                                ...selCountries,
                                                                selItems: [...selCountries.selItems, c.id]
                                                            })
                                                    }}
                                                    checkedIcon={
                                                        <Check className={classes.checkedIcon} />
                                                    }
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    classes={{
                                                        checked: classes.checked,
                                                        root: classes.checkRoot,
                                                    }}
                                                />
                                            }
                                            label={c.nicename}
                                        />
                                    </div>
                                    {(c.iso === US_ISO && selCountries.selItems.includes(c.id)) &&
                                        <Link
                                            href="javascript:void(0)"
                                            onClick={() => {
                                                setOpenModal(!openModal)
                                            }}
                                            style={{
                                                fontSize: 12
                                            }}>
                                            Select state list
                                        </Link>
                                    }
                                </div>
                            </GridItem>
                        ))
                    }
                </GridContainer>
            </div>
        </>
    )
}