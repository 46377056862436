import React from "react";
import { Box } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Edit from "@material-ui/icons/Edit";

export default function EditableCardHeader() {
    return (
        <Box style={{ display: "inline-block" }}>
            <CardHeader color="rose" icon>
                <CardIcon color="rose">
                    <Edit />
                </CardIcon>
            </CardHeader>
        </Box>
    );
}
