import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useDealer from './useDealer';
import DealerShipping from 'services/DealerShipping';
import { useSnackbar } from './useSnackbar';

const useManageShipping = () => {
    const openSnackbar = useSnackbar();
    const { id: dealerId } = useDealer();
    const [initialValues, setInitialValues] = useState({
        shippingMethods: []
    });

    useEffect(() => {
        const fetchShippingMethods = async (dealerId) => {
            try {
                const methods = await DealerShipping.getAll(dealerId);
                setInitialValues({ shippingMethods: methods });
            } catch (error) {
                console.error('Error fetching shipping methods:', error);
            }
        };

        fetchShippingMethods(dealerId);
    }, [dealerId]);

    const validationSchema = yup.object({
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateShipping(values);
        },
    });

    const updateShipping = async (shippingData) => {
        try {
            for (let i = 0; i < shippingData.shippingMethods.length; i++) {
                const shippingMethod = shippingData.shippingMethods[i];
                await DealerShipping.update(shippingMethod.id, shippingMethod);
            }
            openSnackbar('success', 'Shipping method updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating the shipping method. Please try again.');
        }
    }

    const handleAddShippingMethod = async (shippingData) => {
        try {
            const newShipping = await DealerShipping.create({ ...shippingData, dealerId });
            const updatedShippingMethods = [...formik.values.shippingMethods, ...newShipping];
            formik.setFieldValue('shippingMethods', updatedShippingMethods);
            openSnackbar('success', 'Shipping method inserted successfully.');
        } catch (error) {
            openSnackbar('error', 'Error creating shipping method.');
        }
    }

    const updateShippingRange = (shippingData) => {
        const updatedShippingMethods = formik.values.shippingMethods.map((shippingMethod) => {
            if (shippingMethod.id === shippingData.id) {
                return { ...shippingMethod, ranges: shippingData.ranges };
            }
            return shippingMethod;
        });

        formik.setFieldValue('shippingMethods', updatedShippingMethods);
    }

    const handleDeleteShippingMethod = async (id) => {
        try {
            await DealerShipping.remove(id);
            const updatedShippingMethods = formik.values.shippingMethods.filter(
                (shippingMethod) => shippingMethod.id !== id
            );
            formik.setFieldValue('shippingMethods', updatedShippingMethods);
            openSnackbar('success', 'Shipping method deleted successfully.');
        } catch (error) {
            console.error('Error deleting shipping method:', error);
        }
    }

    return {
        formik,
        handleAddShippingMethod,
        updateShippingRange,
        handleDeleteShippingMethod
    };
};

export default useManageShipping;