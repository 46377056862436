import React from "react";
import { Box, Typography } from "@material-ui/core";
import TextFieldRender from 'components/TextFieldRender/TextFieldRender';
import Button from "components/CustomButtons/Button.js";
import useRequestForm from "hooks/useRequestForm";
import useRequestValidation from "hooks/useRequestValidation";

export default function ReportOtherIssuesForm({ initialValues = {} }) {
    const { submitRequest } = useRequestForm();
    const formik = useRequestValidation('report-other-issues', submitRequest, initialValues);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Typography variant="body1" style={{ fontSize: 13 }}>
                    Describe the issue you are encountering and we will reach out  <br />
                    to you shortly with a solution. (The more detailed the better!)
                </Typography>

                <Box my={1.5}>
                    <TextFieldRender
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formik.values.issueDescription}
                        handleBlur={(value) => formik.setFieldValue("issueDescription", value)}
                        error={formik.touched.issueDescription && Boolean(formik.errors.issueDescription)}
                    />
                </Box>

                <Typography variant="body1" style={{ fontWeight: 900 }}>
                    How would you like us to reach out to you?
                </Typography>

                <Typography variant="body1" style={{ fontSize: 13 }} gutterBottom>
                    Please enter the best way to contact you.
                </Typography>

                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="Name:"
                        variant="outlined"
                        size="small"
                        value={formik.values.name}
                        handleBlur={(value) => formik.setFieldValue("name", value)}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                </Box>
                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="Email or Phone:"
                        variant="outlined"
                        size="small"
                        value={formik.values.emailOrPhone}
                        handleBlur={(value) => formik.setFieldValue("emailOrPhone", value)}
                        error={formik.touched.emailOrPhone && Boolean(formik.errors.emailOrPhone)}
                    />
                </Box>
                <Box textAlign="center">
                    <Button type="submit" color="rose">
                        Submit
                    </Button>
                </Box>
            </Box>
        </form>
    )
}
