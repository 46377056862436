import { useState, useEffect } from "react";
import StateService from "services/StateService";

const useStates = () => {
    const [states, setStates] = useState([]);
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const data = await StateService.get();
                if (isMounted) {
                    setStates(data);
                }
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };

        fetchStates();

        return () => {
            setIsMounted(false);
        };
    }, [isMounted]);

    const fetchStatesByCountry = async (countryName = "") => {
        const data = await StateService.get(countryName);
        return data;
    };

    return {
        states,
        fetchStatesByCountry,
    };
};

export default useStates;
