import http from "./Http";

const getAll = async () => {
    return http.get("/users");
};

const post = async body => {
    return http.post("/users", {
        ...body,
        password: btoa(body.password)
    });
};

const put = async body => {
    return http.put(`/users/${body.id}`, body);
};

const login = async body => {
    const user = await http.post("/users/login", {
        ...body,
        password: btoa(body.password)
    });
    return user.data
};

const enableUser = async id => {
    return http.delete(`/users/enable-user/${id}`);
};


const del = async id => {
    return http.delete(`/users/${id}`);
};

const checkUser = async (userName, dlid = '') => {
    return http.get(`/users/check-user/${userName}?dlid=${dlid}`).then(r => r.data);
};

const UserService = {
    getAll,
    post,
    put,
    login,
    enableUser,
    del,
    checkUser
};

export default UserService;