import React from "react";
import { Typography, Checkbox } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

const SelectAllCheckbox = ({ checked, setChecked, setSelected, items = [] }) => {
    const classes = useStyles();

    return (
        <div
            style={{
                textAlign: "center",
                fontSize: 15,
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography style={{ fontSize: 16 }}>Select All</Typography>
            <Checkbox
                tabIndex={-1}
                checked={checked}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (checked) {
                        setSelected([]);
                    } else {
                        setSelected(items.map((o) => o.id));
                    }
                    setChecked(!checked);
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                }}
            />
        </div>
    );
};

export default SelectAllCheckbox;
