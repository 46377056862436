import { InputAdornment, Switch, FormControlLabel, Typography, FormControl, InputLabel, Input, FormHelperText, Box } from '@material-ui/core';
import CustomBox from 'components/Box/Box';
import Button from "components/CustomButtons/Button.js";
import useHandlingFee from 'hooks/useHandlingFee';

export default function HandlingFee() {
    const { formik } = useHandlingFee();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography gutterBottom style={{ fontSize: 16, fontWeight: 500 }}>
                Handling Fee:
            </Typography>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Order Total:</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                        name="handlingFeeOrderTotal"
                        value={formik.values.handlingFeeOrderTotal}
                        onChange={formik.handleChange}
                        error={formik.touched.handlingFeeOrderTotal && Boolean(formik.errors.handlingFeeOrderTotal)}
                    />
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Charge Handling Fee:</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                        name="handlingFeeForLessTotal"
                        value={formik.values.handlingFeeForLessTotal}
                        onChange={formik.handleChange}
                        error={formik.touched.handlingFeeForLessTotal && Boolean(formik.errors.handlingFeeForLessTotal)}
                    />
                    <FormHelperText>
                        [If Amount is less than Order Total]
                    </FormHelperText>
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Charge Handling Fee:</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                        name="handlingFeeForGreaterTotal"
                        value={formik.values.handlingFeeForGreaterTotal}
                        onChange={formik.handleChange}
                        error={formik.touched.handlingFeeForGreaterTotal && Boolean(formik.errors.handlingFeeForGreaterTotal)}
                    />
                    <FormHelperText>
                        [If Amount is greater than Order Total]
                    </FormHelperText>
                </FormControl>
            </CustomBox>
            <CustomBox>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            name="handlingFeeHideFee"
                            checked={formik.values.handlingFeeHideFee}
                            onChange={formik.handleChange}
                        />
                    }
                    label="Hide Fee:"
                />
            </CustomBox>
            <Box textAlign="center">
                <Button type="submit" color="rose">
                    Update Handling Fee
                </Button>
            </Box>
        </form>
    )
}
