import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
    Box,
    TextField,
    InputAdornment,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import ColorsList from "./ColorSelect";
import RangeManager from "./RangeManager";
import DeleteShipping from "./DeleteShipping";

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

function getRowCount(data) {
    const rowCount = data.filter((row) => Object.values(row).some((value) => value !== "")).length;
    return rowCount;
}

export default function ShippingMethodTable({
    formik,
    updateShippingRange,
    handleDeleteShippingMethod,
}) {
    const classes = useStyles();

    const handleChangeRange = (shipping) => {
        updateShippingRange(shipping);
    };

    const handleDeleteShipping = (id) => {
        handleDeleteShippingMethod(id);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div style={{ overflowX: "auto" }}>
                <Table size="small" className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ minWidth: 200 }}>
                                Shipping Method
                            </StyledTableCell>
                            <StyledTableCell>Shipping</StyledTableCell>
                            <StyledTableCell>% Order Total</StyledTableCell>
                            <StyledTableCell>Flat Ship. Charges</StyledTableCell>
                            <StyledTableCell>Pick up</StyledTableCell>
                            <StyledTableCell>Free Ship.</StyledTableCell>
                            <StyledTableCell>Hide Ship. Addr.</StyledTableCell>
                            <StyledTableCell>Flag Color</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formik.values.shippingMethods.map((method, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    <TextFieldRender
                                        variant="outlined"
                                        size="small"
                                        value={method.shippingMethodName}
                                        handleBlur={(value) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].shippingMethodName`,
                                                value
                                            )
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <TextFieldRender
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={method.minShipping}
                                        handleBlur={(value) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].minShipping`,
                                                parseFloat(value)
                                            )
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    min
                                                </InputAdornment>
                                            ),
                                        }}
                                        style={{ width: 140, marginBottom: 5 }}
                                    />
                                    <TextFieldRender
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={method.maxShipping}
                                        handleBlur={(value) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].maxShipping`,
                                                parseFloat(value)
                                            )
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    max
                                                </InputAdornment>
                                            ),
                                        }}
                                        style={{ width: 140, marginBottom: 5 }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <TextFieldRender
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={method.orderTotal}
                                        handleBlur={(value) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].orderTotal`,
                                                parseInt(value)
                                            )
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">%</InputAdornment>
                                            ),
                                        }}
                                        style={{ width: 100 }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <TextFieldRender
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={method.flatShipCharges}
                                        handleBlur={(value) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].flatShipCharges`,
                                                parseFloat(value)
                                            )
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">$</InputAdornment>
                                            ),
                                        }}
                                        style={{ width: 100 }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Checkbox
                                        checked={method.pickUp}
                                        color="primary"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].pickUp`,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Checkbox
                                        checked={method.freeShip}
                                        color="primary"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].freeShip`,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Checkbox
                                        checked={method.hideShipAddr}
                                        color="primary"
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].hideShipAddr`,
                                                e.target.checked
                                            )
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <ColorsList
                                        value={method.flagColor}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                `shippingMethods[${index}].flagColor`,
                                                e.target.value
                                            )
                                        }
                                    />
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <DeleteShipping
                                        id={method.id}
                                        handleDeleteShipping={handleDeleteShipping}
                                    />
                                    <RangeManager
                                        id={method.id}
                                        rangeCount={getRowCount(method.ranges)}
                                        shippingMethodName={method.shippingMethodName}
                                        handleChangeRange={handleChangeRange}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <Box textAlign="center" mt={2}>
                <Button type="submit" color="rose">
                    Update Shipping Options
                </Button>
            </Box>
        </form>
    );
}
