import http from "./Http";

const getAll = async (dlid) => {
    return http.get(`/shipping?dlid=${dlid}`);
};

const post = async body => {
    return http.post(`/shipping`, body);
};

const put = async body => {
    return http.put(`/shipping/${body.dealerShippingId}`, body);
};

const del = async id => {
    return http.delete(`/shipping/${id}`);
};

const ShippingService = {
    getAll,
    post,
    put,
    del
};

export default ShippingService;