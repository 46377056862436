import axios from "axios";
import { getUser } from "helpers";

const { id = 0, firstName = "", lastName = "" } = getUser() || {};
const userName = `${firstName} ${lastName}`;
const dealerId = parseInt(localStorage.getItem("_dlid") || "0");

export const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;
export const BASE_URL_API_ECOMMERCE = process.env.REACT_APP_BASE_URL_API_ECOMMERCE;
export const BASE_URL_FRONTEND_PORT = process.env.REACT_APP_BASE_URL_FRONTEND_PORT;
export const BASE_URL_FRONTEND = process.env.REACT_APP_BASE_URL_FRONTEND;
export const BASIC_AUTH = process.env.REACT_APP_BASIC_AUTH;
export const BASE_URL_TRANSLATION = process.env.REACT_APP_BASE_URL_TRANSLATION;

export default axios.create({
    baseURL: BASE_URL_API,
    headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${BASIC_AUTH}`,
        DealerId: dealerId,
        UserId: id,
        UserName: userName,
    },
});
