import { useState, useEffect } from 'react';
import { createMuiTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid, Button, AppBar, Toolbar, IconButton, LinearProgress, Switch, Checkbox, Radio, Card, CardActionArea, CardMedia, CardContent, CardActions, TextField, Paper, Tabs, Tab } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PageLayout from "layouts/PageLayout";
import CustomButton from "components/CustomButtons/Button.js";
import ColorPickerTextField from './ColorPickerTextField';
import useDealer from 'hooks/useDealer';
import { useSnackbar } from 'hooks/useSnackbar';
import ThemeService from 'services/ThemeService';

const colorInfo = [
    { name: 'primary', label: 'Primary Color' },
    { name: 'secondary', label: 'Secondary Color' },
    { name: 'headerBackground', label: 'Header Background' },
    { name: 'bodyBackground', label: 'Body Background' },
    { name: 'footerBackground', label: 'Footer Background' },
];

const createCustomTheme = (themeColors) => {
    return createMuiTheme({
        palette: {
            primary: {
                main: themeColors.primary,
            },
            secondary: {
                main: themeColors.secondary,
            },
        },
    });
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *:not(:first-child)': {
            margin: theme.spacing(1),
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function ThemeColorCustomizer() {
    const { id: dealerId, themeId } = useDealer();
    const openSnackbar = useSnackbar();

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const [theme] = await ThemeService.getAll(themeId);

                if (theme) {
                    const { palette } = JSON.parse(theme.js);
                    const { primary, secondary, headerBackground, bodyBackground, footerBackground } = palette;

                    if (primary && secondary) {
                        setThemeColors({
                            primary: primary.main,
                            secondary: secondary.main,
                            headerBackground: headerBackground.main,
                            bodyBackground: bodyBackground.main,
                            footerBackground: footerBackground.main
                        })
                    }
                }

            } catch (error) {
                console.error('Error fetching custom page:', error);
            }
        };

        if (themeId) fetchTheme();
    }, [themeId]);

    const [themeColors, setThemeColors] = useState({
        primary: '#000000',
        secondary: '#ffffff',
    });

    const handleColorChange = (name, value) => {
        setThemeColors({
            ...themeColors,
            [name]: value,
        });
    };

    const handleClick = async () => {
        try {
            const data = {
                dealerId,
                themeColors,
            };
            console.log(data);
            await ThemeService.put(data);
            openSnackbar('success', 'Theme updated successfully');
        } catch (error) {
            openSnackbar('error', 'An error occurred updating the theme. Please try again.');
        }
    }

    const customTheme = createCustomTheme(themeColors);
    const classes = useStyles();

    return (
        <PageLayout>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    {colorInfo.map((info) => (
                        <Box key={info.name} display="flex" alignItems="center">
                            <Typography variant="caption" style={{ fontSize: 14, minWidth: 250 }}>
                                {info.label}: {themeColors[info.name]}
                            </Typography>
                            <ColorPickerTextField
                                name={info.name}
                                value={themeColors[info.name]}
                                onColorChange={handleColorChange}
                            />
                        </Box>
                    ))}
                    <Box mt={2} style={{ textAlign: 'center' }}>
                        <CustomButton type="button" color="rose" onClick={handleClick}>
                            Save changes
                        </CustomButton>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={3}>
                        <ThemeProvider theme={customTheme}>
                            <Grid item xs={6}>
                                { /* BUTTON */}
                                <Box>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        BUTTON
                                    </Typography>
                                    <div className={classes.root}>
                                        <Button variant="contained" color="primary">
                                            BUTTON
                                        </Button>
                                        <Button variant="outlined" color="primary">
                                            BUTTON
                                        </Button>
                                        <Button variant="text" color="primary">
                                            BUTTON
                                        </Button>
                                    </div>
                                </Box>
                                { /* APP BAR */}
                                {/*<Box>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        TOP APP BAR
                                    </Typography>
                                    <AppBar position="static">
                                        <Toolbar>
                                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                                <MenuIcon />
                                            </IconButton>
                                            <Typography variant="h6" className={classes.title}>
                                                Headline 6
                                            </Typography>
                                            <IconButton color="inherit">
                                                <AccountCircle />
                                            </IconButton>
                                        </Toolbar>
                                    </AppBar>
                            </Box>*/}
                                { /* LINEAR PROGRESS */}
                                <Box mt={2}>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        LINEAR PROGRESS
                                    </Typography>
                                    <LinearProgress />
                                </Box>
                                { /* SWITCH */}
                                <Box mt={2}>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        SWITCH
                                    </Typography>
                                    <Switch />
                                    <Switch checked={true} />
                                </Box>
                                { /* CHECKBOX */}
                                <Box mt={2}>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        CHECKBOX
                                    </Typography>
                                    <Checkbox />
                                    <Checkbox checked />
                                </Box>
                                { /* RADIO */}
                                <Box mt={2}>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        RADIO BUTTON
                                    </Typography>
                                    <Radio />
                                    <Radio checked />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                { /* TABS */}
                                <Box>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        TABS
                                    </Typography>
                                    <Paper square>
                                        <Tabs
                                            value={0}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="disabled tabs example"
                                        >
                                            <Tab label="Active" />
                                            <Tab label="Disabled" disabled />
                                            <Tab label="Active" />
                                        </Tabs>
                                    </Paper>
                                </Box>
                                { /* BUTTON */}
                                <Box>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        BUTTON
                                    </Typography>
                                    <div className={classes.root}>
                                        <Button variant="contained" color="primary" startIcon={<MenuIcon />}>
                                            BUTTON
                                        </Button>
                                        <IconButton variant="contained" color="primary">
                                            <MenuIcon />
                                        </IconButton>
                                    </div>
                                </Box>
                                { /* CARD */}
                                <Box>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        CARD
                                    </Typography>
                                    <Card>
                                        <CardActionArea>
                                            <CardMedia
                                                image="/static/images/cards/contemplative-reptile.jpg"
                                                title="Contemplative Reptile"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Lizard
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                                    across all continents except Antarctica
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Button variant="contained" size="small" color="primary">
                                                Share
                                            </Button>
                                            <Button size="small" color="primary">
                                                Learn More
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                                { /* CARD */}
                                <Box>
                                    <Typography variant="overline" display="block" gutterBottom>
                                        TEXT FIELD
                                    </Typography>
                                    <Box mb={1}>
                                        <TextField fullWidth label="Outlined" variant="outlined" />
                                    </Box>
                                    <TextField fullWidth label="Filled" variant="filled" helperText="Assistive text" />
                                </Box>
                            </Grid>
                        </ThemeProvider>

                    </Grid>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

export default ThemeColorCustomizer;
