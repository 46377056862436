import { useEffect, useState } from "react";
import GenericSelect from "components/GenericSelect/GenericSelect";
import DealerMakeService from "services/DealerMakeService";
import DealerCatalogService from "services/DealerCatalogService";

export default function MakesSelector({ catalogId, value, showAllMakes = false, ...props }) {
    const dealerId = localStorage.getItem("_dlid");
    const [makes, setMakes] = useState([]);

    useEffect(() => {
        const fetchMakes = async () => {
            const catalogs = await DealerCatalogService.getAll(dealerId);

            if (catalogs.data.some((c) => c.catalogId == catalogId)) {
                const catalog = catalogs.data.find((c) => c.catalogId == catalogId);
                let makes = await DealerMakeService.getMakes(dealerId, catalog.catalogName);
                makes = makes.filter((m) => m.name !== "All");

                return [{ id: -1, name: "All" }, ...makes];
            }
        };

        let isMounted = true;

        if (dealerId && catalogId) {
            fetchMakes().then((data) => {
                if (isMounted) setMakes(data);
            });
        }

        return () => {
            isMounted = false;
        };
    }, [dealerId, catalogId]);

    return (
        <GenericSelect
            {...props}
            label="Make"
            value={makes.some((m) => m.id == value) ? value : ""}
            valueProp="id"
            labelProp="name"
            options={makes}
            fullWidth={true}
        />
    );
}
