import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import VehicleService from 'services/VehicleService';

export default function EngineSelector(props) {
    const {
        dlid,
        makeId,
        selectedYear,
        selectedModel,
        selectedTrim,
        selectedEngine,
        handleChange
    } = props;
    const [engines, setEngines] = useState([]);

    useEffect(() => {
        if (dlid && makeId && selectedYear && selectedModel, selectedTrim)
            loadEngines(dlid, makeId, selectedYear, selectedModel, selectedTrim);
    }, [selectedYear, selectedModel, selectedTrim]);

    const loadEngines = async (dlid, makeId, selectedYear, selectedModel, selectedTrim) => {
        try {
            const engines = await VehicleService.getEngines({
                dlid,
                makeId,
                catalog: 'isuzu',
                yearId: selectedYear,
                modelId: selectedModel,
                trimId: selectedTrim
            });
            setEngines(engines);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <TextField
            select
            label="Engine"
            value={selectedEngine}
            onChange={handleChange}
            variant="outlined"
            fullWidth
        >
            {engines.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};