import React from "react";
import { Box, Button, Typography } from "@material-ui/core/";
import ReplyIcon from "@material-ui/icons/Reply";

const emptyMessage = {
    id: "",
    subject: "",
    date: "",
    from: "",
    to: "",
    content: "",
};

export default function MessageContent({ message = emptyMessage, onReplyMail = () => {} }) {
    if (!message.id) return null;

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <Typography variant="h6" style={{ fontSize: 18, flex: 1 }}>
                    {message.subject}
                </Typography>
                <Typography color="textSecondary" style={{ fontSize: 14, textAlign: "right" }}>
                    {message.createdAtParsed}
                </Typography>
            </Box>
            <Box display="flex" mt={1}>
                <Typography style={{ minWidth: 60 }}>From:</Typography>
                <Typography>{message.fromMail}</Typography>
            </Box>
            <Box display="flex">
                <Typography style={{ minWidth: 60 }}>To:</Typography>
                <Typography>{message.toMail}</Typography>
            </Box>
            <Box mt={2}>
                <div dangerouslySetInnerHTML={{ __html: message.message }} />
            </Box>
            <Box mt={2}>
                <Button
                    variant="outlined"
                    startIcon={<ReplyIcon />}
                    onClick={() => onReplyMail(message)}
                >
                    Reply
                </Button>
            </Box>
        </Box>
    );
}
