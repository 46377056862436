import http from "./Http";

const getAll = async (dlid) => {
    try {
        const response = await http.get(`/dealer-shipping?dlid=${dlid}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const get = async (id) => {
    try {
        const response = await http.get(`/dealer-shipping/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const create = async (body) => {
    try {
        const response = await http.post(`/dealer-shipping`, body);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const update = async (id, body) => {
    try {
        return await http.put(`/dealer-shipping/${id}`, body);
    } catch (error) {
        throw error;
    }
};

const remove = async (id) => {
    try {
        return await http.delete(`/dealer-shipping/${id}`);
    } catch (error) {
        throw error;
    }
};

const putFreeShipping = async (body) => {
    try {
        return await http.put(`/dealer-shipping/free-shipping`, body);
    } catch (error) {
        throw error;
    }
};

const putHandlingFee = async (body) => {
    try {
        return await http.put(`/dealer-shipping/handling-fee`, body);
    } catch (error) {
        throw error;
    }
};

const putCustomFee = async (body) => {
    try {
        return await http.put(`/dealer-shipping/custom-fee`, body);
    } catch (error) {
        throw error;
    }
};

const putUpsCarrier = async (body) => {
    try {
        return await http.put(`/dealer-shipping/ups-carrier`, body);
    } catch (error) {
        throw error;
    }
};

const putFedexCarrier = async (body) => {
    try {
        return await http.put(`/dealer-shipping/fedex-carrier`, body);
    } catch (error) {
        throw error;
    }
};

const DealerShipping = {
    getAll,
    get,
    create,
    update,
    remove,
    putFreeShipping,
    putHandlingFee,
    putCustomFee,
    putUpsCarrier,
    putFedexCarrier
};

export default DealerShipping;
