import React, { useState, useEffect, useRef } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
    const {
        title = 'title here',
        subtitle = 'subtitle here',
        onAccept = () => { },
        onCancel = () => { }
    } = props;
    const [open, setOpen] = React.useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => onCancel(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {subtitle}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onAccept();
                    handleClose();
                }} color="primary" autoFocus>
                    Accept
                </Button>
                <Button onClick={() => {
                    onCancel();
                    handleClose();
                }} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    );
}
