export const useFieldUpdater = () => {
    const updateFieldValue = (setValues, fieldName, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    return {
        updateFieldValue,
    };
};
