import React from "react";
import { Checkbox, TextField, InputAdornment, IconButton } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal";
import Table from "components/ReactTable/ReactTableV2";
import useModal from "hooks/useModal";
import useCustomerSelection from "hooks/useCustomerSelection";

export default function CustomerSelectionDialog({
    selected = [],
    handleRecipientExists = () => {},
}) {
    const { search, setSearch, customers, changePagination, pageCount } = useCustomerSelection();
    const { open, handleOpenModal, handleCloseModal } = useModal();

    const columns = [
        {
            Header: "",
            accessor: "id",
            Cell: ({ cell }) => {
                const customerId = cell.row.original.billEmail;
                return (
                    <Checkbox
                        checked={selected.includes(customerId)}
                        onChange={() => {
                            handleRecipientExists(customerId);
                        }}
                    />
                );
            },
        },
        {
            Header: "Name",
            accessor: "billCompany",
        },
        {
            Header: "Email",
            accessor: "billEmail",
        },
    ];

    const fetchData = async ({ pageIndex, pageSize }) => changePagination(pageIndex, pageSize);

    return (
        <>
            <Button color="success" size="sm" variant="contained" onClick={handleOpenModal}>
                SEARCH
            </Button>

            <Modal
                fullWidth
                open={open}
                maxWidth="md"
                modalTitle="Select members"
                handleClose={handleCloseModal}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => {}} edge="end">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Table
                    columns={columns}
                    data={customers.data}
                    fetchData={fetchData}
                    pageCount={pageCount}
                />
            </Modal>
        </>
    );
}
