import { useEffect } from "react";
import TooglePassword from "components/TooglePassword/TooglePassword";
import PasswordChangeDialog from "components/PasswordChangeDialog/PasswordChangeDialog";
import useCrud from "hooks/useCrud";

export default function EditPassword({ dealerId = "", customerId = "" }) {
    const { data, readAllEntities, updateEntity } = useCrud("customers", {}, false);

    const fetchDealer = async () => {
        await readAllEntities({
            dealer_id: dealerId,
            id: customerId,
        });
    };

    useEffect(() => {
        if (dealerId && customerId) {
            fetchDealer();
        }
    }, [dealerId, customerId]);

    const handleSuccess = async ({ password }) => {
        const customer = {
            id: customerId,
            password: btoa(password),
        };

        await updateEntity(customer);
        await fetchDealer();
    };

    if (data.length === 1) {
        return (
            <TooglePassword
                password={data[0].password}
                helperText={<PasswordChangeDialog onSuccess={handleSuccess} />}
            />
        );
    }

    return null;
}
