import React, { useState, useEffect } from "react";

//libs
import { useFormik } from 'formik';
import * as yup from 'yup';

//core
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Notification from "components/Notification/Notification.js";
import CustomButton from "components/CustomButtons/Button.js";

//styles
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

//services
import DealerScriptService from 'services/DealerScriptService';

//default values
const defInitialValues = {
    "orderSuccessPageHeaderScript": "",
    "orderSuccessPageBodyScript": "",
    "customBodyScripts": "",
    "sslSiteSealCode": "",
    "customHeaderScripts": "",
    "googleAnalytics": "",
    "googleBingConversion": "",
    "googleAnalyticsAccount": "",
    "enableGoogleAnalyticsTracking": false,
    "facebookPageUrl": "",
    "twitterPageUrl": "",
    "linkedinPageUrl": "",
    "instagramPageUrl": "",
    "pinterestPageUrl": "",
    "youtubePageUrl": "",
    "sitemapXml": ""
};

//const
const SITE_MAP_XML = 'sitemapXml';

export default function DealerScripts({ form, dlid }) {
    const [notification, setNotification] = useState({
        open: false,
        message: 'Settings updated successfully'
    });
    const [initialValues, setInitialValues] = useState(defInitialValues);
    const classes = useStyles();

    useEffect(() => {
        const getMisc = async (dlid) => {
            const misc = await DealerScriptService.getAll(dlid).then(d => d.data.length === 1 ? d.data[0] : d.data);
            setInitialValues(misc);
        };
        if (dlid > 0) getMisc(dlid);
        if (!dlid) setInitialValues(defInitialValues);

    }, [dlid]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values) => {
            var formData = new FormData();

            for (var key in values) {
                if (![SITE_MAP_XML].includes(key)) formData.append(key, values[key]);
            }

            if (values.sitemapXml) {
                if (values.sitemapXml.name) {
                    formData.append(SITE_MAP_XML, values.sitemapXml.name);
                    formData.append("files", values.sitemapXml);
                } else {
                    formData.append(SITE_MAP_XML, values.sitemapXml);
                }
            }

            update(dlid, formData);
        },
    });

    const update = async (dlid, body) => {
        try {
            await DealerScriptService.put(dlid, body);
            openNotification(true, 'Dealer updated');
        } catch (error) {
            console.error(error);
        }
    }

    const openNotification = (open = notification.open, message = notification.message) => setNotification({
        ...notification,
        open,
        message
    });

    return <>
        <form onSubmit={formik.handleSubmit}>
            <Notification
                open={notification.open}
                message={notification.message}
                close
                color="success"
                onClose={(e) => openNotification(e)}
            />

            <GridContainer>
                <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                    <legend style={{ fontSize: 16, fontWeight: 'bold' }}>Scripts</legend>
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left' }}>
                        Order Success Page Header Script:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="orderSuccessPageHeaderScript"
                        name="orderSuccessPageHeaderScript"
                        value={formik.values.orderSuccessPageHeaderScript}
                        onChange={formik.handleChange}
                        error={formik.touched.orderSuccessPageHeaderScript && Boolean(formik.errors.orderSuccessPageHeaderScript)}
                        helperText={formik.touched.orderSuccessPageHeaderScript && formik.errors.orderSuccessPageHeaderScript}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                    <p>[Will Insert the code on Order Success page inside &lt;head&gt; tag]</p>
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left' }}>
                        Order Success Page Body Script:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="orderSuccessPageBodyScript"
                        name="orderSuccessPageBodyScript"
                        value={formik.values.orderSuccessPageBodyScript}
                        onChange={formik.handleChange}
                        error={formik.touched.orderSuccessPageBodyScript && Boolean(formik.errors.orderSuccessPageBodyScript)}
                        helperText={formik.touched.orderSuccessPageBodyScript && formik.errors.orderSuccessPageBodyScript}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                    <p>[Will insert the code on Order Success page inside &lt;body&gt; tag]</p>
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left' }}>
                        Custom Body scripts:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="customBodyScripts"
                        name="customBodyScripts"
                        value={formik.values.customBodyScripts}
                        onChange={formik.handleChange}
                        error={formik.touched.customBodyScripts && Boolean(formik.errors.customBodyScripts)}
                        helperText={formik.touched.customBodyScripts && formik.errors.customBodyScripts}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left' }}>
                        SSL Site Seal Code:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="sslSiteSealCode"
                        name="sslSiteSealCode"
                        value={formik.values.sslSiteSealCode}
                        onChange={formik.handleChange}
                        error={formik.touched.sslSiteSealCode && Boolean(formik.errors.sslSiteSealCode)}
                        helperText={formik.touched.sslSiteSealCode && formik.errors.sslSiteSealCode}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left' }}>
                        Custom Header Scripts:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="customHeaderScripts"
                        name="customHeaderScripts"
                        value={formik.values.customHeaderScripts}
                        onChange={formik.handleChange}
                        error={formik.touched.customHeaderScripts && Boolean(formik.errors.customHeaderScripts)}
                        helperText={formik.touched.customHeaderScripts && formik.errors.customHeaderScripts}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left' }}>
                        Google Analytics:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="googleAnalytics"
                        name="googleAnalytics"
                        value={formik.values.googleAnalytics}
                        onChange={formik.handleChange}
                        error={formik.touched.googleAnalytics && Boolean(formik.errors.googleAnalytics)}
                        helperText={formik.touched.googleAnalytics && formik.errors.googleAnalytics}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left' }}>
                        Google & BingAds Conversion:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="googleBingConversion"
                        name="googleBingConversion"
                        value={formik.values.googleBingConversion}
                        onChange={formik.handleChange}
                        error={formik.touched.googleBingConversion && Boolean(formik.errors.googleBingConversion)}
                        helperText={formik.touched.googleBingConversion && formik.errors.googleBingConversion}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '10px 10px 10px 0px', }}>
                        Google & BingAds Conversion:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="googleAnalyticsAccount"
                        name="googleAnalyticsAccount"
                        value={formik.values.googleAnalyticsAccount}
                        onChange={formik.handleChange}
                        error={formik.touched.googleAnalyticsAccount && Boolean(formik.errors.googleAnalyticsAccount)}
                        helperText={formik.touched.googleAnalyticsAccount && formik.errors.googleAnalyticsAccount}
                    />
                    <p>[Enter your Google Analytics Account# to enable Ecommerce Tracking on your Store]</p>
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '0px 10px 10px 0px' }}>
                        Enable Google eCommerce Analytics Tracking:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={2}>
                    <Switch
                        id="enableGoogleAnalyticsTracking"
                        name="enableGoogleAnalyticsTracking"
                        checked={formik.values.enableGoogleAnalyticsTracking}
                        value={formik.values.enableGoogleAnalyticsTracking}
                        onChange={formik.handleChange}
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '10px 10px 10px 0px', }}>
                        Facebook Page URL:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="facebookPageUrl"
                        name="facebookPageUrl"
                        value={formik.values.facebookPageUrl}
                        onChange={formik.handleChange}
                        error={formik.touched.facebookPageUrl && Boolean(formik.errors.facebookPageUrl)}
                        helperText={formik.touched.facebookPageUrl && formik.errors.facebookPageUrl}
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '10px 10px 10px 0px', }}>
                        Twitter Page URL:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="twitterPageUrl"
                        name="twitterPageUrl"
                        value={formik.values.twitterPageUrl}
                        onChange={formik.handleChange}
                        error={formik.touched.twitterPageUrl && Boolean(formik.errors.twitterPageUrl)}
                        helperText={formik.touched.twitterPageUrl && formik.errors.twitterPageUrl}
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '10px 10px 10px 0px', }}>
                        Linkedin Page URL:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="linkedinPageUrl"
                        name="linkedinPageUrl"
                        value={formik.values.linkedinPageUrl}
                        onChange={formik.handleChange}
                        error={formik.touched.linkedinPageUrl && Boolean(formik.errors.linkedinPageUrl)}
                        helperText={formik.touched.linkedinPageUrl && formik.errors.linkedinPageUrl}
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '10px 10px 10px 0px', }}>
                        Instagram Page URL:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="instagramPageUrl"
                        name="instagramPageUrl"
                        value={formik.values.instagramPageUrl}
                        onChange={formik.handleChange}
                        error={formik.touched.instagramPageUrl && Boolean(formik.errors.instagramPageUrl)}
                        helperText={formik.touched.instagramPageUrl && formik.errors.instagramPageUrl}
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '10px 10px 10px 0px', }}>
                        Pinterest Page URL:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="pinterestPageUrl"
                        name="pinterestPageUrl"
                        value={formik.values.pinterestPageUrl}
                        onChange={formik.handleChange}
                        error={formik.touched.pinterestPageUrl && Boolean(formik.errors.pinterestPageUrl)}
                        helperText={formik.touched.pinterestPageUrl && formik.errors.pinterestPageUrl}
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '10px 10px 10px 0px', }}>
                        Youtube Page URL:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                    <TextField

                        fullWidth
                        margin="dense"
                        id="youtubePageUrl"
                        name="youtubePageUrl"
                        value={formik.values.youtubePageUrl}
                        onChange={formik.handleChange}
                        error={formik.touched.youtubePageUrl && Boolean(formik.errors.youtubePageUrl)}
                        helperText={formik.touched.youtubePageUrl && formik.errors.youtubePageUrl}
                    />
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer style={{ marginTop: 20 }}>
                <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                    <legend style={{ fontSize: 16, fontWeight: 'bold' }}>Upload File</legend>
                </GridItem>
            </GridContainer>

            { /* */}
            <GridContainer>
                <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} style={{ marginLeft: 30, float: 'left', padding: '5px 10px 10px 0px', }}>
                        Sitemap File[xml file only]:
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={3} style={{ marginTop: 5 }}>
                    {
                        !formik.values.sitemapXml &&
                        <input id="sitemapXml" name="sitemapXml" type="file" onChange={(e) => formik.setFieldValue("sitemapXml", e.target.files[0])} />
                    }
                    {
                        formik.values.sitemapXml &&
                        <Button color="secondary" variant="contained" endIcon={<Close />} size="small" onClick={() => formik.setFieldValue("sitemapXml", null)}>
                            Delete
                        </Button>
                    }
                </GridItem>
            </GridContainer>

            <div style={{ textAlign: 'center', margin: 10 }}>
                <CustomButton type="submit" color="rose">Save</CustomButton>
            </div>
        </form>
    </>
}