export const NEW_STORE_ORDER = 'NEW_STORE_ORDER'
export const NEW_ADMIN_ORDER = 'NEW_ADMIN_ORDER'
export const PRICE_CHANGE = 'PRICE_CHANGE'
export const QUANTITY_CHANGE = 'QUANTITY_CHANGE'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const WEIGHT_CHANGE = 'WEIGHT_CHANGE'
export const STATUS_CHANGE = 'STATUS_CHANGE'
export const SHIP_GROUP_CHANGE = 'SHIP_GROUP_CHANGE'

//FORMS
export const CREATE_FORM = 'CREATE_FORM';
export const EDIT_FORM = 'EDIT_FORM';
export const CREATE = 'CREATE';
export const EDIT = 'EDIT';