import http from "./Http";

const getAll = async (dealerId) => {
    const { data } = await http.get(`/popular-models?dealerId=${dealerId}`);
    return data;
};

const put = async (body) => {
    const { data } = await http.put(`/popular-models`, body);
    return data;
};

const PopularModelsService = {
    getAll,
    put
};

export default PopularModelsService;
