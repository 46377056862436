import React from "react";
import { Checkbox } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

const RowCheckbox = ({ original, selected, setSelected }) => {
    const classes = useStyles();

    return (
        <div style={{ textAlign: "left" }}>
            <Checkbox
                tabIndex={-1}
                checked={selected.includes(original.id)}
                onClick={(e) => {
                    if (selected.includes(original.id)) {
                        setSelected(selected.filter((s) => s !== original.id));
                    } else {
                        setSelected([...selected, original.id]);
                    }
                }}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                }}
            />
        </div>
    );
};

export default RowCheckbox;
