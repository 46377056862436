import { withStyles } from "@material-ui/core/styles";
import { Box, Radio } from "@material-ui/core";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Table from "components/ReactTable/ReactTableV2";
import InfoButton from "components/InfoButton/InfoButton";
import DeleteButton from "components/DeleteButton/DeleteButton";
import CreateDealerPricing from "./CreateDealerPricing";

const StyledTextField = withStyles(() => ({
    root: {
        maxWidth: 100,
    },
}))(TextFieldRender);

export default function DealerPricingTable({
    formik,
    showDefaultCol = false,
    data = [],
    updateDealerPricing = () => {},
    handleDeleteDealerPricing = () => {},
    setAsDefault = () => {},
}) {
    const columns = [
        !showDefaultCol && {
            Header: "Catalog",
            accessor: "catalogName",
        },
        showDefaultCol
            ? {
                  Header: "Default",
                  accessor: "isDefault",
                  Cell: ({ row }) => {
                      return (
                          <Radio
                              checked={row.original.isDefault == 1}
                              value={row.original.id}
                              onClick={() => {
                                  setAsDefault(row.original.id);
                              }}
                          />
                      );
                  },
              }
            : {
                  Header: "Make",
                  accessor: "displayName",
              },
        /*{
            Header: "Customer",
            accessor: "displayNameCustomer",
            Cell: ({ value, row: { index } }) => value,
        },*/
        {
            Header: "From",
            accessor: "startRange",
            Cell: ({ value, row: { index } }) => value,
        },
        {
            Header: "To",
            accessor: "endRange",
            Cell: ({ value, row: { index } }) => value,
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        Cost plus %
                        <InfoButton description="If you set  COST PLUS <Value> this value is the added percentage the item will be sold as on the front end." />
                    </Box>
                );
            },
            accessor: "costPlus",
            Cell: ({ value, row: { index } }) => value,
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        List minus %
                        <InfoButton description="If you set LIST MINUS <Value> this value is the discounted percentage the item will be sold as on the front end. " />
                    </Box>
                );
            },
            accessor: "listMinus",
            Cell: ({ value, row: { index } }) => value,
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        Min profit %
                        <InfoButton description="This value is specifically for items that do NOT have a cost price. It is a mandated option, and by default, the system will assign a value of 20% here." />
                    </Box>
                );
            },
            accessor: "minProfit",
            Cell: ({ value, row: { index } }) => value,
        },
        {
            Header: () => {
                return (
                    <Box style={{ display: "flex" }}>
                        Inflate Msrp %
                        <InfoButton description="Inflate MSRP value is used to artificially inflate the shown suggest retail price.  " />
                    </Box>
                );
            },
            accessor: "inflateMsrp",
            Cell: ({ value, row: { index } }) => value,
        },
        {
            Header: "",
            accessor: "id",
            Cell: ({ value, row }) => {
                return (
                    <>
                        <CreateDealerPricing
                            initialValues={row.original}
                            handleAddDealerPricing={(values) => {
                                updateDealerPricing(values);
                            }}
                        />
                        <DeleteButton
                            handleClick={() => {
                                handleDeleteDealerPricing(value);
                            }}
                        />
                    </>
                );
            },
        },
    ].filter(Boolean);

    return <Table columns={columns} data={data} fetchData={() => {}} pageCount={1} />;
}
