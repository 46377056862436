import { useEffect } from "react";
import useDealerId from "./useDealerId";
import useDealerCatalogs from "./useDealerCatalogs";
import { BASIC_AUTH, BASE_URL_API_ECOMMERCE } from "services/Http";
import Http from "services/Http";

let scriptLoaded = false;

const useParts = () => {
    let xhr = null;
    const dealerId = useDealerId();
    const dealerCatalogs = useDealerCatalogs();

    useEffect(() => {
        const isMotor = dealerCatalogs.some((dc) => dc.catalogName === "motor");
        const script = document.createElement("script");

        if (isMotor) {
            script.src = "/jsonpipe.js";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (!scriptLoaded) {
                    scriptLoaded = true;
                }
            };
        }

        return () => {
            if (isMotor) {
                if (xhr) {
                    xhr.abort();
                    xhr = null;
                }

                scriptLoaded = false;
                document.body.removeChild(script);
            }
        };
    }, [dealerId, dealerCatalogs]);

    const searchParts = async (keyword = "") => {
        if (!keyword) {
            return [];
        }

        const cleanPartNumber = keyword.replace(/[^a-zA-Z0-9]/g, "");
        const isMotor = dealerCatalogs.some((dc) => dc.catalogName === "motor");

        let enPart = [];
        let promises = [];

        if (isMotor) {
            enPart = await fetchSuggestionsWithChunks("en", cleanPartNumber);
        } else {
            enPart = await fetchSuggestionsPaginated({
                locale: "en",
                keyword: cleanPartNumber,
                catalogName: dealerCatalogs[0].catalogName,
            });
        }

        if (enPart.length > 0) {
            promises = ["es", "pt", "fr"].map((locale) => {
                if (isMotor) {
                    return fetchSuggestionsWithChunks(locale, cleanPartNumber);
                } else {
                    return fetchSuggestionsPaginated({
                        locale,
                        keyword: cleanPartNumber,
                        catalogName: dealerCatalogs[0].catalogName,
                    });
                }
            });

            const [esPart, ptPart, frPart] = await Promise.all(promises);

            return [
                {
                    enPartName: enPart[0].name,
                    esPartName: esPart[0].name,
                    ptPartName: ptPart[0].name,
                    frPartName: frPart[0].name,
                },
            ];
        }

        return [];
    };

    const fetchSuggestionsWithChunks = (locale = "en", keyword = "") => {
        return new Promise((resolve, reject) => {
            let receivedChunk = [];
            const url = `${BASE_URL_API_ECOMMERCE}/suggestions-search?dlid=${dealerId}`;
            const data = {
                keyword: keyword,
                catalog: "motor",
                locale: locale,
                allMakes: true,
            };

            let xhr = window.jsonpipe.flow(url, {
                delimiter: "#####",
                success: (chunk) => {
                    receivedChunk = chunk;

                    if (xhr) {
                        xhr.abort();
                    }

                    resolve(chunk);
                },
                complete: (statusText) => {
                    resolve(receivedChunk);
                },
                timeout: 3000000,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${BASIC_AUTH}`,
                },
                data: JSON.stringify(data),
                withCredentials: false,
            });

            xhr.onerror = () => {
                reject(new Error("Error de red: la solicitud no se pudo completar"));
            };
        });
    };

    const fetchSuggestionsPaginated = ({
        locale = "en",
        keyword = "",
        catalogName = "",
        offset = 0,
        limit = 10,
    }) => {
        return new Promise((resolve, reject) => {
            const url = `${BASE_URL_API_ECOMMERCE}/suggestions-search?dlid=${dealerId}&offset=${offset}&limit=${limit}`;
            const data = {
                keyword: keyword,
                catalog: catalogName,
                locale: locale,
                allMakes: true,
            };

            Http.post(url, data)
                .then((response) => {
                    if (response.data.length > 0) {
                        resolve(response.data.filter((_, i) => i === 0));
                    } else {
                        resolve([]);
                    }
                })
                .catch((error) => reject(error));
        });
    };

    return {
        searchParts,
    };
};

export default useParts;
