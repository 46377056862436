export const DEF_INITIAL_VALUES = [
    {
        en_title: '',
        es_title: '',
        fr_title: '',
        pt_title: '',
        fields: [
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            }
        ]
    },
    {
        en_title: '',
        es_title: '',
        fr_title: '',
        pt_title: '',
        fields: [
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            }
        ]
    },
    {
        en_title: '',
        es_title: '',
        fr_title: '',
        pt_title: '',
        fields: [
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            }
        ]
    },
    {
        en_title: '',
        es_title: '',
        fr_title: '',
        pt_title: '',
        fields: [
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            },
            {
                en_text: '',
                es_text: '',
                fr_text: '',
                pt_text: '',
                url: '',
                newTab: false
            }
        ]
    }
];