import http from "./Http";

const post = async body => {
    return http.post(`/quote-log`, body);
};

const QuoteLogService = {
    post
};

export default QuoteLogService;