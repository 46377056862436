import { useState, useEffect } from "react";
import CustomerService from "services/CustomerService";

const useCustomers = (dlid) => {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        const getCustomers = async (dlid = "") => {
            const customers = await CustomerService.getAll(dlid, "", 0, 10000000n).then(
                (o) => o.data
            );
            setCustomers(customers?.data || []);
        };

        getCustomers(dlid);
    }, [dlid]);

    return customers;
};

export default useCustomers;
