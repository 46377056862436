import React, { useState, useMemo } from "react";

// core components
import ReactTableV2 from "components/ReactTable/ReactTableV2";

// material
import TextField from '@material-ui/core/TextField';

export default function QuoteLog({ formik }) {

    const columns = [
        {
            Header: "Created at",
            accessor: "createdAt",
        },
        {
            Header: "Quote number",
            accessor: "quoteNumber",
        },
        {
            Header: "User",
            accessor: "user",
        },
        {
            Header: "Action",
            accessor: "action",
        },
        {
            Header: "Memo",
            Cell: ({ row: { original } }) => {
                return (
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        defaultValue={original.memo}
                        variant="outlined"
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                )
            }
        }
    ];

    return (
        <>
            <ReactTableV2
                data={formik.values.log || []}
                columns={columns}
                rowKey="id"
            />
        </>
    );
}