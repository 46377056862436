import React from "react";
import { Box, Typography } from "@material-ui/core";
import RequestActions from "./RequestActions";
import TextFieldRender from 'components/TextFieldRender/TextFieldRender';
import CatalogSelector from "components/CatalogSelector/CatalogSelector";
import useRequestForm from "hooks/useRequestForm";
import useRequestValidation from "hooks/useRequestValidation";

export default function DiscontinuedForm({ initialValues = {}, onSuccess = () => { } }) {
    const { submitRequest, onApprove, onDecline, onMarkAsOpen } = useRequestForm(onSuccess);
    const formik = useRequestValidation('discontinued', submitRequest, initialValues);

    const handleCatalogChange = (e) => formik.setFieldValue("catalogId", e.target.value);

    console.log(
        formik.values
    )

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Typography variant="body1">
                    Please provide us with the information about the discontinued part.
                </Typography>

                <Box my={2}>
                    <CatalogSelector
                        value={formik.values.catalogId}
                        onChange={handleCatalogChange}
                        error={formik.touched.catalogId && Boolean(formik.errors.catalogId)}
                    />
                </Box>
                {initialValues && initialValues.isOpen === 1 && (
                    <Box mb={2}>
                        <TextFieldRender
                            fullWidth
                            label="Item Title:"
                            variant="outlined"
                            size="small"
                            value={formik.values.name || ""}
                            handleBlur={(value) => formik.setFieldValue("name", value)}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                        />
                    </Box>
                )}
                <Box mb={2}>
                    <TextFieldRender
                        fullWidth
                        label="Current Part#:"
                        variant="outlined"
                        size="small"
                        value={formik.values.partNumber}
                        handleBlur={(value) => formik.setFieldValue("partNumber", value)}
                        error={formik.touched.partNumber && Boolean(formik.errors.partNumber)}
                    />
                </Box>
                {(Object.keys(initialValues).length === 0 || (initialValues && initialValues.isOpen === 0)) && (
                    <Box mb={2}>
                        <TextFieldRender
                            fullWidth
                            label="Comment:"
                            multiline
                            rows={4}
                            variant="outlined"
                            value={formik.values.comment}
                            handleBlur={(value) => formik.setFieldValue("comment", value)}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                        />
                    </Box>
                )}
                {initialValues && initialValues.isOpen === 1 && (
                    <>
                        <Box mb={2}>
                            <TextFieldRender
                                fullWidth
                                label="Price:"
                                variant="outlined"
                                size="small"
                                value={formik.values.listPrice || ""}
                                handleBlur={(value) => formik.setFieldValue("listPrice", value)}
                                error={formik.touched.listPrice && Boolean(formik.errors.listPrice)}
                            />
                        </Box>

                        <Box mb={2}>
                            <TextFieldRender
                                fullWidth
                                label="Cost:"
                                variant="outlined"
                                size="small"
                                value={formik.values.cost || ""}
                                handleBlur={(value) => formik.setFieldValue("cost", value)}
                                error={formik.touched.cost && Boolean(formik.errors.cost)}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextFieldRender
                                fullWidth
                                label="Core:"
                                variant="outlined"
                                size="small"
                                value={formik.values.costPrice || ""}
                                handleBlur={(value) => formik.setFieldValue("costPrice", value)}
                                error={formik.touched.costPrice && Boolean(formik.errors.costPrice)}
                            />
                        </Box>
                    </>
                )}
                <RequestActions
                    initialValues={initialValues}
                    formik={formik}
                    onApprove={onApprove}
                    onDecline={onDecline}
                    onMarkAsOpen={onMarkAsOpen}
                />
            </Box>
        </form>
    )
}
