import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "components/ReactTable/ReactTable.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Typography from '@material-ui/core/Typography';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import TextFieldOriginal from "@material-ui/core/TextField";
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';
import AreYouSure from "components/AreYouSure/AreYouSure";
import Notification from "components/Notification/Notification.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DealerPricingService from '../../../services/DealerPricingService';

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

function TextField({ labelText = '', initialValue = '', onChange, otherProps }) {
    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <CustomInput
        labelText={labelText}
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            type: "number",
            value: value,
            onChange: ({ target: { value } }) => setValue(value),
            onBlur: onChange,
            ...otherProps
        }}
    />;
}

function EditPricing({ original, pricing, onSubmit = () => { } }) {
    const [isDefault, setIsDefault] = useState(original.isDefault || false);
    const [from, setFrom] = useState(original.from || 0);
    const [to, setTo] = useState(original.to || 0);
    const [costPlus, setCostPlus] = useState(original.costPlus || 0);
    const [listMinus, setListMinus] = useState(original.listMinus || 0);
    const [minProfit, setMinProfit] = useState(original.minProfit || 0);
    const [inflateMsrp, setInflateMsrp] = useState(original.inflateMsrp || 0);
    const [show, setShow] = useState(false);

    return (
        <>
            {show &&
                <Alert severity="error" style={{ marginTop: 10, marginBottom: 10 }}>
                    There is already a range that overlaps with {from} and {to}. <b>Modify and try again</b>
                </Alert>
            }
            <GridContainer>
                {!isDefault && (
                    <>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField type="number" labelText="From" initialValue={from} onChange={({ target: { value } }) => {
                                if (original.from != value) setFrom(Number(value));
                            }} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextField type="number" labelText="To" initialValue={to} onChange={({ target: { value } }) => {
                                if (original.to != value) setTo(Number(value));
                            }} />
                        </GridItem>
                    </>
                )}
                <GridItem xs={12} sm={12} md={3}>
                    <TextField type="number" labelText="Cost plus" initialValue={costPlus} onChange={({ target: { value } }) => {
                        if (original.costPlus != value) setCostPlus(Number(value));
                    }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <TextField type="number" labelText="List minus" initialValue={listMinus} onChange={({ target: { value } }) => {
                        if (original.listMinus != value) setListMinus(Number(value));
                    }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <TextField type="number" labelText="Min profit" initialValue={minProfit} onChange={({ target: { value } }) => {
                        if (original.minProfit != value) setMinProfit(Number(value));
                    }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <TextField type="number" labelText="Inflate Msrp" initialValue={inflateMsrp} onChange={({ target: { value } }) => {
                        if (original.inflateMsrp != value) setInflateMsrp(Number(value));
                    }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                    <Button color="rose" size="sm" onClick={() => {
                        if (!original.isDefault && pricing.filter(p => p.id != original.id).some(p => p.from <= from && p.to >= to)) {
                            setShow(true);
                        } else {
                            onSubmit(
                                [
                                    ...pricing.filter(p => p.id !== original.id),
                                    {
                                        id: original.id,
                                        from,
                                        to,
                                        costPlus,
                                        listMinus,
                                        minProfit,
                                        inflateMsrp,
                                        isDefault
                                    }
                                ]
                            );
                            setShow(false);
                        }
                    }}>
                        Save
                    </Button>
                </GridItem>
            </GridContainer>
        </>
    );
}

const getRetailPrice = (
    dlid,
    catalogId,
    slugs,
    partNumber,
    cost,
    listPrice,
    pricingRules
) => {
    let currentPricingRules = pricingRules;
    // Pricing Functions
    const localPrice = (cost, listPrice, pricingRules) => {
        let defaultMinProfit = 20; // Hardcoded fail safe default very minimum profit if no rules
        let retPrice = 0.0;
        // Default Rule the platform is LIST MINUS.
        // Meaning that if no rule is ever entered all items will be shown at LIST prices.
        if (pricingRules && pricingRules.priorityToggle == "p") {
            // Apply pricing Rules First
            if (listPrice > 0) {
                retPrice = listPrice;
                if (pricingRules && pricingRules.listMinus) {
                    retPrice = listPriceRules(listPrice, pricingRules.listMinus);
                }
            } else {
                // Item has only Cost
                retPrice = parseFloat(cost) + cost * (defaultMinProfit / 100); // Very minimun profit if no rules over Cost
                if (pricingRules && pricingRules.costPlus) {
                    retPrice = costRules(cost, pricingRules.costPlus);
                }
            }
        } else {
            // Apply cost Rules First
            if (cost > 0) {
                // Item has Cost
                retPrice = parseFloat(cost) + cost * (defaultMinProfit / 100); // Very minimun profit if no rules over Cost
                if (pricingRules && pricingRules.costPlus) {
                    retPrice = costRules(cost, pricingRules.costPlus);
                }
            } else {
                retPrice = listPrice;
                if (pricingRules && pricingRules.listMinus) {
                    retPrice = listPriceRules(listPrice, pricingRules.listMinus);
                }
            }
        }

        // No matter the togglePriority
        // See if we comply with min Profit if not, then apply minProfit Rule
        if (cost > 0 && pricingRules && pricingRules.minProfit) {
            let minSalesPrice =
                parseFloat(cost) + cost * (pricingRules.minProfit / 100);
            if (retPrice < minSalesPrice) {
                retPrice = minSalesPrice;
            }
        }
        return retPrice;
    };

    const listPriceRules = (listPrice, priceMinus) => {
        // Rule 2 :LIST MINUS <Value> this value is the discounted percentage
        // the item will be sold as on the front end
        return parseFloat(listPrice) - listPrice * (priceMinus / 100);
    };

    const costRules = (cost, costPlus) => {
        // Rule 1 COST PLUS <Value> this value is the added percentage
        // the item will be sold as on the front end
        const retP = parseFloat(cost) + cost * (costPlus / 100);
        return retP;
    };

    // Apply pricing rules to price, while in development we return the same input listPrice
    let retPrice = listPrice;
    // Apply rules over price ranges if price ranges exists
    if (pricingRules.priceRanges && pricingRules.priceRanges.length > 0) {
        if (pricingRules.default.priorityToggle == "p") {
            for (let i = 0; i < pricingRules.priceRanges.length; i++) {
                if (
                    listPrice >= pricingRules.priceRanges[i].from &&
                    listPrice <= pricingRules.priceRanges[i].to
                ) {
                    currentPricingRules = pricingRules.priceRanges[i].rules;
                    retPrice = localPrice(
                        cost,
                        listPrice,
                        pricingRules.priceRanges[i].rules
                    );
                }
            }
        } else {
            for (let i = 0; i < pricingRules.priceRanges.length; i++) {
                if (
                    cost >= pricingRules.priceRanges[i].from &&
                    cost <= pricingRules.priceRanges[i].to
                ) {
                    currentPricingRules = pricingRules.priceRanges[i].rules;
                    retPrice = localPrice(
                        cost,
                        listPrice,
                        pricingRules.priceRanges[i].rules
                    );
                }
            }
        }
    } else {
        // apply default Rules
        retPrice = localPrice(cost, listPrice, pricingRules.default);
    }
    let showPrice = getShowPrice(listPrice, retPrice, pricingRules);
    return { price: retPrice, showPrice: showPrice.toFixed(2) };
};

const getShowPrice = (listPrice, retailPrice, pricingRules) => {
    let inflateMsrp = 18; // Minimum hardcoded default in case no pricingRules defined
    if (pricingRules && pricingRules.inflateMsrp) {
        inflateMsrp = pricingRules.inflateMsrp;
        for (let i = 0; i < pricingRules.priceRanges.length; i++) {
            if (
                listPrice >= pricingRules.priceRanges[i].from &&
                listPrice <= pricingRules.priceRanges[i].to
            ) {
                inflateMsrp = pricingRules.priceRanges[i].rules.inflateMsrp;
            }
        }
    }
    // returns inflated showPrice
    let showPrice = retailPrice;
    if (inflateMsrp) {
        showPrice = parseFloat(retailPrice) + retailPrice * (inflateMsrp / 100);
    }
    return showPrice;
};

export default function DealerPricing({ dlid }) {
    const classes = useStyles();
    const [id, setId] = useState(0);
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [rowId, setRowId] = useState(-1);
    const [cost, setCost] = useState(0);
    const [listPrice, setListPrice] = useState(0);
    const [pricing, setPricing] = useState([]);
    const [pricingRules, setPricingRules] = useState("");
    const [retailPrice, setRetailPrice] = useState("");
    const [notification, setNotification] = useState({
        open: false,
        color: 'success',
        message: 'Pricing updated successfully'
    });

    useEffect(() => {
        const getPricing = async (dlid) => {
            const pricing = await DealerPricingService.get(dlid).then(x => x.data.map((p, index) => ({ ...p, id: index + 1 })));
            setPricing(pricing);
        };

        if (dlid) getPricing(dlid);
    }, [dlid]);

    useEffect(() => {
        if (cost && listPrice) {
            let newRetailPrice = getRetailPrice(
                1,
                1,
                {},
                "",
                cost,
                listPrice,
                JSON.parse(pricingRules)
            );

            setRetailPrice("RetailPrice:" + newRetailPrice.price + " , ShowPrice:" + newRetailPrice.showPrice);
        }
    }, [cost, listPrice, pricingRules]);

    useEffect(() => {
        if (pricing.length > 0) {
            const { from, to, id, isDefault, ...def } = pricing.find(p => p.isDefault);

            const pricingRules = {
                "default": def,
                priceRanges: pricing.filter(p => !('temp' in p) && !p.isDefault).map(p => {
                    return {
                        from: p.from,
                        to: p.to,
                        rules: {
                            costPlus: p.costPlus,
                            listMinus: p.listMinus,
                            minProfit: p.minProfit,
                            inflateMsrp: p.inflateMsrp,
                            priorityToggle: p.priorityToggle
                        }
                    }
                })
            };

            setPricingRules(
                JSON.stringify(pricingRules, null, "\t")
            );


        }
    }, [pricing]);

    const columns = [
        {
            Header: () => <div style={{ textAlign: 'center' }}></div>,
            accessor: "isDefault",
            Cell: ({ row: { original: { id, isDefault } } }) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        {isDefault && <Chip size="small" label="Default" />}
                    </div>
                );
            }
        },
        {
            Header: "From",
            accessor: "from",
        },
        {
            Header: "To",
            accessor: "to",
        },
        {
            Header: "Cost plus",
            accessor: "costPlus",
        },
        {
            Header: "List minus",
            accessor: "listMinus",
        },
        {
            Header: "Min profit",
            accessor: "minProfit",
        },
        {
            Header: "Inflate Msrp",
            accessor: "inflateMsrp",
        },
        {
            Header: () => null,
            id: 'expander',
            Cell: ({ row }) => {
                const rowIdx = row.id;

                return (
                    <>
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            onClick={() => {
                                if (rowIdx == rowId) {
                                    setRowId(-1);
                                } else {
                                    setRowId(rowIdx);
                                }

                                row.toggleRowExpanded();
                            }}
                        >
                            <Edit />
                        </Button>
                        {!row.original.isDefault && <Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            onClick={() => {
                                setId(row.original.id);
                                setOpen(!open);
                            }}
                        >
                            <Close />
                        </Button>}
                    </>
                );
            }
        }
    ];

    const renderRowSubComponent = ({ row: { original } }) => {
        return (
            <EditPricing original={original} pricing={pricing} onSubmit={async (newPricing) => {
                setPricing(newPricing);
                setRowId(-1);
                updatePricingRules(newPricing);
            }} />
        );
    }

    const updatePricingRules = async (newPricing) => {
        await DealerPricingService.put(dlid, newPricing);
        setNotification({ ...notification, open: true });
        setVisible(true);
    }

    return (
        <>
            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    const newPricing = pricing.filter(p => p.id !== id);
                    setPricing(newPricing);
                    setOpen(!open);
                    setRowId(-1);
                    updatePricingRules(newPricing);
                }}
                onCancel={() => setOpen(!open)} />

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button disabled={rowId > -1} color="rose" size="sm" onClick={() => {
                        const { costPlus, listMinus, minProfit, inflateMsrp, priorityToggle } = pricing.find(p => p.isDefault);

                        const newPricing = {
                            id: pricing.length + 1,
                            to: 0,
                            from: 0,
                            costPlus,
                            listMinus,
                            minProfit,
                            inflateMsrp,
                            priorityToggle,
                            isDefault: false,
                            temp: true
                        };

                        setPricing([
                            ...pricing,
                            newPricing
                        ]);

                        setRowId(pricing.length);
                    }}>
                        add new pricing rule
                    </Button>
                    <Notification
                        open={notification.open}
                        message={notification.message}
                        close
                        color="success"
                        onClose={(e) => {
                            setNotification({ ...notification, open: false });
                        }}
                    />
                    <ReactTable
                        columns={columns}
                        data={pricing.sort((a, b) => a.id - b.id)}
                        renderRowSubComponent={renderRowSubComponent}
                        showPagination={false}
                        showSorting={false}
                        activeRow={rowId} />
                </GridItem>
            </GridContainer>

            {visible &&
                <>
                    <GridContainer style={{ marginTop: 20 }}>
                        <GridItem xs={12} sm={12} md={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                Test Pricing Rules
                            </Typography>
                        </GridItem>
                    </GridContainer>
                    {retailPrice && <Alert severity="info">
                        {retailPrice}
                    </Alert>}

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextFieldOriginal
                                type="number"
                                fullWidth
                                margin="dense"
                                label="Cost"
                                onBlur={(e) => setCost(e.target.value)}
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <TextFieldOriginal
                                type="number"
                                fullWidth
                                margin="dense"
                                label="List Price"
                                onBlur={(e) => setListPrice(e.target.value)}
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextFieldOriginal
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={20}
                                value={pricingRules}
                                margin="dense"
                                label="Pricing Rule"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </>
            }
        </>
    )
};