import React, { useState, useEffect } from "react";
import OrderStatusService from "services/OrderStatusService";

const useOrderStatus = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await OrderStatusService.getAll();
                setData(response.data);
            } catch (err) {
            }
        };

        fetchData();
    }, []);

    return data;
}

export default useOrderStatus;
