import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "./useSnackbar";
import useDealer from "./useDealer";
import CustomPageService from "services/CustomPageService";

export const initialValues = {
    enableSection: false,
    enableSlider: false,
    sliderImageSrc: "",
    title: "",
    metaTitle: "",
    metaKeyword: "",
    metaDescription: "",
    pageContent: "",
    isActive: false,
    pagePosition: "N",
    menuTitle: "",
    pageUrl: "",
    isVisible: true,
    enMetaTitle: "",
    esMetaTitle: "",
    ptMetaTitle: "",
    frMetaTitle: "",
    enMetaKeyword: "",
    esMetaKeyword: "",
    ptMetaKeyword: "",
    frMetaKeyword: "",
    enMetaDescription: "",
    esMetaDescription: "",
    ptMetaDescription: "",
    frMetaDescription: "",
    enTitle: "",
    esTitle: "",
    ptTitle: "",
    frTitle: "",
    enPageContent: "",
    esPageContent: "",
    ptPageContent: "",
    frPageContent: "",
};

const useCustomPageForm = (
    isEditForm = false,
    defaultValues = initialValues,
    formSubmitCallback = () => {}
) => {
    const openSnackbar = useSnackbar();
    const { id: dealerId } = useDealer();
    const validationSchema = yup.object({
        //title: yup.string().required(),
        //pageContent: yup.string().required(),
        pageUrl: yup.string().required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: defaultValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("values", values);
            if (isEditForm) {
                await updateCustomPage(values);
            } else {
                await handleAddCustomPage(values);
            }
            formSubmitCallback();
        },
    });

    const handleAddCustomPage = async (pageData) => {
        try {
            await CustomPageService.post({ ...pageData, dealerId });
            openSnackbar("success", "Page inserted successfully.");
        } catch (error) {
            openSnackbar("error", "Error creating page.");
        }
    };

    const updateCustomPage = async (pageData) => {
        try {
            await CustomPageService.put({ ...pageData, dealerId });
            openSnackbar("success", "Page updated successfully.");
        } catch (error) {
            openSnackbar("error", "An error occurred while updating page. Please try again.");
        }
    };

    return {
        formik,
        handleAddCustomPage,
        updateCustomPage,
    };
};

export default useCustomPageForm;
