import { useState, useEffect } from "react";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

const PLACE = "tc";
const DURATION = 3000;
const COLOR = "info";
const ICON = AddAlert;

export default function Notification(props) {
    const { duration = DURATION, color = COLOR, message = "", onClose = () => {} } = props;
    const [open, setOpen] = useState(props.open || false);

    useEffect(() => {
        if (props.open !== open) {
            setOpen(props.open);
        }

        const timer = setTimeout(() => {
            setOpen(false);
            onClose();
        }, duration);

        return () => {
            setOpen(false);
            clearTimeout(timer);
        };
    }, [props.open]);

    return (
        <>
            {open && (
                <SnackbarContent
                    message={message}
                    close
                    color={color}
                    closeNotification={() => setOpen(false)}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
}
