import React from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const style = () => ({
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
        "& small": {
            fontSize: "80%",
            fontWeight: "400",
        },
    },
});

const useStyles = makeStyles(style);

export default function FormFrame({ title = "NO TITLE", closeDialog = () => {}, children }) {
    const classes = useStyles();

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Edit />
                        </CardIcon>
                        <div style={{ display: "flex" }}>
                            <h4 className={classes.cardIconTitle}>{title}</h4>
                            <Button
                                justIcon
                                key="close"
                                aria-label="Close"
                                color="transparent"
                                onClick={closeDialog}
                                style={{
                                    color: "#969696",
                                    marginLeft: "auto",
                                }}
                            >
                                <Close />
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>{children}</CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}
