import React, { useState, useEffect } from "react";
import useDealer from "hooks/useDealer";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SelectDealer from "components/SelectDealer/SelectDealer";
import PartCreationTabs from "components/PartCreationTabs/PartCreationTabs";
import Button from "components/CustomButtons/Button.js";
import Notification from "components/Notification/Notification.js";
import Modal from "components/Modal/Modal.js";
import PartVehicleList from "components/PartVehicleList/PartVehicleList";

// @material-ui
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Assignment from "@material-ui/icons/Assignment";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';

// service
import DealerCatalogService from "services/DealerCatalogService";
import PartService from "services/PartService";

// const
import { CREATE_PART, EDIT_PART, DEF_INITIAL_VALUES } from "components/PartCreationForm/PartCreationForm";

export default function Parts() {
    const { id: dlid } = useDealer();
    const [catalogs, setCatalogs] = useState([]);
    const [catalogId, setCatalogId] = useState(0);
    const [partNumber, setPartNumber] = useState('');
    const [form, setForm] = useState(CREATE_PART);
    const [part, setPart] = useState(DEF_INITIAL_VALUES);
    const [vehicles, setVehicles] = useState([]);
    const [notification, setNotification] = useState({
        open: false,
        color: 'success'
    });
    const [modal, setModal] = useState({
        open: false,
        title: "Create part"
    });
    const catalogName = catalogs.find(c => c.id === catalogId)?.name || '';

    useEffect(() => {
        const getCatalogs = async (dlid) => {
            const catalogs = await DealerCatalogService.getAll(dlid).then(x => x.data);
            setCatalogs(catalogs);
            if (catalogs.length === 1) setCatalogId(catalogs[0].id)
        };

        if (dlid) getCatalogs(dlid);
    }, [dlid]);

    const handleClick = async () => {
        const vehicles = await PartService.getByPartNo(catalogName, partNumber).then(x => x.data);
        setVehicles(vehicles);
    }

    const onSuccess = async () => {
        setPart(DEF_INITIAL_VALUES);
        setModal({ ...modal, open: !modal.open });
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage
        });
    }

    return (
        <>
            {notification.open &&
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                    onClose={() => setNotification({ ...notification, open: false })}
                />
            }

            <Modal
                open={modal.open}
                modalTitle={form.title}
                handleClose={() => {
                    setModal({ ...modal, open: false });
                }}
            >
                {modal.open ? (
                    <>
                        <div style={{ minWidth: 1200 }}></div>
                        <PartCreationTabs
                            catalog={catalogName}
                            onSuccess={onSuccess}
                        />
                    </>
                ) : null}
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <SelectDealer />
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button color="primary" onClick={() => {
                                setForm(CREATE_PART);
                                setPart(DEF_INITIAL_VALUES);
                                setModal({ ...modal, open: true });
                            }}>
                                Create part
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Catalog</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={catalogId}
                                            onChange={(e) => setCatalogId(e.target.value)}
                                        >
                                            {
                                                catalogs.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel>Part number</InputLabel>
                                        <Input
                                            value={partNumber}
                                            onChange={(e) => setPartNumber(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClick}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <PartVehicleList
                                        catalog={catalogName}
                                        vehicles={vehicles}
                                        setVehicles={setVehicles}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}