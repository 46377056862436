import {
    Box,
    Card,
    CardContent,
    Divider,
    FormControlLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    TextField,
    Typography,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

export default function CreateItemCore(product) {
    const {
        name = "Body Side Graphics",
        partNumber = "822139219AA",
        description = "Body side graphic is designed to fit Jeep Grand Cherokee 4xe.",
    } = product;

    return (
        <Box>
            <Card>
                <CardContent>
                    <Box display="flex">
                        <Typography variant="h6" gutterBottom>
                            PART NUMBER:
                        </Typography>
                        <Typography
                            variant="h6"
                            gutterBottom
                            style={{ marginLeft: 10, fontWeight: "normal" }}
                        >
                            {" "}
                            {partNumber}
                        </Typography>
                    </Box>
                    <Box>
                        <List dense component="nav">
                            <ListItem>
                                <ListItemText primary="Item(s) Cost" />
                                <ListItemSecondaryAction>
                                    <Typography style={{ fontWeight: 600 }}>$75.00</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primary="Core" />
                                <ListItemSecondaryAction>
                                    <Typography style={{ fontWeight: 600 }}>N/A</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primary="Labor Cost" />
                                <ListItemSecondaryAction>
                                    <Typography style={{ fontWeight: 600 }}>$112.00</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText primary="Labor Time" />
                                <ListItemSecondaryAction>
                                    <Typography style={{ fontWeight: 600 }}>0.5</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Box>
                </CardContent>
            </Card>
            <Box mt={2.5}>
                <TextField fullWidth variant="outlined" label="Name/Title" />
            </Box>
            <Box mt={2}>
                <TextField fullWidth multiline variant="outlined" rows={4} label="Description" />
            </Box>
            <Box mt={2}>
                <TextField fullWidth variant="outlined" label="Selling Price ($)" />
            </Box>
            <Box mt={2}>
                <TextField fullWidth variant="outlined" label="Labor ($)" />
            </Box>
            <Box mt={2}>
                <TextField fullWidth variant="outlined" label="Labor Time / Hour ($)" />
            </Box>
            <Box mt={2} display="flex">
                <Box flex={6}>
                    <FormControlLabel
                        value="start"
                        control={<Switch color="primary" />}
                        label="Pick up Only"
                        labelPlacement="start"
                    />
                </Box>
                <Box flex={6}>
                    <FormControlLabel
                        value="start"
                        control={<Switch color="primary" />}
                        label="Hide Item"
                        labelPlacement="start"
                    />
                </Box>
            </Box>
            <Box display="flex">
                <Box flex={6}>
                    <FormControlLabel
                        value="start"
                        control={<Switch color="primary" />}
                        label="Additional Image"
                        labelPlacement="start"
                    />
                </Box>
                <Box flex={6}>
                    <FormControlLabel
                        value="start"
                        control={<Switch color="primary" />}
                        label="Set Image as default"
                        labelPlacement="start"
                    />
                </Box>
            </Box>
            <Box mt={2} textAlign="center">
                <Button type="submit" color="rose">
                    Save and close
                </Button>
            </Box>
        </Box>
    );
}
