import { Select, MenuItem } from '@material-ui/core';

const colors = [
    { value: 1, label: 'Red' },
    { value: 2, label: 'Green' },
    { value: 3, label: 'Blue' },
    { value: 4, label: 'Black' },
    { value: 5, label: 'White' },
    { value: 6, label: 'Grey' },
    { value: 7, label: 'Brown' },
    { value: 8, label: 'Pink' },
    { value: 9, label: 'Orange' },
    { value: 10, label: 'Yellow' },
];


export default function ColorsList({ value = '', onChange = () => { }, ...otherProps }) {
    return (
        <Select
            {...otherProps}
            fullWidth
            value={value}
            onChange={onChange}
        >
            <MenuItem value={0}>Select</MenuItem>
            {colors.map((color, index) => (
                <MenuItem key={color.value} value={color.value}>{color.label}</MenuItem>
            ))}
        </Select>
    )
}
