import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DealerShipping from 'services/DealerShipping';
import useDealer from './useDealer';
import { useSnackbar } from './useSnackbar';

const useFreeShippingForm = () => {
    const openSnackbar = useSnackbar();
    const dealer = useDealer();
    const dealerId = dealer.id;
    const [initialValues, setInitialValues] = useState({
        freeShippingEnabled: false,
        freeShippingMinAmount: 0,
    });

    useEffect(() => {
        if (dealer.id > 0)
            setInitialValues({
                freeShippingEnabled: dealer.freeShippingEnabled === 1,
                freeShippingMinAmount: dealer.freeShippingMinAmount
            });
    }, [dealer]);

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateFreeShipping(values);
        },
    });

    const updateFreeShipping = async (shippingData) => {
        try {
            await DealerShipping.putFreeShipping({ ...shippingData, dealerId });
            openSnackbar('success', 'Free shipping updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating the free shipping. Please try again.');
        }
    }

    const resetForm = () => {
        formik.resetForm();
    }

    return {
        formik,
        resetForm
    };
}

export default useFreeShippingForm;