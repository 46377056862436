import { useState, useEffect } from "react";
import PageLayout from "layouts/PageLayout";
import { FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';

// core components
import Box from "components/Box/Box";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";
import InputFile from "components/InputFile/InputFile";
import Button from "components/CustomButtons/Button.js";
import useCustomPage from "hooks/useCustomPage";
import useCustomPageForm, { initialValues } from "hooks/useCustomPageForm";
import DeleteButton from "components/DeleteButton/DeleteButton";

export default function AboutUs() {
    const [page, setPage] = useState({
        isEditForm: false,
        initialValues: {
            ...initialValues,
            pageUrl: "about-us",
            isVisible: false
        }
    });
    const { pages = [] } = useCustomPage();
    const { formik } = useCustomPageForm(page.isEditForm, page.initialValues);

    useEffect(() => {
        if (pages.some(p => p.pageUrl === "about-us")) {
            const page = pages.find(p => p.pageUrl === "about-us");

            setPage({
                isEditForm: true,
                initialValues: {
                    ...page,
                    enableSection: page.enableSection === 1,
                    enableSlider: page.enableSlider === 1,
                },
            });
        }
    }, [pages]);

    return (
        <PageLayout>
            <form onSubmit={formik.handleSubmit}>
                <Box>
                    <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
                        Enable About Us Section:
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                name="enableSection"
                                checked={formik.values.enableSection}
                                value={formik.values.enableSection}
                                onChange={formik.handleChange}
                            />
                        }
                        label={formik.values.enableSection ? "On" : "Off"}
                    />
                </Box>
                <Box style={{ display: "flex" }}>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
                            Enable About Us Slider:
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="enableSlider"
                                    checked={formik.values.enableSlider}
                                    value={formik.values.enableSlider}
                                    onChange={formik.handleChange}
                                />
                            }
                            label={formik.values.enableSlider ? "On" : "Off"}
                        />
                    </Box>
                    <Box style={{ flex: 1 }}>
                        <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
                            Image:
                        </Typography>
                        {
                            formik.values.sliderImageSrc.length === 0 ? (
                                <InputFile
                                    onFileUpload={(f) => formik.setFieldValue("sliderImageSrc", f.fileName)}
                                />
                            ) : (
                                <>
                                    <Typography variant="caption" style={{ textTransform: 'none' }}>
                                        {formik.values.sliderImageSrc}
                                    </Typography>
                                    <DeleteButton
                                        handleClick={() => formik.setFieldValue("sliderImageSrc", "")}
                                    />
                                </>
                            )
                        }

                    </Box>
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        size="small"
                        label="Title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                    />
                </Box>
                <Box>
                    <Typography variant="subtitle2" gutterBottom style={{ textTransform: 'none' }}>
                        Page content
                    </Typography>
                    <HtmlEditor
                        dlid="0"
                        height={200}
                        title=""
                        data={formik.values.pageContent}
                        onBlur={(value) => formik.setFieldValue("pageContent", value)}
                        error={true}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        size="small"
                        label="Meta title"
                        name="metaTitle"
                        value={formik.values.metaTitle}
                        onChange={formik.handleChange}
                        error={formik.touched.metaTitle && Boolean(formik.errors.metaTitle)}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        size="small"
                        label="Meta keyword"
                        name="metaKeyword"
                        value={formik.values.metaKeyword}
                        onChange={formik.handleChange}
                        error={formik.touched.metaKeyword && Boolean(formik.errors.metaKeyword)}
                    />
                </Box>
                <Box>
                    <TextField
                        fullWidth
                        size="small"
                        label="Meta description"
                        name="metaDescription"
                        value={formik.values.metaDescription}
                        onChange={formik.handleChange}
                        error={formik.touched.metaDescription && Boolean(formik.errors.metaDescription)}
                    />
                </Box>
                <Box textAlign="center">
                    <Button type="submit" color="rose">
                        Save and close
                    </Button>
                </Box>
            </form>
        </PageLayout>
    );
}
