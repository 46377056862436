import useUser from "./useUser";
import OrderLogService from "services/OrderLogService";

const NEW_STORE_ORDER = "NEW_STORE_ORDER";
const NEW_ADMIN_ORDER = "NEW_ADMIN_ORDER";
const PRICE_CHANGE = "PRICE_CHANGE";
const QUANTITY_CHANGE = "QUANTITY_CHANGE";
const REMOVE_PRODUCT = "REMOVE_PRODUCT";
const ADD_PRODUCT = "ADD_PRODUCT";
const WEIGHT_CHANGE = "WEIGHT_CHANGE";
const STATUS_CHANGE = "STATUS_CHANGE";
const SHIP_GROUP_CHANGE = "SHIP_GROUP_CHANGE";
const TRACKING_CHANGE = "TRACKING_CHANGE";

const useOrderLog = (orderId, orderNumber) => {
    const [user] = useUser();
    const userId = user && user.id ? user.id : 0;

    const logStates = {
        NEW_STORE_ORDER,
        NEW_ADMIN_ORDER,
        PRICE_CHANGE,
        QUANTITY_CHANGE,
        REMOVE_PRODUCT,
        ADD_PRODUCT,
        WEIGHT_CHANGE,
        STATUS_CHANGE,
        SHIP_GROUP_CHANGE,
        TRACKING_CHANGE,
    };

    const createOrderLog = async ({
        dealerId,
        actionName,
        originalValues,
        prevValue,
        currValue,
    }) => {
        try {
            if (originalValues.id > 0) {
                await OrderLogService.post({
                    dealerId: dealerId,
                    orderId: orderId,
                    userId: userId,
                    orderNumber: orderNumber,
                    action: actionName,
                    memo: JSON.stringify({
                        ...originalValues,
                        ...prevValue,
                        ...currValue,
                    }),
                });
            }
        } catch (error) {
            console.error(
                "error",
                "An error occurred while updating the ups carrier. Please try again."
            );
        }
    };

    return {
        logStates,
        createOrderLog,
    };
};

export default useOrderLog;
