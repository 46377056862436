import { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import PageLayout from "layouts/PageLayout";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/ReactTable/ReactTableV2";
import CalendarPicker from "components/CalendarPicker/CalendarPicker";
import moment from 'moment';
import useDealer from 'hooks/useDealer';
import ReportService from 'services/ReportService';
import { financial } from 'helpers';
import { BASE_URL_API } from 'services/Http';

export default function SalesReport() {
    const { id: dealerId } = useDealer();
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').format('MM/DD/YYYY'));
    const [toDate, setToDate] = useState(moment().format('MM/DD/YYYY'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ReportService.fetchOrderStatusCount(
                    dealerId,
                    fromDate,
                    toDate
                );
                setData(response.data);
            } catch (err) {
            }
        };

        fetchData();
    }, [dealerId, fromDate, toDate]);

    const columns = [
        {
            Header: 'Name',
            accessor: 'status',
        },
        {
            Header: '# Orders',
            accessor: 'orderCount',
        },
        {
            Header: 'Amount',
            accessor: 'totalOrderAmount',
            Cell: ({ cell: { value } }) => `$${financial(value)}`
        },
        {
            Header: 'Action',
            accessor: 'id',
            Cell: ({ cell }) => {
                if (cell.row.original.orderCount === 0) return '';

                const statusId = cell.row.original.id;

                return (
                    <Link
                        href={`${BASE_URL_API}/reports/download-order-details-csv?dealerId=${dealerId}&startDate=${fromDate}&endDate=${toDate}&statusId=${statusId}`}
                        download="order_status_details.csv"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Download

                    </Link>
                );
            }
        },
    ];

    return (
        <PageLayout>
            <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                    <CalendarPicker label="From date" date={fromDate} setDate={setFromDate} />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <CalendarPicker label="To date" date={toDate} setDate={setToDate} />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={() => {

                        }}>
                        Go
                    </Button>
                </GridItem>
            </GridContainer>
            <br />
            <Table
                columns={columns}
                data={data}
                fetchData={() => { }}
                pageCount={1}
            />
        </PageLayout>
    )
}
