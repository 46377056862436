import React, { useState, useEffect } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

// custom components
import Dealer from "./Tabs/Dealer";
import DealerCatalog from "./Tabs/DealerCatalog";
import DealerMiscellaneous from "./Tabs/DealerMiscellaneous";
import DealerShipping from 'components/DealerShipping/DealerShipping';
import DealerScripts from './Tabs/DealerScripts';
import DealerMakes from './Tabs/DealerMakes';
import DealerPayments from './Tabs/DealerPayments';
import DealerPricing from './Tabs/DealerPricing';
import DealerUsers from "./Tabs/DealerUsers";
import DealerPageTech from "./Tabs/DealerPageTech";

export default function CreateDealer({
    tabId = 0,
    form,
    initialValues,
    onChangeTab = () => { },
    onSuccess = () => { }
}) {
    const [disabled, setDisabled] = useState({
        open: false,
        message: 'Settings updated successfully'
    });

    useEffect(() => {
        setDisabled(initialValues.id ? false : true)
    }, [initialValues]);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <NavPills
                    active={tabId}
                    onChange={(e) => onChangeTab(e)}
                    color="warning"
                    tabs={[
                        {
                            tabButton: "Dealer",
                            disabled: false,
                            tabContent: (
                                <Dealer dlid={initialValues.id} initialValues={initialValues} form={form} onSubmit={(e) => {
                                    onSuccess(e);
                                }} />
                            ),
                        },
                        {
                            tabButton: "Settings",
                            disabled,
                            tabContent: (
                                <DealerMiscellaneous dlid={initialValues.id} form={form} />
                            ),
                        },
                        {
                            tabButton: "Pricing",
                            disabled,
                            tabContent: (
                                <DealerPricing dlid={initialValues.id} />
                            ),
                        },
                        {
                            tabButton: "Scripts",
                            disabled,
                            tabContent: (
                                <DealerScripts dlid={initialValues.id} form={form} />
                            ),
                        },
                        {
                            tabButton: "Catalogs",
                            disabled,
                            tabContent: (
                                <DealerCatalog dlid={initialValues.id} />
                            ),
                        },
                        /*{
                            tabButton: "Makes",
                            disabled,
                            tabContent: (
                                <DealerMakes dlid={initialValues.id} />
                            ),
                        },*/
                        /*{
                            tabButton: "Pages",
                            disabled,
                            tabContent: (
                                <>Pages tab</>
                            ),
                        },*/
                        {
                            tabButton: "Payments",
                            disabled,
                            tabContent: (
                                <DealerPayments dlid={initialValues.id} />
                            ),
                        },
                        {
                            tabButton: "Shipping",
                            disabled,
                            tabContent: (
                                <DealerShipping dlid={initialValues.id} />
                            ),
                        },
                        /*{
                            tabButton: "Specials",
                            disabled,
                            tabContent: (
                                <>Specials tab</>
                            ),
                        },*/
                        {
                            tabButton: "Pagetech",
                            disabled,
                            tabContent: (
                                <DealerPageTech dlid={initialValues.id} />
                            ),
                        },
                        {
                            tabButton: "Users",
                            disabled,
                            tabContent: (
                                <DealerUsers dlid={initialValues.id} />
                            ),
                        },
                    ]}
                />
            </GridItem>
        </GridContainer>
    );
}