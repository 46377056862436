import http from "./Http";

const getAll = async (dealerId) => {
    return http.get(`/dealer-tax?dealerId=${dealerId}`);
};

const post = async (body) => {
    return http.post("/dealer-tax", body);
};

const put = async (body) => {
    return http.put(`/dealer-tax/${body.id}`, body);
};

const del = async (id) => {
    return http.delete(`/dealer-tax/${id}`);
};

const putGeneralTax = async (body) => {
    return http.put(`/dealers/${body.dealerId}/general-tax`, body);
};

const DealerTaxService = {
    getAll,
    post,
    put,
    del,
    putGeneralTax,
};

export default DealerTaxService;
