import React, { useState, useEffect, useRef } from "react";

// @material-ui
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Assignment from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";

// core components
import ButtonMUI from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Notification from "components/Notification/Notification.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import Modal from "components/BasicDialog/BasicDialog";
import OrderStatus from "components/OrderStatus/OrderStatus";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useUser from "hooks/useUser";

//libs
import memoize from "fast-memoize";

//service
import OrderService from "../../services/OrderService";
import CustomerService from "../../services/CustomerService";
import DealerService from "../../services/DealerService";
import PrintOrder from "components/PrintOrder/PrintOrder";
import EditOrder from "components/EditOrderNew/EditOrder";
import { formatDate, financial } from "helpers";

export const CREATE_ORDER = {
    title: "Create order",
    successMessage: "Order created successfully",
};

export const EDIT_ORDER = {
    title: "Edit order",
    successMessage: "Order updated successfully",
};

export const MOVE_ORDER = {
    title: "Edit order",
    successMessage: "Orders moved successfully",
};

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import MessageButton from "components/MessageButton/MessageButton";
import DeleteButton from "components/DeleteButton/DeleteButton";
import { Link, Typography } from "@material-ui/core";
import { check } from "prettier";
const useStyles = makeStyles(styles);

const NEW_ORDERS = 1;

export default function Orders({ orderStatusId = NEW_ORDERS }) {
    const classes = useStyles();
    const [user] = useUser();
    const [statusId, setStatusId] = useState(orderStatusId);
    const [selected, setSelected] = useState([]);
    const [dlid, setDlid] = useState(0);
    const [cid, setCid] = useState(0);
    const [dealers, setDealers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [newStatusId, setNewStatusId] = useState(0);
    const [search, setSearch] = useState("");
    const [orders, setOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const [notification, setNotification] = useState({
        open: false,
        message: CREATE_ORDER.successMessage,
    });
    const [modal, setModal] = useState({
        open: false,
        title: CREATE_ORDER.title,
    });
    const [initialValues, setInitialValues] = useState({});
    const [form, setForm] = useState(CREATE_ORDER);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const getDealers = async (dlid) => {
            const dealers = await DealerService.getAll(dlid).then((o) => o.data);
            if (dealers.length === 1) setDlid(dealers[0]?.id);
            setDealers(dealers);
        };
        if (user?.isAdmin === 0) {
            getDealers(user?.dealerId);
        } else {
            getDealers("");
        }
    }, [dlid, user?.dealerId]);

    useEffect(() => {
        const getCustomers = async (dlid) => {
            const customers = await CustomerService.getAll(dlid, "", 0, 10000000n).then(
                (o) => o.data
            );
            setCustomers(customers.data);
        };
        getCustomers(dlid);
    }, [dlid]);

    const getOrder = async (id) => {
        const order = await OrderService.get(id);
        return order;
    };

    const columns = [
        {
            Header: () => (
                <div
                    style={{
                        textAlign: "center",
                        fontSize: 15,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography style={{ fontSize: 16 }}>Select All</Typography>
                    <Checkbox
                        tabIndex={-1}
                        checked={checked}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (checked) {
                                setSelected([]);
                            } else {
                                setSelected(orders.map((o) => o.id));
                            }
                            setChecked(!checked);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                        }}
                    />
                    {/*<Link
                        href="javascript:void(0)"
                        onClick={(e) => {
                            e.preventDefault();
                            setSelected(orders.map((o) => o.id));
                        }}
                        variant="body2"
                        style={{ marginRight: 2 }}
                    >
                        Select All
                    </Link>*/}
                    {/*<Link
                        href="javascript:void(0)"
                        onClick={() => setSelected([])}
                        variant="body2"
                        style={{ marginLeft: 2, marginRight: 2 }}
                        disabled
                    >
                        Unselect
                    </Link>*/}
                </div>
            ),
            accessor: "isChecked",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <Checkbox
                                tabIndex={-1}
                                checked={selected.includes(original.id)}
                                onClick={(e) => {
                                    if (selected.includes(original.id)) {
                                        setSelected(selected.filter((s) => s != original.id));
                                    } else {
                                        setSelected([...selected, original.id]);
                                    }
                                }}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                }}
                            />
                        </div>
                    </>
                );
            },
        },
        {
            Header: "Order number",
            accessor: "orderNumber",
        },
        {
            Header: "Name",
            accessor: "billCompany",
        },
        {
            Header: "Total",
            Cell: ({
                row: {
                    original: { orderAmount = 0 },
                },
            }) => {
                return `$${financial(orderAmount)}`;
            },
        },
        {
            Header: "Sent To",
            accessor: "shipCompany",
        },
        {
            Header: "Date",
            Cell: ({
                row: {
                    original: { createdAt },
                },
            }) => {
                return `${formatDate(createdAt)}`;
            },
        },
        {
            Header: () => <div style={{ textAlign: "center" }}>Payment</div>,
            accessor: "payment",
            Cell: ({
                row: {
                    original: { pay_terms },
                },
            }) => {
                return <div style={{ textAlign: "center" }}>{pay_terms}</div>;
            },
        },
        {
            Header: () => <div style={{ textAlign: "center" }}></div>,
            accessor: "actions",
            Cell: ({ row: { original } }) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        {original.id && (
                            <PrintOrder dealerId={original.dealerId} id={original.id} />
                        )}
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            title="Edit order products"
                            onClick={async () => {
                                setForm(EDIT_ORDER);
                                const order = await getOrder(original.id);
                                setInitialValues({
                                    ...order,
                                    details: order.details.map((od) => {
                                        const qtyDifference =
                                            parseInt(od.qty) - parseInt(od.shipQty);

                                        return {
                                            ...od,
                                            qtyShipped: od.shipQty,
                                            shipQty: !od.shipQty ? "" : qtyDifference,
                                        };
                                    }),
                                });
                                setModal({
                                    ...modal,
                                    open: !modal.open,
                                });
                            }}
                        >
                            <EditIcon />
                        </Button>
                        {/*<MessageButton orderNumber={original.orderNumber} />*/}
                        {/*<Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            title="Delete"
                            onClick={() => {
                                setInitialValues(original);
                                setOpen(true);
                            }}
                        >
                            <Close />
                        </Button>*/}
                    </div>
                );
            },
        },
    ];

    const fetchData = React.useCallback(
        memoize(
            (fromDate, toDate, statusId, search, cid, dlid) => async ({ pageSize, pageIndex }) => {
                const fetchId = ++fetchIdRef.current;

                if (fetchId === fetchIdRef.current) {
                    const startRow = pageSize * pageIndex;

                    const orders = await OrderService.getAll(
                        startRow,
                        pageSize,
                        fromDate ? formatDate(fromDate) : "",
                        toDate ? formatDate(toDate) : "",
                        statusId,
                        search,
                        cid,
                        dlid
                    ).then((x) => x.data);
                    setOrders(orders?.data || []);
                    setPageCount(Math.ceil(orders.recordsTotal / pageSize));
                }
            }
        ),
        []
    );

    const onSuccess = async (order) => {
        if (form === CREATE_ORDER) {
            setOrders([order, ...orders]);
        }

        if (form === EDIT_ORDER) {
            const nOrders = orders.map((c) => (c.id === order.id ? { ...c, ...order } : c));
            setOrders(nOrders);
        }

        setInitialValues({});
        setModal({ ...modal, open: !modal.open });
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={``}
                onAccept={async () => {
                    await OrderService.putOrderStatus(selected, newStatusId);
                    setOrders([...orders].filter((o) => !selected.includes(o.id)));
                    setOpen(false);
                    setSelected([]);
                    setNewStatusId(0);
                    setNotification({
                        open: true,
                        message: MOVE_ORDER.successMessage,
                    });
                }}
                onCancel={() => setOpen(false)}
            />

            {notification.open && (
                <Notification
                    open={notification.open}
                    message={notification.message}
                    close
                    color="success"
                    onClose={(e) => setNotification({ ...notification, open: false })}
                />
            )}

            <Modal
                fullWidth
                maxWidth="xl"
                open={modal.open}
                title={form.title}
                onClose={() => {
                    setModal({ ...modal, open: false });
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
            >
                {modal.open ? (
                    <EditOrder form={form} order={initialValues} onSuccess={onSuccess} />
                ) : null}
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="primary"
                            icon
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_ORDER);
                                    setInitialValues({});
                                    setModal({ ...modal, open: true });
                                }}
                            >
                                Create order
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <div style={{ marginBottom: 10 }}>
                                <GridContainer>
                                    {user?.isAdmin === 1 && (
                                        <GridItem xs={12} sm={12} md={2}>
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="demo-simple-select-label">
                                                    Dealer
                                                </InputLabel>
                                                <Select
                                                    id="dealerId"
                                                    name="dealerId"
                                                    value={dlid}
                                                    onChange={(e) => {
                                                        setDlid(e.target.value);
                                                        setCid(0);
                                                    }}
                                                    fullWidth
                                                >
                                                    {dealers.length > 1 && (
                                                        <MenuItem key={0} value={0}>
                                                            All dealers
                                                        </MenuItem>
                                                    )}
                                                    {dealers.map((dealer, index) => (
                                                        <MenuItem key={index} value={dealer.id}>
                                                            {dealer.id}. {dealer.company}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    )}
                                    <GridItem xs={12} sm={12} md={2}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Customers
                                            </InputLabel>
                                            <Select
                                                id="dealerId"
                                                name="dealerId"
                                                value={cid}
                                                onChange={(e) => {
                                                    setCid(e.target.value);
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem key={0} value={0}>
                                                    Select customer
                                                </MenuItem>

                                                {customers.map((customer, index) => (
                                                    <MenuItem key={index} value={customer.id}>
                                                        {customer.billCompany}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                id="date-picker-dialog"
                                                label="From date"
                                                format="MM/dd/yyyy"
                                                value={fromDate}
                                                onChange={(value) => setFromDate(value)}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                fullWidth
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                id="date-picker-dialog"
                                                label="To date"
                                                format="MM/dd/yyyy"
                                                value={toDate}
                                                onChange={(value) => setToDate(value)}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                fullWidth
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <OrderStatus
                                            statusId={statusId}
                                            onChange={(value) => setStatusId(value)}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        <TextField
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            label="Search String"
                                            helperText="[Name, Order# etc]"
                                            fullWidth
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={2} style={{ display: "none" }}>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {
                                                console.log(fromDate, toDate, status, search);
                                            }}
                                        >
                                            Search
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style={{ marginTop: 10 }}>
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{ textAlign: "right" }}
                                    >
                                        <ButtonMUI
                                            color="transparent"
                                            variant="outlined"
                                            onClick={() => {
                                                setDlid(0);
                                                setCid(0);
                                                setFromDate(null);
                                                setToDate(null);
                                                setStatusId(1);
                                                setSearch("");
                                            }}
                                        >
                                            Clear filters
                                        </ButtonMUI>
                                    </GridItem>
                                </GridContainer>
                            </div>

                            {/* */}
                            {selected.length > -1 && (
                                <GridContainer style={{ marginBottom: 10 }}>
                                    <GridItem xs={12} sm={2}>
                                        <OrderStatus
                                            showAll={false}
                                            label="Update Order Status"
                                            onChange={(value) => setNewStatusId(value)}
                                        />
                                        {/*<FormControl className={classes.formControl} fullWidth>
                                            <InputLabel id="demo-simple-select-label">Move {selected.length} selected orders to</InputLabel>
                                            <Select
                                                value={newStatusId}
                                                onChange={(e) => setNewStatusId(e.target.value)}
                                            >
                                                {status.filter(s => s.id != NEW_ORDERS).map(item => (
                                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>*/}
                                    </GridItem>
                                    <GridItem xs={12} sm={2}>
                                        {/*<Button
                                            disabled={newStatusId === 0}
                                            color="success"
                                            variant="contained"
                                            startIcon={<SendIcon />}
                                            onClick={() => {
                                                setOpen(!open);
                                            }}
                                        >
                                            Apply
                                        </Button>*/}
                                        <DeleteButton
                                            disabled={newStatusId === 0 || selected.length === 0}
                                            variant="contained"
                                            buttonWithIcon
                                            startIcon={<SendIcon />}
                                            buttonLabel="Apply"
                                            customTitle="Confirm Order Status?"
                                            customSubTitle="This will change the current order status."
                                            confirmationBtnTitle="Save"
                                            handleClick={async () => {
                                                await OrderService.putOrderStatus(
                                                    selected,
                                                    newStatusId
                                                );
                                                setOrders(
                                                    [...orders].filter(
                                                        (o) => !selected.includes(o.id)
                                                    )
                                                );
                                                setOpen(false);
                                                setSelected([]);
                                                setNewStatusId(0);
                                                setNotification({
                                                    open: true,
                                                    message: MOVE_ORDER.successMessage,
                                                });
                                            }}
                                            style={{
                                                marginTop: 10,
                                                backgroundColor:
                                                    newStatusId !== 0 && selected.length !== 0
                                                        ? "#4caf50"
                                                        : undefined,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            )}
                            {/* */}

                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    <ReactTable
                                        columns={columns}
                                        data={orders}
                                        fetchData={fetchData(
                                            fromDate,
                                            toDate,
                                            statusId,
                                            search,
                                            cid,
                                            dlid
                                        )}
                                        pageCount={pageCount}
                                        manualPagination={true}
                                        showSorting={false}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
