import React, { useState, useEffect } from "react";
// @material-ui/icons
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import useOrderStatus from "hooks/useOrderStatus";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

export default function OrderStatus(props) {
    const {
        showInputLabel = true,
        onChange = () => {},
        label = "Order status",
        showAll = true,
    } = props;
    const classes = useStyles();
    const [statusId, setStatusId] = useState(props.statusId || 0);
    const orderStatus = useOrderStatus();

    useEffect(() => {
        if (props.statusId) {
            setStatusId(props.statusId);
        }
    }, [props.statusId]);

    return (
        <FormControl className={classes.formControl} fullWidth>
            {showInputLabel && <InputLabel>{label}</InputLabel>}
            <Select
                value={statusId}
                onChange={(e) => {
                    setStatusId(e.target.value);
                    onChange(e.target.value);
                }}
            >
                {showAll && <MenuItem value={0}>All</MenuItem>}
                {orderStatus.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.status}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
