import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DealerShipping from 'services/DealerShipping';
import useDealer from './useDealer';
import { useSnackbar } from './useSnackbar';

const useHandlingFee = () => {
    const openSnackbar = useSnackbar();
    const dealer = useDealer();
    const dealerId = dealer.id;
    const [initialValues, setInitialValues] = useState({
        handlingFeeOrderTotal: 0,
        handlingFeeForLessTotal: 0,
        handlingFeeForGreaterTotal: 0,
        handlingFeeHideFee: false
    });

    useEffect(() => {
        if (dealer.id > 0)
            setInitialValues({
                handlingFeeOrderTotal: dealer.handlingFeeOrderTotal,
                handlingFeeForLessTotal: dealer.handlingFeeForLessTotal,
                handlingFeeForGreaterTotal: dealer.handlingFeeForGreaterTotal,
                handlingFeeHideFee: dealer.handlingFeeHideFee === 1
            });
    }, [dealer]);

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateHandlingFee(values);
        },
    });

    const updateHandlingFee = async (shippingData) => {
        try {
            await DealerShipping.putHandlingFee({ ...shippingData, dealerId });
            openSnackbar('success', 'Handling fee updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating the handling fee. Please try again.');
        }
    }

    return {
        formik
    };
}

export default useHandlingFee;