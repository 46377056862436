import { useState, useEffect } from "react";
import useDealerId from "./useDealerId";
import useCrud from "./useCrud";
import useEmail from "./useEmail";
import useAdminUserInfo from "./useAdminUserInfo";
import useSelection from "./useSelection";

const entityName = "dealer_communications";

const useCommunicationHub = (orderNumber = "") => {
    const [selectedFolder, setSelectedFolder] = useState(1);
    const [showCreateMail, setShowCreateMail] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState({
        id: "",
    });
    const [composeEmailFields, setComposeEmailFields] = useState({
        subject: "",
    });
    const dealerId = useDealerId();
    const queryParams = {
        dealer_id: dealerId,
        ...(orderNumber && orderNumber.toString().length > 0 && { order_number: orderNumber }),
    };
    const {
        data: messages = [],
        readAllEntities,
        createEntity,
        updateEntity,
        deleteAllEntity,
    } = useCrud(entityName, queryParams, false);
    const { sendEmailAsync } = useEmail();
    const { adminUserName = "" } = useAdminUserInfo();
    const {
        selected: selectedMessages,
        setSelected,
        selectedAll,
        handleClickSelectAll,
        handleClickSelectItem,
    } = useSelection();
    const { data: order } = useCrud("orders", { dealer_id: dealerId, order_number: orderNumber });
    const { deleteEntity: deleteFolder } = useCrud("multi_table", {}, false);

    useEffect(() => {
        if (dealerId && selectedFolder) fetchMessages();
    }, [dealerId, orderNumber, selectedFolder]);

    const fetchMessages = async () => {
        const params = {
            dealer_id: dealerId,
            is_visible: 1,
            folder_id: selectedFolder,
        };

        if (orderNumber && orderNumber.toString().length > 0) {
            params.order_number = orderNumber;
        }

        await readAllEntities(params);
    };

    /**
     * Open create mail form
     * @returns
     */
    const openCreateMail = () => setShowCreateMail(true);

    /**
     * Close create mail form
     * @returns
     */
    const closeCreateMail = () => {
        setShowCreateMail(false);
    };

    /**
     * Select folder (inbox, send email, draft)
     * @param {*} folder
     */
    const handleClickFolder = (folder) => {
        setSelectedFolder(folder);
        setSelected([]);
    };

    /**
     * Select message to view it
     * @param {*} message
     * @returns
     */
    const handleClickMessage = async (message) => {
        closeCreateMail();
        setSelectedMessage(message);
    };

    const markMessageAsRead = async (messageId) => {
        if (messageId) {
            await updateEntity({ id: messageId, is_read: 1 }, () => {}, false);
        }
    };

    /**
     * By clicking on the "Compose email" option
     */
    const handleClickComposeEmail = () => {
        let toMail = "";

        if (order.length === 1) {
            toMail = order[0].billEmail;
        }

        setComposeEmailFields({
            folderId: 1,
            orderNumber: orderNumber,
            toMail: toMail,
            ccMail: "",
            subject: "",
            in_reply_to_message_id: "",
        });
        openCreateMail();
    };

    /**
     * By clicking on the "Reply" button
     */
    const handleClickReplyEmail = (message) => {
        const subject = message.isDraft === 1 ? message.subject : `RE: ${message.subject}`;
        const messageText = message.isDraft === 1 ? message.message : "";

        setComposeEmailFields({
            id: message.id,
            orderNumber: message.orderNumber,
            toMail: message.fromMail,
            toName: message.fromName,
            ccMail: message.ccMail,
            subject: subject,
            message: messageText,
            inReplyToMessageId: message.id,
        });

        openCreateMail();
    };

    /**
     *
     * @param {*} message
     */
    const sendEmail = async ({
        orderNumber = "",
        toName = "",
        toMail = "",
        ccMail = "",
        subject = "",
        message = "",
        inReplyToMessageId = null,
    }) => {
        const communicationEntity = {
            dealer_id: dealerId,
            order_number: orderNumber,
            from_name: adminUserName,
            from_mail: "noreply@ordernotification.net",
            to_name: toName,
            to_mail: toMail,
            cc_mail: ccMail,
            subject: subject,
            message: message,
            is_from_admin: 1,
            in_reply_to_message_id: inReplyToMessageId,
            folder_id: 2,
        };

        await createEntity(communicationEntity);

        closeCreateMail();

        await sendEmailAsync(
            communicationEntity.to_mail,
            communicationEntity.cc_mail,
            communicationEntity.subject,
            communicationEntity.message
        );
    };

    /**
     *
     * @param {*} id
     */
    const handleClickDeleteItem = async (id) => {
        const body = {
            id,
            is_visible: 0,
        };
        await updateEntity(body, fetchMessages);
    };

    /**
     *
     */
    const handleClickDeleteAll = async () => {
        const transformedMessages = selectedMessages.map((id) => ({
            id,
            is_visible: 0,
        }));

        await deleteAllEntity(transformedMessages, fetchMessages);
    };

    /**
     *
     * @param {*} param0
     */
    const handleCreateDraft = async ({
        id = "",
        orderNumber = "",
        toName = "",
        toMail = "",
        ccMail = "",
        subject = "",
        message = "",
        inReplyToMessageId = null,
    }) => {
        const communicationEntity = {
            dealer_id: dealerId,
            order_number: orderNumber,
            from_name: adminUserName,
            from_mail: "noreply@ordernotification.net",
            to_name: toName,
            to_mail: toMail,
            cc_mail: ccMail,
            subject: subject,
            message: message,
            is_from_admin: 1,
            in_reply_to_message_id: inReplyToMessageId,
            is_draft: 1,
            folder_id: 3,
        };

        if (communicationEntity.in_reply_to_message_id) {
            communicationEntity.id = id;
            await updateEntity(communicationEntity, fetchMessages);
        } else {
            await createEntity(communicationEntity, fetchMessages);
        }

        closeCreateMail();
    };

    const handleMoveMessageToFolder = async (folderId = null) => {
        for (let i = 0; i < selectedMessages.length; i++) {
            const messageId = selectedMessages[i];

            const body = {
                id: messageId,
                folder_id: folderId,
            };

            await updateEntity(body, () => {});
        }

        fetchMessages();
    };

    const handleDeleteFolder = async (
        folderId,
        folderName = "",
        customFolders = [],
        setCustomFolders
    ) => {
        const params = {
            dealer_id: dealerId,
            is_visible: 1,
            folder_id: folderId,
        };

        const messages = await readAllEntities(params);

        if (messages.length > 0) {
            return alert("You must delete all messages from the folder before deleting the folder");
        }

        if (window.confirm(`Are you sure you want to delete the folder "${folderName}"?`)) {
            await deleteFolder(folderId, () => {
                const updatedFolders = customFolders.filter((folder) => folder.id !== folderId);
                setCustomFolders(updatedFolders);
            });
        }
    };

    return {
        messages: messages.sort((a, b) => b.id - a.id),
        selectedFolder,
        setSelectedFolder,
        openCreateMail,
        closeCreateMail,
        handleClickFolder,
        selectedMessage,
        setSelectedMessage,
        handleClickMessage,
        showCreateMail,
        handleClickComposeEmail,
        handleClickReplyEmail,
        composeEmailFields,
        sendEmail,
        selectedMessages,
        selectedAll,
        handleClickSelectAll,
        handleClickSelectItem,
        handleClickDeleteAll,
        handleClickDeleteItem,
        handleCreateDraft,
        handleMoveMessageToFolder,
        markMessageAsRead,
        handleDeleteFolder,
    };
};

export default useCommunicationHub;
