import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';

// @material-ui/core components
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import DealerRedirectService from "services/DealerRedirectService";

export const CREATE_REDIRECT = {
    title: 'Create redirect',
    successMessage: 'Redirect created successfully'
}

export const EDIT_REDIRECT = {
    title: 'Edit redirect',
    successMessage: 'Redirect updated successfully'
}

export default function CreateRedirect({ form = CREATE_REDIRECT, initialValues, onSuccess }) {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            dealerId: initialValues.dealerId || 0,
            sourceUrl: initialValues.sourceUrl || '',
            targetUrl: initialValues.targetUrl || ''
        },
        validationSchema: yup.object({
            sourceUrl: yup
                .string()
                .required('Required'),
            targetUrl: yup
                .string()
                .required('Required')
        }),
        onSubmit: (values) => {
            if (form === CREATE_REDIRECT) createRedirect(values);
            if (form === EDIT_REDIRECT) updateRedirect(values);
        },
    });

    const createRedirect = async body => {
        try {
            const redirect = await DealerRedirectService.post(body);
            onSuccess(redirect);
        } catch (error) {
            console.error(error);
        }
    }

    const updateRedirect = async body => {
        try {
            const redirect = await DealerRedirectService.put(body.id, body);
            onSuccess({
                ...redirect,
                id: body.id
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="sourceUrl"
                                name="sourceUrl"
                                label="Source URL"
                                value={formik.values.sourceUrl}
                                onChange={formik.handleChange}
                                error={formik.touched.sourceUrl && Boolean(formik.errors.sourceUrl)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="targetUrl"
                                name="targetUrl"
                                label="Target URL"
                                value={formik.values.targetUrl}
                                onChange={formik.handleChange}
                                error={formik.touched.targetUrl && Boolean(formik.errors.targetUrl)}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 5 }}>
                            <Button type="submit" color="rose">Save and close</Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </GridItem>
        </GridContainer>
    );
}