import http from "./Http";

const getAll = async () => {
    const { data } = await http.get("/payment-methods");
    return data;
};

const get = async (id) => {
    const { data } = await http.get(`/payment-methods/${id}`);
    return data;
};

const post = async body => {
    const { data } = await http.post("/payment-methods", body);
    return data;
};

const put = async body => {
    const { data } = await http.put(`/payment-methods/${body.id}`, body);
    return data;
};

const del = async id => {
    return http.delete(`/payment-methods/${id}`);
};

const PaymentMethodService = {
    getAll,
    get,
    post,
    put,
    del
};

export default PaymentMethodService;
