import React from "react";
import Table from "components/ReactTable/ReactTableV2";
import useOrder from "hooks/useOrder";

export default function OrderTable() {
    const { order = [] } = useOrder();

    const columns = [
        {
            Header: "Order number",
            accessor: "orderNumber"
        },
        {
            Header: "Name",
            accessor: "billCompany",
        },
    ];

    return (
        <Table
            columns={columns}
            data={order.data}
            fetchData={() => { }}
            pageCount={1}
        />
    )
}
