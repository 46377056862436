import React, { useState, useEffect } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// material
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';

// services
import ProductService from "services/ProductService";

const LIMIT = 10;

export default function Searchkeyword({ dealer = { catalogs: [] }, onClick = () => { } }) {
    const [search, setSearch] = useState({
        value: '',
        isLoading: false
    });
    const [products, setProducts] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [skip, setSkip] = useState(0);

    const getProducts = async ({ dlid = '', skip = '', limit = LIMIT, keyword = '', catalog = '' }) => {
        const products = await ProductService.getSuggestions(dlid, skip, limit, {
            keyword,
            catalog,
            locale: "en",
            allMakes: true
        })
        return products;
    }

    const handleChange = (e) => {
        setSearch({ ...search, value: e.target.value });
    };

    const handleClickSearch = async ({ currentTarget, oldProducts = [], skip }) => {
        setSearch({ ...search, isLoading: true });
        setSkip(skip);

        if (currentTarget) setAnchorEl(currentTarget);

        for (let i = 0; i < dealer.catalogs.length; i++) {
            const catalog = dealer.catalogs[i];
            const newProducts = await getProducts({
                dlid: dealer.id,
                skip,
                keyword: search.value,
                catalog: catalog.catalogName
            });

            setProducts([
                ...oldProducts,
                ...newProducts.map(p => ({ ...p, catalogId: catalog.id }))
            ]);
        }

        setSearch(prevState => ({ ...prevState, isLoading: false }));
    };

    const handleClickProduct = (product) => {
        setAnchorEl(null);
        onClick(product);
    }

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <FormControl aria-describedby={id} fullWidth>
                        <InputLabel>Keyword or part number</InputLabel>
                        <Input
                            value={search.value}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        disabled={search.value.length <= 3}
                                        onClick={(e) => {
                                            handleClickSearch({
                                                currentTarget: e.currentTarget,
                                                oldProducts: [],
                                                skip: 0
                                            });
                                        }}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {search.isLoading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={() => {
                            setAnchorEl(null);
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        PaperProps={{
                            style: {
                                width: 500,
                                height: 300
                            },
                        }}
                    >
                        {
                            products.map((product, index) => {
                                const [make, year, model, trim, engine, category, subcategory] = product.slugs;

                                product = {
                                    ...product,
                                    path_image: product.path_image ? `${dealer.imagesBaseUrl}${product.path_image}` : '/not-available.png'
                                };

                                return (
                                    <Box
                                        key={index}
                                        pl={2}
                                        mt={1}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => handleClickProduct(product)}
                                    >
                                        <Box style={{ width: 150, textAlign: 'center' }}>
                                            <img
                                                src={product.path_image}
                                                height={50}
                                            />
                                        </Box>
                                        <Box>
                                            <Box sx={{ flex: '1 1 auto' }}>
                                                <Box>
                                                    <Typography variant="caption">
                                                        <b>{product.name}</b>
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="caption">
                                                        Part Number: {product.part_number}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="caption">
                                                        {year.name} {make.name} {model.name}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="caption" fontWeight="bold">
                                                        ${product.price.price}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })
                        }
                        <Box style={{ textAlign: 'center' }}>
                            <Button
                                color="primary"
                                onClick={(e) => {
                                    const newSkip = skip + LIMIT;
                                    handleClickSearch({
                                        oldProducts: products,
                                        skip: newSkip
                                    });
                                }}
                            >
                                Show more
                            </Button>
                        </Box>
                    </Popover>
                </GridItem>
            </GridContainer>
        </>
    )
}