import { useState, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from './useSnackbar';
import EmailService from 'services/EmailService';

const useNewMessageForm = () => {
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState();
    const openSnackbar = useSnackbar();
    const initialValues = {
        recipients: [],
        subject: '',
        messageBody: '',
        attachment: null
    };

    const validationSchema = Yup.object().shape({
        recipients: Yup.array().min(1, 'You must select at least one recipient.'),
        subject: Yup.string().required(),
        messageBody: Yup.string().required()
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            submitRequest(values);
        },
    });

    const submitRequest = async (values) => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append('recipient', values.recipients);
            formData.append('subject', values.subject);
            formData.append('messageBody', values.messageBody);
            formData.append('attachment', values.attachment);
            const result = await EmailService.post(formData);

            openSnackbar('success', result.data.status);
            resetForm();
            setIsLoading(false);
        } catch (error) {
            openSnackbar('error', error.response.data.status);
        }
    };

    const resetForm = () => {
        formik.resetForm();

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    }

    const addRecipient = (recipient) => {
        formik.setFieldValue('recipients', [...formik.values.recipients, recipient]);
    };


    const removeRecipient = (index) => {
        const updatedRecipients = [...formik.values.recipients];
        updatedRecipients.splice(index, 1);
        formik.setFieldValue('recipients', updatedRecipients);
    };

    const handleRecipientExists = (recipient) => {
        if (formik.values.recipients.includes(recipient)) {
            const index = formik.values.recipients.indexOf(recipient);
            removeRecipient(index);
        } else {
            addRecipient(recipient);
        }
    }

    return {
        isLoading,
        formik,
        recipientCount: formik.values.recipients.length,
        handleRecipientExists,
        fileInputRef
    };
}

export default useNewMessageForm;