import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";
import useFormik from "hooks/useFormik";
import Box from "components/Box/Box";

const fields = [
    {
        fieldName: "en",
        required: true,
    },
    {
        fieldName: "es",
        required: true,
    },
    {
        fieldName: "pt",
        required: true,
    },
    {
        fieldName: "fr",
        required: true,
    },
];

export default function CreateTranslate({
    initialValues,
    createEntity = () => {},
    updateEntity = () => {},
    onSuccess = () => {},
}) {
    const handleInsert = (values) => {
        createEntity(values);
        onSuccess();
    };

    const handleUpdate = (values) => {
        updateEntity(values);
        onSuccess();
    };

    const formik = useFormik(fields, initialValues, handleInsert, handleUpdate);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box mt={1.5}>
                <TextFieldRender
                    fullWidth
                    variant="outlined"
                    label="EN"
                    value={formik.values.en}
                    error={formik.touched.en && Boolean(formik.errors.en)}
                    handleBlur={(value) => formik.setFieldValue("en", value)}
                />
            </Box>
            <Box mt={1.5}>
                <TextFieldRender
                    fullWidth
                    variant="outlined"
                    label="ES"
                    value={formik.values.es}
                    error={formik.touched.es && Boolean(formik.errors.es)}
                    handleBlur={(value) => formik.setFieldValue("es", value)}
                />
            </Box>
            <Box mt={1.5}>
                <TextFieldRender
                    fullWidth
                    variant="outlined"
                    label="PT"
                    value={formik.values.pt}
                    error={formik.touched.pt && Boolean(formik.errors.pt)}
                    handleBlur={(value) => formik.setFieldValue("pt", value)}
                />
            </Box>
            <Box mt={1.5}>
                <TextFieldRender
                    fullWidth
                    variant="outlined"
                    label="FR"
                    value={formik.values.fr}
                    error={formik.touched.fr && Boolean(formik.errors.fr)}
                    handleBlur={(value) => formik.setFieldValue("fr", value)}
                />
            </Box>
            <Box>
                <Button type="submit" color="rose" fullWidth>
                    Save and close
                </Button>
            </Box>
        </form>
    );
}
