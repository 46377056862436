import { useState } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Box,
    Button,
    Typography,
    InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import { useFormik } from "formik";
import * as yup from "yup";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";

const emptyMessage = {
    fromMail: "",
    ccMail: "",
    subject: "",
    message: "",
};

export default function CreateMessage({
    message = emptyMessage,
    onSendMail = () => {},
    onCloseMail = () => {},
}) {
    const [showCcField, setShowCcField] = useState(false);

    const validationSchema = yup.object({
        toMail: yup.string().required(),
        subject: yup.string().required(),
        message: yup.string().required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: message,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            onSendMail(values);
        },
    });

    const handleToggleCcField = () => setShowCcField(!showCcField);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Card variant="outlined">
                <CardHeader
                    title={<Typography color="textSecondary">{formik.values.subject}</Typography>}
                    style={{ paddingBottom: 0 }}
                    action={
                        <IconButton onClick={() => onCloseMail(formik.values)}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <CardContent style={{ paddingTop: 0 }}>
                    <Box mb={2}>
                        <TextFieldRender
                            fullWidth
                            size="small"
                            placeholder="To"
                            value={formik.values.toMail}
                            handleBlur={(value) => formik.setFieldValue("toMail", value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">To:</InputAdornment>
                                ),
                                /*endAdornment: (
                                    <Button color="primary" onClick={handleToggleCcField}>
                                        CC
                                    </Button>
                                ),*/
                            }}
                            error={formik.touched.toMail && Boolean(formik.errors.toMail)}
                        />
                    </Box>
                    {showCcField && (
                        <Box mb={2}>
                            <TextFieldRender
                                fullWidth
                                size="small"
                                placeholder="CC"
                                value={formik.values.ccMail}
                                handleBlur={(value) => formik.setFieldValue("ccMail", value)}
                            />
                        </Box>
                    )}
                    <Box mb={2}>
                        <TextFieldRender
                            fullWidth
                            size="small"
                            placeholder="Subject"
                            value={formik.values.subject}
                            handleBlur={(value) => formik.setFieldValue("subject", value)}
                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                        />
                    </Box>
                    <TextFieldRender
                        fullWidth
                        multiline
                        rows={20}
                        placeholder="Body"
                        value={formik.values.message}
                        handleBlur={(value) => formik.setFieldValue("message", value)}
                        error={formik.touched.message && Boolean(formik.errors.message)}
                    />
                    <Box mt={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            endIcon={<SendIcon />}
                        >
                            Send
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </form>
    );
}
