import { Box, Paper, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomerForm from "components/CustomerForm/CustomerForm";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableWrapper from "components/TableWrapper/TableWrapper";
import CheckboxMUI from "components/CheckboxMUI/CheckboxMUI";
import GenericSelect from "components/GenericSelect/GenericSelect";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import { financial } from "helpers";
import { useEffect, useState } from "react";

const StyledCustomerForm = withStyles((theme) => ({
    root: {
        "& .MuiGrid-root .MuiGrid-root:nth-child(n+6)": {
            display: "none",
        },
        "& .MuiGrid-root .MuiFormControlLabel-root": {
            display: "none",
        },
    },
}))(CustomerForm);

export default function RmaHeader({
    formik,
    orderDetail,
    rmaCommunication,
    customerCommunication = [],
    rma,
}) {
    const {
        rmaStatusOptions,
        templates,
        doNotsendNotification,
        approvalNeeded,
        rmaStatus,
        template,
        message,
        handleDoNotSendNotificationChange,
        handleApprovalNeededChange,
        handleRmaStatusChange,
        handleTemplateChange,
        handleMessageChange,
        handleClickReturnReceipt,
    } = rmaCommunication;

    const [rmaPartStatus, setPartStatus] = useState(rma?.rmaPartStatus || "");

    useEffect(() => {
        if (rma?.rmaPartStatus) {
            setPartStatus(rma?.rmaPartStatus);
        }
    }, [rma?.rmaPartStatus]);

    const columns = [
        {
            Header: "Part Number(s)",
            accessor: "partNumber",
        },
        {
            Header: "Description",
            accessor: "description",
        },
        {
            Header: "Price",
            Cell: ({ row }) => {
                return `$${financial(row.original.price)}`;
            },
        },
        {
            Header: "Ordered Qty",
            accessor: "qty",
        },
        {
            Header: "Qty Shipped",
            accessor: "shipQty",
        },
        {
            Header: "Sales tax amount",
            Cell: ({ row }) => {
                return `$${financial(row.original.salesTaxAmount)}`;
            },
        },
        {
            Header: "Subtotal",
            Cell: ({ row }) => {
                const { rmaCarrierInfo, rmaTrackingNumber, rmaShippedDate } = rma;
                const hasRmaDetails = rmaCarrierInfo && rmaTrackingNumber && rmaShippedDate;

                return (
                    <Box textAlign="center">
                        <Typography style={{ fontSize: 14 }}>
                            ${financial(row.original.price + row.original.salesTaxAmount)}
                        </Typography>
                        {rmaStatus === "Approved" && hasRmaDetails && (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    handleClickReturnReceipt(
                                        row.original.rmaId,
                                        setPartStatus,
                                        rma.type,
                                        rma
                                    );
                                }}
                            >
                                {rma.type} Receipt
                            </Button>
                        )}
                        {rmaStatus === "Approved" && !hasRmaDetails && (
                            <Button variant="outlined" size="small" disabled>
                                Awaiting Carrier Info
                            </Button>
                        )}
                    </Box>
                );
            },
        },
    ];

    if (orderDetail.some((item) => item.corePrice)) {
        columns.splice(3, 0, {
            Header: "Core Value",
            Cell: ({ row }) => {
                return row.original.corePrice ? `$${financial(row.original.corePrice)}` : "-";
            },
        });
    }

    const columns2 = [
        {
            Header: "From",
            accessor: "fromName",
        },
        {
            Header: "Message",
            accessor: "message",
            Cell: ({ cell }) => {
                return (
                    <div
                        style={{
                            whiteSpace: "pre-line",
                            wordBreak: "break-word",
                        }}
                        dangerouslySetInnerHTML={{ __html: cell.value }}
                    />
                );
            },
        },
        {
            Header: "Date",
            accessor: "createdAtParsed",
        },
    ];

    const isReceived = rmaPartStatus === "Received";
    const filteredOptions = isReceived
        ? rmaStatusOptions
        : rmaStatusOptions.filter((option) => option.value !== "Refunded");

    return (
        <Box>
            <Box my={3}>
                <StyledCustomerForm formik={formik} rmaNumber={rma?.rmaNumber} />
            </Box>
            <Box my={3}>
                <Typography variant="subtitle2" gutterBottom>
                    PRODUCT INFORMATION
                </Typography>
                <TableWrapper columns={columns} data={orderDetail} />
            </Box>
            <Box my={3}>
                <Typography variant="subtitle2" gutterBottom>
                    CUSTOMER COMMUNICATION
                </Typography>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <CheckboxMUI
                            label="Do not send notification email to customer"
                            checked={doNotsendNotification}
                            onChange={handleDoNotSendNotificationChange}
                        />
                        <CheckboxMUI
                            label={`Add "Your approval is needed" in customer email`}
                            checked={approvalNeeded}
                            onChange={handleApprovalNeededChange}
                        />
                        <GenericSelect
                            fullWidth
                            label="Update Rma Status"
                            size="small"
                            value={rmaStatus}
                            onChange={handleRmaStatusChange}
                            options={filteredOptions}
                            mt={10}
                            showNone={false}
                        />
                        <GenericSelect
                            fullWidth
                            label="Select message template"
                            valueProp="id"
                            labelProp="name"
                            size="small"
                            value={template}
                            onChange={handleTemplateChange}
                            options={templates}
                            mt={10}
                            mb={10}
                        />
                        <TextFieldRender
                            fullWidth
                            variant="outlined"
                            label="Write a message"
                            multiline
                            rows={10}
                            style={{ marginTop: 20 }}
                            value={message}
                            onChange={handleMessageChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                        <Paper elevation={3}>
                            <TableWrapper columns={columns2} data={customerCommunication} />
                        </Paper>
                    </GridItem>
                </GridContainer>
            </Box>
        </Box>
    );
}
