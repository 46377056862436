import { useState } from "react";
import EmailService from "services/EmailService";
import { useSnackbar } from "./useSnackbar";

const useEmail = () => {
    const [isLoading, setIsLoading] = useState();
    const openSnackbar = useSnackbar();

    const sendEmailAsync = async (
        recipients = "",
        cc = "",
        subject = "",
        messageBody = "",
        attachment = ""
    ) => {
        setIsLoading(true);

        try {
            const formData = new FormData();
            formData.append("recipient", recipients);
            formData.append("subject", subject);
            formData.append("messageBody", messageBody);

            if (cc) {
                formData.append("cc", cc);
            }

            if (attachment) {
                formData.append("attachment", attachment);
            }

            const result = await EmailService.post(formData);
            openSnackbar("success", result.data.status);
            setIsLoading(false);
        } catch (error) {
            openSnackbar("error", error.response.data.status);
            setIsLoading(true);
        }
    };

    return {
        isLoading,
        sendEmailAsync,
    };
};

export default useEmail;
