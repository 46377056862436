import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const BasicDialog = ({ open = false, onClose, title = "", children, ...otherProps }) => {
    return (
        <Dialog open={open} onClose={onClose} {...otherProps}>
            {/*<DialogTitle id="basic-dialog-title">{title}</DialogTitle>*/}
            <DialogContent>
                {children}
                {/*<Button onClick={onClose} color="primary">
                    Cerrar
                </Button>*/}
            </DialogContent>
        </Dialog>
    );
};

export default BasicDialog;
