import http from "./Http";

const getAll = async (entity, queryString = "") => {
    return http.get(`/entities/${entity}?${queryString}`);
};

const post = async (entity, body) => {
    return http.post(`/entities/${entity}`, body);
};

const put = async (entity, body) => {
    return http.put(`/entities/${entity}/${body.id}`, body);
};

const del = async (entity, id) => {
    return http.delete(`/entities/${entity}/${id}`);
};

const ApiService = {
    getAll,
    post,
    put,
    del,
};

export default ApiService;
