import React, { useState, useEffect } from "react";

// @material-ui/icons
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SaveIcon from '@material-ui/icons/Save';

// core components
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import FormLabel from "@material-ui/core/FormLabel";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import Link from '@material-ui/core/Link';

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

export default function GoogleShopFeed() {
    const classes = useStyles();

    const columns = [
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "Message",
            accessor: "message",
        },
    ];

    return (
        <>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <p>
                                Automate your Google Shopping Feed by entering your FTP credentials below. Please allow up to 24 hours for Google to start displaying your catalogs content in your Google Merchant Center.
                            </p>
                            <div style={{ marginLeft: '30%', marginRight: '30%' }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                                            Enable Automated Feed:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <Switch
                                            id="vinToOrder"
                                            name="vinToOrder"
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                                            FTP Server Name:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            margin="dense"
                                            id="headerTitle"
                                            name="headerTitle"
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                                            FTP Username:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            margin="dense"
                                            id="headerTitle"
                                            name="headerTitle"
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                                            FTP Password:
                                        </FormLabel>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            margin="dense"
                                            id="headerTitle"
                                            name="headerTitle"
                                        />
                                    </GridItem>
                                </GridContainer>

                                <div style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>
                                    <Link href="javascript: void(0)">
                                        Click here to download sample .txt file
                                    </Link>
                                </div>

                                <div style={{ textAlign: 'center' }}>
                                    <Button color="success" size="sm" variant="contained" startIcon={<InsertDriveFileIcon />}>
                                        Generate
                                    </Button>
                                    <Button color="success" size="sm" variant="contained" startIcon={<SaveIcon />}>
                                        Save
                                    </Button>
                                </div>
                            </div>

                            <Typography variant="subtitle2" gutterBottom>
                                Google Shopping Feed Log
                            </Typography>

                            <div style={{ width: 200 }}>
                                <ReactTable
                                    columns={columns}
                                    data={[]}
                                    showPagination={false}
                                    showSorting={false}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}