import { useState } from "react";
import useCrud from "./useCrud";
import RmaService from "services/RmaService";
import useEmail from "./useEmail";
import useDealer from "./useDealer";
import { financial } from "helpers";

const rmaStatusOptions = [
    {
        value: "Pending",
        label: "Pending",
    },
    {
        value: "Approved",
        label: "Approved",
    },
    {
        value: "Denied",
        label: "Denied",
    },
    {
        value: "Refunded",
        label: "Refunded",
    },
];

const useRmaCommunication = () => {
    const { data: templates = [] } = useCrud("templates", { type: "rma" });
    const { createEntity, readAllEntities } = useCrud("dealer_communications", {}, false);
    const { updateEntity } = useCrud("rma", {}, false);
    const { readAllEntities: fetchOrder } = useCrud("orders", {}, false);
    const [doNotsendNotification, setDoNotSendNotification] = useState(false);
    const [approvalNeeded, setApprovalNeeded] = useState(false);
    const [rmaStatus, setRmaStatus] = useState("");
    const [template, setTemplate] = useState("");
    const [message, setMessage] = useState("");
    const { sendEmailAsync } = useEmail();
    const dealer = useDealer();

    const handleDoNotSendNotificationChange = (event) => {
        setDoNotSendNotification(event.target.checked);
    };

    const handleApprovalNeededChange = (event) => {
        setApprovalNeeded(event.target.checked);
    };

    const handleRmaStatusChange = (event) => {
        setRmaStatus(event.target.value);
    };

    const handleTemplateChange = (event) => {
        setTemplate(event.target.value);

        const template = templates.find((t) => t.id === event.target.value);

        if (template) {
            setMessage(template.description);
        }
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleClickReturnReceipt = async (rmaId, setPartStatus, rmaType = "Return", rma) => {
        try {
            const res = await RmaService.updateReceipt(rmaId);
            setPartStatus("Received");

            if (rmaType == "Return") {
                alert("RMA accepted and ready to be refunded");
            } else {
                alert("Core accepted and ready to be refunded");
            }

            insertNotification(rma);
        } catch (error) {}
    };

    const insertNotification = async (rma) => {
        try {
            const [order] = await fetchOrder({ dealer_id: rma.dealerId, order_number: 246 });
            let subject = `${rma.type} Update for Order ${rma.orderNumber}`;
            let body = `
            <p>RMA/${rma.type} Your Refund has been Processed</p>
            <p>Request Update for Order ${rma.orderNumber}</p>
            <p>Dear ${order.billCompany},</p>
            <p>We hope this message finds you well.</p>
            <p>
            We are writing to inform you that your refund request for Order ${
                rma.orderNumber
            } has been successfully processed. 
            The amount of $${financial(
                rma.price
            )} has been credited back to your original payment method.
            </p>
            <p>Please allow 5-7 business days for the refund to reflect in your account. If you have any questions or need further assistance, please don't hesitate to contact our support team.</p>
            <p>Thank you for your understanding.</p>
            <p>Best regards,</p>
            <p>Customer Service Team</p>`;

            const communicationEntity = {
                dealer_id: rma.dealerId,
                order_number: rma.orderNumber,
                from_name: dealer.company,
                from_mail: dealer.notificationEmail,
                to_name: order.billCompany,
                to_mail: order.billEmail,
                subject: subject,
                message: body,
                folder_id: 1,
                rma_id: rma.id,
                rma_order_number: rma.rmaNumber,
            };

            await createEntity(communicationEntity, () => {}, false);
            await sendEmailAsync(order.billEmail, "", subject, body, "");
        } catch (error) {
            console.error("Error registering notification", error);
        }
    };

    return {
        rmaStatusOptions,
        templates,
        doNotsendNotification,
        approvalNeeded,
        rmaStatus,
        setRmaStatus,
        template,
        message,
        handleDoNotSendNotificationChange,
        handleApprovalNeededChange,
        handleRmaStatusChange,
        handleTemplateChange,
        handleMessageChange,
        handleClickReturnReceipt,
    };
};

export default useRmaCommunication;
