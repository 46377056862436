import React, { useState } from "react";
import PageLayout from "layouts/PageLayout";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import { Grid, Paper, TextField, Button, Checkbox, Typography, Link, Box } from "@material-ui/core";
import ReactTable from "components/ReactTable/ReactTable";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import DeleteButton from "components/DeleteButton/DeleteButton";
import useEditPartForm from "hooks/useEditPartForm";
import EditButton from "components/EditButton/EditButton";
import { format } from "date-fns";

const useStyles = makeStyles(styles);

export default function EditPart() {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const [selected, setSelected] = useState([]);
    const {
        handleClickSearch,
        handleClickEdit,
        handleClickDelete,
        partNumber,
        setPartNumber,
        product,
        parts,
    } = useEditPartForm();

    const columns = [
        {
            Header: () => (
                <div
                    style={{
                        textAlign: "center",
                        fontSize: 15,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography style={{ fontSize: 16 }}>Select All</Typography>
                    <Checkbox
                        tabIndex={-1}
                        checked={checked}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (checked) {
                                setSelected([]);
                            } else {
                                setSelected(parts.map((o) => o.id));
                            }
                            setChecked(!checked);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                        }}
                    />
                </div>
            ),
            accessor: "isChecked",
            Cell: ({ row: { original } }) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <Checkbox
                            tabIndex={-1}
                            checked={selected.includes(original.id)}
                            onClick={(e) => {
                                if (selected.includes(original.id)) {
                                    setSelected(selected.filter((s) => s !== original.id));
                                } else {
                                    setSelected([...selected, original.id]);
                                }
                            }}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: "Item/Part",
            accessor: "title",
        },
        {
            Header: "Part Number",
            accessor: "partNumber",
        },
        {
            Header: "Selling Price",
            Cell: ({ row }) => {
                return `$${parseFloat(row.original.sellingPrice).toFixed(2)}`;
            },
        },
        /*{
            Header: "Labor Price",
            Cell: ({ row }) => {
                return "";
            },
        },
        {
            Header: "Labor Time",
            Cell: ({ row }) => {
                return "";
            },
        },*/
        {
            Header: "Date",
            accessor: "updatedAt",
            Cell: ({ value }) => {
                // Formatear la fecha
                return format(new Date(value), "MM/dd/yyyy HH:mm");
            },
        },
        {
            Header: () => <div style={{ textAlign: "center" }}></div>,
            accessor: "actions",
            Cell: ({ row: { original } }) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <EditButton handleClick={() => handleClickEdit(original)} />
                    </div>
                );
            },
        },
    ];

    return (
        <PageLayout>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper style={{ padding: 15 }}>
                            <Typography variant="h6" style={{ fontSize: 15 }}>
                                Search by Part Number
                            </Typography>
                            <TextField
                                label="Part Number"
                                variant="outlined"
                                value={partNumber}
                                fullWidth
                                margin="normal"
                                onChange={(e) => setPartNumber(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickSearch}
                                disabled={partNumber.length === 0}
                            >
                                Search
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper style={{ padding: 15 }}>
                            <Typography variant="h6" style={{ fontSize: 15 }}>
                                #Part Num.
                            </Typography>
                            {Object.keys(product).length > 0 && (
                                <>
                                    <Typography>{product.part_number}</Typography>
                                    <Typography>{product.name}</Typography>
                                    <Link href="#" onClick={() => handleClickEdit(product)}>
                                        Edit
                                    </Link>
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                <Box display="flex" mt={2} mb={2}>
                    <Typography variant="h6" style={{ fontSize: 15 }}>
                        Existing Rules
                    </Typography>
                    <DeleteButton
                        buttonWithIcon
                        size="small"
                        style={{ marginLeft: "auto" }}
                        handleClick={() => handleClickDelete(selected)}
                    />
                </Box>
                <ReactTable
                    columns={columns}
                    data={parts}
                    fetchData={() => {}}
                    manualPagination={false}
                    showSorting={false}
                    showPagination={false}
                />
            </div>
        </PageLayout>
    );
}
