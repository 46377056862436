import React, { useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "normal",
        borderBottom: "none !important",
    },
    body: {
        fontSize: 14,
        borderBottom: "none !important",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const newStyles = {
    ...styles,
    formControlMargins: {
        margin: "3px 0 !important",
    },
    gridContainer: {
        justifyContent: "center",
    },
};

const useStyles = makeStyles(newStyles);

function Tabla({ columns, data, onSort, fetchData, loading, pageCount: controlledPageCount }) {
    const classes = useStyles();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: controlledPageCount,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (fetchData) {
            fetchData({ pageIndex: pageIndex + 1, pageSize });
        }
    }, [pageIndex, pageSize]);

    return (
        <div className="ReactTable -striped -highlight">
            <Table {...getTableProps()}>
                <TableHead>
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <StyledTableCell
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    {column.render("Header")}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <StyledTableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <StyledTableCell {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </StyledTableCell>
                                    );
                                })}
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>

            {pageOptions.length > 1 && (
                <div className="pagination-top">
                    <div className="-pagination">
                        <div className="-previous">
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                className="-btn"
                            >
                                Previous
                            </button>
                        </div>
                        <div className="-center">
                            <GridContainer className={classes.gridContainer}>
                                <GridItem xs={12} sm={6} md={4}>
                                    <FormControl
                                        fullWidth
                                        className={
                                            classes.selectFormControl +
                                            " " +
                                            classes.formControlMargins
                                        }
                                    >
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={pageSize}
                                            onChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                            inputProps={{
                                                name: "numberOfRows",
                                                id: "number-of-rows",
                                            }}
                                        >
                                            {[10, 20, 30, 40, 50].map((prop) => {
                                                return (
                                                    <MenuItem
                                                        key={prop}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected:
                                                                classes.selectMenuItemSelected,
                                                        }}
                                                        value={prop}
                                                    >
                                                        {prop} rows
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className="-next">
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                className="-btn"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Tabla;
