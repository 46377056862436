// core components
import ReactTableV2 from "components/ReactTable/ReactTableV2";

// material
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";

export default function QuoteLog({ formik }) {
    const columns = [
        {
            Header: "Created at",
            accessor: "createdAt",
        },
        /*{
            Header: "Quote number",
            accessor: "orderNumber",
        },*/
        {
            Header: "User",
            accessor: "user",
        },
        {
            Header: "Action",
            Cell: ({ row: { original } }) => {
                try {
                    const memo = JSON.parse(original?.memo);

                    return (
                        <Typography>
                            {original.action === "STATUS_CHANGE" && "prevOrderStatus" in memo
                                ? `Status Changed From ${memo.prevOrderStatus} to ${memo.nextOrderStatus}`
                                : original.action}
                        </Typography>
                    );
                } catch (error) {
                    return "";
                }
            },
        },
        /*{
            Header: "Memo",
            Cell: ({ row: { original } }) => {
                return (
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        defaultValue={original.memo}
                        variant="outlined"
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                );
            },
        },*/
    ];

    return (
        <>
            <ReactTableV2 data={formik.values.log || []} columns={columns} rowKey="id" />
        </>
    );
}
