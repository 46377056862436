import { useState } from 'react';
import PriceIncorrectForm from 'views/RequestForm/PriceIncorrectForm';
import DiscontinuedForm from 'views/RequestForm/DiscontinuedForm';
import OldSupersededForm from 'views/RequestForm/OldSupersededForm';
import ReportOtherIssuesForm from 'views/RequestForm/ReportOtherIssuesForm';
import { useSnackbar } from './useSnackbar';
import RequestService from 'services/RequestService';

const useRequestForm = (onSuccess = () => { }) => {
    const openSnackbar = useSnackbar();
    const [selectedForm, setSelectedForm] = useState({
        title: "Submit request",
        value: "",
        component: null
    });

    const resetSelectedForm = () => {
        setSelectedForm({
            title: "Submit request",
            value: "",
            component: null
        });
    }

    const getSelectedForm = (formValue = '') => {
        switch (formValue) {
            case 'price-incorrect':
                return { title: 'Price Incorrect - Part #', component: <PriceIncorrectForm /> };
            case 'discontinued':
                return { title: 'Discontinued - Part', component: <DiscontinuedForm /> };
            case 'old-superseded':
                return { title: 'Old/Superseded - Part #', component: <OldSupersededForm /> };
            case 'report-other-issues':
                return { title: 'Report Other Issue', component: <ReportOtherIssuesForm /> };
            default:
                return null;
        }
    };

    const handleRadioChange = (e) => {
        const formValue = e.target.value;
        const { title, component } = getSelectedForm(formValue);

        setSelectedForm({
            title,
            value: formValue,
            component
        });
    };

    const submitRequest = async (values) => {
        try {
            const result = await RequestService.post(values);
            openSnackbar('success', result.status);
            onSuccess();
        } catch (error) {
            openSnackbar('error', error.response.data.message);
        }
    };

    const onApprove = async (body) => {
        try {
            const result = await RequestService.approveRequest(body.id, body);
            openSnackbar('success', result.status);
            onSuccess();
        } catch (error) {
            openSnackbar('error', error.response.data.message);
        }
    }

    const onDecline = async (id) => {
        try {
            const result = await RequestService.declineRequest(id);
            openSnackbar('success', result.status);
            onSuccess();
        } catch (error) {
            openSnackbar('error', error.response.data.message);
        }
    }

    const onMarkAsOpen = async (id) => {
        try {
            const result = await RequestService.markAsOpen(id);
            openSnackbar('success', result.status);
            onSuccess();
        } catch (error) {
            openSnackbar('error', error.response.data.message);
        }
    }

    return {
        selectedForm,
        handleRadioChange,
        submitRequest,
        resetSelectedForm,
        onApprove,
        onDecline,
        onMarkAsOpen
    };
}

export default useRequestForm;