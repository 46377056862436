import { useState, useMemo } from 'react';

function usePagination(initialPage = 1, initialPageSize = 10, totalRecords = 0) {
    const [pagination, setPagination] = useState({
        page: initialPage,
        pageSize: initialPageSize,
    });

    const pageCount = useMemo(() => Math.ceil(totalRecords / pagination.pageSize), [
        totalRecords,
        pagination.pageSize,
    ]);

    const changePagination = (newPage, newPageSize) => {
        setPagination({ page: newPage, pageSize: newPageSize });
    };

    return {
        pagination,
        changePagination,
        pageCount,
    };
}

export default usePagination;
