import { useEffect, useState } from 'react';
import LogService from 'services/LogService';
import usePagination from './usePagination';
import useDealer from "./useDealer";

const useActivityLog = (startDate = '', endDate = '') => {
    const { id: dealerId } = useDealer();
    const [activityLog, setActivityLog] = useState({
        totalRecords: 0,
        logs: [],
    });
    const { pagination, changePagination, pageCount } = usePagination(1, 10, activityLog.totalRecords);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await LogService.get(
                    dealerId,
                    startDate,
                    endDate,
                    pagination.page,
                    pagination.pageSize
                );
                setActivityLog(response.data);
            } catch (err) {
            }
        };

        fetchData();
    }, [dealerId, startDate, endDate, pagination.page, pagination.pageSize]);

    return {
        activityLog,
        setActivityLog,
        pagination,
        changePagination,
        pageCount
    };
};

export default useActivityLog;
