import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import PageLayout from "layouts/PageLayout";
import ImageUpload from "components/ImageUpload/ImageUpload";
import Table from "components/ReactTable/ReactTableV2";
import SelectAllCheckbox from "components/SelectAllCheckbox/SelectAllCheckbox";
import RowCheckbox from "components/RowCheckbox/RowCheckbox";
import useImageLibrary from "hooks/useImageLibrary";
import DeleteButton from "components/DeleteButton/DeleteButton";

export default function ImageLibrary() {
    const [checked, setChecked] = useState(false);
    const [selected, setSelected] = useState([]);
    const {
        images = [],
        getAllImages,
        createImage,
        deleteImage,
        deleteMultipleImages,
    } = useImageLibrary();

    const handleDeleteImage = async (id) => {
        await deleteImage(id);
    };

    const columns = [
        {
            Header: () => (
                <SelectAllCheckbox
                    checked={checked}
                    setChecked={setChecked}
                    selected={selected}
                    setSelected={setSelected}
                    items={images}
                />
            ),
            accessor: "isChecked",
            Cell: ({ row: { original } }) => (
                <RowCheckbox original={original} selected={selected} setSelected={setSelected} />
            ),
            getTdProps: () => ({
                style: {
                    width: "50px",
                    padding: "0",
                },
            }),
        },
        {
            Header: "Image",
            accessor: "file_name",
            Cell: ({ row }) => (
                <a
                    href={`${row.original.filePath}?t=${new Date().getTime()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src={`${row.original.filePath}?t=${new Date().getTime()}`}
                        alt={row.original.fileName}
                        width="100"
                    />
                </a>
            ),
        },
        {
            Header: "Url",
            accessor: "filePath",
            Cell: ({ row }) => (
                <a
                    href={`${row.original.filePath}?t=${new Date().getTime()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {`${row.original.filePath}?t=${new Date().getTime()}`}
                </a>
            ),
        },
        {
            Header: "Action",
            accessor: "actions",
            Cell: ({ row }) => (
                <DeleteButton handleClick={() => handleDeleteImage(row.original.id)} />
            ),
        },
    ];

    const handleSaveImages = async (formData) => {
        await createImage(formData);
    };

    return (
        <PageLayout>
            <ImageUpload
                onSave={handleSaveImages}
                existingImages={images.map((img) => img.fileName)}
            />
            <Paper style={{ marginLeft: 14, marginRight: 14, padding: 5 }}>
                <Box display="flex">
                    <Typography variant="h6">Manage Image Box</Typography>
                    <DeleteButton
                        disabled={selected.length === 0}
                        buttonWithIcon
                        style={{ marginLeft: "auto" }}
                        handleClick={async () => {
                            await deleteMultipleImages(selected);
                            setSelected([]);
                        }}
                    />
                </Box>
                <Box style={{ marginTop: 5 }}>
                    <Table columns={columns} data={images} fetchData={() => {}} pageCount={1} />
                </Box>
            </Paper>
        </PageLayout>
    );
}
