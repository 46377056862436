import http from "./Http";

const getAll = async ({ dlid = '', cid = '', fromDate = '', toDate = '', statusId = '', search = '' }) => {
    return http.get(`/dashboard?dlid=${dlid}&cid=${cid}&fromDate=${fromDate}&toDate=${toDate}&statusId=${statusId}`);
};

const CustomerService = {
    getAll,
};

export default CustomerService;