import React, { useState } from "react";

//@material-ui/core components
import TextField from "@material-ui/core/TextField";

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

export default function EditColumnText(props) {
    const { onSubmit = () => { } } = props;
    const [enTitle, setEnTitle] = useState(props.enText);
    const [esTitle, setEsTitle] = useState(props.esText);
    const [ptTitle, setPtTitle] = useState(props.ptText);
    const [frTitle, setFrTitle] = useState(props.frText);

    const handleClick = () => {
        onSubmit({
            enTitle,
            esTitle,
            frTitle,
            ptTitle
        });
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="EN column title"
                        value={enTitle}
                        onChange={(e) => setEnTitle(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="ES column title"
                        value={esTitle}
                        onChange={(e) => setEsTitle(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="PT column title"
                        value={ptTitle}
                        onChange={(e) => setPtTitle(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="FR column title"
                        value={frTitle}
                        onChange={(e) => setFrTitle(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 10 }}>
                    <Button type="button" color="rose" onClick={handleClick}>Save and close</Button>
                </GridItem>
            </GridContainer>
        </>
    )
}