import { useState, useEffect } from "react";
import DealerService from "services/DealerService";

const FILTER_DEALERS = 1;

const useDealers = () => {
    const [dealers, setDealers] = useState([]);
    const sdlid = parseInt(localStorage.getItem("_sdlid"));
    const dlid = parseInt(localStorage.getItem("_dlid"));

    useEffect(() => {
        const getDealers = async (dlid = '') => {
            const dealers = await DealerService.getAll(dlid).then(o => o.data);
            setDealers(dealers);
        };

        if (sdlid === FILTER_DEALERS) getDealers(dlid);
        else getDealers();
    }, [sdlid]);

    return dealers;
};

export default useDealers;