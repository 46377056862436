import React, { useState, useMemo, useEffect } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTableV2 from "components/ReactTable/ReactTableV2";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import OrderStatusShipment from "components/OrderStatusShipment/OrderStatusShipment";

// custom components
import SearchProduct from "./SearchProduct";

// material
import { TextField, Box, InputAdornment, Chip } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// service
import OrderService from "services/OrderService";

// helpers
import { financial } from "helpers";

// const
import { ORDER_STATUS } from "../InitialValues";
import {
    PRICE_CHANGE,
    QUANTITY_CHANGE,
    WEIGHT_CHANGE,
    STATUS_CHANGE,
    SHIP_GROUP_CHANGE,
    REMOVE_PRODUCT,
} from "const";

// hooks
import useUser from "hooks/useUser";
import useOrderStatusShipment from "hooks/useOrderStatusShipment";
import useOrderLog from "hooks/useOrderLog";

// libs
import moment from "moment";
import useCrud from "hooks/useCrud";
import DeleteButton from "components/DeleteButton/DeleteButton";

export default function QuoteProducts(props) {
    const {
        dealer,
        formik,
        isShipDisabled = false,
        newProducts = [],
        setNewProducts = () => {},
        trackingNumber = "",
        shippingCarrier = "",
    } = props;
    const [rowIndex, setRowIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [user] = useUser();
    const orderStatus = useOrderStatusShipment();
    const { dealerId, id: orderId, orderNumber } = formik.values;
    const { logStates, createOrderLog } = useOrderLog(orderId, orderNumber);
    const { readAllEntities, deleteEntity } = useCrud("order_details", {}, false);

    useEffect(() => {
        if (formik.values.id) {
            fetchProducts(formik.values.id).then((productsParsed = []) => {
                if (trackingNumber) {
                    const detailsParsed = productsParsed.map((d) => {
                        if (d.statusOrder != 6) {
                            const maxShipQty = parseInt(d.qty) - parseInt(d.qtyShipped);

                            return {
                                ...d,
                                trackingNumber: trackingNumber,
                                shipQty: maxShipQty,
                                statusOrder: 6,
                                shippingCarrier: shippingCarrier,
                            };
                        }
                        return d;
                    });

                    formik.setFieldValue("details", detailsParsed);
                }
            });
        }
    }, [formik.values.id, trackingNumber, shippingCarrier]);

    const fetchProducts = async () => {
        const queryParams = { order_id: formik.values.id };
        const products = await readAllEntities(queryParams);
        const productsParsed = products.map((p) => {
            const qtyDifference = parseInt(p.qty) - parseInt(p.shipQty);

            return {
                ...p,
                qtyShipped: p.shipQty,
                shipQty: !p.shipQty ? "" : qtyDifference,
            };
        });
        formik.setFieldValue("details", productsParsed);
        return productsParsed;
    };

    const columns = useMemo(
        () => [
            {
                Header: "Part Number(s)",
                accessor: "partNumber",
            },
            {
                Header: "Description",
                Cell: ({
                    row: {
                        original: { description, year, makeSlug, modelSlug },
                    },
                }) => {
                    return (
                        <>
                            {description}
                            {
                                <div>
                                    {year} {makeSlug} {modelSlug}
                                </div>
                            }
                        </>
                    );
                },
            },
            {
                Header: "Price",
                id: "price",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        name={`details[${index}].price`}
                        value={original.price}
                        onChange={async (e) => {
                            const oldPrice = original.price;
                            const newPrice = parseFloat(e.target.value);
                            formik.setFieldValue(`details[${index}].price`, newPrice);

                            createOrderLog({
                                dealerId: dealerId,
                                actionName: logStates.PRICE_CHANGE,
                                originalValues: original,
                                prevValue: { oldPrice },
                                currValue: { newPrice },
                            });
                        }}
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: "right",
                                width: 90,
                            },
                        }}
                    />
                ),
            },
            {
                Header: "Ordered Qty",
                id: "qty",
                Cell: ({ row: { original, index } }) => (
                    <Box style={{ textAlign: "center" }}>
                        <span style={{ textDecoration: "underline" }}>{original.qty}</span>
                    </Box>
                ),
            },
            {
                Header: "Qty Shipped",
                id: "qtyShipped",
                Cell: ({ row: { original, index } }) => (
                    <Box style={{ textAlign: "center" }}>
                        <span style={{ textDecoration: "underline" }}>{original.qtyShipped}</span>
                    </Box>
                ),
            },
            {
                Header: "Item Weight",
                id: "itemWeight",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        name={`details[${index}].itemWeight`}
                        value={original.itemWeight || ""}
                        onChange={async (e) => {
                            const oldItemWeight = original.itemWeight;
                            const newItemWeight = e.target.value;
                            formik.setFieldValue(`details[${index}].itemWeight`, newItemWeight);

                            createOrderLog({
                                dealerId: dealerId,
                                actionName: logStates.WEIGHT_CHANGE,
                                originalValues: original,
                                prevValue: { oldItemWeight },
                                currValue: { newItemWeight },
                            });
                        }}
                        InputProps={{
                            style: {
                                width: 80,
                            },
                            endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                        }}
                    />
                ),
            },
            {
                Header: "Status",
                id: "statusOrder",
                Cell: ({ row: { original, index } }) => {
                    return (
                        <div style={{ marginTop: original.pickupOnly == 1 ? 25 : 0 }}>
                            <OrderStatusShipment
                                statusId={original.statusOrder}
                                onChange={async (newStatusId) => {
                                    const prevStatus =
                                        orderStatus.find((s) => s.id == original.statusOrder)
                                            ?.status || "";
                                    const newStatus =
                                        orderStatus.find((s) => s.id == newStatusId)?.status || "";
                                    formik.setFieldValue(
                                        `details[${index}].statusOrder`,
                                        newStatusId
                                    );

                                    createOrderLog({
                                        dealerId: dealerId,
                                        actionName: logStates.STATUS_CHANGE,
                                        originalValues: original,
                                        prevValue: { oldStatusOrder: prevStatus },
                                        currValue: { newStatusOrder: newStatus },
                                    });
                                }}
                            />
                            {original.pickupOnly == 1 && (
                                <Chip
                                    label={"Pickup Only"}
                                    size="small"
                                    style={{ marginTop: 2.5 }}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "Tracking Number",
                id: "trackingNumber",
                Cell: ({ row: { original, index } }) => {
                    return (
                        <TextField
                            name={`details[${index}].trackingNumber`}
                            value={original.trackingNumber || ""}
                            onChange={async (e) => {
                                const oldTrackingNumber = original.trackingNumber;
                                const newTrackingNumber = e.target.value;
                                formik.setFieldValue(
                                    `details[${index}].trackingNumber`,
                                    newTrackingNumber
                                );

                                createOrderLog({
                                    dealerId: dealerId,
                                    actionName: logStates.TRACKING_CHANGE,
                                    originalValues: original,
                                    prevValue: { oldTrackingNumber },
                                    currValue: { newTrackingNumber },
                                });
                            }}
                            InputProps={{
                                style: {
                                    width: 80,
                                },
                            }}
                        />
                    );
                },
            },
            {
                Header: "Shipping Carrier",
                id: "shippingCarrier",
                Cell: ({ row: { original, index } }) => {
                    return (
                        <TextField
                            name={`details[${index}].shippingCarrier`}
                            value={original.shippingCarrier || ""}
                            onChange={async (e) => {
                                const newShippingCarrier = e.target.value;
                                formik.setFieldValue(
                                    `details[${index}].shippingCarrier`,
                                    newShippingCarrier
                                );
                            }}
                            InputProps={{
                                style: {
                                    width: 80,
                                },
                            }}
                        />
                    );
                },
            },

            {
                Header: "Ship Qty",
                id: "shipQty",
                Cell: ({ row: { original, index } }) => {
                    const maxShipQty = parseInt(original.qty) - parseInt(original.qtyShipped);

                    return (
                        <TextField
                            type="number"
                            name={`details[${index}].shipQty`}
                            value={original.shipQty || ""}
                            style={{ width: 80 }}
                            disabled={isShipDisabled}
                            InputProps={{ inputProps: { min: 0, max: maxShipQty } }}
                            onChange={(e) => {
                                const newShipQty = e.target.value;

                                if (newShipQty >= maxShipQty) {
                                    formik.setFieldValue(`details[${index}].shipQty`, maxShipQty);
                                } else {
                                    formik.setFieldValue(`details[${index}].shipQty`, newShipQty);
                                }
                            }}
                        />
                    );
                },
            },
            {
                Header: "Subtotal",
                Cell: ({
                    row: {
                        original: { price, qty },
                    },
                }) => financial(price * qty),
            },
            {
                Header: () => "Actions",
                id: "expander",
                Cell: ({ row }) => (
                    <>
                        {/*<Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            onClick={() => {
                                const isExpanded = !(row.isExpanded || false);
                                const currentDate = moment(new Date()).format("MM/DD/YYYY");
                                const msgLog = `${currentDate}, ${user.completeName || ""}`;

                                if (isExpanded) {
                                    if (row.original.intMemo) {
                                        formik.setFieldValue(
                                            `details[${row.index}].intMemo`,
                                            `${row.original.intMemo}\n${msgLog}: `
                                        );
                                    } else {
                                        formik.setFieldValue(
                                            `details[${row.index}].intMemo`,
                                            msgLog
                                        );
                                    }
                                }

                                if (row.toggleRowExpanded) row.toggleRowExpanded();
                            }}
                        >
                            <Edit />
                        </Button>*/}
                        <DeleteButton
                            handleClick={async () => {
                                if ("id" in row.original) {
                                    await deleteEntity(row.original.id, fetchProducts);

                                    const subTotal = formik.values.details
                                        .filter((d) => d.id != row.original.id)
                                        .reduce((acc, item) => {
                                            const itemTotalPrice = item.price * item.qty;
                                            return acc + itemTotalPrice;
                                        }, 0);

                                    const orderSummary = await OrderService.getOrderSummary(
                                        formik.values.dealerId,
                                        subTotal,
                                        formik.values.shipState
                                    );

                                    const order = await OrderService.put(formik.values.id, {
                                        ...formik.values,
                                        orderSummary: {
                                            summary: orderSummary?.data?.orderSummary || [],
                                            total: orderSummary?.data?.total,
                                        },
                                    });
                                } else {
                                    formik.setFieldValue(
                                        "details",
                                        formik.values.details.filter((_, i) => i !== row.index)
                                    );
                                }
                            }}
                        />
                        {/*<Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            title="Delete"
                            onClick={() => {
                                setRowIndex(row.index);
                                setOpen(true);
                            }}
                        >
                            <Close />
                        </Button>*/}
                    </>
                ),
            },
            /*{
                Header: "Quantity",
                id: "qty",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        type="number"
                        name={`details[${index}].qty`}
                        value={original.qty}
                        onChange={async (e) => {
                            const oldQty = original.qty;
                            const newQty = Number(e.target.value);
                            formik.setFieldValue(`details[${index}].qty`, newQty);

                            createOrderLog({
                                dealerId: dealerId,
                                actionName: logStates.QUANTITY_CHANGE,
                                originalValues: original,
                                prevValue: { oldQty },
                                currValue: { newQty }
                            });
                        }}
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: 'right',
                                width: 70
                            }
                        }}
                    />
                )
            },
            {
                Header: "Item Weight",
                id: "itemWeight",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        name={`details[${index}].itemWeight`}
                        value={original.itemWeight || ''}
                        onChange={async (e) => {
                            const oldItemWeight = original.itemWeight;
                            const newItemWeight = e.target.value;
                            formik.setFieldValue(`details[${index}].itemWeight`, newItemWeight);

                            createOrderLog({
                                dealerId: dealerId,
                                actionName: logStates.WEIGHT_CHANGE,
                                originalValues: original,
                                prevValue: { oldItemWeight },
                                currValue: { newItemWeight }
                            });
                        }}
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: 'right', width: 90
                            }
                        }}
                    />
                )
            },
            {
                Header: "Status",
                id: "statusOrder",
                Cell: ({ row: { original, index } }) => (
                    <OrderStatusShipment
                        statusId={original.statusOrder}
                        onChange={async (newStatusId) => {
                            const prevStatus = orderStatus.find(s => s.id == original.statusOrder)?.status || '';
                            const newStatus = orderStatus.find(s => s.id == newStatusId)?.status || '';
                            formik.setFieldValue(`details[${index}].statusOrder`, newStatusId);

                            createOrderLog({
                                dealerId: dealerId,
                                actionName: logStates.STATUS_CHANGE,
                                originalValues: original,
                                prevValue: { oldStatusOrder: prevStatus },
                                currValue: { newStatusOrder: newStatus }
                            });
                        }}
                    />
                )
            },
            {
                Header: "Ship Group",
                id: "shippingGroup",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        name={`details[${index}].shippingGroup`}
                        value={original.shippingGroup || ''}
                        onChange={async (e) => {
                            const oldShippingGroup = original.shippingGroup;
                            const newShippingGroup = e.target.value;
                            formik.setFieldValue(`details[${index}].shippingGroup`, newShippingGroup);

                            createOrderLog({
                                dealerId: dealerId,
                                actionName: logStates.SHIP_GROUP_CHANGE,
                                originalValues: original,
                                prevValue: { oldShippingGroup },
                                currValue: { newShippingGroup }
                            });
                        }}
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: 'right', width: 90
                            }
                        }}
                    />
                )
            },
            {
                Header: "Subtotal",
                Cell: ({ row: { original: { price, qty } } }) => financial(price * qty)
            },
            {
                Header: () => "Actions",
                id: 'expander',
                Cell: ({ row }) => (
                    <>
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            onClick={() => {
                                const isExpanded = !(row.isExpanded || false);
                                const currentDate = moment(new Date()).format('MM/DD/YYYY');
                                const msgLog = `${currentDate}, ${user.completeName || ''}`;

                                if (isExpanded) {
                                    if (row.original.intMemo) {
                                        formik.setFieldValue(`details[${row.index}].intMemo`, `${row.original.intMemo}\n${msgLog}: `);
                                    } else {
                                        formik.setFieldValue(`details[${row.index}].intMemo`, msgLog);
                                    }
                                }

                                if (row.toggleRowExpanded)
                                    row.toggleRowExpanded();
                            }}
                        >
                            <Edit />
                        </Button>
                        <Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            title="Delete"
                            onClick={() => {
                                setRowIndex(row.index);
                                setOpen(true);
                            }}
                        >
                            <Close />
                        </Button>
                    </>
                ),
            },*/
        ],
        [user, isShipDisabled]
    );

    const renderRowSubComponent = React.useCallback(({ row: { original, index } }) => {
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth
                        multiline
                        rows={6}
                        label="Internal memo"
                        name={`details[${index}].intMemo`}
                        value={original.intMemo}
                        onChange={formik.handleChange}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth
                        multiline
                        rows={6}
                        label="User memo"
                        name={`details[${index}].userMemo`}
                        value={original.userMemo}
                        onChange={formik.handleChange}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth
                        multiline
                        rows={6}
                        label="Shipping memo"
                        name={`details[${index}].shipMemo`}
                        value={original.shipMemo}
                        onChange={formik.handleChange}
                    />
                </GridItem>
            </GridContainer>
        );
    }, []);

    const delProduct = async (id) => {
        try {
            await OrderService.delProduct(id);
            fetchProducts();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    /*const newDetails = [...formik.values.details];
                    const id = newDetails[rowIndex].id;
                    await delProduct(id);
                    newDetails.splice(rowIndex, 1);
                    formik.setFieldValue("details", newDetails);
                    setRowIndex(-1);
                    setOpen(false);*/
                }}
                onCancel={() => setOpen(false)}
            />
            <Box m={1}>
                <SearchProduct
                    dealer={dealer}
                    orderId={formik.values.id}
                    onAddProduct={async (value) => {
                        formik.setFieldValue("details", [
                            ...formik.values.details,
                            {
                                ...value,
                                partNumber: value.part_number,
                                makeSlug: value.make_slug,
                                modelSlug: value.model_slug,
                                trimSlug: value.trim_slug,
                                engineSlug: value.engine_slug,
                                vehicleToEngineConfigId: value.VehicleToEngineConfigID,
                                pictureUrl: value.picture_url,
                                catalogId: value.catalog_id,
                                token: value.token,
                                itemWeight: "",
                                trackingNumber: "",
                            },
                        ]);
                        //const data = await fetchProducts();
                    }}
                />
            </Box>
            <ReactTableV2
                data={formik.values.details || []}
                columns={columns}
                rowKey="id"
                renderRowSubComponent={renderRowSubComponent}
            />
        </>
    );
}
