import React, { useEffect, useState } from "react";
import PageLayout from "layouts/PageLayout";
import useDealerId from "hooks/useDealerId";
import useDealer from "hooks/useDealer";
import DealerDataService from "services/DealerService";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function PartNumberUrl() {
    const [partNumber, setPartNumber] = useState("");
    const [makes, setMakes] = useState([]);
    const [selectedMake, setSelectedMake] = useState("");
    const [partNumberUrl, setPartNumberUrl] = useState("");
    const [copied, setCopied] = useState(false);
    const dealerId = useDealerId();
    const { dealerDomain = "" } = useDealer();

    useEffect(() => {
        const fetchMakes = async () => {
            const { makes = [] } = await DealerDataService.get(dealerId);
            setMakes(makes);
        };

        if (dealerId) {
            fetchMakes();
        }

        return () => {
            setMakes([]);
        };
    }, [dealerId]);

    useEffect(() => {
        setPartNumberUrl(`${dealerDomain}/search/${partNumber}`);
    }, [partNumber, dealerDomain]);

    return (
        <PageLayout>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <TextField
                        label="Enter Part #"
                        value={partNumber}
                        fullWidth
                        margin="normal"
                        onChange={(e) => setPartNumber(e.target.value)}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="make-select-label">Please Select Make</InputLabel>
                        <Select
                            labelId="make-select-label"
                            value={selectedMake}
                            onChange={(e) => setSelectedMake(e.target.value)}
                        >
                            {makes.map((make) => (
                                <MenuItem key={make.id} value={make.id}>
                                    {make.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <TextField
                        label="Part Number URL"
                        value={partNumberUrl}
                        fullWidth
                        margin="normal"
                        disabled
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CopyToClipboard
                                        text={partNumberUrl}
                                        onCopy={() => setCopied(true)}
                                    >
                                        <IconButton>
                                            <FileCopyOutlinedIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {copied && <span style={{ color: "green" }}>Copied!</span>}
                </GridItem>
            </GridContainer>
        </PageLayout>
    );
}
