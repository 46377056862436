import React, { useState, useEffect } from "react";
import useDealer from "hooks/useDealer";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SelectDealer from "components/SelectDealer/SelectDealer";
import Button from "components/CustomButtons/Button.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";

import { Checkbox } from "@material-ui/core";
import ReactTable from "components/ReactTable/ReactTable.js";
import CreateRedirect from "components/CreateRedirect/CreateRedirect";
import ImportRedFile from "components/ImportRedFile/ImportRedFile";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Edit from '@material-ui/icons/Edit';
import Close from "@material-ui/icons/Close";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);
import DealerRedirectService from "services/DealerRedirectService";

import CreateShopFeed, { EDIT_REDIRECT, DEF_INIT_VALUES } from "components/CreateShopFeed/CreateShopFeed";
import DealerMiscService from "services/DealerMiscService";

export default function DealerShopFeed() {
    const classes = useStyles();
    const dealer = useDealer();
    const [dealerMisc, setDealerMisc] = useState({});
    const [selected, setSelected] = useState([]);
    const [form, setForm] = useState(EDIT_REDIRECT);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        color: 'success'
    });

    useEffect(() => {
        const getDealer = async (dlid) => {
            const dealer = await DealerMiscService.getAll(dlid).then(d => d.data?.[0] || {});
            setDealerMisc(dealer);
        };
        if (dealer.id) getDealer(dealer.id);
    }, [dealer.id]);

    const handleClickCheckbox = (id) => {
        /*if (selected.includes(id)) {
            setSelected(
                selected.filter(s => s != id)
            );
        } else {
            setSelected([...selected, id]);
        }*/
    }

    const onSuccess = (redirect) => {
        /*if (form === EDIT_REDIRECT) {
            setRedirects([...redirects, redirect]);
        }


        if (form === EDIT_REDIRECT) {
            const nRedirects = redirects.map(c => c.id === redirect.id ? { ...c, ...redirect } : c);
            setRedirects(nRedirects);
        }

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage
        });*/
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage
        });
    }

    return (
        <>
            {notification.open &&
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                    onClose={() => setNotification({ ...notification, open: false, message: form.successMessage })}
                />
            }

            <GridContainer>
                <GridItem xs={12}>
                    <SelectDealer />
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <p>
                                Automate your Google Shopping Feed by entering your FTP credentials below. Please allow up to 24 hours for Google to start displaying your catalogs content in your Google Merchant Center.
                            </p>
                            <CreateShopFeed
                                initialValues={dealerMisc}
                                onSuccess={onSuccess}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}