import http from "./Http";

const getAll = async (id) => {
    const res = await http.get(`/dealer-techpages?dlid=${id}`);
    return res.data;
};

const post = async body => {
    return http.post("/dealer-techpages", body);
};

const put = async body => {
    return http.put(`/dealer-techpages/${body.id}`, body);
};

const del = async id => {
    return http.delete(`/dealer-techpages/${id}`);
};

const DealerPageTechService = {
    getAll,
    post,
    put,
    del
};

export default DealerPageTechService;