import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DealerShipping from 'services/DealerShipping';
import useDealer from './useDealer';
import { useSnackbar } from './useSnackbar';
import DealerTaxService from 'services/DealerTaxService';

const useAllStateTax = () => {
    const openSnackbar = useSnackbar();
    const dealer = useDealer();
    const dealerId = dealer.id;
    const [initialValues, setInitialValues] = useState({
        allStatesProvinceTaxRate: 0,
        applyLaborOnOrderTotal: 0,
        applyTaxToPartsAccessories: 0,
        applyTaxToShipping: 0
    });

    useEffect(() => {
        if (dealer.id > 0)
            setInitialValues({
                allStatesProvinceTaxRate: dealer.allStatesProvinceTaxRate,
                applyLaborOnOrderTotal: dealer.applyLaborOnOrderTotal,
                applyTaxToPartsAccessories: dealer.applyTaxToPartsAccessories,
                applyTaxToShipping: dealer.applyTaxToShipping
            });
    }, [dealer]);

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateGeneralTax(values);
        },
    });

    const updateGeneralTax = async (shippingData) => {
        try {
            await DealerTaxService.putGeneralTax({ ...shippingData, dealerId });
            openSnackbar('success', 'Sales tax updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating the sales tax. Please try again.');
        }
    }

    return {
        formik
    };
}

export default useAllStateTax;