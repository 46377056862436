import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import DealerTaxService from "services/DealerTaxService";
import { useSnackbar } from "./useSnackbar";
import useDealer from "./useDealer";

const useCreateDealerTax = (
    isCreateForm = false,
    defaultValues = {
        id: 0,
        stateCode: "",
        taxPercentage: 0,
        applyLaborOnOrderTotal: false,
        applyTaxToPartsAccessories: false,
        applyTaxToShipping: false,
    },
    handleSuccess = () => {}
) => {
    const openSnackbar = useSnackbar();
    const { id: dealerId } = useDealer();
    const [initialValues, setInitialValues] = useState(defaultValues);

    const validationSchema = yup.object({
        stateCode: yup.string().required(),
        taxPercentage: yup.number().required(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (isCreateForm) {
                await handleInsertTax({ ...values, dealerId });
            } else {
                await handleUpdateTax({ ...values, dealerId });
            }
        },
    });

    const handleInsertTax = async (taxData) => {
        try {
            await DealerTaxService.post(taxData);
            handleSuccess();
            openSnackbar("success", "Tax inserted successfully.");
        } catch (error) {
            openSnackbar("error", "Error creating tax.");
        }
    };

    const handleUpdateTax = async (taxData) => {
        try {
            await DealerTaxService.put(taxData);
            handleSuccess();
            openSnackbar("success", "Tax updated successfully.");
        } catch (error) {
            openSnackbar("error", "Error updating tax.");
        }
    };

    return {
        formik,
    };
};

export default useCreateDealerTax;
