import { useEffect, useState } from "react";
import EditRma from "views/Rma/EditRma";
import { useConfirmationDialog } from "./useConfirmationDialog";
import RmaService from "services/RmaService";

const useRma = () => {
    const [rmaData, setRmaData] = useState({
        recordsTotal: 0,
        data: [],
    });
    const { openDialog, closeDialog } = useConfirmationDialog();

    useEffect(() => {
        const fetchRma = async () => {
            const res = await RmaService.getAll();
            setRmaData(res);
        };

        fetchRma();

        return () => {};
    }, []);

    const handleClickEdit = (row) => {
        openDialog({
            customDialog: true,
            fullWidth: true,
            maxWidth: "xl",
            message: <EditRma rmaId={row.id} rmaNumber={row.rmaNumber} closeDialog={closeDialog} />,
        });
    };

    const handleClickDelete = async (id) => {
        const res = await RmaService.del(id);
    };

    return {
        rmaData: rmaData.data,
        handleClickEdit,
        handleClickDelete,
    };
};

export default useRma;
