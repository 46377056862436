import http from "./Http";

const getAll = async (dlid = "", value = "", startRow = 0, pageSize = 10) => {
    return http.get(
        `/customers?dlid=${dlid}&value=${value}&startRow=${startRow}&pageSize=${pageSize}`
    );
};

const getAllV2 = async (page = "", pageSize = "", search = "", dealerId = "") => {
    const url = `/v2/customers?page=${page}&pageSize=${pageSize}&q=${search}&dealerId=${dealerId}`;
    return http.get(url);
};

const get = async (id = "") => {
    return http.get(`/customers/${id}`);
};

const post = async (body) => {
    return http.post(`/customers`, body);
};

const put = async (id, body) => {
    return http.put(`/customers/${id}`, body);
};

const del = async (id) => {
    return http.delete(`/customers/${id}`);
};

const CustomerService = {
    getAll,
    getAllV2,
    get,
    post,
    put,
    del,
};

export default CustomerService;
