import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiAlert from '@material-ui/lab/Alert';

// @material-ui/icons
import Email from "@material-ui/icons/VpnKey";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

//libs
import { useFormik } from 'formik';
import * as yup from 'yup';

//const
export const INITIAL_VALUES = {
    newPassword: '',
    repeatNewPassword: ''
};

const defValidationSchema = {
    newPassword: yup.string()
        .required('Field required')
        .min(6, 'Password min length'),
    repeatNewPassword: yup.string()
        .required('Field required')
        .min(6, 'Password min length')
        .oneOf([yup.ref('newPassword'), null], 'Passwords not match')
}

const useStyles = makeStyles(styles);

import ResetPasswordService from "services/ResetPasswordService";

export default function ResetPassword() {
    const [alert, setAlert] = React.useState({
        open: false,
        text: ''
    });
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_VALUES,
        validationSchema: yup.object(defValidationSchema),
        onSubmit: async (values) => {
            resetPassword(token, values.newPassword, values.repeatNewPassword);
        },
    });

    const resetPassword = async (token, newPassword, repeatNewPassword) => {
        try {
            await ResetPasswordService.resetPassword(token, newPassword, repeatNewPassword);
            setAlert({ ...alert, open: true, text: 'Password changed successfully', severity: 'success' });
            location.href = '/auth/login-page';
        } catch (error) {
            setAlert({ ...alert, open: true, text: 'Error changing password ' + error.response.data.message, severity: 'error' });
        }
    }

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form onSubmit={formik.handleSubmit}>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="rose"
                            >
                                <h4 className={classes.cardTitle}>Reset password</h4>
                            </CardHeader>
                            <CardBody>
                                {alert.open ? <MuiAlert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                                    {alert.text}
                                </MuiAlert> : null}
                                <CustomInput
                                    type="password"
                                    labelText="New password"
                                    id="newPassword"
                                    name="newPassword"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: "password",
                                        onChange: formik.handleChange,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={formik.values.newPassword}
                                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                />
                                <CustomInput
                                    labelText="Repeat new password"
                                    id="repeatNewPassword"
                                    name="repeatNewPassword"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: "password",
                                        onChange: formik.handleChange,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={formik.values.repeatNewPassword}
                                    error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button type="submit" color="rose" simple size="lg" block>
                                    Reset password
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div >
    );
}
