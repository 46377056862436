import useCrud from "./useCrud";
import { useEffect } from "react";

const useNotifications = () => {
    const _dlid = localStorage.getItem("_dlid");
    const _sdlid = localStorage.getItem("_sdlid"); //1 o 0
    const { data: communications = [], readAllEntities, updateEntity, setData } = useCrud(
        "dealer_communications",
        {},
        false
    );

    useEffect(() => {
        let dlid = "";

        if (_sdlid != "0") {
            dlid = _dlid;
        }
        fetchNotifications(dlid);
    }, [_dlid, _sdlid]);

    const fetchNotifications = async (dlid = "") => {
        const queryParams = { is_from_admin: 0, is_read: 0 };

        if (dlid) {
            queryParams.dealer_id = dlid;
        }

        const data = await readAllEntities(queryParams);
        const dataParsed = data.sort((a, b) => b.id - a.id);
        setData(dataParsed);
    };

    const handleClickMessage = async (notificationId) => {
        await updateEntity({ id: notificationId, is_read: 1 }, () => {}, false);
        location.href = `/admin/message-center?nId=${notificationId}`;
    };

    return {
        notifications: communications,
        handleClickMessage,
    };
};

export default useNotifications;
