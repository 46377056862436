import { useEffect, useState } from "react";
import { useConfirmationDialog } from "./useConfirmationDialog";
import useDealerId from "./useDealerId";
import { useSnackbar } from "./useSnackbar";
import { TranslateUIService, TranslatePartsService } from "services/TranslateService";

const TAB_UI = 0;
const TAB_PARTS = 1;

const useTranslate = () => {
    const openSnackbar = useSnackbar();
    const { openDialog, closeDialog } = useConfirmationDialog();
    const dealerId = useDealerId();
    const [translations, setTranslations] = useState({
        page: 0,
        totalPages: 0,
        totalItems: 0,
        data: [],
    });
    const [query, setQuery] = useState("");
    const [tabId, setTabId] = useState(0);
    const [service, setService] = useState(TranslateUIService);

    useEffect(() => {
        fetchTranslation(dealerId);

        return () => {
            setTranslations([]);
        };
    }, [dealerId, service]);

    const fetchTranslation = async (dealerId, page = 1, limit = 10, query = "") => {
        try {
            const res = await service.getAll(dealerId, page, limit, query);
            setTranslations({
                ...res.data,
                data: res.data.data.map((d) => ({ ...d, id: d._id })),
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickDelete = async (idToDelete) => {
        try {
            await service.del(idToDelete);

            const updatedTranslations = translations.data.filter(
                (translation) => translation.id !== idToDelete
            );
            setTranslations({
                ...translations,
                data: updatedTranslations,
            });

            openSnackbar("success", "Remove successfully.");
            closeDialog();
        } catch (error) {
            openSnackbar("error", "Error deleting.");
        }
    };

    const handleClickInsert = async (newTranslation) => {
        try {
            const translation = await service
                .post({
                    dealerId: dealerId,
                    ...newTranslation,
                })
                .then((d) => {
                    return {
                        ...d,
                        id: d._id,
                    };
                });

            setTranslations((prevTranslations) => ({
                ...prevTranslations,
                totalItems: prevTranslations.totalItems + 1,
                data: [...prevTranslations.data, translation],
            }));

            openSnackbar("success", "Inserted successfully.");
            closeDialog();
        } catch (error) {
            openSnackbar("error", "Error creating.");
        }
    };

    const handleClickUpdate = async (updatedValues) => {
        try {
            const idToUpdate = updatedValues.id;
            await service.put(idToUpdate, updatedValues);

            const updatedTranslations = translations.data.map((translation) => {
                if (translation.id === idToUpdate) {
                    return { ...translation, ...updatedValues };
                } else {
                    return translation;
                }
            });

            setTranslations({
                ...translations,
                data: updatedTranslations,
            });

            openSnackbar("success", "Updated successfully.");
            closeDialog();
        } catch (error) {
            openSnackbar("error", "Error updating.");
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabId(newValue);

        switch (newValue) {
            case TAB_UI:
                setService(TranslateUIService);
                break;
            case TAB_PARTS:
                setService(TranslatePartsService);
                break;
            default:
                setService(TranslateUIService);
        }
    };

    return {
        openDialog,
        closeDialog,
        dealerId,
        translations,
        fetchTranslation,
        query,
        setQuery,
        handleClickDelete,
        handleClickInsert,
        handleClickUpdate,
        tabId,
        handleTabChange,
    };
};

export default useTranslate;
