import React, { useState, useEffect } from "react";

// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from "components/CustomButtons/Button.js";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import MakeYears from "./MakeYears";

// @material-ui/icons
import ClearIcon from '@material-ui/icons/Clear';
import Check from "@material-ui/icons/Check";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import CatalogMakeService from '../../../services/CatalogMakeService';

const useStyles = makeStyles(styles);

export default function CatalogMakes({
    dlid = '',
    catalogId = '',
    catalogName = '',
    onSuccess = () => { }
}) {
    const classes = useStyles();
    const [makes, setMakes] = useState([]);
    const [rowIdOver, setRowIdOver] = useState(-1);
    const [rowIdSel, setRowIdSel] = useState(-1);

    useEffect(() => {
        const getMakes = async (dlid, catalogId) => {
            const makes = await CatalogMakeService.getAll(dlid, catalogId).then(x => x.data);
            setMakes(makes);
        };

        if (dlid && catalogId) getMakes(dlid, catalogId);
    }, [dlid, catalogId]);

    const handleClickCheckbox = (e, index, make) => {
        const _makes = makes.map(m => m.makeId === make.makeId ? { ...m, isChecked: !m.isChecked } : m);
        setMakes(_makes);
        setRowIdSel(e.target.checked ? index : -1)
    }

    const handleChangeYears = (makeId, years = []) => {
        const _makes = makes.map(m => {
            return m.makeId === makeId ? { ...m, years: years.filter(y => y.isChecked).map(y => y.name) } : m
        });
        setMakes(_makes);
    }

    const handleClickSave = async (dlid, catalogId, makes = []) => {
        await CatalogMakeService.post({
            dealerId: dlid,
            catalogId,
            makes
        });
        onSuccess();
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    {
                        [...Array(Math.ceil(makes.length / 4) * 4)].map((e, i) => {
                            const from = i - 2;
                            const to = i + 1;
                            const make = makes[i];

                            return (
                                <>
                                    {
                                        make ?
                                            <GridItem
                                                key={i}
                                                xs={12}
                                                sm={12}
                                                md={3}
                                                onMouseOver={() => setRowIdOver(i)}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={false}
                                                                    tabIndex={-1}
                                                                    checked={make.isChecked}
                                                                    onClick={(e) => {
                                                                        handleClickCheckbox(e, i + 1, make);
                                                                    }}
                                                                    checkedIcon={
                                                                        <Check className={classes.checkedIcon} />
                                                                    }
                                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                                    classes={{
                                                                        checked: classes.checked,
                                                                        root: classes.checkRoot,
                                                                    }}
                                                                />
                                                            }
                                                            label={make.displayName}
                                                        />
                                                    </div>
                                                    {rowIdOver === i &&
                                                        <Link
                                                            href="javascript:void(0)"
                                                            onClick={() => {
                                                                setRowIdSel(i + 1);
                                                            }}
                                                            style={{
                                                                fontSize: 12
                                                            }}>
                                                            Edit years
                                                        </Link>
                                                    }
                                                </div>
                                            </GridItem> :
                                            <GridItem
                                                key={i}
                                                xs={12}
                                                sm={12}
                                                md={3}
                                            >

                                            </GridItem>
                                    }

                                    {
                                        ((i + 1) % 4 === 0) &&
                                        rowIdSel >= from && rowIdSel <= to &&
                                        <GridItem
                                            key={`years_${i}`}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                        >
                                            <GridItem
                                                key={i + 1}
                                                xs={12}
                                                sm={12}
                                                md={12}
                                            >
                                                <Card className={classes.root}>
                                                    <CardHeader
                                                        action={
                                                            <IconButton onClick={() => setRowIdSel(-1)}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        }
                                                        title={`${makes[rowIdSel - 1]?.displayName} years:`}
                                                        style={{
                                                            paddingTop: 5,
                                                            paddingBottom: 0
                                                        }}
                                                    />
                                                    <CardContent style={{ paddingTop: 2 }}>
                                                        <MakeYears
                                                            defYears={makes[rowIdSel - 1]?.years || []}
                                                            dlid={dlid}
                                                            makeId={makes[rowIdSel - 1]?.makeId}
                                                            catalogId={catalogId}
                                                            catalogName={catalogName}
                                                            onSave={(makeId, years) => handleChangeYears(makeId, years)}
                                                        />
                                                    </CardContent>
                                                </Card>
                                            </GridItem>
                                        </GridItem>
                                    }
                                </>
                            );
                        })
                    }
                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 5 }}>
                        <Button color="rose" onClick={() => handleClickSave(dlid, catalogId, makes)}>
                            Save and close
                        </Button>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    {
                        makes.map((make, i) => {
                            const from = (i + 2) - 4;
                            const to = i + 1;

                            return (
                                <>
                                    <GridItem
                                        key={i}
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        onMouseOver={() => setRowIdOver(i)}
                                        onMouseOut={() => setRowIdOver(i)}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={false}
                                                            tabIndex={-1}
                                                            checked={make.isChecked}
                                                            onClick={(e) => {
                                                                handleClickCheckbox(e, i + 1, make);
                                                            }}
                                                            checkedIcon={
                                                                <Check className={classes.checkedIcon} />
                                                            }
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                            }}
                                                        />
                                                    }
                                                    label={make.displayName}
                                                />
                                            </div>
                                            {rowIdOver === i &&
                                                <Link
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                        setRowIdSel(i + 1);
                                                    }}
                                                    style={{
                                                        fontSize: 12
                                                    }}>
                                                    Edit years
                                                </Link>
                                            }
                                        </div>
                                    </GridItem>
                                    {
                                        (
                                            rowIdSel != -1 &&
                                            ((i + 1) % 4 == 0) &&
                                            (
                                                rowIdSel >= from && rowIdSel <= to
                                            )
                                        ) && (
                                            <GridItem
                                                key={i + 1}
                                                xs={12}
                                                sm={12}
                                                md={12}
                                            >
                                                <Card className={classes.root}>
                                                    <CardHeader
                                                        action={
                                                            <IconButton onClick={() => setRowIdSel(-1)}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        }
                                                        title={`${makes[rowIdSel - 1]?.displayName} years:`}
                                                        style={{
                                                            paddingTop: 5,
                                                            paddingBottom: 0
                                                        }}
                                                    />
                                                    <CardContent style={{ paddingTop: 2 }}>
                                                        <MakeYears
                                                            defYears={makes[rowIdSel - 1]?.years || []}
                                                            dlid={dlid}
                                                            makeId={makes[rowIdSel - 1]?.makeId}
                                                            catalogId={catalogId}
                                                            catalogName={catalogName}
                                                            onSave={(makeId, years) => handleChangeYears(makeId, years)}
                                                        />
                                                    </CardContent>
                                                </Card>
                                            </GridItem>
                                        )
                                    }
                                </>
                            )
                        })
                    }
                    <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 5 }}>
                        <Button color="rose" onClick={() => handleClickSave(dlid, catalogId, makes)}>
                            Save and close
                        </Button>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )
}