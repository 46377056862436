import http, { BASE_URL_TRANSLATION } from "./Http";

const getAllUI = async (dealerId = "", page = 1, limit = 10, query = "") => {
    const { data } = await http.get(
        `${BASE_URL_TRANSLATION}/translations?dlid=${dealerId}&page=${page}&limit=${limit}&q=${query}`
    );
    return data;
};

const postUI = async (body) => {
    const { data } = await http.post(`${BASE_URL_TRANSLATION}/translations`, body);
    return data;
};

const putUI = async (id = "", body) => {
    const { data } = await http.put(`${BASE_URL_TRANSLATION}/translations/${id}`, body);
    return data;
};

const delUI = async (id = "") => {
    const { data } = await http.delete(`${BASE_URL_TRANSLATION}/translations/${id}`);
    return data;
};

const TranslateUIService = {
    getAll: getAllUI,
    post: postUI,
    put: putUI,
    del: delUI,
};

const getAllParts = async (dealerId = "", page = 1, limit = 10, query = "") => {
    const { data } = await http.post(
        `${BASE_URL_TRANSLATION}/translations-parts/search?dlid=${dealerId}&page=${page}&limit=${limit}&q=${query}`
    );
    return data;
};

const postPart = async (body) => {
    const { data } = await http.post(`${BASE_URL_TRANSLATION}/translations-parts`, body);
    return data;
};

const putPart = async (id = "", body) => {
    const { data } = await http.put(`${BASE_URL_TRANSLATION}/translations-parts/${id}`, body);
    return data;
};

const delPart = async (id = "") => {
    const { data } = await http.delete(`${BASE_URL_TRANSLATION}/translations-parts/${id}`);
    return data;
};

const TranslatePartsService = {
    getAll: getAllParts,
    post: postPart,
    put: putPart,
    del: delPart,
};

export { TranslateUIService, TranslatePartsService };
