import { useState, useEffect } from "react";
import { VectorMap } from "react-jvectormap";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table.js";
import OrderFilterSelector from "components/OrderFilterSelector/OrderFilterSelector";

import DashboardService from "../../services/DashboardService";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { financial } from "helpers";
import useDealer from "hooks/useDealer";
import { Link, Typography } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(styles);

function StatisticCard({
    color = "warning",
    iconText = "content_copy",
    title = "",
    qty = "",
    qtyTitle = "",
    helperText = "",
    linkEnabled = false,
    linkTo = "",
}) {
    const classes = useStyles();

    const handleClick = (e) => {
        e.preventDefault();
        location.href = linkTo;
    };

    const content = (
        <Card>
            <CardHeader stats icon>
                <CardIcon color={color}>
                    <Icon>{iconText}</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>{title}</p>
                <h3 className={classes.cardTitle}>
                    {qty} <small>{qtyTitle}</small>
                </h3>
            </CardHeader>
            <CardFooter stats>
                <div className={classes.stats} style={{ display: "flex", alignContent: "center" }}>
                    <DateRange />
                    <span style={{ marginTop: 2 }}>{helperText}</span>
                </div>
            </CardFooter>
        </Card>
    );

    return linkEnabled ? (
        <Link underline="none" component="div" to={linkTo} onClick={handleClick}>
            <a href={linkTo}>{content}</a>
        </Link>
    ) : (
        <div>{content}</div>
    );
}

export default function Dashboard() {
    const classes = useStyles();
    const [statistics, setStatistics] = useState({
        actives: 0,
        archived: 0,
        orderCount: 0,
        orderAmount: 0,
        orderStats: [],
    });
    const dealer = useDealer();
    const [fromDate, setFromDate] = useState(moment().subtract(7, "days").format("MM/DD/YYYY"));
    const [toDate, setToDate] = useState(moment().format("MM/DD/YYYY"));

    useEffect(() => {
        return () => {
            setStatistics({
                actives: 0,
                archived: 0,
                orderCount: 0,
                orderAmount: 0,
                orderStats: [],
            });
        };
    }, []);

    const getStatistics = async ({
        dlid = "",
        cid = "",
        fromDate = "",
        toDate = "",
        statusId = "",
        search = "",
    }) => {
        const statistics = await DashboardService.getAll({
            dlid,
            cid,
            fromDate,
            toDate,
            statusId,
            search,
        }).then((d) => d.data);
        setStatistics(statistics);
    };

    const handleFilterChange = (dealerId, customerId, fromDate, toDate, statusId) => {
        getStatistics({ dlid: dealerId, cid: customerId, fromDate, toDate, statusId });
    };

    const stateDataMap = statistics.orderStats.reduce((result, item) => {
        const [stateCode, quantity] = item;
        const key = `US-${stateCode}`;
        result[key] = quantity;
        return result;
    }, {});

    const formattedNumber = statistics.orderAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={12}>
                    <Card style={{ padding: 10 }}>
                        <OrderFilterSelector onChange={handleFilterChange} />
                    </Card>
                </GridItem>
            </GridContainer>
            {/* */}
            <GridContainer>
                {/*<GridItem xs={12} sm={6} md={6} lg={3}>
                    <StatisticCard
                        color="success"
                        iconText="store"
                        qty={statistics.actives}
                        title="Active Stores"
                        helperText="10/25/2023 to 10/25/2023"
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <StatisticCard
                        iconText="archive"
                        title="Archived Stores"
                        qty={statistics.archived}
                        qtyTitle=""
                        helperText="10/25/2023 to 10/25/2023"
                    />
                </GridItem>*/}
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <StatisticCard
                        linkEnabled
                        linkTo="/admin/orders"
                        color="success"
                        title="New Orders"
                        qty={statistics.orderCount}
                        qtyTitle="orders"
                        helperText={`${fromDate} to ${toDate}`}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <StatisticCard
                        iconText="attach_money"
                        title="Orders Amount"
                        qty={formattedNumber}
                        helperText={`${fromDate} to ${toDate}`}
                    />
                </GridItem>
            </GridContainer>
            {/* */}
            <GridContainer>
                <Card>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Icon>language</Icon>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Global Sales by Top Locations</h4>
                    </CardHeader>
                    <CardBody>
                        {dealer?.country == "CAN" ? (
                            <Typography
                                variant="h5"
                                gutterBottom
                                style={{ textAlign: "center", marginTop: 25, marginBottom: 25 }}
                            >
                                Coming soon
                            </Typography>
                        ) : null}

                        <GridContainer justify="space-between">
                            <GridItem xs={12} sm={12} md={5}>
                                <Table tableData={statistics.orderStats} />
                            </GridItem>

                            {dealer?.country !== "CAN" ? (
                                <GridItem xs={12} sm={12} md={6}>
                                    <VectorMap
                                        map={"us_aea"}
                                        backgroundColor="transparent"
                                        zoomOnScroll={false}
                                        containerStyle={{
                                            width: "100%",
                                            height: "280px",
                                        }}
                                        containerClassName="map"
                                        regionStyle={{
                                            initial: {
                                                fill: "#e4e4e4",
                                                "fill-opacity": 0.9,
                                                stroke: "none",
                                                "stroke-width": 0,
                                                "stroke-opacity": 0,
                                            },
                                        }}
                                        series={{
                                            regions: [
                                                {
                                                    values: stateDataMap,
                                                    scale: ["#AAAAAA", "#444444"],
                                                    normalizeFunction: "polynomial",
                                                },
                                            ],
                                        }}
                                    />
                                </GridItem>
                            ) : null}
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridContainer>
        </>
    );
}
