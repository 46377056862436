import React from "react";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";

export default function RequestActions({ initialValues, formik, onApprove, onDecline, onMarkAsOpen }) {
    return (
        <Box textAlign="center">
            {
                (initialValues && initialValues.isOpen === 1) ? (
                    <>
                        <Button color="success" onClick={() => onApprove(formik.values)}>
                            Approve
                        </Button>
                        <Button color="google" onClick={() => onDecline(formik.values.id)}>
                            Decline
                        </Button>
                    </>
                ) : null
            }
            {
                (initialValues && initialValues.isOpen === 0) ? (
                    <Button color="success" onClick={() => onMarkAsOpen(formik.values.id)}>
                        Mark as open
                    </Button>
                ) : null
            }
            {
                Object.keys(initialValues).length === 0 && (
                    <Button type="submit" color="rose">
                        Submit
                    </Button>
                )
            }
        </Box>
    )
}
