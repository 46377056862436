import { useState, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BASE_URL_API } from "services/Http";

const UPLOAD_ENDPOINT = "upload";

export default function HtmlEditor(props) {
    const { title = '', data = '', onBlur = () => { }, height = 500, dlid = "" } = props
    const [value, setValue] = useState('');
    const _dlid = dlid ? dlid : localStorage.getItem("_dlid");

    function uploadAdapter(loader) {
        const baseUrlApi = BASE_URL_API.replace('api', '');

        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("files", file);
                        body.append("baseUrlApi", baseUrlApi);
                        fetch(`${baseUrlApi}${UPLOAD_ENDPOINT}?dlid=${_dlid}`, {
                            method: "post",
                            body: body
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                resolve({
                                    default: encodeURI(res.url)
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return (
        <>
            <h5>{title}</h5>
            <CKEditor
                {...props}
                editor={ClassicEditor}
                data={data}
                config={{ height: `100px`, extraPlugins: [uploadPlugin] }}
                onReady={editor => {
                    if (editor)
                        editor.editing.view.change(writer => {
                            writer.setStyle('height', `${height}px`, editor.editing.view.document.getRoot());
                        });
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue(data);
                }}
                onBlur={() => onBlur(value)}
                onFocus={(event, editor) => {
                    //console.log('Focus.', editor);
                }}
            />
        </>
    )
}