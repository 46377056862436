import http from "./Http";

const getAll = async (dealerId) => {
    return http.get(`/pages?dealerId=${dealerId}&1=1`);
};

const post = async (body) => {
    return http.post("/pages", body);
};

const put = async (body) => {
    return http.put(`/pages/${body.id}`, body);
};

const del = async (id) => {
    return http.delete(`/pages/${id}`);
};

const CustomPageService = {
    getAll,
    post,
    put,
    del,
};

export default CustomPageService;
