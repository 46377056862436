import http from "./Http";

const getAll = async (open = '', page = '', pageSize = '') => {
    const url = `/client-request?isOpen=${open}&page=${page}&pageSize=${pageSize}`;
    return http.get(url);
};

const post = async body => {
    const res = await http.post(`/client-request/`, body);
    return res.data;
};

const approveRequest = async (id, body) => {
    const res = await http.post(`/client-request/approve/${id}`, body);
    return res.data;
};

const declineRequest = async (id) => {
    const res = await http.post(`/client-request/decline/${id}`);
    return res.data;
};

const markAsOpen = async (id) => {
    const res = await http.post(`/client-request/mark-as-open/${id}`);
    return res.data;
};

const RequestService = {
    getAll,
    post,
    approveRequest,
    declineRequest,
    markAsOpen
};

export default RequestService;