import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    IconButton,
    MenuItem,
    Switch,
    TextField,
    Tooltip,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import SearchIcon from "@material-ui/icons/Search";
import SaveIcon from "@material-ui/icons/Save";
import PageLayout from "layouts/PageLayout";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TextFileReaderUploader from "components/TextFileReaderUploader/TextFileReaderUploader";
import useBulkItems from "hooks/useBulkItems";
import useDealerCatalogs from "hooks/useDealerCatalogs";
import BulkTable from "./BulkTable";
import CustomButton from "components/CustomButtons/Button.js";

export default function BulkEditItem() {
    const {
        catalogName,
        setCatalogName,
        mustValidate,
        setMustValidate,
        products = [],
        setProducts,
        productsEditedParts = [],
        handleCSVUpload,
        handlePartNumberChange,
        handleClickDelete,
        partNumberFilter,
        setPartNumberFilter,
        handleDownloadCSV,
        handleApplyChanges,
    } = useBulkItems();
    const dealerCatalogs = useDealerCatalogs();

    const filteredProducts =
        partNumberFilter.length > 0
            ? products.filter((p) =>
                  p.partNumber.toLowerCase().includes(partNumberFilter.toLowerCase())
              )
            : products;

    return (
        <PageLayout>
            {/* x */}
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <Box display="flex">
                        <TextField
                            size="small"
                            label="Type part number"
                            variant="outlined"
                            fullWidth
                            value={partNumberFilter}
                            onChange={(e) => setPartNumberFilter(e.target.value)}
                        />
                        <IconButton style={{ marginLeft: 5 }}>
                            <Tooltip title="Search">
                                <SearchIcon fontSize="small" />
                            </Tooltip>
                        </IconButton>
                    </Box>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={9}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={mustValidate}
                                onChange={(e) => {
                                    setMustValidate(e.target.checked);
                                }}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Must validate?"
                    />
                    <Divider orientation="vertical" style={{ marginLeft: 20, marginRight: 20 }} />
                    <TextField
                        flex={1}
                        select
                        label="Catalog"
                        value={catalogName}
                        onChange={(e) => {
                            setCatalogName(e.target.value);
                        }}
                        style={{
                            marginRight: 10,
                            width: 150,
                        }}
                    >
                        {dealerCatalogs.map((option) => (
                            <MenuItem key={option.catalogName} value={option.catalogName}>
                                {option.enDisplayName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextFileReaderUploader
                        disabled={catalogName === ""}
                        accept=".csv"
                        onUpload={handleCSVUpload}
                    />
                    <Divider orientation="vertical" style={{ marginLeft: 20, marginRight: 20 }} />
                    <Tooltip title="Download CSV file sample">
                        <a href="/bulk_template.csv" download="bulk_template.csv">
                            <IconButton>
                                <GetAppIcon fontSize="small" />
                            </IconButton>
                        </a>
                    </Tooltip>
                    <Tooltip title="Download CSV file existing rules">
                        <IconButton onClick={handleDownloadCSV}>
                            <GetAppIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </GridItem>
            </GridContainer>
            {/* x */}
            <Box mt={2.5}>
                <BulkTable
                    products={filteredProducts}
                    setProducts={setProducts}
                    onDeleteClick={handleClickDelete}
                    onPartNumberChange={handlePartNumberChange}
                />
            </Box>
            {/* x */}
            <Box textAlign="center" mt={2.5}>
                <CustomButton type="button" color="rose" onClick={handleApplyChanges}>
                    Apply changes
                </CustomButton>
            </Box>
        </PageLayout>
    );
}
