import React, { useEffect } from "react";
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Divider,
    Box,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import useEditPartForm from "hooks/useEditPartForm";
import { financial } from "helpers";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formGroup: {
        marginBottom: theme.spacing(2),
    },
    saveButton: {
        marginTop: theme.spacing(2),
    },
}));

export default function EditPartForm({ product, onSuccess }) {
    const classes = useStyles();
    const { formik } = useEditPartForm(onSuccess);

    useEffect(() => {
        if (Object.keys(product).length > 0) {
            if ("_model_id" in product) {
                formik.setValues({
                    ...formik.values,
                    title: product.name,
                    price: product.price.price || product.price,
                    sellingPrice: product.price.price || product.price,
                    partNumber: product.part_number,
                });
            } else {
                formik.setValues({
                    ...product,
                    cost: product.cost || "",
                    listPrice: product.listPrice || "",
                    sellingPrice: product.sellingPrice || "",
                    core: product.core || "",
                    fixedShippingPrice: product.fixedShippingPrice || "",
                    hideItem: Boolean(product.hideItem),
                    activateItem: Boolean(product.activateItem),
                    pickupOnly: Boolean(product.pickupOnly),
                });
            }
        }
    }, [product]);

    console.error("product", product);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} className={classes.formGroup}>
                <Typography variant="h6" gutterBottom>
                    Part # {product?.part_number || product?.partNumber}
                </Typography>

                <Grid item xs={12}>
                    <TextField
                        label="Title"
                        variant="outlined"
                        fullWidth
                        id="title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.formGroup}>
                <Grid item xs={6}>
                    {formik.values?.cost && (
                        <Typography>Cost: ${financial(formik.values?.cost)}</Typography>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Typography>
                        List: ${financial(formik.values.price || formik.values.listPrice)}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Selling Price"
                        variant="outlined"
                        fullWidth
                        id="sellingPrice"
                        name="sellingPrice"
                        value={formik.values.sellingPrice}
                        onChange={formik.handleChange}
                        error={formik.touched.sellingPrice && Boolean(formik.errors.sellingPrice)}
                        helperText={formik.touched.sellingPrice && formik.errors.sellingPrice}
                        InputProps={{
                            startAdornment: <Typography>$</Typography>,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="number"
                        label="Core"
                        variant="outlined"
                        fullWidth
                        id="core"
                        name="core"
                        value={formik.values.core}
                        onChange={formik.handleChange}
                        error={formik.touched.core && Boolean(formik.errors.core)}
                        helperText={formik.touched.core && formik.errors.core}
                        InputProps={{
                            startAdornment: <Typography>$</Typography>,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.formGroup}>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="hideItem"
                                checked={formik.values.hideItem}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Hide Item"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="activateItem"
                                checked={formik.values.activateItem}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Activate Item"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="pickupOnly"
                                checked={formik.values.pickupOnly}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Pickup Only"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.formGroup}>
                <Grid item xs={12}>
                    <TextField
                        label="Fixed Shipping Price"
                        variant="outlined"
                        fullWidth
                        id="fixedShippingPrice"
                        name="fixedShippingPrice"
                        value={formik.values.fixedShippingPrice}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.fixedShippingPrice &&
                            Boolean(formik.errors.fixedShippingPrice)
                        }
                        helperText={
                            formik.touched.fixedShippingPrice && formik.errors.fixedShippingPrice
                        }
                        InputProps={{
                            startAdornment: <Typography>$</Typography>,
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: "none" }}>
                    <TextField
                        label="Additional Image"
                        variant="outlined"
                        fullWidth
                        id="additionalImage"
                        name="additionalImage"
                        value={formik.values.additionalImage}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.additionalImage && Boolean(formik.errors.additionalImage)
                        }
                        helperText={formik.touched.additionalImage && formik.errors.additionalImage}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: "none" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="additionalImageDefault"
                                checked={formik.values.additionalImageDefault}
                                onChange={formik.handleChange}
                            />
                        }
                        label="Check to make additional image default"
                    />
                </Grid>
            </Grid>
            <Divider />
            <Box ml={2} textAlign="center">
                <Button type="submit" color="rose">
                    Save and close
                </Button>
            </Box>
        </form>
    );
}
