import http from "./Http";

const getAll = async (dlid) => {
    return http.get(`/dealer-catalogs?dlid=${dlid}`);
};

const post = async body => {
    return http.post(`/dealer-catalogs`, body);
};

const put = async body => {
    return http.put(`/dealer-catalogs/${body.id}`, body);
};

const del = async (id, dlid, catalogId) => {
    return http.delete(`/dealer-catalogs/${id}?dlid=${dlid}&catalogId=${catalogId}`);
};

const DealerCatalogService = {
    getAll,
    post,
    put,
    del
};

export default DealerCatalogService;