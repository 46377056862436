import { withStyles } from "@material-ui/core/styles";
import { Box, TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal";
import useModal from "hooks/useModal";
import useDealerPricingForm from "hooks/useDealerPricingForm";
import EditButton from "components/EditButton/EditButton";
import MakesSelector from "components/MakesSelector/MakesSelector";
import CustomerSelector from "components/CustomerSelector/CustomerSelector";
import CatalogSelector from "components/CatalogSelector/CatalogSelector";
import useDealerId from "hooks/useDealerId";

const StyledBox = withStyles((theme) => ({
    root: {
        marginBottom: 10,
    },
}))(Box);

export default function CreateDealerPricing({
    initialValues = {},
    handleAddDealerPricing = () => {},
}) {
    const dealerId = useDealerId();
    const onSubmit = async (values) => {
        handleAddDealerPricing(values);
        handleCloseModal();
    };

    const { open, handleOpenModal, handleCloseModal } = useModal();
    const { formik, resetForm } = useDealerPricingForm(initialValues, onSubmit);

    return (
        <>
            {initialValues && initialValues.id ? (
                <EditButton handleClick={handleOpenModal} />
            ) : (
                <Button color="primary" onClick={handleOpenModal}>
                    Add pricing rule
                </Button>
            )}

            {open ? (
                <Modal
                    fullWidth
                    maxWidth="xs"
                    open={open}
                    modalTitle={"Create pricing rule"}
                    handleClose={() => {
                        resetForm();
                        handleCloseModal();
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <StyledBox>
                            <CatalogSelector
                                value={formik.values.catalogId || ""}
                                onChange={(e) => {
                                    formik.setFieldValue("catalogId", e.target.value);
                                }}
                            />
                        </StyledBox>
                        <StyledBox>
                            <MakesSelector
                                catalogId={formik.values.catalogId}
                                value={formik.values.makeId || ""}
                                onChange={(e) => {
                                    formik.setFieldValue("makeId", e.target.value);
                                }}
                            />
                        </StyledBox>
                        <StyledBox style={{ display: "none" }}>
                            <CustomerSelector
                                dealerId={dealerId}
                                customerId={formik.values.customerId}
                                setCustomerId={(e) => {
                                    formik.setFieldValue("customerId", e.target.value);
                                }}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="From"
                                name="startRange"
                                value={formik.values.startRange || ""}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.startRange && Boolean(formik.errors.startRange)
                                }
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="To"
                                name="endRange"
                                value={formik.values.endRange || ""}
                                onChange={formik.handleChange}
                                error={formik.touched.endRange && Boolean(formik.errors.endRange)}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="Cost plus"
                                name="costPlus"
                                value={formik.values.costPlus || ""}
                                onChange={formik.handleChange}
                                error={formik.touched.costPlus && Boolean(formik.errors.costPlus)}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="List minus"
                                name="listMinus"
                                value={formik.values.listMinus || ""}
                                onChange={formik.handleChange}
                                error={formik.touched.listMinus && Boolean(formik.errors.listMinus)}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="Min profit"
                                name="minProfit"
                                value={formik.values.minProfit || ""}
                                onChange={formik.handleChange}
                                error={formik.touched.minProfit && Boolean(formik.errors.minProfit)}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="Inflate Msrp"
                                name="inflateMsrp"
                                value={formik.values.inflateMsrp || ""}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.inflateMsrp && Boolean(formik.errors.inflateMsrp)
                                }
                            />
                        </StyledBox>
                        <Box textAlign="center">
                            <Button type="submit" color="rose">
                                Save and close
                            </Button>
                        </Box>
                    </form>
                </Modal>
            ) : null}
        </>
    );
}
