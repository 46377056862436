import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// const
import { CREATE_PAGE_TECH, EDIT_PAGE_TECH } from './DealerPageTech';

//services
import PageTechService from '../../../services/DealerPageTechService';
import PageService from '../../../services/PageService';

const validationSchema = yup.object({

});

const useStyles = makeStyles({
    updateProfileButton: {
        float: "right",
    },
});

export default function CreatePageTechMeta({
    formik
    /*dlid = '',
    form = CREATE_PAGE_TECH,
    initialValues,
    onSuccess = () => { }*/
}) {
    /*const [pages, setPages] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const getPages = async () => {
            const pages = await PageService.getAll();
            setPages(pages)
        };
        getPages();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            enMetaTitle: initialValues.enMetaTitle || '',
            enMetaDescription: initialValues.enMetaDescription || '',
            enMetaKeywords: initialValues.enMetaKeywords || '',
            esMetaTitle: initialValues.esMetaTitle || '',
            esMetaDescription: initialValues.esMetaDescription || '',
            esMetaKeywords: initialValues.esMetaKeywords || '',
            ptMetaTitle: initialValues.ptMetaTitle || '',
            ptMetaDescription: initialValues.ptMetaDescription || '',
            ptMetaKeywords: initialValues.ptMetaKeywords || '',
            frMetaTitle: initialValues.frMetaTitle || '',
            frMetaDescription: initialValues.frMetaDescription || '',
            frMetaKeywords: initialValues.frMetaKeywords || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (form === CREATE_PAGE_TECH) createPageTech({ ...values, dealerId: dlid });
            if (form === EDIT_PAGE_TECH) updatePageTech({ ...values, dealerId: dlid });
        },
    });

    const createPageTech = async body => {
        try {
            const techPage = await PageTechService.post(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    }

    const updatePageTech = async body => {
        try {
            const techPage = await PageTechService.put(body);
            onSuccess(techPage.data);
        } catch (error) {
            console.error(error);
        }
    }*/

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {/* English */}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            autoFocus
                            fullWidth
                            margin="dense"
                            id="enMetaTitle"
                            name="enMetaTitle"
                            label="EN meta title"
                            value={formik.values.enMetaTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.enMetaTitle && Boolean(formik.errors.enMetaTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="enMetaDescription"
                            name="enMetaDescription"
                            label="EN meta description"
                            value={formik.values.enMetaDescription}
                            onChange={formik.handleChange}
                            error={formik.touched.enMetaDescription && Boolean(formik.errors.enMetaDescription)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="enMetaKeywords"
                            name="enMetaKeywords"
                            label="EN meta keywords"
                            value={formik.values.enMetaKeywords}
                            onChange={formik.handleChange}
                            error={formik.touched.enMetaKeywords && Boolean(formik.errors.enMetaKeywords)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                </GridContainer>

                {/* Spanish */}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="esMetaTitle"
                            name="esMetaTitle"
                            label="ES meta title"
                            value={formik.values.esMetaTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.esMetaTitle && Boolean(formik.errors.esMetaTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="esMetaDescription"
                            name="esMetaDescription"
                            label="ES meta content"
                            value={formik.values.esMetaDescription}
                            onChange={formik.handleChange}
                            error={formik.touched.esMetaDescription && Boolean(formik.errors.esMetaDescription)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="esMetaKeywords"
                            name="esMetaKeywords"
                            label="ES meta keywords"
                            value={formik.values.esMetaKeywords}
                            onChange={formik.handleChange}
                            error={formik.touched.esMetaKeywords && Boolean(formik.errors.esMetaKeywords)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                </GridContainer>

                {/* Portugués */}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="ptMetaTitle"
                            name="ptMetaTitle"
                            label="PT meta title"
                            value={formik.values.ptMetaTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.ptMetaTitle && Boolean(formik.errors.ptMetaTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="ptMetaDescription"
                            name="ptMetaDescription"
                            label="PT meta content"
                            value={formik.values.ptMetaDescription}
                            onChange={formik.handleChange}
                            error={formik.touched.ptMetaDescription && Boolean(formik.errors.ptMetaDescription)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="ptMetaKeywords"
                            name="ptMetaKeywords"
                            label="PT meta keywords"
                            value={formik.values.ptMetaKeywords}
                            onChange={formik.handleChange}
                            error={formik.touched.ptMetaKeywords && Boolean(formik.errors.ptMetaKeywords)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                </GridContainer>

                {/* Francés */}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="frMetaTitle"
                            name="frMetaTitle"
                            label="FR meta title"
                            value={formik.values.frMetaTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.frMetaTitle && Boolean(formik.errors.frMetaTitle)}
                            inputProps={{ maxLength: 512 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="frMetaDescription"
                            name="frMetaDescription"
                            label="FR meta content"
                            value={formik.values.frMetaDescription}
                            onChange={formik.handleChange}
                            error={formik.touched.frMetaDescription && Boolean(formik.errors.frMetaDescription)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="frMetaKeywords"
                            name="frMetaKeywords"
                            label="FR meta keywords"
                            value={formik.values.frMetaKeywords}
                            onChange={formik.handleChange}
                            error={formik.touched.frMetaKeywords && Boolean(formik.errors.frMetaKeywords)}
                            inputProps={{ maxLength: 5000 }}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )
}