import { useState, useEffect } from "react";
import useDealerId from "./useDealerId";
import useCrud from "./useCrud";
import useDialog from "./useDialog";

const defaultValues = {
    id: "",
    display: false,
    showInCarouselFormat: false,
    ruleName: "",
    catalogId: "",
    mainPartNumber: "",
    products: [],
};

const useRelatedItems = () => {
    const dealerId = useDealerId();
    const [formValues, setFormValues] = useState(defaultValues);
    const { open, setOpen } = useDialog();
    const relItems = useCrud("dealer_related_items", {}, false);
    const relItemsDet = useCrud("dealer_related_items_details", {}, false);

    useEffect(() => {
        if (dealerId) {
            fetchRelItems();
        }

        return () => {
            if (open) {
                setFormValues(defaultValues);
            }
        };
    }, [open, dealerId]);

    const fetchRelItems = async () => {
        await relItems.readAllEntities({
            dealer_id: dealerId,
        });
    };

    const handleInsert = async (data) => {
        const partNumbers = extractPartNumbers(data.products);
        const relItemEntity = {
            dealer_id: dealerId,
            show_in_carousel_format: data.showInCarouselFormat,
            rule_name: data.ruleName,
            catalog_id: data.catalogId,
            main_part_number: data.mainPartNumber,
            related_part_numbers: partNumbers,
            display: data.display ? 1 : 0,
        };

        await relItems.createEntity(relItemEntity);

        const relItemId = await getLastInsertId();
        await createNewProducts(data.products, relItemId);
        await fetchRelItems();

        setOpen(false);
    };

    const handleUpdate = async (data) => {
        const partNumbers = extractPartNumbers(data.products);
        const relItemEntity = {
            id: data.id,
            show_in_carousel_format: data.showInCarouselFormat,
            rule_name: data.ruleName,
            main_part_number: data.mainPartNumber,
            related_part_numbers: partNumbers,
        };

        await relItems.updateEntity(relItemEntity);

        const pIdsToDelete = data.products.filter((p) => p.status === "deleted").map((p) => p.id);

        if (pIdsToDelete.length > 0) {
            await relItemsDet.deleteAllEntitiesHard(pIdsToDelete);
        }

        await createNewProducts(data.products, relItemEntity.id);
        await fetchRelItems();

        setOpen(false);
    };

    const getLastInsertId = async () => {
        const items = await relItems.readAllEntities({ dealer_id: dealerId });
        const { id = "" } = items[items.length - 1];
        return id;
    };

    const parseProduct = (product) => {
        const [make, year, model, trim, engine, category, subcategory] = product.slugs;

        const parsedProduct = {
            dealer_id: dealerId,
            product_id: product.id,
            name: product.name,
            part_number: product.part_number,
            path_image: product.pathImage || product.path_image,
            assembly: product.assembly,
            make_id: make.id,
            make_name: make.name,
            make_slug: make.slug,
            year_name: year.name,
            model_id: model.id,
            model_name: model.name,
            model_slug: model.slug,
            trim_id: trim.id,
            trim_name: trim.name,
            trim_slug: trim.slug,
            engine_id: engine.id,
            engine_name: engine.name,
            engine_slug: engine.slug,
            engine_vtceid: engine.VehicleToEngineConfigID,
            category_id: category.id,
            category_name: category.name,
            category_slug: category.slug,
            subcategory_id: subcategory.id,
            subcategory_name: subcategory.name,
            subcategory_slug: subcategory.slug,
            price_price: product.price.price,
            price_show_price: product.price.showPrice,
        };

        return parsedProduct;
    };

    const handleClickEdit = async (initialValues) => {
        const detail = await relItemsDet.readAllEntities({
            related_item_id: initialValues.id,
        });
        setFormValues({
            ...initialValues,
            showInCarouselFormat: initialValues.showInCarouselFormat === 1,
            products: detail.map((d) => ({ ...d, part_number: d.partNumber, status: "" })),
        });
        setOpen(true);
    };

    const extractPartNumbers = (products = []) => {
        return products
            .filter((p) => p.status !== "deleted")
            .map((p) => p.part_number)
            .join(", ");
    };

    const createNewProducts = async (products = [], relItemId = "") => {
        const newProducts = products.filter((p) => p.status === "new");

        for (let i = 0; i < newProducts.length; i++) {
            const parsedProduct = parseProduct(newProducts[i]);
            parsedProduct.related_item_id = relItemId;
            await relItemsDet.createEntity(parsedProduct);
        }
    };

    const handleClickDelete = async (id) => {
        const items = await relItemsDet.readAllEntities({ related_item_id: id });
        const pIdsToDelete = items.map((p) => p.id);

        if (pIdsToDelete.length > 0) {
            await relItemsDet.deleteAllEntitiesHard(pIdsToDelete);
        }

        await relItems.deleteEntity(id);
        await fetchRelItems();
    };

    const handleClickShowHide = async (id, e) => {
        const relItemEntity = {
            id: id,
            display: e.target.checked ? 1 : 0,
        };

        await relItems.updateEntity(relItemEntity);
        await fetchRelItems();
    };

    return {
        open,
        setOpen,
        formValues,
        data: relItems.data,
        handleInsert,
        handleUpdate,
        handleClickEdit,
        handleClickDelete,
        handleClickShowHide,
    };
};

export default useRelatedItems;
