import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const getSuggestions = async (dlid = "", skip = 0, limit = 0, body) => {
    const res = await http.post(
        BASE_URL_API_ECOMMERCE + `/suggestions-search?dlid=${dlid}&offset=${skip}&limit=${limit}`,
        body
    );
    return res.data;
};

const validatePartNumbers = async ({
    dlid = "",
    USA = "USA",
    catalog = "motor",
    partNumbers = [],
}) => {
    const res = await http.post(
        BASE_URL_API_ECOMMERCE +
            `/validate-part-numbers?dealerId=${dlid}&region=${USA}&catalog=${catalog}`,
        {
            partNumbers,
        }
    );
    return res.data.data;
};

const ProductService = {
    getSuggestions,
    validatePartNumbers,
};

export default ProductService;
