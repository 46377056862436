import React from "react";
import { Button } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

function TextFileReaderUploader({ accept, onUpload = () => {}, ...otherProps }) {
    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const fileData = e.target.result;
                onUpload(fileData);
            };

            reader.readAsText(file);
        }
    };

    const handleClick = () => {
        // Limpiar el valor del campo de entrada de archivos
        const fileInput = document.getElementById("file-input");
        fileInput.value = "";
    };

    return (
        <Button
            {...otherProps}
            variant="outlined"
            component="label"
            size="small"
            onClick={handleClick}
        >
            Upload File
            <input id="file-input" hidden type="file" accept={accept} onChange={handleFileUpload} />
            <PublishIcon />
        </Button>
    );
}

export default TextFileReaderUploader;
