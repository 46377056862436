import React, { useState, useMemo } from "react";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTableV2 from "components/ReactTable/ReactTableV2";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import SearchProduct from "./SearchProduct";

// material
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// service
import QuoteService from "services/QuoteService";
import QuoteLogService from "services/QuoteLogService";

// hooks
import useUser from "hooks/useUser";

// libs
import moment from "moment";

// const
import { PRICE_CHANGE, QUANTITY_CHANGE, WEIGHT_CHANGE, STATUS_CHANGE, SHIP_GROUP_CHANGE, REMOVE_PRODUCT } from "const";
import { financial } from "helpers";

export default function QuoteProducts({ dealer, formik }) {
    const [rowIndex, setRowIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [user] = useUser();

    const columns = useMemo(
        () => [
            {
                Header: "Sku",
                accessor: "partNumber"
            },
            {
                Header: "Description",
                Cell: ({ row: { original: { description, year, makeSlug, modelSlug } } }) => {
                    return (
                        <>
                            {description}
                            {
                                <div>
                                    {year}
                                    {' '}
                                    {makeSlug}
                                    {' '}
                                    {modelSlug}
                                </div>
                            }
                        </>
                    )
                }
            },
            {
                Header: "Price",
                id: "price",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        name={`details[${index}].price`}
                        value={original.price}
                        onChange={async (e) => {
                            const oldPrice = original.price;
                            const newPrice = e.target.value;
                            formik.setFieldValue(`details[${index}].price`, newPrice);

                            if (original.id > 0)
                                await QuoteLogService.post({
                                    dealerId: formik.values.dealerId,
                                    quoteId: formik.values.id,
                                    userId: user.id,
                                    quoteNumber: formik.values.quoteNumber,
                                    action: PRICE_CHANGE,
                                    memo: JSON.stringify({
                                        ...original,
                                        oldPrice,
                                        newPrice
                                    })
                                });
                        }}
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: 'right', width: 90
                            }
                        }}
                    />
                )
            },
            {
                Header: "Quantity",
                id: "qty",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        type="number"
                        name={`details[${index}].qty`}
                        value={original.qty}
                        onChange={async (e) => {
                            const oldQty = original.qty;
                            const newQty = Number(e.target.value);
                            formik.setFieldValue(`details[${index}].qty`, newQty);

                            if (original.id > 0)
                                await QuoteLogService.post({
                                    dealerId: formik.values.dealerId,
                                    quoteId: formik.values.id,
                                    userId: user.id,
                                    quoteNumber: formik.values.quoteNumber,
                                    action: QUANTITY_CHANGE,
                                    memo: JSON.stringify({
                                        ...original,
                                        oldQty,
                                        newQty
                                    })
                                });
                        }}
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: 'right', width: 90
                            }
                        }}
                    />
                )
            },
            {
                Header: "Item Weight",
                id: "itemWeight",
                Cell: ({ row: { original, index } }) => (
                    <TextField
                        name={`details[${index}].itemWeight`}
                        value={original.itemWeight || ''}
                        onChange={async (e) => {
                            const oldItemWeight = original.itemWeight;
                            const newItemWeight = e.target.value;
                            formik.setFieldValue(`details[${index}].itemWeight`, newItemWeight);

                            if (original.id > 0)
                                await QuoteLogService.post({
                                    dealerId: formik.values.dealerId,
                                    quoteId: formik.values.id,
                                    userId: user.id,
                                    quoteNumber: formik.values.quoteNumber,
                                    action: WEIGHT_CHANGE,
                                    memo: JSON.stringify({
                                        ...original,
                                        oldItemWeight,
                                        newItemWeight
                                    })
                                });
                        }}
                        inputProps={{
                            min: 0,
                            style: {
                                textAlign: 'right', width: 90
                            }
                        }}
                    />
                )
            },
            {
                Header: "Subtotal",
                Cell: ({ row: { original: { price, qty } } }) => financial(price * qty)
            },
            {
                Header: () => "Actions",
                id: 'expander',
                Cell: ({ row }) => (
                    <>
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            onClick={() => {
                                const isExpanded = !(row.isExpanded || false);
                                const currentDate = moment(new Date()).format('MM/DD/YYYY');
                                const msgLog = `${currentDate}, ${user.completeName || ''}`;

                                if (isExpanded) {
                                    if (row.original.intMemo) {
                                        formik.setFieldValue(`details[${row.index}].intMemo`, `${row.original.intMemo}\n${msgLog}: `);
                                    } else {
                                        formik.setFieldValue(`details[${row.index}].intMemo`, msgLog);
                                    }
                                }

                                row.toggleRowExpanded();
                            }}
                        >
                            <Edit />
                        </Button>
                        <Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            title="Delete"
                            onClick={() => {
                                setRowIndex(row.index);
                                setOpen(true);
                            }}
                        >
                            <Close />
                        </Button>
                    </>
                ),
            },
        ],
        [user]
    );

    const renderRowSubComponent = React.useCallback(
        ({ row: { original, index } }) => {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            multiline
                            rows={6}
                            label="Internal memo"
                            name={`details[${index}].intMemo`}
                            value={original.intMemo || ''}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            multiline
                            rows={6}
                            label="User memo"
                            name={`details[${index}].userMemo`}
                            value={original.userMemo || ''}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <TextField
                            fullWidth
                            multiline
                            rows={6}
                            label="Shipping memo"
                            name={`details[${index}].shipMemo`}
                            value={original.shipMemo || ''}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                </GridContainer>
            );
        },
        []
    );

    const delProduct = async id => {
        try {
            await QuoteService.delProduct(id);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    const newDetails = [...formik.values.details];
                    const id = newDetails[rowIndex].id;
                    await delProduct(id);
                    newDetails.splice(rowIndex, 1);
                    formik.setFieldValue("details", newDetails);
                    setRowIndex(-1);
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
            <Box m={1}>
                <SearchProduct
                    dealer={dealer}
                    onAddProduct={(value) => {
                        const newDetails = [...formik.values.details];
                        formik.setFieldValue("details", [...newDetails, { ...value, itemWeight: '' }]);
                    }}
                />
            </Box>
            <ReactTableV2
                data={formik.values.details || []}
                columns={columns}
                rowKey="id"
                renderRowSubComponent={renderRowSubComponent}
            />
        </>
    );
}