import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DealerShipping from 'services/DealerShipping';
import { useSnackbar } from './useSnackbar';

const DEFAULT_RANGES = Array.from({ length: 5 }, () => ({
    minOrderTotalRange: '',
    maxOrderTotalRange: '',
    shipChargeOrderTotal: '',
    flatShipCharge: '',
    minShipCharge: '',
}));

const useRangeManager = (formSubmitCallback) => {
    const openSnackbar = useSnackbar();
    const [initialValues, setInitialValues] = useState({
        ranges: DEFAULT_RANGES
    });

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            formSubmitCallback(values);
            updateShippingMethod(values);
        },
    });

    const fetchShippingMethod = async (id) => {
        try {
            const methods = await DealerShipping.get(id);

            if (methods.length > 0) {
                const method = methods[0];
                const ranges = method.ranges;

                setInitialValues({
                    ...method,
                    ranges: ranges.length === 0 ? DEFAULT_RANGES : ranges
                });
            }
        } catch (error) {
            console.error('Error fetching shipping methods:', error);
        }
    }

    const updateShippingMethod = async (updatedMethod) => {
        try {
            await DealerShipping.update(updatedMethod.id, updatedMethod);
            openSnackbar('success', 'Range updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating range. Please try again.');
        }
    };

    return {
        formik,
        fetchShippingMethod
    };
}

export default useRangeManager;