import React from "react";
import PageLayout from "layouts/PageLayout";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import Table from "components/ReactTable/ReactTableV2";
import Button from "components/CustomButtons/Button.js";
import AreYouSureWithHooks from "components/AreYouSureWithHooks/AreYouSureWithHooks";
import CreateDealerTax from "./CreateDealerTax";
import AllStatesTax from "./AllStatesTax";
import useDealerTax from "hooks/useDealerTax";
import DeleteButton from "components/DeleteButton/DeleteButton";

export default function DealerTax() {
    const { taxes = [], handleDeleteTax, fetchTaxes } = useDealerTax();

    const columns = [
        {
            Header: "State/Province",
            accessor: "stateName",
        },
        {
            Header: "%",
            accessor: "taxPercentage",
        },
        {
            Header: "Apply Current Tax Rules To Showroom",
            accessor: "applyCurrentTaxRulesToShowroom",
        },
        {
            Header: "Labor",
            accessor: "applyLaborOnOrderTotal",
            Cell: ({ cell }) => (cell.value ? "Y" : "N"),
        },
        {
            Header: "Parts/Accessories",
            accessor: "applyTaxToPartsAccessories",
            Cell: ({ cell }) => (cell.value ? "Y" : "N"),
        },
        {
            Header: "Shipping",
            accessor: "applyTaxToShipping",
            Cell: ({ cell }) => (cell.value ? "Y" : "N"),
        },
        {
            Header: "No Tax",
            accessor: "noTax",
            Cell: ({ cell }) => (cell.value ? "Y" : "N"),
        },
        {
            Header: "Action",
            Cell: ({ cell }) => (
                <>
                    <CreateDealerTax
                        defaultValues={cell.row.original}
                        onRegistrationComplete={fetchTaxes}
                    />
                    {` | `}
                    <DeleteButton
                        title="dealer tax"
                        handleClick={async () => {
                            handleDeleteTax(cell.row.original.id);
                        }}
                    />
                    {/*<AreYouSureWithHooks onConfirm={() => handleDeleteTax(cell.row.original.id)} />*/}
                </>
            ),
        },
    ];

    return (
        <PageLayout customComponent={<></>}>
            <Box marginBottom={2}>
                <Card>
                    <CardContent>
                        <AllStatesTax />
                    </CardContent>
                </Card>
            </Box>
            <Card>
                <CardContent>
                    <Box display="flex">
                        <Typography style={{ fontSize: 18 }} gutterBottom>
                            Individual State/Province Sales Tax Rules
                        </Typography>
                        <Typography style={{ marginLeft: "auto" }} gutterBottom>
                            <CreateDealerTax isCreateForm onRegistrationComplete={fetchTaxes} />
                        </Typography>
                    </Box>
                    <Box>
                        <Table columns={columns} data={taxes} fetchData={() => {}} pageCount={1} />
                    </Box>
                    <Box textAlign="center" mt={2}>
                        <Button type="submit" color="rose">
                            Update sales tax
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </PageLayout>
    );
}
