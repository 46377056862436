import React, { useState, useEffect } from "react";

//@material-ui/core components
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ButtonMUI from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import Tooltip from "@material-ui/core/Tooltip";

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal.js";
import EditFieldText from "components/EditFieldText/EditFieldText";
import EditColumnText from "components/EditColumnText/EditColumnText";
import Notification from "components/Notification/Notification.js";

//libs
import { useFormik, FormikProvider, FieldArray } from "formik";

import DealerMiscService from "services/DealerMiscService";
import DealerService from "services/DealerService";
import { DEF_INITIAL_VALUES } from "./initialValues";
import CreateCustomPage from "views/CustomPage/CreateCustomPage";
import EditButton from "components/EditButton/EditButton";
import DeleteButton from "components/DeleteButton/DeleteButton";

const COMP_EDIT_FIELD_TEXT = "COMP_EDIT_FIELD_TEXT";
const COMP_EDIT_COL_TEXT = "COMP_EDIT_COL_TEXT";

export default function EditFooterOptions({ dlid = 0 }) {
    const [initValues, setInitValues] = useState({
        columns: DEF_INITIAL_VALUES,
    });
    const [component, setComponent] = useState(null);
    const [dialog, setDialog] = useState({
        open: false,
    });
    const [field, setField] = useState({
        col_index: null,
        field_index: null,
        values: null,
    });
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
        message: "Successful update of footer options",
    });

    useEffect(() => {
        const getDealer = async (dlid) => {
            const { dealerPages = [] } = await DealerService.get(dlid);
            return dealerPages;
        };

        const getMisc = async (dlid) => {
            const { dealerPages = [] } = await DealerService.get(dlid);
            const misc = await DealerMiscService.getAll(dlid).then((d) =>
                d.data.length === 1 ? d.data[0] : d.data
            );

            const menuItems = dealerPages.map((page) => {
                const { pageUrl, menuTitle } = page;
                const url = `/page/${pageUrl}`;

                return {
                    isCustomPage: true,
                    url: url,
                    newTab: false,
                    en_text: menuTitle,
                    es_text: "",
                    fr_text: "",
                    pt_text: "",
                    customPageInitialValues: page,
                };
            });

            const updatedFooterOptions = misc.footerOptions.columns.map((c) => {
                if (c.en_title === "INFORMATION") {
                    const filteredItems = [...c.fields, ...menuItems].filter((m) => m.en_text);
                    let emptyItems = [...c.fields, ...menuItems].filter((m) => !m.en_text);

                    return {
                        ...c,
                        fields: [...filteredItems, ...emptyItems].filter((_, i) => i <= 6),
                    };
                }

                return {
                    ...c,
                    fields: [...c.fields],
                };
            });

            if (updatedFooterOptions) {
                setInitValues({
                    columns: updatedFooterOptions,
                });
            }
        };

        if (dlid) {
            getMisc(dlid);
        }
    }, [dlid]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initValues,
        onSubmit: (values) => {
            update(dlid, values);
        },
    });

    const update = async (dlid, body) => {
        const minColumnsLength = 4;
        const minFieldsLength = 7;

        const emptyField = {
            url: "",
            newTab: false,
            en_text: "",
            es_text: "",
            fr_text: "",
            pt_text: "",
        };

        if (body.columns.length < minColumnsLength) {
            const missingElements = minColumnsLength - body.columns.length;

            for (let i = 0; i < missingElements; i++) {
                body.columns.push({
                    en_title: "",
                    es_title: "",
                    fields: Array(minFieldsLength).fill(emptyField),
                    fr_title: "",
                    pt_title: "",
                });
            }
        }

        body.columns.forEach((column) => {
            if (column.fields.length < minFieldsLength) {
                const missingFields = minFieldsLength - column.fields.length;
                for (let i = 0; i < missingFields; i++) {
                    column.fields.push({ ...emptyField });
                }
            } else {
                column.fields = column.fields.map((field) => ({ ...emptyField, ...field }));
            }
        });

        try {
            await DealerMiscService.putFooter(dlid, body);
            setNotification({ ...notification, open: !notification.open });
        } catch (error) {
            console.error(error);
        }
    };

    const toogleDialog = () =>
        setDialog({
            ...dialog,
            open: !dialog.open,
        });

    const handleClickEditField = (col_index, field_index) => {
        setComponent(COMP_EDIT_FIELD_TEXT);
        setField({
            col_index,
            field_index,
            values: formik.values.columns[col_index].fields[field_index],
        });
        toogleDialog();
    };

    const handleClickEditCol = (col_index) => {
        setComponent(COMP_EDIT_COL_TEXT);
        setField({
            col_index,
            field_index: null,
            values: formik.values.columns[col_index],
        });
        toogleDialog();
    };

    const handleEditCol = ({ enTitle = "", esTitle = "", frTitle = "", ptTitle = "" }) => {
        const column = `columns[${field.col_index}]`;

        formik.setFieldValue(`${column}.en_title`, enTitle);
        formik.setFieldValue(`${column}.es_title`, esTitle);
        formik.setFieldValue(`${column}.fr_title`, frTitle);
        formik.setFieldValue(`${column}.pt_title`, ptTitle);

        resetField();
        toogleDialog();
    };

    const handleEditField = ({
        enText = "",
        esText = "",
        frText = "",
        ptText = "",
        url = "",
        newTab = false,
    }) => {
        const column = `columns[${field.col_index}].fields[${field.field_index}]`;

        formik.setFieldValue(`${column}.en_text`, enText);
        formik.setFieldValue(`${column}.es_text`, esText);
        formik.setFieldValue(`${column}.fr_text`, frText);
        formik.setFieldValue(`${column}.pt_text`, ptText);
        formik.setFieldValue(`${column}.url`, url);
        formik.setFieldValue(`${column}.newTab`, newTab);

        resetField();
        toogleDialog();
    };

    const resetField = () => setField({ col_index: null, field_index: null, values: null });

    console.log(formik.values);
    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                    onClose={() => setNotification({ ...notification, open: !notification.open })}
                />
            )}

            {/* Dialog */}
            <Modal
                open={dialog.open}
                modalTitle=""
                handleClose={() => {
                    resetField();
                    toogleDialog();
                }}
            >
                <div style={{ minWidth: 400 }}></div>
                {component === COMP_EDIT_FIELD_TEXT && field.values != null ? (
                    <EditFieldText
                        enText={field.values.en_text}
                        esText={field.values.es_text}
                        frText={field.values.fr_text}
                        ptText={field.values.pt_text}
                        url={field.values.url}
                        newTab={field.values.newTab}
                        onSubmit={handleEditField}
                    />
                ) : null}
                {component === COMP_EDIT_COL_TEXT && field.values != null ? (
                    <EditColumnText
                        enText={field.values.en_title}
                        esText={field.values.es_title}
                        frText={field.values.fr_title}
                        ptText={field.values.pt_title}
                        onSubmit={handleEditCol}
                    />
                ) : null}
            </Modal>

            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <FieldArray
                        name="columns"
                        render={(_) => (
                            <>
                                <GridContainer>
                                    {formik.values.columns.map((column, col_index) => (
                                        <GridItem key={col_index} xs={12} sm={3}>
                                            <Grid container direction="column">
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        fullWidth
                                                        margin="dense"
                                                        name={`columns[${col_index}].en_title`}
                                                        label={`EN column ${col_index + 1} title`}
                                                        value={
                                                            formik.values.columns[col_index]
                                                                .en_title
                                                        }
                                                        onChange={formik.handleChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <EditButton
                                                                        handleClick={() =>
                                                                            handleClickEditCol(
                                                                                col_index
                                                                            )
                                                                        }
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <FieldArray
                                                    name="fields"
                                                    render={(_) => (
                                                        <>
                                                            {column.fields.map(
                                                                (field, field_index) => (
                                                                    <Grid
                                                                        key={field_index}
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                    >
                                                                        <TextField
                                                                            fullWidth
                                                                            margin="dense"
                                                                            name={`columns[${col_index}].fields[${field_index}].en_text`}
                                                                            label={`EN field ${
                                                                                field_index + 1
                                                                            }`}
                                                                            value={
                                                                                formik.values
                                                                                    .columns[
                                                                                    col_index
                                                                                ].fields[
                                                                                    field_index
                                                                                ].en_text
                                                                            }
                                                                            onChange={
                                                                                formik.handleChange
                                                                            }
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        {field.isCustomPage && (
                                                                                            <Tooltip title="Custom page">
                                                                                                <InsertDriveFileOutlinedIcon />
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </InputAdornment>
                                                                                ),
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        {field.isCustomPage ? (
                                                                                            <>
                                                                                                <CreateCustomPage
                                                                                                    isEditForm
                                                                                                    defaultValues={
                                                                                                        field.customPageInitialValues
                                                                                                    }
                                                                                                    onRegistrationComplete={() => {
                                                                                                        location.reload();
                                                                                                    }}
                                                                                                />
                                                                                                <DeleteButton
                                                                                                    handleClick={() => {
                                                                                                        formik.setFieldValue(
                                                                                                            `columns[${col_index}].fields[${field_index}]`,
                                                                                                            {
                                                                                                                url:
                                                                                                                    "",
                                                                                                                newTab: false,
                                                                                                                en_text:
                                                                                                                    "",
                                                                                                                es_text:
                                                                                                                    "",
                                                                                                                fr_text:
                                                                                                                    "",
                                                                                                                pt_text:
                                                                                                                    "",
                                                                                                            }
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <EditButton
                                                                                                    handleClick={() =>
                                                                                                        handleClickEditField(
                                                                                                            col_index,
                                                                                                            field_index
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <DeleteButton
                                                                                                    handleClick={() => {
                                                                                                        formik.setFieldValue(
                                                                                                            `columns[${col_index}].fields[${field_index}]`,
                                                                                                            {
                                                                                                                url:
                                                                                                                    "",
                                                                                                                newTab: false,
                                                                                                                en_text:
                                                                                                                    "",
                                                                                                                es_text:
                                                                                                                    "",
                                                                                                                fr_text:
                                                                                                                    "",
                                                                                                                pt_text:
                                                                                                                    "",
                                                                                                            }
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                        </GridItem>
                                    ))}
                                </GridContainer>
                            </>
                        )}
                    />
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ textAlign: "center", marginTop: 25 }}
                        >
                            <Button type="submit" color="rose">
                                Save
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </FormikProvider>
        </>
    );
}
