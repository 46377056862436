import React from "react";
import {
    Select,
    MenuItem,
    Link,
    FormControl,
    InputLabel,
    InputAdornment,
    Box,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import Modal from "components/Modal/Modal";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";
import useModal from "hooks/useModal";
import useStates from "hooks/useStates";
import useCreateDealerTax from "hooks/useCreateDealerTax";
import EditButton from "components/EditButton/EditButton";

export default function CreateDealerTax({
    isCreateForm = false,
    defaultValues,
    onRegistrationComplete = () => {},
}) {
    const handleSuccess = () => {
        handleCloseModal();
        onRegistrationComplete();
    };

    const { open, handleOpenModal, handleCloseModal } = useModal();
    const { states = [] } = useStates();
    const { formik } = useCreateDealerTax(isCreateForm, defaultValues, handleSuccess);
    const modalTitle = isCreateForm ? "Create tax" : "Edit tax";

    const handleClick = (e) => {
        e.preventDefault();
        handleOpenModal();
    };

    return (
        <>
            {isCreateForm ? (
                <Button color="primary" onClick={handleOpenModal}>
                    Create rule
                </Button>
            ) : (
                <EditButton handleClick={handleClick} />
            )}

            <Modal open={open} modalTitle={modalTitle} handleClose={handleCloseModal}>
                <form onSubmit={formik.handleSubmit}>
                    <Box>
                        <FormControl
                            fullWidth
                            error={formik.touched.stateCode && Boolean(formik.errors.stateCode)}
                        >
                            <InputLabel>State/Province</InputLabel>
                            <Select
                                readOnly={!isCreateForm}
                                fullWidth
                                label="State/Province"
                                name="stateCode"
                                value={formik.values.stateCode}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={""}>Select</MenuItem>
                                {states.map((color, index) => (
                                    <MenuItem key={color.code} value={color.code}>
                                        {color.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <TextFieldRender
                            type="number"
                            fullWidth
                            label="All States/Province Tax Rate"
                            name="stateProvince"
                            value={formik.values.taxPercentage}
                            handleBlur={(value) =>
                                formik.setFieldValue("taxPercentage", parseFloat(value))
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            error={
                                formik.touched.taxPercentage && Boolean(formik.errors.taxPercentage)
                            }
                        />
                    </Box>
                    <Box mt={2}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Tax Option:</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.applyTaxToPartsAccessories}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "applyTaxToPartsAccessories",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="  Apply tax to parts/accessories"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.applyTaxToShipping}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "applyTaxToShipping",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="  Apply tax to shipping"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formik.values.applyLaborOnOrderTotal}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    "applyLaborOnOrderTotal",
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    }
                                    label="Apply Labor on Order Total"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                    <Box textAlign="center" mt={2}>
                        <Button type="submit" color="rose">
                            Save sales tax
                        </Button>
                    </Box>
                </form>
            </Modal>
        </>
    );
}
