import React, { useState, useEffect } from "react";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import CreateDealerPayment from "components/CreateDealerPayment/CreateDealerPayment";

import DealerPaymentService from "../../../services/DealerPaymentService";
import DeleteButton from "components/DeleteButton/DeleteButton";

export const CREATE_DEALER_PAYMENT = {
    title: "Create dealer payment",
    successMessage: "Dealer payment created successfully",
};

export const EDIT_DEALER_PAYMENT = {
    title: "Edit dealer payment",
    successMessage: "Dealer payment updated successfully",
};

export default function DealerPayments({ dlid }) {
    const [open, setOpen] = useState(false);
    const [dealerPayments, setDealerPayments] = useState([]);
    const [form, setForm] = useState(CREATE_DEALER_PAYMENT);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });

    const getDealerPayments = async (dlid) => {
        const payments = await DealerPaymentService.getAll(dlid);
        setDealerPayments(payments.data);
    };

    useEffect(() => {
        if (dlid) getDealerPayments(dlid);
    }, [dlid]);

    const columns = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Actions",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                color="warning"
                                className="edit"
                                title="Edit"
                                onClick={() => {
                                    setForm(EDIT_DEALER_PAYMENT);
                                    setOpenModal(true);
                                    setInitialValues(original);
                                }}
                            >
                                <Edit />
                            </Button>
                            <DeleteButton
                                title="payment"
                                handleClick={async () => {
                                    await DealerPaymentService.del(original.id);
                                    setDealerPayments(
                                        [...dealerPayments].filter(
                                            (payment) => payment.id !== original.id
                                        )
                                    );
                                }}
                            />
                            {/*<Button
                                justIcon
                                round
                                simple
                                color="danger"
                                className="edit"
                                title="Delete"
                                onClick={() => {
                                    setInitialValues(original);
                                    setOpen(true);
                                }}
                            >
                                <Close />
                            </Button>*/}
                        </div>
                    </>
                );
            },
        },
    ];

    const onSuccess = (payment) => {
        if (form === CREATE_DEALER_PAYMENT) {
            setDealerPayments([...dealerPayments, payment]);
        }

        if (form === EDIT_DEALER_PAYMENT) {
            const updatedPayments = dealerPayments.map((p) =>
                p.id === payment.id ? { ...p, ...payment } : p
            );
            setDealerPayments(updatedPayments);
        }

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
        getDealerPayments(dlid);
    };

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                />
            )}

            <Modal open={openModal} modalTitle={form.title} handleClose={setOpenModal}>
                <div style={{ minWidth: 800 }}></div>
                <CreateDealerPayment
                    dlid={dlid}
                    form={form}
                    initialValues={initialValues}
                    onSuccess={onSuccess}
                />
            </Modal>

            <Button
                color="primary"
                onClick={() => {
                    setForm(CREATE_DEALER_PAYMENT);
                    setOpenModal(true);
                    setInitialValues({});
                }}
            >
                Create dealer payment
            </Button>

            <ReactTable
                columns={columns}
                data={dealerPayments}
                showPagination={false}
                showSorting={false}
            />

            <AreYouSure
                open={open}
                title={`Are you sure?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    await DealerPaymentService.del(initialValues.id);
                    setDealerPayments(
                        [...dealerPayments].filter((payment) => payment.id !== initialValues.id)
                    );
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}

/*import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "components/ReactTable/ReactTable.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Checkbox } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import { Check } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Notification from "components/Notification/Notification.js";
import DealerCatalogService from '../../../services/DealerCatalogService';
import DealerPaymentService from '../../../services/DealerPaymentService';

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
const useStyles = makeStyles(styles);

function TextField({ labelText = '', initialValue = '', onChange, otherProps }) {
    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return <CustomInput
        labelText={labelText}
        formControlProps={{
            fullWidth: true,
        }}
        inputProps={{
            value: value,
            onChange: ({ target: { value } }) => setValue(value),
            onBlur: onChange,
            ...otherProps
        }}
    />;
}

function EditPayment({ original, payments, onSubmit }) {
    const {
        id,
        name,
        description,
        apiUrl,
        clientId,
        createdAt,
        updatedAt
    } = original;

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(payments);
    }, [payments]);

    return <React.Fragment>
        <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <TextField labelText={"Name"} initialValue={name} onChange={(e) => {
                    let cat = data.find(x => x.id === id);

                    if (name != e.target.value)
                        setData(
                            [
                                ...data.filter(x => x.id !== id),
                                {
                                    ...cat,
                                    name: e.target.value
                                }
                            ]
                        );
                }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField labelText={"Description"} initialValue={description} onChange={(e) => {
                    let cat = data.find(x => x.id === id);

                    if (description != e.target.value)
                        setData(
                            [
                                ...data.filter(x => x.id !== id),
                                {
                                    ...cat,
                                    description: e.target.value
                                }
                            ]
                        );
                }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField labelText={"API url"} initialValue={apiUrl} onChange={(e) => {
                    let cat = data.find(x => x.id === id);

                    if (apiUrl != e.target.value)
                        setData(
                            [
                                ...data.filter(x => x.id !== id),
                                {
                                    ...cat,
                                    apiUrl: e.target.value
                                }
                            ]
                        );
                }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField labelText={"Client id"} initialValue={clientId} onChange={(e) => {
                    let cat = data.find(x => x.id === id);

                    if (clientId != e.target.value)
                        setData(
                            [
                                ...data.filter(x => x.id !== id),
                                {
                                    ...cat,
                                    clientId: e.target.value
                                }
                            ]
                        );
                }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField labelText={"Created At"} initialValue={createdAt} otherProps={{ readOnly: true }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <TextField labelText={"Updated At"} initialValue={updatedAt} otherProps={{ readOnly: true }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginBottom: 10 }}>
                <Button color="rose" size="sm" onClick={() => onSubmit(original, data)}>
                    Save
                </Button>
            </GridItem>
        </GridContainer>
    </React.Fragment>;
}

export default function DealerPayments({ dlid }) {
    const classes = useStyles();
    const [rowId, setRowId] = useState(-1);
    const [payments, setPayments] = useState([]);
    const [notification, setNotification] = useState({
        open: false,
        message: 'Dealer catalog updated successfully'
    });

    useEffect(() => {
        const getPayments = async (dlid) => {
            const payments = await DealerPaymentService.getAll(dlid).then(x => x.data);
            setPayments(payments)
        };
        const getCatalogs = async (dlid) => {
            const catalogs = await DealerCatalogService.getAll(dlid).then(x => x.data);
            //setCatalogs(catalogs);
        };

        if (dlid) getCatalogs(dlid);
        getPayments(dlid)
    }, [dlid]);

    const handleClick = async ({ target: { checked } }, catalog) => {
        console.log(catalog)
    }

    const columns = [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Description",
            accessor: "description",
        },
        {
            Header: () => null,
            id: 'expander',
            Cell: ({ row }) => {
                const rowIdx = row.id;

                return <>
                    <Button
                        justIcon
                        round
                        simple
                        color="warning"
                        className="edit"
                        onClick={() => {
                            if (rowIdx == rowId) {
                                setRowId(-1);
                            } else {
                                setRowId(rowIdx);
                            }

                            row.toggleRowExpanded();
                        }}
                    >
                        <Edit />
                    </Button>
                </>;
            }
        },
    ];

    const postCatalog = async (catalog) => {
        const dealerCatalogId = await DealerCatalogService.post(catalog).then(x => x.data.dealerCatalogId);

        setPayments(
            [
                ...payments.filter(x => x.id !== catalog.id),
                {
                    ...catalog,
                    isChecked: true,
                    dealerCatalogId
                }
            ]

        );
    }

    const putPayment = async (payment) => {
        await DealerPaymentService.put(payment);
    }

    const renderRowSubComponent = ({ row: { original } }) => {
        return <>
            <div style={{ marginTop: 5, marginBottom: 5 }}>
                <Notification
                    open={notification.open}
                    message={notification.message}
                    close
                    color="success"
                    onClose={(e) => openNotification(e)}
                />
            </div>
            <EditPayment original={original} payments={payments} onSubmit={async (original, data) => {
                let payment = original;

                if (payment.id) {
                    payment = data.find(x => x.id === original.id);
                    await putPayment(payment);
                }
            }} />
        </>;
    }

    const openNotification = (open = notification.open, message = notification.message) => setNotification({
        ...notification,
        open,
        message
    });

    return (
        <>
            <ReactTable
                columns={columns}
                data={payments.sort((a, b) => a.id - b.id)}
                renderRowSubComponent={renderRowSubComponent}
                showPagination={false}
                showSorting={false}
                activeRow={rowId} />
        </>
    )
};*/
