import TextFieldMask from "components/TextFieldRender/TextFieldRender";
import TableWrapper from "components/TableWrapper/TableWrapper";

export default function BulkTable({
    products = [],
    setProducts = () => {},
    onPartNumberChange = () => {},
    onDeleteClick = () => {},
}) {
    const columns = [
        {
            Header: "Part number(s)",
            accessor: "partNumber",
            Cell: ({ row, cell }) => {
                const hasError = cell.row.original.error;

                return (
                    <TextFieldMask
                        value={cell.value || ""}
                        handleBlur={(partNumber = "") => {
                            onPartNumberChange(row.index, partNumber);
                        }}
                        helperText={hasError ? "Part number does not exist" : ""}
                        style={{ minWidth: 200 }}
                        error={hasError}
                    />
                );
            },
        },
        {
            Header: "Name/Title",
            accessor: "name",
            Cell: ({ row, cell }) => (
                <TextFieldMask
                    value={cell.value || ""}
                    handleBlur={(name) => {
                        const updatedProducts = [...products];
                        updatedProducts[row.index].name = name;
                        setProducts(updatedProducts);
                    }}
                />
            ),
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ row, cell }) => (
                <TextFieldMask
                    value={cell.value || ""}
                    handleBlur={(description) => {
                        const updatedProducts = [...products];
                        updatedProducts[row.index].description = description;
                        setProducts(updatedProducts);
                    }}
                />
            ),
        },
        {
            Header: "Selling Price",
            accessor: "list",
            Cell: ({ row, cell }) => (
                <TextFieldMask
                    value={cell.value || ""}
                    handleBlur={(list) => {
                        const updatedProducts = [...products];
                        updatedProducts[row.index].list = list;
                        setProducts(updatedProducts);
                    }}
                />
            ),
        },
        {
            Header: "Labor Time",
            accessor: "laborTime",
            Cell: ({ row, cell }) => (
                <TextFieldMask
                    value={cell.value || ""}
                    handleBlur={(laborTime) => {
                        const updatedProducts = [...products];
                        updatedProducts[row.index].laborTime = laborTime;
                        setProducts(updatedProducts);
                    }}
                />
            ),
        },
        {
            Header: "Core",
            accessor: "core",
            Cell: ({ row, cell }) => (
                <TextFieldMask
                    value={cell.value || ""}
                    handleBlur={(core) => {
                        const updatedProducts = [...products];
                        updatedProducts[row.index].core = core;
                        setProducts(updatedProducts);
                    }}
                />
            ),
        },
        {
            Header: "Hide item(s)",
            accessor: "hideItem",
            Cell: ({ row, cell }) => (
                <TextFieldMask
                    value={cell.value || ""}
                    handleBlur={(hideItem) => {
                        const updatedProducts = [...products];
                        updatedProducts[row.index].hideItem = hideItem;
                        setProducts(updatedProducts);
                    }}
                />
            ),
        },
        {
            Header: "Date",
            accessor: "date",
            Cell: ({ row, cell }) => (
                <TextFieldMask
                    value={cell.value || ""}
                    handleBlur={(date) => {
                        const updatedProducts = [...products];
                        updatedProducts[row.index].date = date;
                        setProducts(updatedProducts);
                    }}
                />
            ),
        },
    ];

    return (
        <TableWrapper
            showDeleteButton
            columns={columns}
            data={products}
            onDeleteClick={onDeleteClick}
            allIds={[]}
            handleClickSelectAll={() => {}}
            handleClickSelectItem={() => {}}
        />
    );
}
