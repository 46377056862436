import http from "./Http";

const getAll = async (
    startRow = 0,
    pageSize = 10,
    fromDate = "",
    toDate = "",
    statusId = "",
    search = "",
    cid = "",
    dlid = ""
) => {
    const res = await http.get(
        `/rma?startRow=${startRow}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&statusId=${statusId}&search=${search}&cid=${cid}&dlid=${dlid}`
    );
    return res.data;
};

const get = async (id) => {
    const res = await http.get(`/rma/${id}`);
    return res.data;
};

const del = async (id) => {
    return http.delete(`/rma/${id}`);
};

const updateReceipt = async (id) => {
    return http.put(`/rma/${id}/receipt`);
};

const RmaService = {
    getAll,
    get,
    del,
    updateReceipt,
};

export default RmaService;
