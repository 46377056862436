import React from "react";
import { useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiAlert from '@material-ui/lab/Alert';

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

//libs
import { useFormik } from 'formik';
import * as yup from 'yup';

//const
export const INITIAL_VALUES = {
    billEmail: ''
};

const defValidationSchema = {
    billEmail: yup.string().required(),
}

const useStyles = makeStyles(styles);

import DealerService from "services/DealerService";
import ResetPasswordService from "services/ResetPasswordService";

export default function LoginPage() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    let dlid = query.get('dlid');
    const [baseUrl, setBaseUrl] = React.useState('');
    const [alert, setAlert] = React.useState({
        open: false,
        text: ''
    });
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    React.useEffect(() => {
        if (window)
            setBaseUrl(`${window.location.origin}/auth`);
    }, []);

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_VALUES,
        validationSchema: yup.object(defValidationSchema),
        onSubmit: async (values) => {
            if (!dlid) {
                const dealers = await DealerService.getAll('').then(d => d.data);
                const cb = d => d.dealerAdminDomain === window.location.hostname;

                if (dealers.some(cb)) {
                    dlid = dealers.find(cb).id;
                }
            }

            sendResetToken(baseUrl, dlid, values.billEmail);
        },
    });

    const sendResetToken = async (baseUrl, dealerId, billEmail) => {
        try {
            await ResetPasswordService.sendResetToken(baseUrl, dealerId, billEmail);
            setAlert({ ...alert, open: true, text: 'Message sent succesfully', severity: 'success' });
        } catch (error) {
            setAlert({ ...alert, open: true, text: 'Error sending the message ' + error.response.data.message, severity: 'error' });
        }
    }

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <form onSubmit={formik.handleSubmit}>
                        <Card login className={classes[cardAnimaton]}>
                            <CardHeader
                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                color="rose"
                            >
                                <h4 className={classes.cardTitle}>Forgot password</h4>
                            </CardHeader>
                            <CardBody>
                                {alert.open ? <MuiAlert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                                    {alert.text}
                                </MuiAlert> : null}
                                <CustomInput
                                    labelText="User"
                                    id="billEmail"
                                    name="billEmail"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        onChange: formik.handleChange,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Email className={classes.inputAdornmentIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={formik.values.billEmail}
                                    error={formik.touched.billEmail && Boolean(formik.errors.billEmail)}
                                />
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <Button type="submit" color="rose" simple size="lg" block>
                                    Reset password
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div >
    );
}
