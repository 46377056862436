import { useState } from "react";

const useShipment = () => {
    const [isShipDisabled, setIsShipDisabled] = useState(false);

    const toggleShipStatus = () => {
        setIsShipDisabled(false);
    };

    return {
        isShipDisabled,
        toggleShipStatus,
    };
};

export default useShipment;
