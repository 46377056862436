import useDealer from "hooks/useDealer";
import DealerUsers from "views/Dealers/Tabs/DealerUsers";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SelectDealer from "components/SelectDealer/SelectDealer";

export default function DealerUsersView() {
    const dealer = useDealer();

    return (
        <GridContainer>
            <GridItem xs={12}>
                <SelectDealer />
            </GridItem>
            <GridItem xs={12}>
                <DealerUsers dlid={dealer.id} />
            </GridItem>
        </GridContainer>
    );
}
