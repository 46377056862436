import http from "./Http";

const getAll = async (dealer_id, part_number) => {
    const params = {};
    if (dealer_id) {
        params.dealer_id = dealer_id;
    }
    if (part_number) {
        params.part_number = part_number;
    }

    return http.get("/editparts", { params });
};

const get = async (id) => {
    return http.get(`/editparts/${id}`);
};

const post = async (body) => {
    return http.post("/editparts", body);
};

const put = async (id, body) => {
    return http.put(`/editparts/${id}`, body);
};

const del = async (id) => {
    return http.delete(`/editparts/${id}`);
};

const EditPartService = {
    getAll,
    get,
    post,
    put,
    del,
};

export default EditPartService;
