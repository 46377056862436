import React from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export default function SkeletonTable({
    columnNames = [],
    rowCount = 5,
    noRecords = false,
    noRecordsMessage = "No records found",
}) {
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {columnNames.map((columnName, index) => (
                            <TableCell key={index}>{columnName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {noRecords ? (
                        <TableRow>
                            <TableCell colSpan={columnNames.length}>{noRecordsMessage}</TableCell>
                        </TableRow>
                    ) : (
                        [...Array(rowCount)].map((row, index) => (
                            <TableRow key={index}>
                                {columnNames.map((columnName, index) => (
                                    <TableCell key={index} component="th" scope="row">
                                        <Skeleton variant="rect" height={20} />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
