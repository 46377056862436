import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from './useSnackbar';
import useDealer from './useDealer';
import PopularModelsService from 'services/PopularModelsService';

const vehicleTitleSettingsOpt = [
    { value: 'MNO', label: 'Model Name Only' },
    { value: 'MP', label: 'Model + Parts' },
    { value: 'MA', label: 'Model + Accessories' },
    { value: 'MM', label: 'Make + Model' },
    { value: 'MMP', label: 'Make + Model + Parts' },
    { value: 'MMA', label: 'Make + Model + Accessories' },
];

const popularModelDestinationOpt = [
    { value: 'P', label: 'Parts' },
    { value: 'A', label: 'Accessories' },
];

const usePopularModels = () => {
    const dealer = useDealer();
    const [initialValues, setInitialValues] = useState({
        popularModelsTitleFormat: '',
        popularModelsHeading: '',
        popularModelsDestination: '',
        popularModelsSubtitle: '',
    });
    const [allPopularModels, setAllPopularModels] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);
    const openSnackbar = useSnackbar();
    const validationSchema = yup.object({

    });

    useEffect(() => {
        const fetchPopularModels = async (dealerId) => {
            try {
                const data = await PopularModelsService.getAll(dealerId);
                setAllPopularModels(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (dealer.id) {
            fetchPopularModels(dealer.id);

            setInitialValues({
                popularModelsTitleFormat: dealer.popularModelsTitleFormat,
                popularModelsHeading: dealer.popularModelsHeading,
                popularModelsDestination: dealer.popularModelsDestination,
                popularModelsSubtitle: dealer.popularModelsSubtitle
            });

            setSelectedModels(JSON.parse(dealer.selectedPopularModels));
        }
    }, [dealer]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updatePopularModels(values);
        },
    });

    const updatePopularModels = async (values) => {
        try {
            await PopularModelsService.put({ ...values, selectedModels, dealerId: dealer.id });
            openSnackbar('success', 'Updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating. Please try again.');
        }
    }

    const handleClickCheckbox = id => {
        if (selectedModels.includes(id)) {
            const updatedSelected = [...selectedModels].filter((selectedId) => selectedId !== id);
            setSelectedModels(updatedSelected);
        } else {
            setSelectedModels([...selectedModels, id]);
        }
    }

    return {
        selectedModels,
        vehicleTitleSettingsOpt,
        popularModelDestinationOpt,
        allPopularModels,
        formik,
        handleClickCheckbox
    }
}

export default usePopularModels;
