import useDealer from "hooks/useDealer";
import DealerScripts from "views/Dealers/Tabs/DealerScripts";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SelectDealer from "components/SelectDealer/SelectDealer";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

import { EDIT } from "const";

export default function DealerScriptsView() {
    const dealer = useDealer();

    return (
        <GridContainer>
            <GridItem xs={12}>
                <SelectDealer />
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Assignment />
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <DealerScripts dlid={dealer.id} form={EDIT} />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}