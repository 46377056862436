import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import YearSelector from "components/YearSelector/YearSelector";
import ModelSelector from "components/ModelSelector/ModelSelector";
import TrimSelector from "components/TrimSelector/TrimSelector";
import EngineSelector from "components/EngineSelector/EngineSelector";

export default function VehicleSelector(props) {
    const {
        dlid = '13',
        makeId = '1',
        selectedYear,
        handleYearChange = () => { },
        selectedModel,
        handleModelChange = () => { },
        selectedTrim,
        handleTrimChange = () => { },
        selectedEngine,
        handleEngineChange = () => { }
    } = props;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    <YearSelector
                        dlid={dlid}
                        makeId={makeId}
                        selectedYear={selectedYear}
                        handleChange={handleYearChange}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <ModelSelector
                        dlid={dlid}
                        makeId={makeId}
                        selectedYear={selectedYear}
                        selectedModel={selectedModel}
                        handleChange={handleModelChange}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TrimSelector
                        dlid={dlid}
                        makeId={makeId}
                        selectedYear={selectedYear}
                        selectedModel={selectedModel}
                        selectedTrim={selectedTrim}
                        handleChange={handleTrimChange}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <EngineSelector
                        dlid={dlid}
                        makeId={makeId}
                        selectedYear={selectedYear}
                        selectedModel={selectedModel}
                        selectedTrim={selectedTrim}
                        selectedEngine={selectedEngine}
                        handleChange={handleEngineChange}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
