import { useState } from 'react';

const useDialog = () => {
    const [open, setOpen] = useState(false);

    return {
        open,
        setOpen
    }
}

export default useDialog;