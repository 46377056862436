import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DealerShipping from 'services/DealerShipping';
import useDealer from './useDealer';
import { useSnackbar } from './useSnackbar';

const useUpsCarrier = () => {
    const openSnackbar = useSnackbar();
    const dealer = useDealer();
    const dealerId = dealer.id;
    const [initialValues, setInitialValues] = useState({
        upsCarrierEnabled: false,
        upsCarrierUserName: '',
        upsCarrierPassword: '',
        upsCarrierAccessLicenseNumber: '',
        upsCarrierShipperNumber: ''
    });

    useEffect(() => {
        if (dealer.id > 0)
            setInitialValues({
                upsCarrierEnabled: dealer.upsCarrierEnabled === 1,
                upsCarrierUserName: dealer.upsCarrierUserName,
                upsCarrierPassword: dealer.upsCarrierPassword,
                upsCarrierAccessLicenseNumber: dealer.upsCarrierAccessLicenseNumber,
                upsCarrierShipperNumber: dealer.upsCarrierShipperNumber
            });
    }, [dealer]);

    const validationSchema = yup.object({

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateUpsCarrier(values);
        },
    });

    const updateUpsCarrier = async (shippingData) => {
        try {
            await DealerShipping.putUpsCarrier({ ...shippingData, dealerId });
            openSnackbar('success', 'Ups carrier updated successfully.');
        } catch (error) {
            openSnackbar('error', 'An error occurred while updating the ups carrier. Please try again.');
        }
    }

    return {
        formik
    };
}

export default useUpsCarrier;