import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Box,
    Paper,
    Typography,
    Checkbox,
    FormControlLabel,
    IconButton,
    Tooltip,
    Divider,
    TextField,
    MenuItem,
} from "@material-ui/core/";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MailboxOptions from "./MailboxOptions";
import MessageContent from "./MessageContent";
import MessageList from "./MessageList";
import CreateMessage from "./CreateMessage";
import useCommunicationHub from "hooks/useCommunicationHub";
import useFolders from "hooks/useFolders";

const StyledBox = withStyles(() => ({
    root: {
        marginRight: 10,
    },
}))(Box);

const StyledPaper = withStyles(() => ({
    root: {
        padding: 10,
        height: "100%",
    },
}))(Paper);

function MessageSelection({
    showMoveTo = false,
    allFolders = [],
    allItemIds = [],
    selectedIds = [],
    selectedAll = false,
    onSelectAll = () => {},
    onDeleteAll = () => {},
    onMoveMessage = () => {},
}) {
    const [moveToFolder, setMoveToFolder] = useState("");

    return (
        <>
            <Box display="flex" alignItems="flex-end">
                <Box flex={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedAll}
                                onClick={() => onSelectAll(allItemIds)}
                            />
                        }
                        label="Select all"
                    />
                </Box>
                <Box>
                    <Tooltip title="Delete selected messages">
                        <IconButton onClick={onDeleteAll}>
                            <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            {showMoveTo && selectedIds.length > 0 && (
                <Box>
                    <TextField
                        select
                        size="small"
                        label="Move to:"
                        value={moveToFolder}
                        onChange={(e) => {
                            setMoveToFolder(e.target.value);
                            onMoveMessage(e.target.value);
                        }}
                        fullWidth
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {allFolders.map((folder) => (
                            <MenuItem key={folder.id} value={folder.id}>
                                {folder.colName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            )}
        </>
    );
}

export default function CommunicationHub({ orderNumber = "", notificationId = "" }) {
    const {
        messages,
        selectedFolder,
        setSelectedFolder,
        handleClickFolder,
        handleClickComposeEmail,
        selectedMessage,
        handleClickMessage,
        showCreateMail,
        handleClickReplyEmail,
        composeEmailFields,
        sendEmail,
        selectedMessages,
        selectedAll,
        handleClickSelectAll,
        handleClickSelectItem,
        handleClickDeleteAll,
        handleClickDeleteItem,
        handleCreateDraft,
        handleMoveMessageToFolder,
        markMessageAsRead,
        handleDeleteFolder,
    } = useCommunicationHub(orderNumber);

    const {
        allFolders = [],
        mainFolders = [],
        customFolders = [],
        setCustomFolders,
        handleCreateFolder,
    } = useFolders();

    const messageIds = messages.map((m) => m.id);

    useEffect(() => {
        const setMessage = async (message) => {
            await handleClickMessage(message);

            if (message.isRead === 0) {
                //await markMessageAsRead(message.id);
            }
        };

        if (messages.length > 0 && notificationId) {
            const message = messages.find((m) => m.id == notificationId);

            if (message) {
                setMessage(message);
            }
        }

        return () => {};
    }, [messages, notificationId]);

    return (
        <Box display="flex" style={{ minHeight: 650 }}>
            <StyledBox flex={2.5}>
                <StyledPaper elevation={2} square>
                    <MailboxOptions
                        mainFolders={mainFolders}
                        customFolders={customFolders}
                        setCustomFolders={setCustomFolders}
                        totalMessages={messages.length}
                        selectedFolder={selectedFolder}
                        setSelectedFolder={setSelectedFolder}
                        onCreateMail={handleClickComposeEmail}
                        handleClickFolder={handleClickFolder}
                        handleCreateFolder={handleCreateFolder}
                        handleDeleteFolder={handleDeleteFolder}
                    />
                </StyledPaper>
            </StyledBox>
            <StyledBox flex={9.5}>
                <StyledPaper elevation={2} square>
                    <Box display="flex" style={{ height: "100%" }}>
                        <StyledBox flex={4}>
                            <Box pl={2} pr={2}>
                                <Typography variant="h6" style={{ fontSize: 18, flex: 1 }}>
                                    Messages{" "}
                                    {orderNumber && orderNumber.length > 0
                                        ? ` for order #${orderNumber}`
                                        : ``}
                                </Typography>
                                <MessageSelection
                                    showMoveTo={![2, 3].includes(selectedFolder)}
                                    allFolders={allFolders}
                                    allItemIds={messageIds}
                                    selectedIds={selectedMessages}
                                    selectedAll={selectedAll}
                                    onSelectAll={handleClickSelectAll}
                                    onDeleteAll={handleClickDeleteAll}
                                    onMoveMessage={handleMoveMessageToFolder}
                                />
                            </Box>
                            <MessageList
                                messages={messages}
                                selectedMessages={selectedMessages}
                                selectedMessageId={selectedMessage.id}
                                onItemClick={handleClickMessage}
                                onToggleCheckbox={handleClickSelectItem}
                                handleClickDeleteItem={handleClickDeleteItem}
                            />
                        </StyledBox>
                        <Divider orientation="vertical" />
                        <StyledBox flex={8} pl={2}>
                            {showCreateMail && (
                                <>
                                    <CreateMessage
                                        message={composeEmailFields}
                                        onSendMail={sendEmail}
                                        onCloseMail={handleCreateDraft}
                                    />
                                </>
                            )}
                            {!showCreateMail && (
                                <MessageContent
                                    message={selectedMessage}
                                    onReplyMail={handleClickReplyEmail}
                                />
                            )}
                        </StyledBox>
                    </Box>
                </StyledPaper>
            </StyledBox>
        </Box>
    );
}
