import React from "react";
import { Link } from "@material-ui/core";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";

export default function DeleteShipping({ id, handleDeleteShipping = () => {} }) {
    const { openDialog } = useConfirmationDialog();

    const handleClick = (e) => {
        e.preventDefault();

        openDialog({
            showActions: true,
            title: "Are you sure?",
            onConfirm: () => {
                handleDeleteShipping(id);
            },
        });
    };

    return (
        <Link href="#" onClick={handleClick} style={{ display: "block" }}>
            Del
        </Link>
    );
}
