import { useEffect, useState } from "react";
import ImageLibraryService from "services/ImageLibraryService";
import useDealerId from "./useDealerId";
import { useSnackbar } from "./useSnackbar";

const useImageLibrary = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dealerId = useDealerId();
    const openSnackbar = useSnackbar();

    useEffect(() => {
        if (dealerId) {
            getAllImages({ dealerId });
        }
    }, [dealerId]);

    const getAllImages = async (params) => {
        setLoading(true);
        try {
            const response = await ImageLibraryService.getAll(params);
            setImages(response);
            setError(null);
        } catch (err) {
            setError(err);
            openSnackbar("error", "Error fetching images.");
        } finally {
            setLoading(false);
        }
    };

    const getImage = async (id) => {
        setLoading(true);
        try {
            const response = await ImageLibraryService.get(id);
            setError(null);
            return response;
        } catch (err) {
            setError(err);
            openSnackbar("error", "Error fetching image.");
        } finally {
            setLoading(false);
        }
    };

    const createImage = async (formData) => {
        formData.append("dealerId", dealerId);
        setLoading(true);
        try {
            const response = await ImageLibraryService.post(formData);
            setError(null);
            openSnackbar("success", "Image created successfully.");
        } catch (err) {
            setError(err);
            openSnackbar("error", "Error creating image.");
        } finally {
            setLoading(false);
            getAllImages({ dealerId });
        }
    };

    const updateImage = async (id, image) => {
        setLoading(true);
        try {
            const response = await ImageLibraryService.put(id, image);
            setImages(images.map((img) => (img.id === id ? response : img)));
            setError(null);
            openSnackbar("success", "Image updated successfully.");
        } catch (err) {
            setError(err);
            openSnackbar("error", "Error updating image.");
        } finally {
            setLoading(false);
        }
    };

    const deleteImage = async (id) => {
        setLoading(true);
        try {
            await ImageLibraryService.del(id);
            setImages(images.filter((img) => img.id !== id));
            setError(null);
            openSnackbar("success", "Image deleted successfully.");
        } catch (err) {
            setError(err);
            openSnackbar("error", "Error deleting image.");
        } finally {
            setLoading(false);
            getAllImages({ dealerId });
        }
    };

    const deleteMultipleImages = async (ids) => {
        setLoading(true);
        try {
            for (let id of ids) {
                await deleteImage(id, false);
            }
            setError(null);
            openSnackbar("success", "Selected images deleted successfully.");
        } catch (err) {
            setError(err);
            openSnackbar("error", "Error deleting selected images.");
        } finally {
            setLoading(false);
            getAllImages({ dealerId });
        }
    };

    return {
        images,
        loading,
        error,
        getAllImages,
        getImage,
        createImage,
        updateImage,
        deleteImage,
        deleteMultipleImages,
    };
};

export default useImageLibrary;
