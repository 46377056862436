import PageLayout from "layouts/PageLayout";
import { Box, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import GenericSelect from 'components/GenericSelect/GenericSelect';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextFieldRender from "components/TextFieldRender/TextFieldRender";
import Button from "components/CustomButtons/Button.js";
import usePopularModels from "hooks/usePopularModels";

export default function PopularModels() {
  const {
    vehicleTitleSettingsOpt = [],
    popularModelDestinationOpt = [],
    allPopularModels = [],
    selectedModels = [],
    formik,
    handleClickCheckbox
  } = usePopularModels();

  return (
    <PageLayout>
      <form onSubmit={formik.handleSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <GenericSelect
              fullWidth
              options={vehicleTitleSettingsOpt}
              label="Vehicle Title Settings"
              name="popularModelsTitleFormat"
              value={formik.values.popularModelsTitleFormat}
              onChange={formik.handleChange}
              error={formik.touched.popularModelsTitleFormat && Boolean(formik.errors.popularModelsTitleFormat)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextFieldRender
              fullWidth
              size="small"
              label="Heading"
              name="popularModelsHeading"
              value={formik.values.popularModelsHeading}
              handleBlur={(value) => formik.setFieldValue("popularModelsHeading", value)}
              error={formik.touched.popularModelsHeading && Boolean(formik.errors.popularModelsHeading)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GenericSelect
              fullWidth
              options={popularModelDestinationOpt}
              label="Popular Model Destination"
              name="popularModelsDestination"
              value={formik.values.popularModelsDestination}
              onChange={formik.handleChange}
              error={formik.touched.popularModelsDestination && Boolean(formik.errors.popularModelsDestination)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextFieldRender
              fullWidth
              size="small"
              label="Subtitle"
              name="popularModelsSubtitle"
              value={formik.values.popularModelsSubtitle}
              handleBlur={(value) => formik.setFieldValue("popularModelsSubtitle", value)}
              error={formik.touched.popularModelsSubtitle && Boolean(formik.errors.popularModelsSubtitle)}
            />
          </GridItem>
        </GridContainer>

        <Typography gutterBottom style={{ marginTop: 20, fontSize: 14, fontWeight: "bold" }}>
          Selected Popular Models
        </Typography>

        <GridContainer>
          {allPopularModels.filter(m => selectedModels.includes(m.modelId)).map((model, index) => (
            <GridItem key={index} xs={12} sm={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={selectedModels.includes(model.modelId)}
                    onChange={(e) => handleClickCheckbox(model.modelId)}
                  />
                }
                label={`${model.modelName} (${model.displayName})`}
              />
            </GridItem>
          ))}
        </GridContainer>

        <Typography gutterBottom style={{ marginTop: 20, fontSize: 14, fontWeight: "bold" }}>
          Popular Models
        </Typography>

        <div style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
          <GridContainer >
            {allPopularModels.map((model, index) => (
              <GridItem key={index} xs={12} sm={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedModels.includes(model.modelId)}
                      onChange={(e) => handleClickCheckbox(model.modelId)}
                    />
                  }
                  label={`${model.modelName} (${model.displayName})`}
                />
              </GridItem>
            ))}
          </GridContainer>
        </div>

        <Box textAlign="center" mt={5}>
          <Button type="submit" color="rose">
            Save and close
          </Button>
        </Box>
      </form>
    </PageLayout>
  )
}
