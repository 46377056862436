import http from "./Http";

const getAll = async (dlid) => {
    return http.get(`/dealer-scripts?dlid=${dlid}`);
};

const put = async (dlid, body) => {
    return http.put(`/dealer-scripts/${dlid}`, body, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
};

const DealerScriptService = {
    getAll,
    put
};

export default DealerScriptService;