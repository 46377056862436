import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';

// @material-ui/core components
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import DealerMiscService from "services/DealerMiscService";

export const EDIT_REDIRECT = {
    title: 'Edit redirect',
    successMessage: 'Google Shopping Feed has been updated successfully'
}

export const DEF_INIT_VALUES = {
    id: 0,
    enableAutomatedFeed: false,
    ftpServerName: '',
    ftpUserName: '',
    ftpPassword: ''
}

export default function CreateShopFeed({ form = EDIT_REDIRECT, initialValues, onSuccess = () => { } }) {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: initialValues.id || 0,
            enableAutomatedFeed: initialValues.enableAutomatedFeed || false,
            ftpServerName: initialValues.ftpServerName || "",
            ftpUsername: initialValues.ftpUsername || "",
            ftpPassword: initialValues.ftpPassword || ""
        },
        validationSchema: yup.object({
            ftpServerName: yup
                .string()
                .required('Required'),
            ftpUsername: yup
                .string()
                .required('Required'),
            ftpPassword: yup
                .string()
                .required('Required')
        }),
        onSubmit: (values) => {
            updateShopFeed(values);
        },
    });

    const updateShopFeed = async body => {
        try {
            const res = await DealerMiscService.putShopFeed(body.id, body);
            onSuccess({
                ...res,
                id: body.id
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={formik.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            Enable Automated Feed
                            <Switch
                                id="enableAutomatedFeed"
                                name="enableAutomatedFeed"
                                checked={formik.values.enableAutomatedFeed}
                                value={formik.values.enableAutomatedFeed}
                                onChange={formik.handleChange}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="ftpServerName"
                                name="ftpServerName"
                                label="FTP Server Name"
                                value={formik.values.ftpServerName}
                                onChange={formik.handleChange}
                                error={formik.touched.ftpServerName && Boolean(formik.errors.ftpServerName)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="ftpUsername"
                                name="ftpUsername"
                                label="FTP Username"
                                value={formik.values.ftpUsername}
                                onChange={formik.handleChange}
                                error={formik.touched.ftpUsername && Boolean(formik.errors.ftpUsername)}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="ftpPassword"
                                name="ftpPassword"
                                label="FTP Password"
                                value={formik.values.ftpPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.ftpPassword && Boolean(formik.errors.ftpPassword)}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'center', marginTop: 5 }}>
                            <Button type="submit" color="rose">Save and close</Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </GridItem>
        </GridContainer>
    );
}