import React, { useState, useEffect } from "react";

//core
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

//custom
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Notification from "components/Notification/Notification.js";
import CustomButton from "components/CustomButtons/Button.js";

//libs
import { useFormik } from "formik";
import * as yup from "yup";

import DealerMiscService from "services/DealerMiscService";
import ThemeService from "services/ThemeService";

const validationSchema = yup.object({
    headerTitle: yup.string().required("Header title is required"),
});

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

import { CREATE, EDIT } from "../Dealers";
import { Alert, AlertTitle } from "@material-ui/lab";
import { InputAdornment } from "@material-ui/core";
import useUser from "hooks/useUser";
import AddressForm from "components/AddressForm/AddressForm";
const LOGO_IMAGE = "logoImage";
const TOP_BANNER_IMAGE = "topBannerImage";
const CUSTOM_FOOTER_IMAGE = "customFooterImage";
const UPLOAD_FAV_ICON = "uploadFavIcon";

const defInitialValues = {
    vinToOrder: false,
    enableQuoteSearch: false,
    forcePhoneCheckout: false,
    headerTitle: "",
    forceVinContactPage: false,
    headerSubTitle: "",
    orderNotificationText: false,
    defaultSearchTab: "",
    hideAccesoriesMobile: false,
    sliderSearchBox: "",
    galeryModeAccesories: "",
    homeButtonLink: "",
    contactUsUrl: "",
    assetsRootUrl: "",
    assetsUploadPath: "",
    imagesBaseUrl: "",
    logoImage: null,
    topBannerImage: null,
    themeId: "",
    customFooterImage: null,
    uploadFavIcon: null,
    address1: "",
    address2: "",
    city: "",
    zip: "",
    country: "",
    state: "",
    telephone: "",
    displayOnePrice: false,
    minOrderAmount: "",
    showCore: false,
    addCoreToPricing: false,
    provideFreeShipping: false,
    freeShippingMinAmount: "",
    handlingFeeOrderTotal: "",
    handlingFeeAmountLessTotal: "",
    handlingFeeAmountGreaterTotal: "",
    handlingFeeHideFee: false,
    customFeeTitle: "",
    customFeeFixed: "",
    customFeePercent: "",
    externalDealerId: "",
    isActive: false,
    configShowBreadcrumb: false,
    configShowCategoryBorder: false,
    configShowShortcutsBorder: false,
    returnsAddressAddress1: "",
    returnsAddressAddress2: "",
    returnsAddressCity: "",
    returnsAddressZip: "",
    returnsAddressCountry: "",
    returnsAddressState: "",
    returnsAddressTelephone: "",
    coreAddressAddress1: "",
    coreAddressAddress2: "",
    coreAddressCity: "",
    coreAddressZip: "",
    coreAddressCountry: "",
    coreAddressState: "",
    coreAddressTelephone: "",
    nextOrderNumber: "",
    nextRmaNumber: "",
};

const listSearchTab = [
    {
        id: 1,
        name: "Parts",
    },
    {
        id: 2,
        name: "Accessories",
    },
];

const listSearchBox = [
    {
        id: 1,
        name: "Select a Vehicle",
    },
    {
        id: 2,
        name: "Search Part Number or VIN",
    },
    {
        id: 3,
        name: "Enter a QUOTE Number",
    },
];

export default function DealerMiscellaneous({ form, dlid }) {
    const [user] = useUser();
    const [notification, setNotification] = useState({
        open: false,
        message: "Settings updated successfully",
    });
    const [initialValues, setInitialValues] = useState(defInitialValues);
    const [themes, setThemes] = useState([]);
    const classes = useStyles();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: (values) => {
            var formData = new FormData();

            for (var key in values) {
                if (
                    ![LOGO_IMAGE, TOP_BANNER_IMAGE, CUSTOM_FOOTER_IMAGE, UPLOAD_FAV_ICON].includes(
                        key
                    )
                )
                    formData.append(key, values[key]);
            }

            if (values.logoImage) {
                if (values.logoImage.name) {
                    formData.append(LOGO_IMAGE, values.logoImage.name);
                    formData.append("files", values.logoImage);
                } else {
                    formData.append(LOGO_IMAGE, values.logoImage);
                }
            }

            if (values.topBannerImage) {
                if (values.topBannerImage.name) {
                    formData.append(TOP_BANNER_IMAGE, values.topBannerImage.name);
                    formData.append("files", values.topBannerImage);
                } else {
                    formData.append(TOP_BANNER_IMAGE, values.topBannerImage);
                }
            }

            if (values.customFooterImage) {
                if (values.customFooterImage.name) {
                    formData.append(CUSTOM_FOOTER_IMAGE, values.customFooterImage.name);
                    formData.append("files", values.customFooterImage);
                } else {
                    formData.append(CUSTOM_FOOTER_IMAGE, values.customFooterImage);
                }
            }

            if (values.uploadFavIcon) {
                if (values.uploadFavIcon.name) {
                    formData.append(UPLOAD_FAV_ICON, values.uploadFavIcon.name);
                    formData.append("files", values.uploadFavIcon);
                } else {
                    formData.append(UPLOAD_FAV_ICON, values.uploadFavIcon);
                }
            }

            update(dlid, formData);
        },
    });

    useEffect(() => {
        const getMisc = async (dlid) => {
            const misc = await DealerMiscService.getAll(dlid).then((d) =>
                d.data.length === 1 ? d.data[0] : d.data
            );
            setInitialValues(misc);
        };
        const getThemes = async () => {
            const themes = await ThemeService.getAll();
            setThemes(themes);
        };

        if (dlid > 0) {
            getMisc(dlid);
            getThemes();
        }
        if (!dlid) setInitialValues(defInitialValues);
    }, [dlid]);

    const update = async (dlid, body) => {
        try {
            await DealerMiscService.put(dlid, body);
            openNotification(true, "Dealer updated");
        } catch (error) {
            console.error(error);
        }
    };

    const openNotification = (open = notification.open, message = notification.message) =>
        setNotification({
            ...notification,
            open,
            message,
        });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Notification
                    open={notification.open}
                    message={notification.message}
                    close
                    color="success"
                    onClose={(e) => openNotification(e)}
                />

                <GridContainer>
                    <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                        <legend style={{ fontSize: 16, fontWeight: "bold" }}>
                            Manage Miscellaneous Settings
                        </legend>
                    </GridItem>
                </GridContainer>

                {user && user.isAdmin === 1 && (
                    <GridContainer>
                        <GridItem xs={12} sm={3}>
                            <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                                {formik.values.isActive ? "Active" : "Inactive"}
                            </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={2}>
                            <Switch
                                id="isActive"
                                name="isActive"
                                checked={formik.values.isActive}
                                value={formik.values.isActive}
                                onChange={formik.handleChange}
                            />
                        </GridItem>
                    </GridContainer>
                )}

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Require VIN to place order:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="vinToOrder"
                            name="vinToOrder"
                            checked={formik.values.vinToOrder}
                            value={formik.values.vinToOrder}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Enable Quote Search:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="enableQuoteSearch"
                            name="enableQuoteSearch"
                            checked={formik.values.enableQuoteSearch}
                            value={formik.values.enableQuoteSearch}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Make Phone# mandatory on Checkout:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="forcePhoneCheckout"
                            name="forcePhoneCheckout"
                            checked={formik.values.forcePhoneCheckout}
                            value={formik.values.forcePhoneCheckout}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Header title:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="headerTitle"
                            name="headerTitle"
                            value={formik.values.headerTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.headerTitle && Boolean(formik.errors.headerTitle)}
                            helperText={formik.touched.headerTitle && formik.errors.headerTitle}
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Mandatory VIN on contact page:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="forceVinContactPage"
                            name="forceVinContactPage"
                            checked={formik.values.forceVinContactPage}
                            value={formik.values.forceVinContactPage}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Header subtitle:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="headerSubTitle"
                            name="headerSubTitle"
                            value={formik.values.headerSubTitle}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.headerSubTitle &&
                                Boolean(formik.errors.headerSubTitle)
                            }
                            helperText={
                                formik.touched.headerSubTitle && formik.errors.headerSubTitle
                            }
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Send Admin Order Notification Email:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="orderNotificationText"
                            name="orderNotificationText"
                            checked={formik.values.orderNotificationText}
                            value={formik.values.orderNotificationText}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Default Tab When Searching:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormControl
                            variant="standard"
                            className={classes.formControl}
                            style={{ width: "100%" }}
                        >
                            <Select
                                id="defaultSearchTab"
                                name="defaultSearchTab"
                                value={formik.values.defaultSearchTab}
                                onChange={(e) =>
                                    formik.setFieldValue("defaultSearchTab", e.target.value)
                                }
                                fullWidth
                            >
                                {listSearchTab.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Hide Accessories Widget on Mobile:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="hideAccesoriesMobile"
                            name="hideAccesoriesMobile"
                            checked={formik.values.hideAccesoriesMobile}
                            value={formik.values.hideAccesoriesMobile}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Slider Search Box Active:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormControl
                            variant="standard"
                            className={classes.formControl}
                            style={{ width: "100%" }}
                        >
                            <Select
                                id="sliderSearchBox"
                                name="sliderSearchBox"
                                value={formik.values.sliderSearchBox}
                                onChange={(e) =>
                                    formik.setFieldValue("sliderSearchBox", e.target.value)
                                }
                                fullWidth
                            >
                                {listSearchBox.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Enable Gallery Mode on Accessories:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="galeryModeAccesories"
                            name="galeryModeAccesories"
                            checked={formik.values.galeryModeAccesories}
                            value={formik.values.galeryModeAccesories}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            External dealer id:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="externalDealerId"
                            name="externalDealerId"
                            value={formik.values.externalDealerId}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.externalDealerId &&
                                Boolean(formik.errors.externalDealerId)
                            }
                            helperText={
                                formik.touched.externalDealerId && formik.errors.externalDealerId
                            }
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Display one price:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="displayOnePrice"
                            name="displayOnePrice"
                            checked={formik.values.displayOnePrice}
                            value={formik.values.displayOnePrice}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Minimum Order Amount:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            type="number"
                            fullWidth
                            margin="dense"
                            id="minOrderAmount"
                            name="minOrderAmount"
                            value={formik.values.minOrderAmount}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.minOrderAmount &&
                                Boolean(formik.errors.minOrderAmount)
                            }
                            helperText={
                                formik.touched.minOrderAmount && formik.errors.minOrderAmount
                            }
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Show Core:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="showCore"
                            name="showCore"
                            checked={formik.values.showCore}
                            value={formik.values.showCore}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Add Core to Pricing:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="addCoreToPricing"
                            name="addCoreToPricing"
                            checked={formik.values.addCoreToPricing}
                            value={formik.values.addCoreToPricing}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Home Button Link:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="homeButtonLink"
                            name="homeButtonLink"
                            value={formik.values.homeButtonLink}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.homeButtonLink &&
                                Boolean(formik.errors.homeButtonLink)
                            }
                            helperText={
                                formik.touched.homeButtonLink && formik.errors.homeButtonLink
                            }
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Contact Us Url:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="contactUsUrl"
                            name="contactUsUrl"
                            value={formik.values.contactUsUrl}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.contactUsUrl && Boolean(formik.errors.contactUsUrl)
                            }
                            helperText={formik.touched.contactUsUrl && formik.errors.contactUsUrl}
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer style={{ display: "none" }}>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Assets Root Url:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="assetsRootUrl"
                            name="assetsRootUrl"
                            value={formik.values.assetsRootUrl}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.assetsRootUrl && Boolean(formik.errors.assetsRootUrl)
                            }
                            helperText={formik.touched.assetsRootUrl && formik.errors.assetsRootUrl}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Assets Upload Path:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="assetsUploadPath"
                            name="assetsUploadPath"
                            value={formik.values.assetsUploadPath}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.assetsUploadPath &&
                                Boolean(formik.errors.assetsUploadPath)
                            }
                            helperText={
                                formik.touched.assetsUploadPath && formik.errors.assetsUploadPath
                            }
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer style={{ display: "none" }}>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Images Base Url:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="imagesBaseUrl"
                            name="imagesBaseUrl"
                            value={formik.values.imagesBaseUrl}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.imagesBaseUrl && Boolean(formik.errors.imagesBaseUrl)
                            }
                            helperText={formik.touched.imagesBaseUrl && formik.errors.imagesBaseUrl}
                        />
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Logo Image:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3} style={{ marginTop: 8 }}>
                        {!formik.values.logoImage && (
                            <input
                                id="logoImage"
                                name="logoImage"
                                type="file"
                                onChange={(e) =>
                                    formik.setFieldValue("logoImage", e.target.files[0])
                                }
                            />
                        )}
                        {formik.values.logoImage && (
                            <Button
                                color="secondary"
                                variant="contained"
                                endIcon={<Close />}
                                size="small"
                                onClick={() => formik.setFieldValue("logoImage", null)}
                            >
                                Delete
                            </Button>
                        )}
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Top Banner Image:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3} style={{ marginTop: 8 }}>
                        {!formik.values.topBannerImage && (
                            <input
                                id="topBannerImage"
                                name="topBannerImage"
                                type="file"
                                onChange={(e) =>
                                    formik.setFieldValue("topBannerImage", e.target.files[0])
                                }
                            />
                        )}
                        {formik.values.topBannerImage && (
                            <Button
                                color="secondary"
                                variant="contained"
                                endIcon={<Close />}
                                size="small"
                                onClick={() => formik.setFieldValue("topBannerImage", null)}
                            >
                                Delete
                            </Button>
                        )}
                    </GridItem>
                </GridContainer>

                {/* */}
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Custom Footer Image:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3} style={{ marginTop: 8 }}>
                        {!formik.values.customFooterImage && (
                            <input
                                id="customFooterImage"
                                name="customFooterImage"
                                type="file"
                                onChange={(e) =>
                                    formik.setFieldValue("customFooterImage", e.target.files[0])
                                }
                            />
                        )}
                        {formik.values.customFooterImage && (
                            <Button
                                color="secondary"
                                variant="contained"
                                endIcon={<Close />}
                                size="small"
                                onClick={() => formik.setFieldValue("customFooterImage", null)}
                            >
                                Delete
                            </Button>
                        )}
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Upload Fav Icon:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3} style={{ marginTop: 8 }}>
                        {!formik.values.uploadFavIcon && (
                            <input
                                id="uploadFavIcon"
                                name="uploadFavIcon"
                                type="file"
                                onChange={(e) =>
                                    formik.setFieldValue("uploadFavIcon", e.target.files[0])
                                }
                            />
                        )}
                        {formik.values.uploadFavIcon && (
                            <Button
                                color="secondary"
                                variant="contained"
                                endIcon={<Close />}
                                size="small"
                                onClick={() => formik.setFieldValue("uploadFavIcon", null)}
                            >
                                Delete
                            </Button>
                        )}
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Theme:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormControl
                            variant="standard"
                            className={classes.formControl}
                            style={{ width: "100%" }}
                        >
                            <Select
                                id="themeId"
                                name="themeId"
                                value={formik.values.themeId}
                                onChange={(e) => formik.setFieldValue("themeId", e.target.value)}
                                fullWidth
                            >
                                {themes.map((theme) => (
                                    <MenuItem key={theme.id} value={theme.id}>
                                        {theme.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                        <legend style={{ fontSize: 16, fontWeight: "bold" }}>Template admin</legend>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Show Breadcrumb:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="configShowBreadcrumb"
                            name="configShowBreadcrumb"
                            checked={formik.values.configShowBreadcrumb}
                            value={formik.values.configShowBreadcrumb}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Apply border to categories:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="configShowCategoryBorder"
                            name="configShowCategoryBorder"
                            checked={formik.values.configShowCategoryBorder}
                            value={formik.values.configShowCategoryBorder}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Apply border to shortcuts:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <Switch
                            id="configShowShortcutsBorder"
                            name="configShowShortcutsBorder"
                            checked={formik.values.configShowShortcutsBorder}
                            value={formik.values.configShowShortcutsBorder}
                            onChange={formik.handleChange}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer style={{ marginTop: 20 }}>
                    <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                        <legend style={{ fontSize: 16, fontWeight: "bold" }}>
                            Store Information
                        </legend>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Address 1:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="address1"
                            name="address1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            error={formik.touched.address1 && Boolean(formik.errors.address1)}
                            helperText={formik.touched.address1 && formik.errors.address1}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Address 2:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="address2"
                            name="address2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            error={formik.touched.address2 && Boolean(formik.errors.address2)}
                            helperText={formik.touched.address2 && formik.errors.address2}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            City:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            ZIP:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="zip"
                            name="zip"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            error={formik.touched.zip && Boolean(formik.errors.zip)}
                            helperText={formik.touched.zip && formik.errors.zip}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Country:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="country"
                            name="country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            error={formik.touched.country && Boolean(formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            State:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="state"
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            error={formik.touched.state && Boolean(formik.errors.state)}
                            helperText={formik.touched.state && formik.errors.state}
                        />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Telephone:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="telephone"
                            name="telephone"
                            value={formik.values.telephone}
                            onChange={formik.handleChange}
                            error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                            helperText={formik.touched.telephone && formik.errors.telephone}
                        />
                    </GridItem>
                </GridContainer>

                {/* Returns Address */}
                <AddressForm
                    title={"Returns Address"}
                    classes={classes}
                    formik={formik}
                    addressFields={{
                        address1: { value: "returnsAddressAddress1" },
                        address2: { value: "returnsAddressAddress2" },
                        city: { value: "returnsAddressCity" },
                        zip: { value: "returnsAddressZip" },
                        country: { value: "returnsAddressCountry" },
                        state: { value: "returnsAddressState" },
                        telephone: { value: "returnsAddressTelephone" },
                    }}
                />

                {/* Core Address */}
                <AddressForm
                    title={"Core Address"}
                    classes={classes}
                    formik={formik}
                    addressFields={{
                        address1: { value: "coreAddressAddress1" },
                        address2: { value: "coreAddressAddress2" },
                        city: { value: "coreAddressCity" },
                        zip: { value: "coreAddressZip" },
                        country: { value: "coreAddressCountry" },
                        state: { value: "coreAddressState" },
                        telephone: { value: "coreAddressTelephone" },
                    }}
                />

                {/* Next number */}
                <GridContainer style={{ marginTop: 20 }}>
                    <GridItem xs={12} sm={12} style={{ marginLeft: 30 }}>
                        <legend style={{ fontSize: 16, fontWeight: "bold" }}>Next number</legend>
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Last Order Number:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            type="number"
                            margin="dense"
                            id="nextOrderNumber"
                            name="nextOrderNumber"
                            value={formik.values.nextOrderNumber}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.nextOrderNumber &&
                                Boolean(formik.errors.nextOrderNumber)
                            }
                            helperText={
                                formik.touched.nextOrderNumber && formik.errors.nextOrderNumber
                            }
                        />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                        <FormLabel className={classes.labelHorizontal} style={{ padding: 10 }}>
                            Last Rma Number:
                        </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={3}>
                        <TextField
                            fullWidth
                            type="number"
                            margin="dense"
                            id="nextRmaNumber"
                            name="nextRmaNumber"
                            value={formik.values.nextRmaNumber}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.nextRmaNumber && Boolean(formik.errors.nextRmaNumber)
                            }
                            helperText={formik.touched.nextRmaNumber && formik.errors.nextRmaNumber}
                        />
                    </GridItem>
                </GridContainer>

                <div style={{ textAlign: "center", margin: 10 }}>
                    <CustomButton type="submit" color="rose">
                        Save
                    </CustomButton>
                </div>
            </form>
        </>
    );
}
