var CryptoJS = require("crypto-js");

const NEXT_PUBLIC_IMAGES_PARTS = "https://www.imagesmartdealer.com/mksvg_feb_2021/mk_";

export const financial = (number) => Number.parseFloat(number).toFixed(2);

export const getPathParts = (make_slug, part) => {
    if (make_slug) {
        const makes = {
            acura: "40",
            alfa_romeo: "0",
            american_motors: "0",
            audi: "38",
            bmw: "37",
            buick: "36",
            cadillac: "35",
            chevrolet: "42",
            chrysler: "41",
            daewoo: "39",
            daihatsu: "0",
            davico: "45",
            dodge: "30",
            eagle: "29",
            fiat: "1",
            ford: "13",
            genesis: "0",
            geo: "0",
            gmc: "12",
            honda: "11",
            hummer: "17",
            hyundai: "10",
            infiniti: "9",
            isuzu: "8",
            jaguar: "7",
            jeep: "6",
            katzkin: "46",
            kia: "5",
            landrover: "4",
            lexus: "3",
            lincoln: "2",
            mazda: "14",
            mercedes_benz: "47",
            mercury: "15",
            mini: "33",
            mitsubishi: "32",
            nismo: "44",
            nissan: "16",
            oldsmobile: "28",
            peugeot: "0",
            plymouth: "27",
            pontiac: "26",
            porsche: "25",
            ram: "48",
            renault: "0",
            saab: "24",
            saturn: "23",
            scion: "22",
            smart: "0",
            sprinter: "31",
            sterling: "0",
            subaru: "21",
            suzuki: "20",
            toyota: "34",
            volkswagen: "19",
            volvo: "18",
            yugo: "0",
        };
        const make = make_slug.trim().toLowerCase().replace(/\s/g, "_");
        return `${NEXT_PUBLIC_IMAGES_PARTS}${makes[make]}/${part}.SVG`;
    }
    return "";
};

export const getUser = () => {
    try {
        const gid = localStorage.getItem("_gid");
        const bytes = CryptoJS.AES.decrypt(gid, "my-secret-key@123");
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (error) {
        return {};
    }
};

export const camelCase = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index == 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, "");
};

export function formatDate(d) {
    const date = new Date(d);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }

    return (d = mm + "/" + dd + "/" + yyyy);
}

export const isURL = (string) => {
    var regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(string);
};
