import { InputAdornment, Switch, FormControlLabel, Typography, FormControl, InputLabel, Input, Box } from '@material-ui/core';
import CustomBox from 'components/Box/Box';
import Button from "components/CustomButtons/Button.js";
import useFreeShippingForm from 'hooks/useFreeShippingForm';

export default function FreeShipping() {
    const { formik } = useFreeShippingForm();

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography gutterBottom style={{ fontSize: 16, fontWeight: 500 }}>
                Free Shipping:
            </Typography>
            <CustomBox>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            name="freeShippingEnabled"
                            checked={formik.values.freeShippingEnabled}
                            onChange={formik.handleChange}
                        />
                    }
                    label="Provide Free Shipping:"
                />
            </CustomBox>
            <CustomBox>
                <FormControl fullWidth>
                    <InputLabel>Amount above which Free shipping is given:</InputLabel>
                    <Input
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                        name="freeShippingMinAmount"
                        value={formik.values.freeShippingMinAmount}
                        onChange={formik.handleChange}
                        error={formik.touched.freeShippingMinAmount && Boolean(formik.errors.freeShippingMinAmount)}
                    />
                </FormControl>
            </CustomBox>
            <Box textAlign="center">
                <Button type="submit" color="rose">
                    Update Free Shipping
                </Button>
            </Box>
        </form>
    )
}
