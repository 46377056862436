import { useState, useEffect } from "react";
import useUser from "./useUser";
import MenuService from "services/MenuService";

//Icons
import * as Icons from "@material-ui/icons";

const arrayToTree = (arr, parent = 0) => {
    return arr
        .filter((item) => item.parentMenuId === parent)
        .map((child) => {
            const { id, icon } = child;

            return {
                ...child,
                views: arrayToTree(arr, id)
                    .sort((a, b) => a.orderMenu - b.orderMenu)
                    .map((_menu) => {
                        const { id, views = [] } = _menu;

                        return {
                            ..._menu,
                            ...(views.length > 0 && {
                                collapse: true,
                                state: `${id}Collapse`,
                            }),
                        };
                    }),
                ...(icon && {
                    icon: Icons[icon],
                }),
            };
        });
};

const useMenu = (assignedRolesOnly = false) => {
    const [user] = useUser();
    const [menu, setMenu] = useState({
        arrMenu: [],
        treeMenu: [],
    });

    useEffect(() => {
        const getMenu = async (menuOptions = []) => {
            let arrMenu = await MenuService.getAll();
            arrMenu = arrMenu.map((m) => ({ ...m, views: [] }));

            if (assignedRolesOnly)
                arrMenu = arrMenu.filter((m) => menuOptions.includes(m.id.toString()));

            arrMenu.sort((a, b) => a.orderMenu - b.orderMenu);

            const treeMenu = arrayToTree(arrMenu, 0).map((_menu) => {
                const { id, views = [] } = _menu;

                return {
                    id,
                    ..._menu,
                    ...(views.length > 0 && {
                        collapse: true,
                        state: `${id}Collapse`,
                    }),
                };
            });

            setMenu({
                arrMenu,
                treeMenu,
            });
        };

        const menuOptions = user?.menuOptions || [];
        getMenu(menuOptions);
    }, [user]);

    return menu;
};

export default useMenu;
