import PageLayout from "layouts/PageLayout";
import TableWrapper from "components/TableWrapper/TableWrapper";
import Button from "components/CustomButtons/Button.js";
import CreateTemplate from "./CreateTemplate";
import useCrud from "hooks/useCrud";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";

export default function MessageTemplate() {
    const { openDialog, closeDialog } = useConfirmationDialog();
    const { data = [], createEntity, updateEntity, deleteEntity } = useCrud("templates");

    const columns = [
        {
            Header: "Template type",
            accessor: "type",
        },
        {
            Header: "Template name",
            accessor: "name",
        },
        {
            Header: "Description",
            accessor: "description",
        },
    ];

    return (
        <PageLayout
            customComponent={
                <Button
                    color="primary"
                    onClick={() => {
                        openDialog({
                            formFrameTitle: "Create template",
                            fullWidth: true,
                            maxWidth: "sm",
                            message: (
                                <CreateTemplate
                                    createEntity={createEntity}
                                    onSuccess={closeDialog}
                                />
                            ),
                        });
                    }}
                >
                    Create template
                </Button>
            }
        >
            <TableWrapper
                showEditButton
                showDeleteButton
                columns={columns}
                data={data}
                onEditClick={(initialValues) => {
                    openDialog({
                        formFrameTitle: "Edit template",
                        fullWidth: true,
                        maxWidth: "sm",
                        message: (
                            <CreateTemplate
                                initialValues={initialValues}
                                updateEntity={updateEntity}
                                onSuccess={closeDialog}
                            />
                        ),
                    });
                }}
                onDeleteClick={deleteEntity}
            />
        </PageLayout>
    );
}
