import { Link, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "components/CustomButtons/Button.js";
import Box from "components/Box/Box";
import { useConfirmationDialog } from "hooks/useConfirmationDialog";

const initialValues = {
    password: "",
    repeatPassword: "",
};

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(4, "Password must be at least 4 characters"),
    repeatPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords do not match")
        .required("Repeat password is required"),
});

function ChangePasswordForm({ onSuccess = () => {} }) {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            onSuccess(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Box xs={12} sm={12} md={12}>
                    <TextField
                        fullWidth
                        type="password"
                        name="password"
                        label="Enter new password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                </Box>
                <Box xs={12} sm={12} md={12} mt={2}>
                    <TextField
                        fullWidth
                        type="password"
                        name="repeatPassword"
                        label="Repeat new password"
                        value={formik.values.repeatPassword}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)
                        }
                        helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                    />
                </Box>
            </Box>
            <div style={{ textAlign: "center", margin: 10 }}>
                <Button type="submit" color="rose">
                    Change password
                </Button>
            </div>
        </form>
    );
}

export default function PasswordChangeDialog({ onSuccess = () => {} }) {
    const { openDialog, closeDialog } = useConfirmationDialog();

    const handleClick = (e) => {
        e.preventDefault();

        openDialog({
            formFrameTitle: "Change password",
            fullWidth: true,
            maxWidth: "sm",
            onConfirm: () => {
                handleClick();
            },
            message: (
                <ChangePasswordForm
                    onSuccess={(values) => {
                        closeDialog();
                        onSuccess(values);
                    }}
                />
            ),
        });
    };

    return (
        <Link href="#" onClick={handleClick}>
            Change Password
        </Link>
    );
}
