import React, { useState, useEffect } from "react";
import useDealer from "hooks/useDealer";

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import SelectDealer from "components/SelectDealer/SelectDealer";
import Button from "components/CustomButtons/Button.js";
import AreYouSure from "components/AreYouSure/AreYouSure";
import Modal from "components/Modal/Modal.js";
import Notification from "components/Notification/Notification.js";

import { Checkbox } from "@material-ui/core";
import ReactTable from "components/ReactTable/ReactTable.js";
import CreateRedirect from "components/CreateRedirect/CreateRedirect";
import ImportRedFile from "components/ImportRedFile/ImportRedFile";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);
import DealerRedirectService from "services/DealerRedirectService";

import { CREATE_REDIRECT, EDIT_REDIRECT } from "components/CreateRedirect/CreateRedirect";
import { UPLOAD_REDIRECT } from "components/ImportRedFile/ImportRedFile";
import DeleteButton from "components/DeleteButton/DeleteButton";

export default function DealerRedirects() {
    const classes = useStyles();
    const dealer = useDealer();
    const [redirects, setRedirects] = useState([]);
    const [selected, setSelected] = useState([]);
    const [form, setForm] = useState(CREATE_REDIRECT);
    const [openModal, setOpenModal] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        color: "success",
    });

    useEffect(() => {
        const getRedirects = async (dlid) => {
            const redirects = await DealerRedirectService.getAll(dlid);
            setRedirects(redirects);
        };
        getRedirects(dealer.id);
    }, [dealer.id]);

    const handleClickCheckbox = (id) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((s) => s != id));
        } else {
            setSelected([...selected, id]);
        }
    };

    const columns = [
        /*{
            Header: "Id",
            Cell: ({ row: { original } }) => {
                return <>
                    <div style={{ textAlign: 'left' }}>
                        <Checkbox
                            tabIndex={-1}
                            checked={selected.includes(original.id)}
                            onClick={(e) => handleClickCheckbox(original.id)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                            }}
                        />
                    </div>
                </>
            }
        },*/
        {
            Header: "Source URL",
            accessor: "sourceUrl",
        },
        {
            Header: "Target URL",
            accessor: "targetUrl",
        },
        {
            Header: "Created at",
            accessor: "createdAt",
        },
        {
            Header: "Updated at",
            accessor: "updatedAt",
        },
        {
            Header: () => <div style={{ textAlign: "center" }}>Edit/Delete</div>,
            accessor: "actions",
            Cell: ({ row: { original } }) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <Button
                            justIcon
                            round
                            simple
                            color="warning"
                            className="edit"
                            title="Edit"
                            onClick={() => {
                                setForm(EDIT_REDIRECT);
                                setOpenModal(true);
                                setInitialValues(original);
                            }}
                        >
                            <Edit />
                        </Button>
                        {/*<Button
                            justIcon
                            round
                            simple
                            color="danger"
                            className="edit"
                            title="Delete"
                            onClick={() => {
                                setInitialValues(original);
                                setOpen(true);
                            }}
                        >
                            <Close />
                        </Button>*/}
                        <DeleteButton
                            title="redirect"
                            handleClick={async () => {
                                await DealerRedirectService.del(original.id);
                                setRedirects([...redirects].filter((c) => c.id !== original.id));
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const onSuccess = (redirect) => {
        if (form === CREATE_REDIRECT) {
            setRedirects([...redirects, redirect]);
        }

        if (form === EDIT_REDIRECT) {
            const nRedirects = redirects.map((c) =>
                c.id === redirect.id ? { ...c, ...redirect } : c
            );
            setRedirects(nRedirects);
        }

        setInitialValues({});
        setOpenModal(false);
        setNotification({
            ...notification,
            open: true,
            message: form.successMessage,
        });
    };

    return (
        <>
            {notification.open && (
                <Notification
                    open={notification.open}
                    color={notification.color}
                    message={notification.message}
                    onClose={() =>
                        setNotification({
                            ...notification,
                            open: false,
                            message: form.successMessage,
                        })
                    }
                />
            )}

            <Modal
                open={openModal}
                modalTitle={form.title}
                handleClose={() => setOpenModal(!openModal)}
            >
                <div style={{ minWidth: 800 }}></div>
                <CreateRedirect
                    form={form}
                    initialValues={initialValues}
                    onSuccess={(redirect) => {
                        onSuccess(redirect);
                    }}
                />
            </Modal>

            <GridContainer>
                <GridItem xs={12}>
                    <SelectDealer />
                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <Button
                                color="primary"
                                onClick={() => {
                                    setForm(CREATE_REDIRECT);
                                    setOpenModal(true);
                                    setInitialValues({
                                        dealerId: dealer.id,
                                    });
                                }}
                            >
                                Create redirect
                            </Button>
                            <ImportRedFile
                                dealerId={dealer.id}
                                onImport={async () => {
                                    setForm(UPLOAD_REDIRECT);
                                    setNotification({
                                        ...notification,
                                        open: true,
                                        message: form.successMessage,
                                    });
                                    const redirects = await DealerRedirectService.getAll(dealer.id);
                                    setRedirects(redirects);
                                }}
                            />
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={columns}
                                data={redirects}
                                showPagination={true}
                                showSorting={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <AreYouSure
                open={open}
                title={`Are you sure you?`}
                subtitle={`The record will be deleted`}
                onAccept={async () => {
                    await DealerRedirectService.del(initialValues.id);
                    setRedirects([...redirects].filter((c) => c.id !== initialValues.id));
                    setOpen(false);
                }}
                onCancel={() => setOpen(false)}
            />
        </>
    );
}
