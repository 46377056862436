import React from "react";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    ListItemIcon,
    Checkbox,
    IconButton,
} from "@material-ui/core/";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const removeHtmlTagsAndTruncate = (text, maxLength = 100) => {
    const plainText = text.replace(/<[^>]*>?/gm, "");
    if (plainText.length <= maxLength) return plainText;
    return plainText.substr(0, maxLength) + " ...";
};

export default function MessageList({
    messages = [],
    selectedMessages = [],
    selectedMessageId = "",
    onItemClick = () => {},
    onToggleCheckbox = () => {},
    handleClickDeleteItem = () => {},
}) {
    if (messages.length === 0)
        return (
            <Box
                ml={2}
                mr={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
            >
                <Box>
                    <Box style={{ textAlign: "center" }}>
                        <img
                            src="/comment_note_regular_icon_205440.png"
                            style={{
                                width: "50px",
                                height: "50px",
                                opacity: 0.25,
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography color="textSecondary" gutterBottom>
                            Your message box is empty
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );

    return (
        <List component="nav">
            {messages.map((message) => {
                const labelId = `checkbox-list-label-${message.id}`;
                const [date] = message.createdAtParsed.split(" ");
                const [_, month, day] = date.split("-");

                return (
                    <React.Fragment key={message.id}>
                        <ListItem
                            button
                            alignItems="flex-start"
                            selected={selectedMessageId === message.id}
                            onClick={() => onItemClick(message)}
                        >
                            <ListItemIcon style={{ minWidth: 30 }}>
                                <Checkbox
                                    edge="start"
                                    checked={selectedMessages.includes(message.id)}
                                    onClick={() => onToggleCheckbox(message.id)}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                style={{
                                    margin: 0,
                                }}
                                primary={
                                    <Box display="flex" alignItems="center">
                                        <Typography style={{ flex: 1 }}>
                                            {message.subject}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {month}-{day}
                                        </Typography>
                                    </Box>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {message.fromName}
                                        </Typography>
                                        <Typography
                                            component={"span"}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span
                                                style={{ display: "block", flex: 1, fontSize: 14 }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: removeHtmlTagsAndTruncate(
                                                            message.message
                                                        ),
                                                    }}
                                                />
                                            </span>
                                            <IconButton
                                                onClick={() => handleClickDeleteItem(message.id)}
                                            >
                                                <DeleteOutlineIcon fontSize="small" />
                                            </IconButton>
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                );
            })}
        </List>
    );
}
