import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import SaveButton from "components/SaveButton/SaveButton";

export default function CreateFolder({ handleCreateFolder = () => {} }) {
    const [folderName, setFolderName] = useState("");

    return (
        <>
            <TextField
                fullWidth
                id="standard-basic"
                label="Folder name"
                value={folderName}
                onChange={(e) => {
                    setFolderName(e.target.value);
                }}
            />
            <SaveButton
                disabled={folderName.length === 0}
                onClick={() => handleCreateFolder(folderName)}
            />
        </>
    );
}
