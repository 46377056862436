import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const getAll = async (
    startRow = 0,
    pageSize = 10,
    fromDate = "",
    toDate = "",
    statusId = "",
    search = "",
    cid = "",
    dlid = ""
) => {
    return http.get(
        `/orders?startRow=${startRow}&pageSize=${pageSize}&fromDate=${fromDate}&toDate=${toDate}&statusId=${statusId}&search=${search}&cid=${cid}&dlid=${dlid}`
    );
};

const get = async (id) => {
    const res = await http.get(`/orders/${id}`);
    return res.data;
};

const put = async (id, body) => {
    const res = await http.put(`/orders/${id}`, body);
    return res.data;
};

const del = async (id, body) => {
    const res = await http.delete(`/orders/${id}`, body);
    return res.data;
};

const delProduct = async (id, body) => {
    const res = await http.delete(`/orders/products/${id}`, body);
    return res.data;
};

const putOrderStatus = async (selected = [], newStatusId = 0) => {
    return http.put(`/orders-status`, {
        selected,
        newStatusId,
    });
};

const lockOrder = async (orderId = "") => {
    return http.put(`/orders/${orderId}/lock`).then((r) => r.data);
};

const unlockOrder = async (orderId = "") => {
    return http.put(`/orders/${orderId}/unlock`).then((r) => r.data);
};

const shipOrder = async (orderId = "", body) => {
    return http
        .put(`/orders/${orderId}/ship`, {
            details: body,
        })
        .then((r) => r.data);
};

const updateOrderStatus = async (body) => {
    return http.put(`/update-order-status`, body);
};

const getOrderSummary = async (dealerId = "", orderAmount = "", stateCode = "") => {
    return http.get(
        `${BASE_URL_API_ECOMMERCE}/order-summary?dealerId=${dealerId}&orderAmount=${orderAmount}&stateCode=${stateCode}`
    );
};

const OrderService = {
    getAll,
    get,
    put,
    del,
    delProduct,
    putOrderStatus,
    lockOrder,
    unlockOrder,
    shipOrder,
    updateOrderStatus,
    getOrderSummary,
};

export default OrderService;
