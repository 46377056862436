import http from "./Http";

const getAll = async () => {
    return http.get(`/order-status`);
};

const OrderStatusService = {
    getAll,
};

export default OrderStatusService;