import { useEffect, useState } from "react";
import usePagination from "./usePagination";
import useDealerId from "./useDealerId";
import CustomerService from "services/CustomerService";

const useCustomerSelection = () => {
    const dealerId = useDealerId();
    const [search, setSearch] = useState();
    const [customers, setCustomers] = useState({
        totalRecords: 0,
        data: [],
    });
    const { pagination, changePagination, pageCount } = usePagination(
        1,
        10,
        customers.totalRecords
    );

    useEffect(() => {
        const fetchCustomer = async () => {
            try {
                const response = await CustomerService.getAllV2(
                    pagination.page,
                    pagination.pageSize,
                    search,
                    dealerId
                );
                setCustomers(response.data);
            } catch (err) {}
        };
        fetchCustomer();
    }, [pagination.page, pagination.pageSize, search, dealerId]);

    return {
        search,
        setSearch,
        customers,
        setCustomers,
        pagination,
        changePagination,
        pageCount,
    };
};

export default useCustomerSelection;
