import { useState, useEffect } from "react";
import { getUser } from "helpers";

const useAdminUserInfo = () => {
    const [user, setUser] = useState({
        completeName: "",
    });

    useEffect(() => {
        const usr = getUser();
        setUser({
            ...usr,
            adminUserName: `${usr.firstName} ${usr.lastName}`,
        });
    }, []);

    return user;
};

export default useAdminUserInfo;
