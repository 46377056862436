import { withStyles } from "@material-ui/core/styles";
import {
    Box,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Modal from "components/Modal/Modal";
import HtmlEditor from "components/HtmlEditor/HtmlEditor";
import EditButton from "components/EditButton/EditButton";
import GenericSelect from "components/GenericSelect/GenericSelect";
import CustomTabs from "components/Tabs/Tabs";
import useModal from "hooks/useModal";
import useCustomPageForm from "hooks/useCustomPageForm";
import useDealer from "hooks/useDealer";

const StyledBox = withStyles((theme) => ({
    root: {
        marginBottom: 10,
    },
}))(Box);

const options = [
    { value: "N", label: "None" },
    { value: "HF", label: "Show in Header & Footer" },
    { value: "H", label: "Show in Header Only" },
    { value: "F", label: "Show in Footer Only" },
];

function CustomPage({ locale = "en", formik }) {
    return (
        <>
            <StyledBox>
                <TextField
                    fullWidth
                    label="Title"
                    name={`${locale}Title`}
                    value={formik.values[`${locale}Title`] || ""}
                    onChange={formik.handleChange}
                    error={
                        formik.touched[`${locale}Title`] && Boolean(formik.errors[`${locale}Title`])
                    }
                />
            </StyledBox>
            <StyledBox>
                <TextField
                    fullWidth
                    label="Meta title"
                    name={`${locale}MetaTitle`}
                    value={formik.values[`${locale}MetaTitle`] || ""}
                    onChange={formik.handleChange}
                    error={
                        formik.touched[`${locale}MetaTitle`] &&
                        Boolean(formik.errors[`${locale}MetaTitle`])
                    }
                />
            </StyledBox>
            <StyledBox>
                <TextField
                    fullWidth
                    label="Meta keyword"
                    name={`${locale}MetaKeyword`}
                    value={formik.values[`${locale}MetaKeyword`] || ""}
                    onChange={formik.handleChange}
                    error={
                        formik.touched[`${locale}MetaKeyword`] &&
                        Boolean(formik.errors[`${locale}MetaKeyword`])
                    }
                />
            </StyledBox>
            <StyledBox>
                <TextField
                    fullWidth
                    label="Meta description"
                    name={`${locale}MetaDescription`}
                    value={formik.values[`${locale}MetaDescription`] || ""}
                    onChange={formik.handleChange}
                    error={
                        formik.touched[`${locale}MetaDescription`] &&
                        Boolean(formik.errors[`${locale}MetaDescription`])
                    }
                />
            </StyledBox>
            <StyledBox>
                <Typography variant="subtitle2" gutterBottom style={{ textTransform: "none" }}>
                    Page content
                </Typography>
                <HtmlEditor
                    dlid="0"
                    height={200}
                    title=""
                    data={formik.values[`${locale}PageContent`] || ""}
                    onBlur={(value) => formik.setFieldValue(`${locale}PageContent`, value)}
                    error={true}
                />
            </StyledBox>
        </>
    );
}

export default function CreateCustomPage({
    isEditForm = false,
    defaultValues,
    onRegistrationComplete = () => {},
}) {
    const onSubmit = async () => {
        handleCloseModal();
        onRegistrationComplete();
    };

    const { open, handleOpenModal, handleCloseModal } = useModal();
    const { formik } = useCustomPageForm(isEditForm, defaultValues, onSubmit);
    const { dealerDomain = "" } = useDealer();
    const modalTitle = isEditForm ? "Edit custom page" : "Create custom page";

    return (
        <>
            {isEditForm ? (
                <EditButton handleClick={handleOpenModal} />
            ) : (
                <Button color="primary" onClick={handleOpenModal}>
                    Add custom page
                </Button>
            )}

            {open ? (
                <Modal
                    fullWidth
                    maxWidth="md"
                    open={open}
                    modalTitle={modalTitle}
                    handleClose={handleCloseModal}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Paper style={{ padding: 15, marginBottom: 15 }}>
                            <CustomTabs
                                tabs={[
                                    {
                                        label: "EN",
                                        content: (
                                            <Box mt={2}>
                                                <CustomPage locale="en" formik={formik} />
                                            </Box>
                                        ),
                                    },
                                    {
                                        label: "ES",
                                        content: (
                                            <Box mt={2}>
                                                <CustomPage locale="es" formik={formik} />
                                            </Box>
                                        ),
                                    },
                                    {
                                        label: "PT",
                                        content: (
                                            <Box mt={2}>
                                                <CustomPage locale="pt" formik={formik} />
                                            </Box>
                                        ),
                                    },
                                    {
                                        label: "FR",
                                        content: (
                                            <Box mt={2}>
                                                <CustomPage locale="fr" formik={formik} />
                                            </Box>
                                        ),
                                    },
                                ]}
                                onTabChange={() => {}}
                            />
                        </Paper>
                        {/*<StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="Title"
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="Meta title"
                                name="metaTitle"
                                value={formik.values.metaTitle}
                                onChange={formik.handleChange}
                                error={formik.touched.metaTitle && Boolean(formik.errors.metaTitle)}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="Meta keyword"
                                name="metaKeyword"
                                value={formik.values.metaKeyword}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.metaKeyword && Boolean(formik.errors.metaKeyword)
                                }
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                size="small"
                                label="Meta description"
                                name="metaDescription"
                                value={formik.values.metaDescription}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.metaDescription &&
                                    Boolean(formik.errors.metaDescription)
                                }
                            />
                        </StyledBox>
                        <StyledBox>
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                style={{ textTransform: "none" }}
                            >
                                Page content
                            </Typography>
                            <HtmlEditor
                                dlid="0"
                                height={200}
                                title=""
                                data={formik.values.pageContent}
                                onBlur={(value) => formik.setFieldValue("pageContent", value)}
                                error={true}
                            />
                        </StyledBox>*/}
                        <StyledBox>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isActive"
                                        color="primary"
                                        checked={formik.values.isActive}
                                        onChange={formik.handleChange}
                                    />
                                }
                                label="Active"
                            />
                        </StyledBox>
                        <StyledBox>
                            <GenericSelect
                                name="pagePosition"
                                label="Page position"
                                value={formik.values.pagePosition}
                                onChange={formik.handleChange}
                                options={options}
                                style={{ width: 200 }}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                label="Menu title"
                                name="menuTitle"
                                value={formik.values.menuTitle}
                                onChange={formik.handleChange}
                                error={formik.touched.menuTitle && Boolean(formik.errors.menuTitle)}
                            />
                        </StyledBox>
                        <StyledBox>
                            <TextField
                                fullWidth
                                label="Page URL"
                                name="pageUrl"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {dealerDomain}/en/page/
                                        </InputAdornment>
                                    ),
                                }}
                                value={formik.values.pageUrl}
                                onChange={formik.handleChange}
                                error={formik.touched.pageUrl && Boolean(formik.errors.pageUrl)}
                            />
                        </StyledBox>
                        <Box textAlign="center">
                            <Button type="submit" color="rose">
                                Save and close
                            </Button>
                        </Box>
                    </form>
                </Modal>
            ) : null}
        </>
    );
}
