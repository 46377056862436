import { withStyles } from '@material-ui/core/styles';
import { Box as BoxMUI } from '@material-ui/core';

const StyledBox = withStyles((theme) => ({
    root: {
        marginBottom: 5
    },
}))(BoxMUI);

export default function Box({ children, ...otherProps }) {
    return <StyledBox {...otherProps}>{children}</StyledBox>;
}
