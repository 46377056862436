import { useEffect, useState } from "react";
import useDealerId from "./useDealerId";
import useCrud from "./useCrud";

const useFolders = () => {
    const [mainFolders, setMainFolders] = useState([]);
    const [customFolders, setCustomFolders] = useState([]);

    const dealerId = useDealerId();
    const { readAllEntities, createEntity } = useCrud("multi_table", {}, false);
    const queryParams = {
        dealer_id: dealerId,
        table_name: "dealer_message_folders",
    };
    /*const queryParams = {
        dealer_id: dealerId,
        table_name: "dealer_message_folders",
    };*/

    /*const { data: mainFolders = [] } = useCrud("multi_table", {
        table_name: "message_folders",
    });

    const { data: customFolders = [], readAllEntities, createEntity } = useCrud(
        "multi_table",
        queryParams
    );*/

    useEffect(() => {
        const fetchMainFolders = async () => {
            const data = await readAllEntities({
                table_name: "message_folders",
            });

            setMainFolders(data);
        };

        const fetchCustomFolders = async () => {
            const data = await readAllEntities({
                table_name: "multi_table",
                dealer_id: dealerId,
                table_name: "dealer_message_folders",
            });

            setCustomFolders(data);
        };

        if (dealerId) {
            fetchMainFolders();
            fetchCustomFolders();
        }

        return () => {};
    }, [dealerId]);

    const fetchFolders = async () => {
        const data = await readAllEntities(queryParams);
        setCustomFolders(data);
    };

    const handleCreateFolder = async (folderName = "") => {
        const communicationEntity = {
            dealer_id: dealerId,
            table_name: "dealer_message_folders",
            col_name: folderName,
        };

        await createEntity(communicationEntity, fetchFolders);
    };

    return {
        allFolders: [...mainFolders, ...customFolders],
        mainFolders,
        customFolders,
        setCustomFolders,
        handleCreateFolder,
    };
};

export default useFolders;
